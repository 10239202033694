import React, { ChangeEvent, FormEvent, MouseEvent } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, withRouter, RouteComponentProps } from "react-router-dom";
import './App.css';
import { optionalCallExpression } from '@babel/types';
import { any } from 'prop-types';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import classes from '*.module.css';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import "react-tabs/style/react-tabs.css";
//import ImageUploader from 'react-images-upload';
import { render } from 'react-dom';
import {FeedbackObject, create_empty_FeedbackObject, Question, create_empty_question, create_empty_formvalues, 
	InputRadio, FieldType, Report, PupilData, PupilNames, QuestionData, Attempt, 
	ClassAssignmentData, User, MarkerType, PageName, ButtonColour, Worksheet, 
	create_empty_worksheet, WorksheetType, PupilWsStats, PupilAnswers, Cohort, 
	AssignmentSortType, Assignment, PupilWSData, PupilWssStats, PupilQuestionStats} from './my_interfaces';
import {getFeedback, loggy, getpercentagescore, symbolPanelSymbolsShared, 
	parsePartsValues, getattemptmanscore, formatcheckboxanswer, formatnumericalanswer,
	 formatselfmarkanswer, formatlongtextanswer, changeAttemptScore, elementsAreNotTrue, 
	 updateManscore, copy, stringarraytolist, calcScores, classdatatocsv, 
	 checkPassStrength,
	 toCamelCase} from './shared_functions'; //ishintavailable
import update from 'immutability-helper'; //https://github.com/kolodny/immutability-helper
import { Topic, topic_table, getexercisestree } from './topics';
import { HighlightSpanKind } from 'typescript';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import { Stats } from 'fs';
import { SEND_ICON , MINIMISED_KEY } from './constants/images';
import { MenuItem, Select, Button as MuiButton} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { grey } from '@mui/material/colors';
import { History } from 'history';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import {Theme } from '@mui/material';
import WorksheetViewer from './components/elearning/WorksheetViewer'
import ClassHomePageNew from './components/elearning/TeacherTools/ClassHomePage/'
import { ClassPageHeader, getPupilNumber } from './components/elearning/TeacherTools/ClassHomePage/ClassPageHeader';
import { promoteUserFromClass, removeUserFromClass, resetPassword, unlockUser } from './components/elearning/TeacherTools/UserManagerList/UserManagerFunctions';


// Create a styled button using the `styled` API
const MyStyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
	color: '#fff',
	padding: '8px 22px',//theme.spacing(2),
	'&:hover': {
	  backgroundColor: theme.palette.primary.dark,
	},
	fontSize: 16,
	letterSpacing: 0.8,
	textTransform: "none",
	marginLeft: theme.spacing(2),
	fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  }));

  const MyStyledLink = styled(Link)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	color: '#fff',
	//padding: theme.spacing(2),
	padding: '8px 22px',
	'&:hover': {
	  backgroundColor: theme.palette.primary.dark,
	  borderColor: theme.palette.primary.dark,
	},
	fontSize: 16,
	fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
	letterSpacing: 0.8,
	textTransform: "none",
	marginLeft: theme.spacing(2),
	textDecoration: 'none', // Remove underline
	display: 'inline-flex', // To align with buttons
	alignItems: 'center', // Vertically center content
	border: '1px solid', // To mimic outlined button
	borderColor: theme.palette.primary.main,
	borderRadius: theme.shape.borderRadius,
  }));
  
  

// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'; //check that having this new module and material-ui doesn't cause conflict

//import "https://cdnjs.cloudflare.com/ajax/libs/react/15.3.1/react-dom.js";

//Create an object that is a list of all of the things in here. Export the constant.
//Then I can hopefully use the object to import everything else that is in here.

export async function fetchquestionsbyID(q_ids: Number[]): Promise<Question[]> {
	let response = await fetch("/questionsbyID",{
		method:'POST',
		body:JSON.stringify({Q_IDs:q_ids}),
		headers:{'Content-Type':'application/json'}
	});
	let questions = await response.json();
	console.log("All questions returned: "+questions.every((e:Question)=>e));
	try{if(!questions.every((e:Question)=>e)){throw "At least one of the questions returned as null"}}
	//throwing this error just stops stuff
	catch{questions = questions.map((q:Question)=>q!=null?q:create_empty_question());}
	console.log("All questions returned: "+questions.every((e:Question)=>e));
	return questions;
}
export function modifyArray(array:any[],index:number,action:string){
	console.log("modifyArray called with array: "+JSON.stringify(array,null,4)+" index: "+index+" and action: "+action);
	if(!(index>-1)){
		console.log("modifyArray index !>-1");//TODO should probably throw an erro
	}
	if(action==="remove"||action==="delete"){
		console.log("splicing out element");
		array.splice(index,1);
	}else if(action==="moveup"){
		if(index===0){
			console.log("can't move top item up one");
		//	throw "can't move top item up one"
		}
		else{
			console.log("moving element up");
			let chosenrow = array[index];
			array[index]=array[index-1];
			array[index-1]=chosenrow;
		}
	}else if(action==="movedown"){
		if(index===(array.length-1)){
			console.log("can't move bottom item down one");
			//	throw "can't move bottom item down one"
		}
		else{
			console.log("moving element down");
			let chosenrow = array[index];
			array[index]=array[index+1];
			array[index+1]=chosenrow;	
		}
	} else if(action==="copy"){
		console.log("copying element");
		if(typeof array[index]==="object"&&array[index]!==null){
			console.log("the thing to be copied is an object");
			array.push(JSON.parse(JSON.stringify(array[index]))); //if it is an object, we need to object assign TODO - this may still be shallow :-s
		} else {
			console.log("the thing to be copied is not an object");
			array.push(array[index]);	//if it is anything else just copy it
		}
	}
	else throw "action for modifyArray not recognised";
	console.log("new array: "+JSON.stringify(array,null,4));
	return array;
}
async function fetchClassesImin(): Promise<string[]>{
	console.log("fetching classes");
	let response = await fetch("/API/classesImIn");
	return response.json()
}
async function fetchmyworksheets(): Promise<Worksheet[]> {//gets all worksheets from the server
	let response = await fetch("/worksheetsassignedtome");
	let worksheets = await response.json();
	return worksheets;
}
async function fetchpublicworksheets(): Promise<Worksheet[]> {//gets all worksheets from the server
	let response = await fetch("/allworksheets");
	let worksheets:Worksheet[] = await response.json();
	worksheets = worksheets.filter((w:Worksheet)=>w.info.status==="public");
	return worksheets;
}
export async function fetchPupilsWssStats(params?:{//U_IDs:number[],
	//W_IDs:number[],
	C_ID:number}): Promise<PupilWssStats[]> {
	console.log("fetchPupilsWssStats called with params"+JSON.stringify(params));
	if(params===undefined){
		let response = await fetch("/getpupilswssstats",{method:"POST"});
		let pupilswssstats:PupilWssStats[] = await response.json();
		return pupilswssstats;
	} else if (params.C_ID!==undefined) {
		let data = {
			C_ID:params.C_ID,
			//U_IDs:params.U_IDs,
			//W_IDs:params.W_IDs,
		};
		let response = await fetch("/getpupilswssstats",{
			method: "POST",
			body: JSON.stringify(data),
			headers:{'Content-Type': 'application/json'	}
		});
		let pupilswssstats:PupilWssStats[] = await response.json();
		return pupilswssstats
	} else {
		throw "incorrect params for fetchPupilsWssStats()"
	}
}
async function get_previous_answers_by_Q_IDs(U_IDs:Number[], Q_IDs: Number[], W_ID:number): Promise<PupilAnswers[]> {
	console.log('FETCH ANS');
	var url = '/fetch_previous_answers_by_Q_IDs';
	var data = {
		U_IDs:U_IDs,
		Q_IDs:Q_IDs,
		W_ID:Number(W_ID),
	};
	console.log("FETCH ANS get_previous_answers_by_Q_IDs with data: "+JSON.stringify(data));
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let pupilsanswers:PupilAnswers[] = await response.json();
	console.log(pupilsanswers);
	//console.log("FETCH ANS got: "+JSON.stringify(pupilsanswers));
	try{
		if(U_IDs.length===0){
			if(pupilsanswers.length!==1){
				console.log("FETCH ANS expected one pupils' answers back, instead got: "+pupilsanswers.length);
				throw "expected one pupils' answers back, instead got: "+pupilsanswers.length;//check that pupilsanswers.length===1}//different logical conditions in here.
			}else{
				console.log("FETCH ANS success: expected one pupils answer back and got one :-)");
				return pupilsanswers;
			};
		} else if(U_IDs.length>0){//check that pupilsanswers.length===U_IDs.length}
			if(U_IDs.length===pupilsanswers.length){
				console.log("FETCH ANS successfully expected and got "+U_IDs.length+" pupils' answers");
				return pupilsanswers;
			} else {
				console.log("FETCH ANS expected "+U_IDs.length+" pupils' answers back, instead got: "+pupilsanswers.length);
				throw "number of answers returned does not match expected";
			}
		} else {
			console.log("FETCH ANS error with number U_IDs requested, U_IDs: "+U_IDs);
			throw "error with number U_IDs requested";
		}
	}
	catch(error){
		console.log("FETCH ANS an error occured" + error);
		throw "one of the answers was not returned"
	};
}
async function fetchPupilAssignmentData(Worksheet_ID:number):Promise<ClassAssignmentData>{//[Worksheet,ClassAssignmentData]>{
	let response = await fetch('/viewworksheetpupil', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify({
			Worksheet_ID,
		//	Class_ID,
		}), // data can be `string` or {object}!
		headers:{'Content-Type': 'application/json'	}
	}) //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	let js_obj = await response.json();
	return js_obj.data;//[js_obj.worksheet, js_obj.data];
}
async function setAssignment(Class_ID:number, W_ID:number){
	let url = '/set_assignment';
	let data = {
		class_ID: Class_ID,
		W_ID :W_ID,
	}
	let response = await fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
	let js_obj = response.json();
	return js_obj;
	//You should now do something along the lines of:
	//this.fetchClasses()
}
async function resetWorksheet(Worksheet_ID:number):Promise<ClassAssignmentData>{//[Worksheet,ClassAssignmentData]>{
	let response = await fetch('/resetworksheetpupil', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify({
			Worksheet_ID,
		//	Class_ID,
		}), // data can be `string` or {object}!
		headers:{'Content-Type': 'application/json'	}
	}) //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	let js_obj = await response.json();
	return js_obj.data;//[js_obj.worksheet, js_obj.data];
}
async function fetchClassAssignmentData(Assignment_ID:number,Class_ID:number):Promise<[Worksheet,ClassAssignmentData,Cohort]>{

	let response = await fetch('/viewassignment', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify({
			Assignment_ID,
			Class_ID,
		}), // data can be `string` or {object}!
		headers:{'Content-Type': 'application/json'	}
	}) //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	
	let js_obj = await response.json();

	return [js_obj.worksheet, js_obj.data, js_obj.clas];

}

function compare_sort_parameter(a:{index:number,sort_parameter:number|string|undefined,param_is_string:boolean},b:{index:number,sort_parameter:number|string|undefined,param_is_string:boolean}){
	if(a===undefined){
		if(b===undefined) {return 0}
		else {return -1}
	}
	else if(b===undefined) {return 1}
	else if(a.sort_parameter===undefined){
		if(b.sort_parameter===undefined) {return 0}
		else {return -1}
	}
	else if(b.sort_parameter===undefined) {return 1}
	if(a.param_is_string){
		let A:string = a.sort_parameter.toString() as string;
		let B:string = b.sort_parameter.toString() as string;
		if(A.toLowerCase()<B.toLowerCase()){
			return -1;
		}
		if(A.toLowerCase()>B.toLowerCase()){
			return 1;
		}
		return 0;
	} else {
		if(a.sort_parameter<b.sort_parameter){
			return -1;
		}
		if(a.sort_parameter>b.sort_parameter){
			return 1;
		}
		return 0;
	}
}
function compare_lastname(a:PupilNames,b:PupilNames){//TODO want to be able to compare by different properties.
	if(a.lastname.toLowerCase()<b.lastname.toLowerCase()){
		return -1;
	}
	if(a.lastname.toLowerCase()>b.lastname.toLowerCase()){
		return 1;
	}
	return 0;
}
function compare_initial(a:PupilWSData|undefined,b:PupilWSData|undefined){//TODO want to be able to compare by different properties.
	if(a===undefined){
		if(b===undefined) {return 0}
		else {return -1}
	}
	else if(b===undefined) {return 1}
	else if(a.initial_percentage===undefined){
		if(b.initial_percentage===undefined) {return 0}
		else {return -1}
	}
	else if(b.initial_percentage===undefined) {return 1}
	else if(a.initial_percentage<b.initial_percentage){
		return -1;
	}
	else if(a.initial_percentage>b.initial_percentage){
		return 1;
	}
	return 0;
}
function compare_adj(a:PupilWSData|undefined,b:PupilWSData|undefined){//TODO want to be able to compare by different properties.
	if(a===undefined){
		if(b===undefined) {return 0}
		else {return -1}
	}
	else if(b===undefined) {return 1}
	else if(a.adjusted_percentage===undefined){
		if(b.adjusted_percentage===undefined) {return 0}
		else {return -1}
	}
	else if(b.adjusted_percentage===undefined) {return 1}
	else if(a.adjusted_percentage<b.adjusted_percentage){
		return -1;
	}
	else if(a.adjusted_percentage>b.adjusted_percentage){
		return 1;
	}
	return 0;
}
function compare_acc(a:PupilWSData|undefined,b:PupilWSData|undefined){//TODO want to be able to compare by different properties.
	if(a===undefined){
		if(b===undefined) {return 0}
		else {return -1}
	}
	else if(b===undefined) {return 1}
	else if(a.max_score===undefined||a.attempted_out_of===undefined){
		if(b.max_score===undefined||b.attempted_out_of===undefined) {return 0}
		else {return -1}
	}
	else if(b.max_score===undefined||b.attempted_out_of===undefined) {return 1}
	else if(a.max_score/a.attempted_out_of<b.max_score/b.attempted_out_of){
		return -1;
	}
	else if(a.max_score/a.attempted_out_of<b.max_score/b.attempted_out_of){
		return 1;
	}
	return 0;
}
function compare_final(a:PupilWSData|undefined,b:PupilWSData|undefined){//TODO want to be able to compare by different properties.
	if(a===undefined){
		if(b===undefined) {return 0}
		else {return -1}
	}
	else if(b===undefined) {return 1}
	else if(a.max_percentage===undefined){
		if(b.max_percentage===undefined) {return 0}
		else {return -1}
	}
	else if(b.max_percentage===undefined) {return 1}
	else if(a.max_percentage<b.max_percentage){
		return -1;
	}
	else if(a.max_percentage>b.max_percentage){
		return 1;
	}
	return 0;
}

//let myImage = new Image();
//ggg
//QuestionpagebyID currently doesn't need to be exported could just be put into Admin
export class QuestionpagebyID extends React.Component {// TODO: aidan reckons this is pointless
	props!: {
		is_iframe:boolean,
		Q_ID:number,
		isdisplayed:boolean,
		action?:"createnew"|"view",//can be view (from question manage page)
	}
	static defaultProps = {
		is_iframe:false,
	}
	state: {
		question?:Question,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			question:undefined,
		}
	}
	async getQuestion(){
		let questions = await fetchquestionsbyID([this.props.Q_ID]);
		this.setState({question:questions[0]});
		console.log("successfully obtained question: "+questions[0].Q_ID);
	}
	componentDidMount(){
		console.log("QuestionpagebyID componentDidMount fetching questionsbyID: "+this.props.Q_ID);	
		this.getQuestion();
	}
	componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
	//	console.log("QuestionpagebyID componentDidUpdate called, now comparing states.") TODO Don't know what this was even here for. 
	//	if(prevState===this.state){ //can't compare the object like this, they'll never be the same. 
	//		console.log("!!!!!!!!!! state match and so we do getQuestion? componentDidUpdate fetching questionsbyID: "+this.props.Q_ID);		
	//		this.getQuestion();	
	//	}
	}
	shouldComponentUpdate(nextProps:this["props"], nextState: this["state"]){
		return (JSON.stringify(nextProps)!==JSON.stringify(this.props)||(JSON.stringify(nextState)!==JSON.stringify(this.state)))
	}
	render(){
		console.log("rendering QuestionpagebyID");
		console.log("Questionloaded?: "+((this.state.question!=null)&&(this.state.question.Q_ID===this.props.Q_ID)));
		var element = null;
		if(this.state.question != null){
			console.log("QuestionpagebyID rendering Questionpage with Question: "+this.state.question.Q_ID+" having been given ID "+this.props.Q_ID);
			element = 	<Questionpage   
				key={"" + this.state.question.Q_ID}
				question={this.state.question}
				action={this.props.action}
				formvalues={create_empty_formvalues(this.state.question)}
				isdisplayed={this.props.isdisplayed}
				is_iframe={this.props.is_iframe} 
				//questionarrayposition={0} 
				updateQButtonColour={function (Q_ID: number, score: (number | null)[], maxscore: number[]): void {
					throw new Error('Function not implemented.');
				} }						
				/>
		}
		else{
			console.log("have determined that the question has not yet loaded");
			element = <p>Loading question</p>
		}			
		return(
			<div 
				className={"center"+(this.props.is_iframe?" iframe":"")}
				>{element}
			</div>
		)
	}
}
export class Questionpage extends React.Component {
	props!: {
		//key:string,// key is not an accessible prop.
		resetnumber?: number,
		questionarrayposition?:number, //0 is the first question
		question: Question,//sometimes it may actually be given a QuestionAnswer
		Qnum_inWS?: number,
		action: "createnew"|"view",//possibly refers to create new submission vs just viewing the question
		inQedittor: false|string,
		formvalues:(string|undefined)[],
		showingfeedback:boolean,
		disabled:boolean, //now just a synonym for whether or not to show submit button
		first?:undefined|1,//1 means that it is the first attempt after a proper reset, not the kind of reset that occurs within a question. 
		showSubmit:boolean,
		brightenSubmit:boolean,
		isassessment:boolean,
		isdisplayed:boolean,
		updateQScore:(Q_ID:number,score:(null|number)[],maxscore:number[])=>void,
		updateQButtonColour: (Q_ID: number, score: (number | null)[], maxscore: number[])=> void,
		inworksheet:boolean,
		inquestionstopractice:boolean,
		nextQactive?:boolean,
		setButtonStatusNav:(button:"submit"|"nextQ",action:"activate"|"deactivate")=>void,
		nextQuestion:()=>void,
		is_iframe:boolean,
		onQsubmit?:()=>void,
		W_ID:number|null,
		setSecretSauce:(saucyinfo:any)=>void,
		inflashcardworksheet?:boolean,
	}
	static defaultProps = {
		//formvalues:create_empty_formvalues(),//can't do because requires question as input
		setSecretSauce:function(woops:any):void{//this function is here because this function shouldn't be called unless defined
			throw "setSecretSauce error, this means that Questionpage tried to call setSecretSauce but was not passed it from the parent"
		},
		action:"createnew",	//TODO I don't understand what this means in the context of questionpage it means a normal question, not view question
		inQedittor: false,
		showingfeedback:false,
		disabled:false,
		showSubmit:true,
		brightenSubmit:false,
		isassessment:false,
		updateQScore:function(Q_ID:number,score:number):void{//this function is here because this function shouldn't be called unless defined
			throw "updateQScore error, this means that Questionpage tried to call updateQScore but was not passed it from the parent"
		},
		setButtonStatusNav:function(button:"submit"|"nextQ",action:"activate"|"deactivate"):void{//this function is here because this function shouldn't be called unless defined
			throw "setButtonStatusNav error, this means that Questionpage tried to call setButtonStatusNav but was not passed it from the parent"
		},
		nextQuestion:function():void{//this function is here because this function shouldn't be called unless defined
			throw "nextQuestion error, this means that Questionpage tried to call nextQuestion but was not passed it from the parent"
		},
		inworksheet:false,
		inquestionstopractice:false,
		is_iframe:false,
		W_ID:null,//means that it is not in a worksheet
	}
	state: {
		resetnumber?:number,
		showingfeedback:boolean,
		disabled:boolean,
		first?:undefined|1,//1 means that it is the first attempt after a proper reset, not the kind of reset that occurs within a question. 
		formvalues:(string|undefined)[],
		feedback: {head:string[],body:FeedbackObject[][]},
		A_ID:number,
		timeout:any,
	//	showSubmit:boolean,
		brightenSubmit:boolean,
		submitActive:boolean,
		haschanged:boolean[],
		hintmode:boolean,
		hintaskedfor:boolean[],
		connectiontimeout:any,
		connected:boolean,
		tryconnectiontimeout:any,
		loggedout:boolean,
		textareaheightssorted:boolean,
		lastTextAreaInFocus:null|{
			elementID:string,
			part?:number,
			fieldtype:FieldType,
			fieldindex:number,
		},
		feedbackloading:boolean,
		hintavailable:boolean,
		ismarking?:boolean,
		showreportdialog:boolean,
	}
	submitRef:React.RefObject<HTMLButtonElement>
	constructor(props:Readonly<Questionpage["props"]>) {
		super(props);
	//	if(props.question.Q_ID===552){
	//		console.log("constructor formvalues: "+props.formvalues);
	//	}
		//	console.log("props is: "+JSON.stringify(props));
		this.submitRef = React.createRef();
		this.state = {//TODO this is bad. it means that when I alter the props, the state doesnt change. 
			resetnumber:props.resetnumber,
			showingfeedback:props.showingfeedback,
			disabled:props.disabled, 
			formvalues:props.formvalues,//==[]?create_empty_formvalues(props.question):props.formvalues,
			first:props.first,
			//feedback:{head:[],body:[]},
			feedback:{
				head:Array(props.question.field_elements.field_desc.length).fill(''),
				body:Array(props.question.field_elements.field_desc.length).fill([]),
				},
			A_ID:-1,
			timeout:null,//initialise timeout that is used in the componentdidUpdate function for saving
		//	showSubmit:props.showSubmit,
			brightenSubmit:props.brightenSubmit,
			submitActive:false,	
			haschanged:Array(props.question.field_elements.field_desc.length).fill(false),
			hintmode:false,
			hintaskedfor:Array(props.question.field_elements.field_desc.length).fill(false),
			connectiontimeout:null,
			connected:true,
			tryconnectiontimeout:null,
			loggedout:false,
			textareaheightssorted:false,
			lastTextAreaInFocus:null,
			feedbackloading:false,
			hintavailable:false,
			ismarking:undefined,
			showreportdialog:false,
		}
		this.onChange = this.onChange.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
		this.onClickSave = this.onClickSave.bind(this)
		this.fetchFeedback = this.fetchFeedback.bind(this)
		this.onClickEdit = this.onClickEdit.bind(this)
		this.onClickReset = this.onClickReset.bind(this)
		this.componentDidMount = this.componentDidMount.bind(this)
		this.componentDidUpdate = this.componentDidUpdate.bind(this)
		this.addSymbol = this.addSymbol.bind(this)
		this.onAskHint = this.onAskHint.bind(this)
		this.connectionChecker = this.connectionChecker.bind(this)
		this.setButtonStatus = this.setButtonStatus.bind(this)
		this.handlFocusTextarea = this.handlFocusTextarea.bind(this)
		this.handleFocusAndEnter = this.handleFocusAndEnter.bind(this)
		this.handleAutomaticSaving = this.handleAutomaticSaving.bind(this)
	//	this.onClickMark = this.onClickMark.bind(this)
		this.onClickRetry = this.onClickRetry.bind(this)
		this.handleToggleReportDialog = this.handleToggleReportDialog.bind(this)
		this.handleOpenReportDialog = this.handleOpenReportDialog.bind(this)
	} 
	async componentDidMount(){
		if(this.props.action==="createnew"&&this.state.showingfeedback){
		//	console.log("fetching feedback for Q with name:"+this.props.question.name+" ID: "+this.props.question.Q_ID);
			let data = {
				formvalues:"last submitted",// Object.assign({}, this.state.formvalues),
				Q_ID:this.props.question.Q_ID//we don't have this in view only mode.
			};
			this.fetchFeedback("/viewfeedback",data,false,false);
			
		//TODO: For some reason the below breaks things, so I am commenting it out for now. 
		//	let hintavailable = await ishintavailable(this.state.formvalues.length,this.props.question.Q_ID);
		//	this.setState({hintavailable})
		}
		if(this.props.is_iframe===false){
		//	document.addEventListener("keydown", (e)=>{if(this.props.isdisplayed){this.handleKeyPress(e)}});
		}
	}
	
	componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
		if(prevProps!=this.props){//this will always be true because they are separate references
			//console.log("props changed componentDidUpdate Questionpage: "+this.props.question.name);
			if(this.props.action==="view"){//||prevProps.key!==this.props.key){//I don't want to reset values normally? or maybe I do.
				this.setState({
					//formvalues:this.props.formvalues, //TODO what is this feature for? - I think it is to prevent errors when modifying number of fields
					formvalues:JSON.parse(JSON.stringify(this.props.formvalues)) as string[],//TODO what is this feature for? - I think it is to prevent errors when modifying number of fields
				})
			}
			if (prevProps.resetnumber!=this.props.resetnumber){//||(this.state.resetnumber!==this.props.resetnumber)||(JSON.parse(JSON.stringify((prevProps.formvalues)))!==(JSON.parse(JSON.stringify((this.props.formvalues)))))){
				if(this.props.question.Q_ID===552){
					console.log("resetnumber changed componentDidUpdate Questionpage: "+this.props.question.name);
				}
				this.setState({
					showingfeedback:this.props.showingfeedback,
					disabled:this.props.disabled, 
					resetnumber:this.props.resetnumber,
					formvalues:JSON.parse(JSON.stringify(this.props.formvalues)) as string[],
					first:this.props.first,
					//formvalues:this.props.formvalues,
					brightenSubmit:this.props.brightenSubmit,
				})
				//console.log("props of questionpage changed while not in view mode"); TODO this should probably be an error, but it happens all the time and seems fine if I don't make it an error. 
				//not sure why the props are changing
				//throw "props of questionpage changed while not in view mode";
				//I think it is fine if it is the isdisplayed prop
			}
		}
	}
	componentWillUnmount(){
		if(this.props.question.Q_ID===552){
			console.log("componentWillUnmount formvalues: "+this.props.formvalues+" resetnumber: "+this.props.resetnumber);
		}
		clearTimeout(this.state.tryconnectiontimeout);
		clearTimeout(this.state.connectiontimeout);
	}
	handleKeyPress = (event:React.KeyboardEvent|KeyboardEvent) => {
		//event.preventDefault(); 
		let enterkeycode = 13;
		let tabkeycode = 9;
	//	let leftkeycode = 37;
	//	let rightkeycode = 39;
		if (event.keyCode == enterkeycode) {
			if(this.state.submitActive||this.props.nextQactive){
				event.preventDefault()
			}
			this.handleEnter()
		} else if (event.keyCode == tabkeycode){
			this.setButtonStatus("submit","deactivate");
			this.setButtonStatus("nextQ","deactivate");
		}
	};
	handleEnter(){
		if(this.state.submitActive===true){//I don't think it can get here
			this.onSubmit();
		} else if(this.props.nextQactive===true){
			this.setState({submitActive:false});//should be done but submit anyway
			if(this.props.inworksheet)
			this.props.nextQuestion();
		}
		//otherwise handle normally?
	}
	setButtonStatus(button:"submit"|"nextQ",action:"activate"|"deactivate",dontfocus?:boolean) {
		console.log(button+" button to be "+action+"d");
		if(dontfocus===undefined){
			dontfocus=false;
		}
		if(button==="submit"){
			if(action==="activate"){
				console.log("HEEEEEEEEEERRRRRRRRRRRREEEEEEEEEEEEE")
				if(this.submitRef.current!=null&&dontfocus!==true){
					this.submitRef.current.focus();
				}
				console.log("HHHHHHHHHHEEEEEEEEEERRRRRRRRR")
				this.setState({submitActive:true})
				console.log("we got here!!!!!!!!!!!!!!!!!!!!!!!!!")
				if(this.props.inworksheet===true){	
					this.props.setButtonStatusNav("nextQ","deactivate")
				}
			} else if (action==="deactivate"){
				this.setState({submitActive:false})
				if(this.submitRef.current!=null){
					this.submitRef.current.blur();
				}
			}
		} else if(button==="nextQ"){
			if((this.props.inworksheet===true)||(this.props.inflashcardworksheet)) {
				this.props.setButtonStatusNav(button,action)
			}
			else console.log( "shouldn't be trying to set next question when not in worksheet mode");
		} 
	}
	connectionChecker () {
		console.log("connectionChecker called");
		clearTimeout(this.state.tryconnectiontimeout);
		this.setState({
			connectiontimeout:setTimeout(
				()=>{
					console.log('start connection timeout');
					this.setState({
						connected:false,
						tryconnectiontimeout:setTimeout(
							()=>{
								this.saveAnswer()
							},
							3000
						)
					})
				}, 
				3000 //wait 3000 milliseconds
			)
		})
	}
	handleFocusAndEnter(newvalue:string,type:FieldType,fieldpartnumber?:number) {
		//if MC, activate submit button
		if(type==="checkbox"||type==="radio"){
			this.setButtonStatus((this.props.showSubmit?"submit":"nextQ"),"activate");
		}
		let submitRequested:boolean = false;
		if(type==="input_text_long"||(type==="input_numerical_twopart"&&fieldpartnumber===1)){//does the enter submitting thing if they are in the answer box only. 
			if(newvalue[newvalue.length-1]==="\n"){
				if(newvalue[newvalue.length-2]==="\n"){
					newvalue = newvalue.slice(0,newvalue.length-2)//remove the two new lines at the end before saving etc.
					if(this.props.showSubmit){
						submitRequested = true;
					} else {
						//how does it actually do the next page?
					}
				} else{
					if(this.props.showSubmit){
						this.setButtonStatus("submit","activate",true);
						this.setButtonStatus("nextQ","deactivate");
					} else {
						this.setButtonStatus("nextQ","activate");
					}
					//somewhere here I could make it so that enter double tap on working gets you to next box. 
				}
			} else {
				if(this.state.submitActive||this.props.nextQactive){
					this.setButtonStatus("submit","deactivate");
					this.setButtonStatus("nextQ","deactivate");
				}
			}
		}
		return {newvalue, submitRequested}
	}
	handleAutomaticSaving(fieldindex:number){
		console.log("handleAutomaticSaving");
		let haschangedtemp = this.state.haschanged;
		haschangedtemp[fieldindex] = true;
		clearTimeout(this.state.timeout);
		this.setState({
			haschanged:haschangedtemp,
			timeout:setTimeout(
				()=>{//console.log('Now'+JSON.stringify(newvalue,null,4));
				this.saveAnswer()}, //ggg
				1000 //wait 1000 milliseconds
			)
		});
		console.log("just set new timeout");
	}
	handleMultiInputFields(tempformvalues:(string|undefined)[],newvalue:string,fieldindex:number,type:FieldType,fieldpartnumber?:number):(string|undefined)[] {
		if(type==="input_numerical_twopart"){
			if(fieldpartnumber===undefined)throw "fieldpartnumber should be defined for input_numerical_twopart";
			let tempformvalue = tempformvalues[fieldindex];
			if(tempformvalue===undefined) throw "in handleMultiInputFields tempformvalue===undefined"
			let oldfieldvalue = JSON.parse(tempformvalue);
			oldfieldvalue[fieldpartnumber] = newvalue;
			tempformvalues[fieldindex] = JSON.stringify(oldfieldvalue);
		}	
		else if(type==="input_self_mark"||type==="input_hybrid"){
			if(fieldpartnumber===undefined)throw "fieldpartnumber should be defined for input_self_mark";
			let tempformvalue = tempformvalues[fieldindex];
			if(tempformvalue===undefined) throw "in handleMultiInputFields tempformvalue===undefined"
			let oldfieldvalue = JSON.parse(tempformvalue);
			oldfieldvalue[fieldpartnumber] = newvalue;
			tempformvalues[fieldindex] = JSON.stringify(oldfieldvalue);
		}
		else {
			tempformvalues[fieldindex] = newvalue;
		}
		return tempformvalues
	}
	onChange(newvalue:string,fieldindex:number,type:FieldType,fieldpartnumber?:number) {

		//handle focus of input fields and submit button and pressing enter button
		let output:{newvalue:string, submitRequested:boolean} = this.handleFocusAndEnter(newvalue,type,fieldpartnumber);
		newvalue = output.newvalue;
		let submitRequested:boolean = output.submitRequested;

		//handle automatic saving
		this.handleAutomaticSaving(fieldindex);
		
		//}
    //	console.log('something has been changed');		//console.log("The useranswer recorded in state: "+ this.state.useranswer);		console.log("Target name: "+e.target.name);		console.log("Target value: "+e.target.value);		//console.log("The useranswer[e.target.name] recorded in state: "+ this.state.useranswer[e.target.name]);
	//	tempformvalues[Number(e.target.name)] = e.target.value;//Because we named the inputs to match their corresponding values in state, it's super easy to update the state
		
		//create temp form values so we don't edit the contents of input boxes while editting
		let tempformvalues = JSON.parse(JSON.stringify(this.state.formvalues)); 		//var tempformvalues = [...this.state.formvalues];

		//stick together the multi-part field types into a string
		tempformvalues = this.handleMultiInputFields(tempformvalues,newvalue,fieldindex,type,fieldpartnumber);
		
										//type==="checkbox"?
										//JSON.stringify(newvalue)
										//:newvalue;//Because we named the inputs to match their corresponding values in state, it's super easy to update the state
	//TODO maybe I just stringify, and if it is already a string it will make no difference, it does make a difference :(
	//	console.log('new tempformvalues are: '+JSON.stringify(tempformvalues));
		this.setState({	//if they edit a value
				formvalues:tempformvalues,
				disabled:false,	//count the fields as not disabled (added line on 15th Oct, thought it may be needed for the logic of other buttons- probably needs removing)
			//	showSubmit:true, //if they edit their answer or start to make an answer, offer the submit button
				brightenSubmit:true, //if they edit their answer or start to make an answer, offer the submit button
			});
		
		if(submitRequested){
			this.onSubmit()
		}
	
	//this.setState({[useranswer[e.target.name]]: e.target.value });
	//	setTimeout(()=>{
	//		console.log('formvalues in state are: '+JSON.stringify(this.state.formvalues));
	//	}, 3000);
	}
	onSubmit = (is_hint_request?:boolean) => {//React.FormEvent<HTMLFormElement>) => { //e: { preventDefault: () => void; }
		console.log("submitting, is_hint_request: "+is_hint_request);
		//TODO alert to check if they would like to alter some of their other questions
		//Are all other questions either 'Correct' or haschanged, if not then alert them.
		//Text will vary slightly based on the whether it was Hint or Check. 
		//if there is a self marking question. the first submit click needs to bring up the markscheme and doesn't need to send it through (for now) could just make it submit when they press reset but haven't marked. 
		if(this.props.question.field_elements.input_self_marks!=undefined){
			if(this.props.question.field_elements.input_self_marks.length>0&&this.state.ismarking!==true){
				this.setState({
					ismarking:true,
				});
				return
			}
		}
		//	if(this.props.question.field_elements.input_self_marks.length>0&&this.state.ismarking===true){
		//		this.setState({ismarking:false});
		//	}
		if(is_hint_request===undefined){is_hint_request=false}
		let url = '';
		let data = {};
		if(this.props.action==="createnew"){
			url = this.props.is_iframe?'/iframe_submit':'/submit';
			data = {
				formvalues:this.state.formvalues,// Object.assign({}, this.state.formvalues),
				Q_ID:this.props.question.Q_ID,
				first:this.state.first,
				W_ID:this.props.W_ID,
				is_hint_request, //TODO this is untested
				//showingfeedback:true,//shouldn't be nesc
			};
		//	this.setState({
		//					disabled:true,	//I don't think this even does anything. 
		//					showSubmit:false,//not nesc as this is done by fetchFeedback
		//				});
		//	this.setButtonStatus("nextQ","activate"); //moved to fetchFeedback where I can determine if they got the question correct or not. 
		} else if (this.props.action==="view") {
			url = '/viewfeedback';
			data = {formvalues:this.state.formvalues,
					question:this.props.question,//may not have a question ID yet - I should be saving progress
					inQedittor:this.props.inQedittor,
					};
		}
		if((this.state.showingfeedback==true)&&elementsAreNotTrue(this.state.haschanged)){
			return
		}
		this.fetchFeedback(url,data,is_hint_request,true);
		if(this.props.onQsubmit!==undefined){
			this.props.onQsubmit();
		}
		this.setButtonStatus("submit","deactivate");
		
//		this.setState({ismarking:false})
	}
//	onClickMark(fieldindex: number){//TODO add use of fieldindex, and in onSubmit()
//		this.setState({ismarking:true})
//	}
	onClickRetry(){
		this.setState({ismarking:false})
	}
	fetchFeedback(url:string,data:any,is_hint_request:boolean,call_updateQButtonColour:boolean){
		console.log("fetchFeedback, is_hint_request: "+is_hint_request);
		fetch(url, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(data), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json'
			}
			}).then(res => res.json())
			.then((resdata) => {
				console.log("got data back from server");
				if(this.props.inQedittor!==false){
			//		console.log("Question asking Q creator to set secret sauce");
					this.props.setSecretSauce(resdata.secret_sauce);
				}
				if(resdata.doublesubmit==true){
					console.log("double submitted");
					this.setState({
						feedbackloading:false,
					})
				} else {
					console.log("not double submitted");
					this.setState({
						feedback:{
							head:is_hint_request?new Array(this.state.feedback.head.length).fill("Hint"):resdata.head,
							body:resdata.body,
						},
						haschanged:Array(this.props.question.field_elements.field_desc.length).fill(false),
						score:resdata.score, //not currently set up to be used.
						feedbackloading:false,
						first:undefined,
					});
					console.log("not double submitted after setState");
					if((this.props.inworksheet||this.props.inquestionstopractice)&&call_updateQButtonColour){
						console.log("not double submitted after setState is inworksheet");
						//this.props.updateQScore(this.props.question.Q_ID,resdata.score,resdata.maxscore);
						//at some point I split out updateQbuttonColour from setButtenStatus, from original updateQScore. I didn't bother updating flashcard worksheet at the same time so it obviously broke. TODO The assessment style worksheet is probably also broken. 
						if(this.props.inflashcardworksheet){
							this.props.updateQScore(this.props.question.Q_ID,resdata.score,resdata.maxscore);
							//this will do the next button status thing I think
						} else {
							this.props.updateQButtonColour(this.props.question.Q_ID,resdata.score,resdata.maxscore);
						}
					}
					console.log("not double submitted after updateQButtonColour");
				}
				if(this.props.inworksheet||this.props.inquestionstopractice){
					console.log("is in worksheet, about to set button status if percentage score is high enough");
					//this.props.updateQScore(this.props.question.Q_ID,resdata.score,resdata.maxscore);
					if(getpercentagescore(resdata.score,resdata.maxscore)===1){
						this.setButtonStatus("nextQ","activate");
					}
				}
			})
		//	.then(response => console.log('Success:'+ JSON.stringify(response)))
			.catch(error => console.error('Error:'+ error));
		//if(this.state.showSubmit===false&&is_hint_request===true){
		//	throw "shouldn't be able to request a hint when showSubmit is false"
		//}
		this.setState({
			showingfeedback:true,
		//	showSubmit:is_hint_request,//this is incorrect anyway, why should the submit button brighten when someone asks for a hint?
			brightenSubmit:!is_hint_request,
			feedbackloading:true,
			hintmode:is_hint_request,
			feedback:{
				head:is_hint_request?new Array(this.state.feedback.head.length).fill("Hint loading..."):new Array(this.state.feedback.head.length).fill("Loading new feedback..."),//dodge
				body:this.state.feedback.body,//new Array(this.state.feedback.head.length).fill([]),
			}
		});
	}
	onAskHint(fieldindex:number){
		console.log("onAskHint");
		let hintaskedfortemp = this.state.hintaskedfor;
		hintaskedfortemp[fieldindex] = true;
		this.setState({
			hintmode:true,
			hintaskedfor:hintaskedfortemp,
			feedback:{
				head:new Array(this.state.feedback.head.length).fill("Hint loading..."),//dodge
				body:this.state.feedback.body,//new Array(this.state.feedback.head.length).fill([]),
			}
		})
		if(this.state.haschanged[fieldindex]||!this.state.showingfeedback){
			this.onSubmit(true)
		}
	}
	saveAnswer(){//justdidquestionreset?:boolean) {
		console.log("saving Answer");
		  var url = '';
		  var data = {};
		if(this.props.action==="createnew"){//createnew mode is the question being done live as opposed to being viewed view questoin manager or createquestion (It is create new attempt record)
		//	this.connectionChecker();
			url = this.props.is_iframe?'/iframe_saveanswer':'/saveanswer';
		//	console.log("this.state.showingfeedback "+this.state.showingfeedback);
			data = {
				formvalues:this.state.formvalues,// Object.assign({}, this.state.formvalues),
				Q_ID:this.props.question.Q_ID,
				showingfeedback:this.state.showingfeedback,
				disabled:this.state.disabled,
				first:this.state.first,
				W_ID:this.props.W_ID,
			//	justdidquestionreset,
			};//{MC_answer:this.state.MC_answer};         // get our form data out of state
			fetch(url, {
				method: 'POST', // or 'PUT'
				body: JSON.stringify(data), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then(res => {
				console.log(res);
				console.log("status: "+res.status);
				return res.json();}
			)
			.then(response => {
					//console.log('Success:', JSON.stringify(response));
					let loggedout = false;
					if (response==="notloggedin"){
						loggedout = true;
					} else if(response.A_ID!==undefined&&response.A_ID!==null){
						if(response.A_ID>-1||response.A_ID===-2){//-2 is temp value for iframes.
						} else {
							throw "A_ID was not >-1";
						}
					} else {
						throw "Did not recieve A_ID from server as expected";//I changed it to return the attempt rather than some error code in the attempt number
					}
					//Mark as connected
					console.log("clearing connectiontimeout")
					clearTimeout(this.state.connectiontimeout);
					clearTimeout(this.state.tryconnectiontimeout);
					this.setState({
						connected:true,
						connectiontimeout:null,
						tryconnectiontimeout:null,
						loggedout:loggedout,
					})
				}
			)
			.catch((error) => {
				console.error('Error:', error);
				this.connectionChecker();
			});
		} else if (this.props.action==="view") {//view mode means that it is being viewed from question manager as opposed to actually doing the question
			console.log("tried to save answer in view mode");
			//throw "tried to save answer in view mode";TODO get rid of this and replace with condition in onChange
		}
	}
/*	checkConnection() {
		console.log("checking connection");
		  var url = 'checkConnection';
		  var data = {};
		fetch(url, {
				method: 'POST', // or 'PUT'
				body: JSON.stringify(data), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then(res => res.json())
			.then(response => {
					console.log('Success:', JSON.stringify(response));
					if(response.A_ID!==undefined&&response.A_ID!==null){
						if(response.A_ID>-1){
							//Mark as connected
							console.log("clearing connectiontimeout")
							clearTimeout(this.state.connectiontimeout);
							this.setState({
								connected:true,
								connectiontimeout:null,
							})
						} else {
							throw "A_ID was not >-1";
						}
					} else {
						throw "Did not recieve A_ID from server as expected";
					}

				}
			)
			.catch(error => console.error('Error:', error));
		} else if (this.props.action==="view") {//view mode means that it is being viewed from question manager as opposed to actually doing the question
			console.log("tried to check connection view mode");
			//throw "tried to save answer in view mode";TODO get rid of this and replace with condition in onChange
		}
	}*/
	onClickSave = (e: React.MouseEvent<HTMLButtonElement>) => {//TODO does nothing. 
		e.preventDefault();
		this.saveAnswer();		
	}
	onClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		console.log('onClickEdit');
		this.setState({disabled:false})
	}
	onClickReset = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		console.log('onClickReset');
		this.setState({
			showingfeedback:false,
			disabled:false,
			first:undefined, //can only reset after submit (I think)
			formvalues:create_empty_formvalues(this.props.question),
		//	showSubmit:false,
			brightenSubmit:false,
			haschanged:Array(this.props.question.field_elements.field_desc.length).fill(false),
			hintmode:false,
			hintaskedfor:Array(this.props.question.field_elements.field_desc.length).fill(false),
			feedback:{
				head:Array(this.props.question.field_elements.field_desc.length).fill(''),
				body:Array(this.props.question.field_elements.field_desc.length).fill([]),
			},
		});
		setTimeout( //what is the point of this? I think it might just be to save a blank. Possibly: TODO: save draft so that the props doesn't reset this.
			()=>{this.saveAnswer()}, 
			500 //wait 1000 milliseconds
		);//TODO do setTimeouts work if they are not in state?
		//this.saveAnswer();
	}
//	nextQuestion =() => { TODO: next question button
//		fetch("/question")
//		.then(response => response.json())
//		.then(jobj2 => this.setState({question: jobj2}))
//	addSymbol(fieldindex:number, symbol:string){
//		let fv = this.state.formvalues;
//		fv[fieldindex]+=symbol;
//		this.setState({formvalues:fv});
//		//	this.refs["field"+fieldindex].refs.taRef;
//		let child:any = this.refs["Qpagebody"];
//		child.refs.taRef.focus();
//	}
	handlFocusTextarea(textareainfo:{
		elementID:string,
		part?:number,
		fieldtype:FieldType,
		fieldindex:number}):void{
		this.setState({
			lastTextAreaInFocus:textareainfo
		})
	}
	addSymbol(fieldindex:number, symbol:string):void{//TODO should pick up the last text area that I was in, is more elegant solution. 
		// see: nextQRef:React.RefObject<HTMLButtonElement> in App.tsx for possible effective implementation. 
		console.log("addSymbol called with symbol: "+symbol+" and fieldindex: "+fieldindex);
		if(document===null) throw "document is null";
		let textareainfo = this.state.lastTextAreaInFocus;
		if(textareainfo==null) return;//If they havn't selected a text area, then stop trying to add a symbol. 
		//TODO: they need to reset the last selected text area if they change question.
		let textareaID = textareainfo.elementID;//"textarea field:"+fieldindex+" Q_ID:"+this.props.question.Q_ID;
		let textareahopeful = document.getElementById(textareaID) as HTMLTextAreaElement; 
		loggy(textareahopeful.value,"textareahopeful.value");
		if(textareahopeful===null) throw "textareahopeful should not be null";
		const textarea:HTMLTextAreaElement = textareahopeful;
		let loc = textarea.selectionStart;
		loggy(loc,"loc");
		
		//Handle numberical two part and self mark since the field values are stored as an array turned to a string

		let formvalue:string|undefined = this.state.formvalues[fieldindex];
		loggy(formvalue,"formvalue");
		if(formvalue===undefined)throw "original string should not be undefined"
		let stringtoedit = formvalue;
		if((textareainfo.fieldtype==="input_numerical_twopart")||(textareainfo.fieldtype==="input_self_mark")){
			if(textareainfo.part===undefined)throw "textarepart should be defined for input_numerical_twopart";
			stringtoedit = JSON.parse(formvalue)[textareainfo.part];
		}
		
		//textarea.setSelectionRange()
		let newstring = stringtoedit.slice(0,loc)+symbol+stringtoedit.slice(loc);
		loggy(newstring,"newstring");
		let stringtosave = newstring;
		if((textareainfo.fieldtype==="input_numerical_twopart")||(textareainfo.fieldtype==="input_self_mark")){
			if(textareainfo.part===undefined)throw "textarepart should be defined for input_numerical_twopart";
			let parsedformvalue = JSON.parse(formvalue)
			parsedformvalue[textareainfo.part]=newstring;
			stringtosave =JSON.stringify(parsedformvalue);
		}
		this.state.formvalues[fieldindex]=stringtosave;//TODO this needs to be fixed with the proper set state thing
		//textarea.focus();
			//console.log(textarea.selectionStart);
			//setCaretToPos(textarea,loc+symbol.length);
			//console.log("new method"+textarea.selectionStart);
		//textarea.setSelectionRange(loc+symbol.length,loc+symbol.length);
			//console.log(textarea.selectionStart);
			//loggy(loc+symbol.length,"loc+symbol.length");
			//loggy(textarea.selectionStart,"textarea.selectionStart");
		this.setState({formvalues:this.state.formvalues},()=>{
			textarea.focus();
			textarea.setSelectionRange(loc+symbol.length,loc+symbol.length);
		});
			//loggy(textarea.selectionStart,"textarea.selectionStart after set state");
		//		//	this.refs["field"+fieldindex].refs.taRef;
		//	let child:any = this.refs["Qpagebody"];
		//	child.refs.taRef.focus();
	//	child.refs.taRef.setSelectionRange(1,2);
		//textarea.selectionStart = loc+1;
	}
	handleOpenReportDialog(){
		this.setState({showreportdialog:true});
	}
	handleToggleReportDialog(){
		this.setState({showreportdialog:!this.state.showreportdialog});
	}
	render() {
	//	console.log("Questionpage is rendering Questionpagebody with Q_ID: "+this.props.question.Q_ID+
	//		", action: "+this.props.action+", showing feedback: "+(this.state.showingfeedback));
	//	console.log("The formvalues are: "+ JSON.stringify(this.state.formvalues));
		let questionnumber = this.props.Qnum_inWS?
		<span 
			style={{
				paddingTop:"10px",
				paddingLeft:"10px", 
				paddingRight:"5px", 
				fontFamily:"'PT sans',sans-serif!important",
				fontSize:"25px",
				float:"left",
			}}>{this.props.Qnum_inWS}.
		</span>:<span></span>;
		return (
			<div onKeyDown={(e)=>{if(this.props.isdisplayed){this.handleKeyPress(e)}}}>
				<p 
					hidden={this.state.connected}
					style={{
						backgroundColor:"white",
						borderColor:"black",
						borderStyle:"dashed",
						position:"fixed",
						width: "80%",
    					maxWidth: "700px",
					}}
					>⚠️ Could not connect to server. Answers will not be saved. Check your internet connection. You may need to log out and log in again. ⚠️
				</p>
				<p 
					hidden={!this.state.loggedout}
					style={{
						backgroundColor:"white",
						borderColor:"black",
						borderStyle:"dashed",
						position:"fixed",
						width: "80%",
    					maxWidth: "700px",
					}}
					>⚠️ You are not logged in, you may have been logged out in another window. Answers will not be saved. You may need to log out and log in again. ⚠️
				</p>
				{questionnumber}
				<Questionpagebody 
					ref="Qpagebody"
					addSymbol={this.addSymbol}
					key={this.props.question.Q_ID} 
					question={this.props.question} 
					onChange={this.onChange} 
					formvalues={this.state.formvalues}
					showingfeedback={this.state.showingfeedback}
					feedback={this.state.feedback}
					disabled={false}//this.state.disabled} Changed to false because I don't want the questions to be disabled on submission anymore
				//	showSubmit={this.state.showSubmit}
					haschanged={this.state.haschanged}
					hintmode={this.state.hintmode}
					hintaskedfor={this.state.hintaskedfor}
					hintOnly={this.props.question.hintOnly}
					onAskHint={this.onAskHint}
					isdisplayed={this.props.isdisplayed}
					setButtonStatus={this.setButtonStatus}
					realSubmit={this.onSubmit}
					is_iframe={this.props.is_iframe}
					handlFocusTextarea={this.handlFocusTextarea}
					feedbackloading={this.state.feedbackloading}
					hintavailable={this.state.hintavailable}
					ismarking={this.state.ismarking}
					isassessment={this.props.isassessment}
				//	onClickMark={this.onClickMark}
					onClickRetry={this.onClickRetry}
				/>
				<div 
					className={"submitbuttonsholder"+(this.props.is_iframe?" iframe":"")}>
					{//TODO:only 'showfeedback' shows in view mode
					}
					{/*<button type="button" className="button" onClick={this.onClickSave} 
						hidden={!((!this.state.disabled)&&(this.props.action==="createnew"))}
						style={((!this.state.disabled)&&(this.props.action==="createnew"))?{}:{display:"none"}}
						>Save</button>*/}
					{/*<button type="button" className="button" onClick={this.onClickEdit}//"submit" form="form_1" value="Submit" 
						hidden={!((this.state.disabled)&&(this.props.action==="createnew"))}
						style={((this.state.disabled)&&(this.props.action==="createnew"))?{}:{display:"none"}}
						>Edit</button>*/}
					
					<button 
						type="button" 
						className={"button faded"+(this.props.is_iframe?" iframe":"")} 
						onClick={this.onClickReset}//"submit" form="form_1" value="Submit" 
						//hidden={!((this.state.showingfeedback)&&(this.props.action==="createnew"))}
						hidden={!(this.state.showingfeedback)}
						style={(this.state.showingfeedback)?{maxWidth:"15%"}:{display:"none"}} 
				//		tabIndex={-1}//Reset
						>{"↻"}
					</button>
					<button 
						className={"button faded"}//+(this.props.is_iframe?" iframe":"")}
						onClick={this.handleToggleReportDialog}
						//hidden = {!this.props.showreportdialog}//
						//Report Problem?
						style={{maxWidth:"15%"}}
						>🚩
					</button>
					
					
					<button 
						type="button" 
						//className="button" 
						className={"button"+(this.state.brightenSubmit?"":" disabled")+(this.state.submitActive?" active":"")+(this.props.is_iframe?" iframe":"")} //i.e. normal button or button faded
						onClick={(e)=>{e.preventDefault();this.onSubmit(false)}}//"submit" form="form_1" value="Submit" 
						//hidden={!(this.props.action==="view")} doesn't do anything when there is styling in the css
						style={(this.props.action==="view")?{}:{display:"none"}} 
						ref={this.props.action==="view"?this.submitRef:"placeholderfornothing"}
						//		tabIndex={-1}
						>Show feedback
					</button>
					
					<button 
						type="button" 
						ref={this.props.action==="createnew"?this.submitRef:"placeholderfornothing"}
						//		tabIndex={-1}
						className={"button"+(this.state.brightenSubmit?"":" disabled")+(this.state.submitActive?" active":"")+(this.props.is_iframe?" iframe":"")} //i.e. normal button or button faded
						//disabled={!this.state.showSubmit}
						onClick={(e)=>{e.preventDefault();this.onSubmit(false)}}
						//hidden={!((!this.state.disabled)&&(this.props.action==="createnew"))}
						//style={((!this.state.disabled)&&(this.props.action==="createnew"))?{}:{display:"none"}}
						//style={this.state.showSubmit?{}:{display:"none"}}
						style={((this.props.action==="createnew")&&(this.props.showSubmit))?{maxWidth:"30%"}:{display:"none"}} 
						>Submit
					</button>
					</div>
					<div 
					//	hidden={this.props.is_iframe}
						//style={{display:"inline"}}
						>
						<ReportProblemComponent 
							Q_ID = {this.props.question.Q_ID}
							formvalues = {this.state.formvalues}
							A_ID = {this.state.A_ID}
							feedback = {this.state.feedback}
							handleOpen = {this.handleOpenReportDialog}
							handleClose = {this.handleToggleReportDialog}
							showdialog = {this.state.showreportdialog}
							
					//		is_iframe={this.props.is_iframe}
						/>
					{
					//	<div 
					//		hidden={this.props.is_iframe}
					//		style={{display:"inline"}}>
					//		<DialogComponent 
					//			Q_ID={this.props.question.Q_ID}
					//			is_iframe={this.props.is_iframe}
					//		/>
					//	</div>
					}
				</div>
			</div>
		)
	} ///*hidden={!this.state.showingfeedback}*/ <button type="button" onClick={this.nextQuestion} autoFocus={true}>	 question</button>
}
interface QuestionpagebodyProps {
	question: Question,
	onChange: (newvalue:string,fieldindex:number,type:FieldType,fieldpartnumber?:number)=>void,
	addSymbol:(fieldindex:number, symbol:string)=>void,
	onAskHint:(fieldindex:number)=>void,
	setButtonStatus: (button:"submit"|"nextQ",action:"activate"|"deactivate")=> void,
	//onSubmit: (e:any)=>void,
	formvalues:(string|undefined)[];
	showingfeedback:boolean,
	disabled: boolean,
//	showSubmit: boolean,
	feedback: {head:string[],body:FeedbackObject[][]};
	haschanged:boolean[],
	hintmode:boolean,
	hintaskedfor:boolean[],
	hintOnly:boolean,
	isdisplayed:boolean,
	realSubmit:(is_hint_request?:boolean|undefined)=>void,
	is_iframe:boolean,
	handlFocusTextarea: (textareainfo:{
		elementID:string,
		part?:number,
		fieldtype:FieldType,
		fieldindex:number})=>void,
	feedbackloading:boolean,
	hintavailable:boolean,
	ismarking?:boolean,
	isassessment:boolean,
//	onClickMark: (fieldindex: number) => void,
	onClickRetry: () => void,
} 
class Questionpagebody extends React.Component<QuestionpagebodyProps>{
	//useful link: https://github.com/SCasarotto/Large-Dynamically-Generated-Forms/blob/master/src/DynamicForm/DynamicForm.js 

	state: {
		textareaheight:number[],
		heightsSet:boolean,
//		ismarking:boolean,
	}
	constructor(props: Readonly<QuestionpagebodyProps>) { 
		super(props); 
		this.state = {
			textareaheight:Array(props.formvalues.length).fill(12),
			heightsSet:false,
//			ismarking:false,
		}
	//	this.setTextareaHeight=this.setTextareaHeight.bind(this);
	//	this.setTextareaHeightforall=this.setTextareaHeightforall.bind(this);
		this.onSubmit=this.onSubmit.bind(this);
	//	this.onClickMark=this.onClickMark.bind(this);
	//	for (let i=0;i<this.props.question.field_elements.input_text_longs.length;i++){
	//		let refname:string = ""+"refInputLong"+i;
	//		this.blabla=React.createRef()
	//	}
	}
	//taRef = React.createRef<HTMLTextAreaElement>();
//	componentDidUpdate(){
////		console.log("Questionpagebody componentDidUpdate called");
//		if(this.props.isdisplayed&&!this.state.heightsSet){
//			this.setTextareaHeightforall();
//		}
//	}
//	setTextareaHeightforall(){
//		console.log("setTextareaHeightforall called");
//		for(let f=0;f<this.props.formvalues.length;f++){
//			if(this.props.question.field_elements.field_desc[f].type==="input_text_long"){
//				let textarea:HTMLElement|null = document.getElementById("textarea field:"+f+" Q_ID:"+this.props.question.Q_ID);
//				if (textarea!==null){
//					this.setTextareaHeight(textarea,f)
//				}
//			}
//		}
//		this.setState({heightsSet:true})
//	}
	onSubmit(e:any) {
		e.preventDefault();
		this.props.realSubmit();
	}
	render() {
//		console.log("rendering Questionpagebody with isdisplayed: "+this.props.isdisplayed+` and
//		heightsSet = `+this.state.heightsSet);
		var fe = this.props.question.field_elements;	
		
	//	console.log("Questionpagebody preparing fields for render");
	//	console.log("The formvalues are: "+ JSON.stringify(this.props.formvalues));
	//	console.log('field_desc: '+fe.field_desc);
		var fields = []; //array of field components for rendering
		for(let i=0;i<fe.field_desc.length;i++){
			let type=fe.field_desc[i].type
			
			//console.log("rendering field i: "+i);
			//console.log("field_desc[i]: "+JSON.stringify(fe.field_desc[i]));
			//console.log("field_desc[i].instance: "+JSON.stringify(fe.field_desc[i].instance));
			//console.log("///////////////////////////////input_radios: "+JSON.stringify(fe.input_radios)+"///////////////////");
			if (type==="info_text"){
				fields[i] = 
					<p 
						key={i} 
						className={"info_text"+(this.props.is_iframe?" iframe":"")}
						dangerouslySetInnerHTML={{__html:fe.info_texts[fe.field_desc[i].instance]}} 
						style={{whiteSpace:'pre-wrap'}}
					/>//{fe.info_texts[fe.field_desc[i].instance]}</p>
			}
			else if (type==="radio")
				fields[i] = 
					<div key={i}>
						<InputRadioComponent 
							key={i}
							inputradio={fe.input_radios[fe.field_desc[i].instance]}
							fieldindex={i}
							fieldvalue={this.props.formvalues[i]}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
							type={"radio"}
						/>
						<Feedback
							feedback={this.props.feedback}
							fieldindex={i}
							faded={this.props.haschanged[i]}
							showingfeedback={this.props.showingfeedback}
							onAskHint={this.props.onAskHint}
							hintaskedfor={this.props.hintaskedfor}
							haschanged={this.props.haschanged}
							hintmode={this.props.hintmode}
							hintOnly={this.props.hintOnly}
							nohints={true}
							is_iframe={this.props.is_iframe}
							feedbackloading={this.props.feedbackloading}
							hidden={this.props.isassessment}
						/>
					</div>
					//.replace(/↵/g, "\n")||''}</p>
			else if (type==="checkbox"){
				fields[i] = 
					<div key={i}>
						<InputCheckboxComponent 
							key={i}
							inputradio={fe.input_checkboxes[fe.field_desc[i].instance]}
							fieldindex={i}
							fieldvalue={this.props.formvalues[i]}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
							type={"checkbox"}
							isdisplayed={this.props.isdisplayed}
						/>
						<Feedback
							feedback={this.props.feedback}
							fieldindex={i}
							faded={this.props.haschanged[i]}
							showingfeedback={this.props.showingfeedback}
							onAskHint={this.props.onAskHint}
							hintaskedfor={this.props.hintaskedfor}
							haschanged={this.props.haschanged}
							hintmode={this.props.hintmode}
							hintOnly={this.props.hintOnly}
							nohints={true}
							is_iframe={this.props.is_iframe}
							feedbackloading={this.props.feedbackloading}
							hidden={this.props.isassessment}
						/>
					</div>
			}
		//	else if (type==="input_text_word"){//TODO could put these into separate components since this is getting big
		//		fields[i] = 
		//			<div key={i}>
		//				<label>
		//					<input 
		//						type="textarea" 
		//						name={""+i}//fieldindex={i}
		//						spellCheck={true}
		//						value={this.props.formvalues[i]}//TODO apparently this switches from uncontrolled to controlled
		//						onChange={(e)=>this.props.onChange(e.target.value,i,type)}
		//						disabled={this.props.disabled}
		//					/>
		//				</label>
		//				<Feedback
		//					feedback={this.props.feedback}
		//					fieldindex={i}
		//					faded={this.props.haschanged[i]}
		//					showingfeedback={this.props.showingfeedback}
		//					onAskHint={this.props.onAskHint}
		//					hintaskedfor={this.props.hintaskedfor}
		//					haschanged={this.props.haschanged}
		//					hintmode={this.props.hintmode}
		//					nohints={false}
		//				/>
		//			</div>
		//	}
			else if (type==="input_text_long"){//TODO could put these into separate components since this is getting big
				let showsymbols = this.props.question.field_elements.input_text_longs[this.props.question.field_elements.field_desc[i].instance].showsymbols;
				let textareavalue = this.props.formvalues[i];
				if(textareavalue===undefined){//throw "undefined textareavalue for input_text_long"
					console.log("setting textareavalue for input_text_long since it was undefined");
					textareavalue="";//don't think this does anything, think it is set here:create_empty_formvalues() in my_interfaces
				}
				fields[i] = 
					<div key={i}>
						<TextAreaInput
							is_iframe = {this.props.is_iframe}
							fieldindex={i}
							Q_ID={this.props.question.Q_ID}
							isdisplayed={this.props.isdisplayed}
							textareavalue={textareavalue}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
						//	setTextareaHeight={this.setTextareaHeight}
							maxscore = {this.props.question.field_elements.field_desc[i].maxscore}
							type={type}
							handlFocusTextarea={this.props.handlFocusTextarea}
						/>
						<SymbolButtons showsymbols={showsymbols} fieldindex={i} addSymbol={this.props.addSymbol}/>
						<Feedback
							feedback={this.props.feedback}
							fieldindex={i}
							faded={this.props.haschanged[i]}
							showingfeedback={this.props.showingfeedback}
							onAskHint={this.props.onAskHint}
							hintaskedfor={this.props.hintaskedfor}
							haschanged={this.props.haschanged}
							hintmode={this.props.hintmode}
							hintOnly={this.props.hintOnly}
							//nohints={false}
							nohints={this.props.isassessment}
							is_iframe={this.props.is_iframe}
							feedbackloading={this.props.feedbackloading}
							hintavailable={this.props.hintavailable}
							hidden={this.props.isassessment}
						/>
					</div>
			}
			else if (type==="input_numerical_twopart"){//TODO could put these into separate components since this is getting big
				let showsymbols = true;//this.props.question.field_elements.input_text_longs[this.props.question.field_elements.field_desc[i].instance].showsymbols;
				let fieldpartsvalues:string[]=parsePartsValues(this.props.formvalues[i]) ;				
				fields[i] = 
					<div key={i}>
						<TextAreaInput
							is_iframe = {this.props.is_iframe}
							fieldindex={i}
							Q_ID={this.props.question.Q_ID}
							isdisplayed={this.props.isdisplayed}
							textareavalue={fieldpartsvalues[0]}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
						//	setTextareaHeight={this.setTextareaHeight}
							maxscore = {this.props.question.field_elements.field_desc[i].maxscore}
							type={type}
							fieldpartnumber={0}//working
							handlFocusTextarea={this.props.handlFocusTextarea}
						/>
						<TextAreaInput
							is_iframe = {this.props.is_iframe}
							fieldindex={i}
							Q_ID={this.props.question.Q_ID}
							isdisplayed={this.props.isdisplayed}
							textareavalue={fieldpartsvalues[1]}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
						//	setTextareaHeight={this.setTextareaHeight}
							maxscore = {this.props.question.field_elements.field_desc[i].maxscore}
							type={type}
							fieldpartnumber={1}//answer
							handlFocusTextarea={this.props.handlFocusTextarea}
						/>
						<SymbolButtons showsymbols={showsymbols} fieldindex={i} addSymbol={this.props.addSymbol}/>
						<Feedback
							feedback={this.props.feedback}
							fieldindex={i}
							faded={this.props.haschanged[i]}
							showingfeedback={this.props.showingfeedback}
							onAskHint={this.props.onAskHint}
							hintaskedfor={this.props.hintaskedfor}
							haschanged={this.props.haschanged}
							hintmode={this.props.hintmode}
							hintOnly={this.props.hintOnly}
							nohints={this.props.isassessment}//false}
							is_iframe={this.props.is_iframe}
							feedbackloading={this.props.feedbackloading}
							hintavailable={this.props.hintavailable}
							hidden={this.props.isassessment}
						/>
					</div>
			}
			else if (type==="input_self_mark"||type==="input_hybrid"){
			//	let showsymbols;
			//	if(type==="input_self_mark") showsymbols = this.props.question.field_elements.input_self_marks[this.props.question.field_elements.field_desc[i].instance].showsymbols;
			//	if(type==="input_hybrid") showsymbols = this.props.question.field_elements.input_hybrids[this.props.question.field_elements.field_desc[i].instance].showsymbols;
			//	let fieldpartsvalues:string[]=parsePartsValues(this.props.formvalues[i]) ;				
				fields[i] = 
					<div key={i}>
						<SelfMarkComponent
							is_iframe = {this.props.is_iframe}
							field_index={i}
							question = {this.props.question}
							isdisplayed={this.props.isdisplayed}
							formvalues={this.props.formvalues}
							onChange={this.props.onChange}
							disabled={this.props.disabled}
							handlFocusTextarea={this.props.handlFocusTextarea}
							type={type}
							addSymbol={this.props.addSymbol}
							showingfeedback={this.props.showingfeedback}
							ismarking={this.props.ismarking||false}
					//		onClickMark={this.props.onClickMark}
							haschanged={this.props.haschanged}
							onClickRetry={this.props.onClickRetry}
							isassessment={this.props.isassessment}
						/>
						<Feedback
							feedback={this.props.feedback}
							fieldindex={i}
							faded={this.props.haschanged[i]}
							showingfeedback={this.props.showingfeedback}
							onAskHint={this.props.onAskHint}
							hintaskedfor={this.props.hintaskedfor}
							haschanged={this.props.haschanged}
							hintmode={this.props.hintmode}
							hintOnly={this.props.hintOnly}
							nohints={true} //this needs to be true if in assessment. 
							is_iframe={this.props.is_iframe}
							feedbackloading={this.props.feedbackloading}
							isselfmark={true}
							hidden={this.props.isassessment}
						/>
					</div>
			}
			else if (type==="info_image"){
				//	console.log("fe.info_images[fe.field_desc[i].instance]: "+JSON.stringify(fe.info_images[fe.field_desc[i].instance]));
					if(fe.info_images[fe.field_desc[i].instance].modified===true){
						let image_file = fe.info_images[fe.field_desc[i].instance].file
						if(image_file != null){
							fields[i] = 
							<img 
								key={i} 
								//src={window.URL.createObjectURL(fe.info_images[fe.field_desc[i].instance])}
								src={window.URL.createObjectURL(image_file)}
								>
							</img>
						}
						else {
							fields[i] = <p>There has been an error with the image, image file null</p>
							console.log("the image is apparently null")
						}
					}
					else if(fe.info_images[fe.field_desc[i].instance].modified===false){
						fields[i] = 
							<img 
								key={i} 
								//src={window.URL.createObjectURL(fe.info_images[fe.field_desc[i].instance])}
								src={"/public/"+fe.info_images[fe.field_desc[i].instance].name}
								>
							</img>
					} else {
						fields[i] = <p>There has been an error with the image</p>
						console.log("the image is apparently neither modified true or modified false")
						//throw "the image is apparently neither modified true or modified false"
					}
			}
				//<p key={i}>{(JSON.stringify(fe.info_images[fe.field_desc[i].instance][0]))}</p>
				//<p key={i}>here is an image</p>
			else {
				fields[i] = 
					 <p key={i}>This is a disaster</p>//console.log("ERROR: unknown field type");//TODO
					 ;
					 throw "could not work out how to render field type: "+type;
			}	

		}
		// console.log("Question page body prepared fields for render, now rendering");
		return (
			<div> 
				{//className="center">
				//<h2>{this.props.question.name}</h2>
				}
					<div>
						<form onSubmit={this.onSubmit}>	{//Form tag makes checkbox work properly
								}
							{fields}
						</form>
					</div>				
			</div>
		);
	}
}
class SelfMarkComponent extends React.Component { //OR HybridComponent
	props!:{
		is_iframe:boolean,
		field_index:number,
		question:Question,
		isdisplayed:boolean,
		formvalues:(string|undefined)[],
		onChange: (newvalue: string, fieldindex: number, type: FieldType, fieldpartnumber?: number | undefined) => void
		disabled:boolean,
		handlFocusTextarea: (textareainfo: {elementID: string;	part?: number | undefined;	fieldtype: FieldType;fieldindex: number;	}) => void
		type:FieldType,
		addSymbol: (fieldindex: number, symbol: string) => void,
		showingfeedback:boolean,
		ismarking:boolean,
		onClickRetry: () => void,
	//	onClickMark: (fieldindex: number) => void,
		haschanged:boolean[],
		isassessment:boolean,
	}
//	state:{
//		ismarking:boolean
//	}
//	constructor(props: Readonly<{}>) { 
//		super(props);
//		this.state = {
//			ismarking:false
//		}
//		this.typeWriter = this.typeWriter.bind(this);
//		this.onClickMark = this.onClickMark.bind(this);
//	}
//	onClickMark(){
//		this.setState({ismarking:true});
//		//this.props.onClickMark(this.props.field_index);
//	}
	render(){
		let i = this.props.field_index;
		let fe = this.props.question.field_elements;
		let selfmark = this.props.type==="input_self_mark"?fe.input_self_marks[fe.field_desc[i].instance]:this.props.type==="input_hybrid"?fe.input_hybrids[fe.field_desc[i].instance]:undefined;
		if(selfmark===undefined){throw "error has occurred selfmark undefined"}
		let showsymbols = selfmark.showsymbols;
		let fieldpartsvalues:string[]=parsePartsValues(this.props.formvalues[i]) ;
	//	let showmarkitbutton = (!this.state.ismarking)&&(this.props.haschanged[this.props.field_index])
		let showretrybutton = (this.props.ismarking)&&(this.props.showingfeedback)&&(!this.props.haschanged[this.props.field_index])
		let markschemestuff = <div
			hidden = {this.props.isassessment}>
				<h3
					hidden={!(this.props.ismarking===true)}
					>Mark your answer
				</h3>
				<p
					hidden={!(this.props.ismarking===true)}
					style={{fontSize:"medium"}}
					>Mark your answer by selecting the points that you included in your answer above.
				</p>
				<InputCheckboxComponent 
					key={i}
					inputradio={{options:selfmark.options, oneOptionOnly:false}}
					fieldindex={i}
					fieldvalue={fieldpartsvalues[1]}
					onChange={this.props.onChange}
					disabled={this.props.disabled}
					type={this.props.type}
					isdisplayed={this.props.isdisplayed}
					hidden={!this.props.ismarking}
				/>
				<div
					style={{textAlign:"center", margin:"auto"}}>
					<button
						onClick={(e)=>{e.preventDefault();this.props.onClickRetry();}}//{ismarking:false}
						className={"button"+(this.props.is_iframe?" iframe":"")}
						style={{display:(showretrybutton?"block":"none"), margin:"auto"}}
						//style={{display:"none"}}
						>Retry question
					</button>
				</div>
			</div>
		
		return(
			<div>
				<TextAreaInput
					is_iframe = {this.props.is_iframe}
					fieldindex={i}
					Q_ID={this.props.question.Q_ID}
					isdisplayed={this.props.isdisplayed}
					textareavalue={fieldpartsvalues[0]}
					onChange={this.props.onChange}
					disabled={this.props.disabled||this.props.ismarking}
					//	setTextareaHeight={this.setTextareaHeight}
					maxscore = {this.props.question.field_elements.field_desc[i].maxscore}
					type={this.props.type}
					fieldpartnumber={0}//working
					handlFocusTextarea={this.props.handlFocusTextarea}
				/>
				<SymbolButtons showsymbols={showsymbols} fieldindex={i} addSymbol={this.props.addSymbol}/>
				<div
					hidden={this.props.isassessment}>
					<StaticHint
						hinttext={selfmark.statichint||''}
					/>
				</div>
			{/*	<button
					onClick={(e)=>{e.preventDefault();this.onClickMark();}}
					className={"button"+(this.props.is_iframe?" iframe":"")}
					style={{display:showmarkitbutton?"block":"none"}}
					>Mark it
			</button>*/}
				{markschemestuff}
			</div>
		)
	}
}
/*export interface FeedbackObject {
	text:string,
	type:string,
	priority:number,
}
export function create_empty_FeedbackObject():FeedbackObject{
	return {
		text:'',
		type:'hint',
		priority:0,
	}
}ts*/
class StaticHint extends React.Component {
	props!:{
		hinttext:string
	}
	state:{
		showhint:boolean
	}
	constructor(props: Readonly<{}>) { 
		super(props);
		this.state = {
			showhint:false,
		}
	}
	render(){
		let returnvalue = [];
		if(this.props.hinttext.length==0){
			return <span/>;
		}
		else if (this.state.showhint===true){
			//normal feedback
			returnvalue[0] = <FeedbackBox
				faded={false}//this.props.faded}
				head={"Hint"}//this.props.feedback.head[this.props.fieldindex]}
				feedbackbody={[{
					text:this.props.hinttext,
					type:"hint",
					priority:0,
				}]}
			/>
		} else{
			//hint button
			returnvalue[0] = 
			<button
				onClick={(e)=>{e.preventDefault();this.setState({showhint:true})}}
				className={"button hint"}//+(this.props.is_iframe?" iframe":"")}
				//hidden={!this.props.hintavailable}
				>Hint
			</button>;
		}
		return(
			returnvalue[0]
		)
	}
}
class TextAreaInput extends React.Component {
	props!:{
		is_iframe:boolean,
		fieldindex:number,
		Q_ID:number,
		isdisplayed:boolean,
		disabled:boolean,
		onChange: (newvalue:string,fieldindex:number,type:FieldType,fieldpartnumber?:number)=>void,
	//	setTextareaHeight:(textarea:HTMLElement,fieldindex:number)=>void,
		maxscore: number,
		type:FieldType
	//	formvalues: (string|undefined)[];
		textareavalue:string;
		fieldpartnumber?:number;
		handlFocusTextarea:(textareainfo:{elementID:string,part?:number,fieldtype:FieldType,fieldindex:number})=>void,
	}
	aRef: React.RefObject<HTMLTextAreaElement>;
	constructor(props: Readonly<{}>) { 
		super(props);
		this.aRef = React.createRef();
		this.setTextareaHeight = this.setTextareaHeight.bind(this);
	}
	//	this.state = {
	//		
	//	}
	//For reference
//	setTextareaHeight(textarea:HTMLElement,fieldindex:number){
//		let lineheight = 29;//textarea.style.lineHeight===null?20:Number(textarea.style.lineHeight);
//		textarea.style.height = "0px";//'inherit';//makes it smaller if too big
//		textarea.style.height = ""+Math.max(textarea.scrollHeight,(this.props.question.field_elements.field_desc[fieldindex].maxscore)*lineheight)+"px";//makes it bigger if more text than box
//	}
	componentDidMount(){
		if(this.aRef.current!==null){
			this.setTextareaHeight(this.aRef.current,this.props.fieldindex);
		}
		else {throw "this.aRef.current is null!"}
	}
	componentDidUpdate(){
		if(this.aRef.current!==null){
			this.setTextareaHeight(this.aRef.current,this.props.fieldindex);
		}
		else {throw "this.aRef.current is null!"}
	}
	setTextareaHeight(textarea:HTMLTextAreaElement,fieldindex:number){
		if(this.props.fieldpartnumber!==1){
			//console.log("textarea.style.lineHeight: "+textarea.style.lineHeight);
			let lineheight = 29;//textarea.style.lineHeight===null?20:Number(textarea.style.lineHeight);
		//	console.log("setTextareaHeight called");
		//	console.log("START textarea.style.height: "+textarea.style.height);
			//textarea.rows = (this.props.question.field_elements.field_desc[fieldindex].maxscore);
			textarea.style.height = "0px";//'inherit';//makes it smaller if too big
			//textarea.style.height = ""+((this.props.question.field_elements.field_desc[fieldindex].maxscore)*lineheight)+"px";//makes it smaller if too big
		//	console.log("textarea.style.height: "+textarea.style.height);
		//	console.log("textarea.scrollHeight: "+textarea.scrollHeight);
		//	textarea.style.height = ""+Math.max(textarea.scrollHeight,(this.props.question.field_elements.field_desc[fieldindex].maxscore)*lineheight)+"px";//makes it bigger if more text than box
			textarea.style.height = ""+Math.max(textarea.scrollHeight,this.props.maxscore*lineheight)+"px";//makes it bigger if more text than box
		//	console.log("FINAL textarea.style.height: "+textarea.style.height);
			//make bigger if there are more marks to earn in the question
		}
		else{
			textarea.style.height = "29px";//+Math.max(e.currentTarget.scrollHeight,(1)*29)+"px";//29 is the line height
		}
	}
	render(){
		let i = this.props.fieldindex
		let elementID = "textarea field:"+i+" Q_ID:"+this.props.Q_ID+(this.props.fieldpartnumber!==undefined?(" part: "+this.props.fieldpartnumber):"")+(this.props.isdisplayed?"":"hideme");//change the id of the hidden text areas so they don't affect get element by ID. needed if duplicate question.
		let placeholder="✎";
		if(this.props.fieldpartnumber==1&&this.props.type==="input_numerical_twopart"){
			placeholder="✎ final answer";
		}
		if(this.props.fieldpartnumber===0&&this.props.type==="input_numerical_twopart"){
			placeholder="✎ working";
		}
		return(
			<label>
				<div className={"textareaholder"+(this.props.is_iframe?" iframe":"")}>
					<textarea	
						maxLength={this.props.type==="input_numerical_twopart"?this.props.maxscore*50:this.props.maxscore*350}
						onFocus={(e)=>{this.props.handlFocusTextarea({
							elementID:(elementID),
							part:this.props.fieldpartnumber,
							fieldtype:this.props.type,
							fieldindex:this.props.fieldindex,
						})}}						
						id={elementID} 
						className={"inputtextarea"+(this.props.disabled?" disabled":"")}
						//style={{height:""+this.state.textareaheight[i]+"px"}}//fontFamily:"Bradley Hand, cursive"}}
						ref={this.aRef}
						//rows={(this.props.question.field_elements.field_desc[i].maxscore)+1}
						name={""+i}//fieldindex={i}
						spellCheck={true}
						value={this.props.textareavalue}//this.props.formvalues[i]}//TODO apparently this switches from uncontrolled to controlled, probably because it is sometimes undefined
						onChange={(e)=>{//e.preventDefault();
						//	console.log("height: "+e.currentTarget.scrollHeight);
						//	if(this.props.fieldpartnumber!==1){
								this.setTextareaHeight(e.currentTarget,i);
						//	} else {
						//		e.currentTarget.style.height = "0px";//'inherit';//makes it smaller if too big
						//		e.currentTarget.style.height = ""+Math.max(e.currentTarget.scrollHeight,(1)*29)+"px";//29 is the line height
						//	}
							this.props.onChange(e.target.value,i,this.props.type,this.props.fieldpartnumber)
						}}
						disabled={this.props.disabled}
						placeholder={placeholder}
						onLoad={(e)=>{
							console.log("height: "+e.currentTarget.scrollHeight);
						//	if(this.props.fieldpartnumber!==1){
								this.setTextareaHeight(e.currentTarget,i);
						//	} else {
						//		//e.currentTarget.style.height = "0px";//'inherit';//makes it smaller if too big
						//		//e.currentTarget.style.height = ""+Math.max(e.currentTarget.scrollHeight,(1)*29)+"px";//29 is the line height
						//		e.currentTarget.style.height = "29px";//+Math.max(e.currentTarget.scrollHeight,(1)*29)+"px";//29 is the line height
						//	}
							//this.props.onChange(e.target.value,i,this.props.type,this.props.fieldpartnumber)
						}}
					/>
				</div>
			</label>
		)
	}
}
class Feedback extends React.Component {
	props!:{
		//feedback:{head:string[],body:string[]},
		feedback:{head:string[],body:FeedbackObject[][]},
		fieldindex:number,
		faded:boolean,
		showingfeedback:boolean,
		onAskHint:(fieldindex:number)=>void,
		hintaskedfor:boolean[],
		haschanged:boolean[],
		hintmode:boolean,
		hintOnly:boolean, //this is set at the question level and indicates a question that has not been finished and thus needs to just say 'Hint' since we don't want to say correct or incorrect when we can't tell. 
		nohints:boolean, //what does this mean? ah, there are no hints for a checkbox question. I think it means that hints are not to be shown. 
		is_iframe:boolean,
		feedbackloading:boolean,
		hintavailable?:boolean, //this indicates if there is actually a hint to show or not. 
		isselfmark?:boolean,
		hidden:boolean,
	}
	static defaultProps = {
		hidden:false,
	}
	render(){
		if(this.props.hidden===true){
			return null
		}
		let returnvalue = [];
		if (!this.props.nohints&&(!this.props.showingfeedback||(this.props.showingfeedback&&this.props.hintmode&&!this.props.hintaskedfor[this.props.fieldindex]))){
		//} else if ((!this.props.showingfeedback||(this.props.showingfeedback&&this.props.hintmode&&!this.props.hintaskedfor[this.props.fieldindex]))){
			//hint button
			returnvalue[0] = 
				<button
					onClick={(e)=>{e.preventDefault();this.props.onAskHint(this.props.fieldindex)}}
					className={"button hint"+(this.props.is_iframe?" iframe":"")}
					hidden={!this.props.hintavailable}
					>Hint
				</button>;
		} else if((!this.props.hintmode)&&this.props.showingfeedback&&(!this.props.hintOnly)){
			//normal feedback
			returnvalue[0] = <FeedbackBox
								faded={this.props.faded}
								head={this.props.feedback.head[this.props.fieldindex]}
								feedbackbody={this.props.feedback.body[this.props.fieldindex]}
								isselfmark={this.props.isselfmark}
							/>
			
		} else if(!this.props.nohints&&(this.props.hintOnly||this.props.hintaskedfor[this.props.fieldindex])){
		//} else if((this.props.hintOnly||this.props.hintaskedfor[this.props.fieldindex])){
			let feedbackbody:FeedbackObject[] = this.props.feedback.body[this.props.fieldindex];
			if(this.props.hintaskedfor[this.props.fieldindex]&&this.props.feedback.body[this.props.fieldindex].length===0){
				let thisishereforsyntaxreasons = "whyhey";
				feedbackbody[0] = {
					text:this.props.feedbackloading?"loading...":"Sadly there is no hint available for this question. You should check your notes or use google to find the answer.",
					type:"hint",
					priority:0
				}
			}
			//hint style feedback
			returnvalue[0] = <FeedbackBox
								faded={this.props.faded}
								head={"Hint"}//this.props.feedback.head[this.props.fieldindex]}//"Hint"
								feedbackbody={feedbackbody}
							/>
		} else if(this.props.nohints){
			returnvalue[0] = <span></span>
		} else throw "Combination of hint mode, showing feedback etc. is not valid"
		return(
			returnvalue[0]
		)
	}
}
export class FeedbackBox extends React.Component{
	props!:{
		faded:boolean,
		head:string,//correct,incorrect,incomplete,hint
		feedbackbody:FeedbackObject[],
		isselfmark?:boolean,
	}
	render(){
		let colouroffeedbackbox = "";
		if(this.props.head===undefined){}
		else if(this.props.head=="Part way there"||this.props.head.startsWith("Hint")){
			colouroffeedbackbox="incomplete";
		} else {
			colouroffeedbackbox=this.props.head;
		}
		if(this.props.isselfmark&&this.props.feedbackbody.length===0&&this.props.head!=="Correct"){
			this.props.feedbackbody.push({
				text:"Learn the answer above and then click retry.",
				type:"hint",
				priority:0,
			})
		}
		return(
			<div 
				className={"feedback "+(this.props.faded?"faded ":"") +colouroffeedbackbox+" "} //TODO isn't this a better solution: +(this.props.faded?" faded":"")
			> 
				<h3
					style={{margin:'0px 0px'}}
					>{this.props.head}{(((this.props.isselfmark===true)&&(this.props.head!=="Loading new feedback..."))?<span style={{fontSize:"smaller", fontStyle:"italic"}}> (based on your marking)</span>:"")}
				</h3>
				<FeedbackBody
					feedbackbody={this.props.feedbackbody}
					faded={this.props.faded}
				/>
			</div>
		)
	}
}
class FeedbackBody extends React.Component{
	props!:{
		feedbackbody:FeedbackObject[],
		faded:boolean
	}
	state:{
		feedbackbodyprinted:string[],
		statementsFinished:number,
		isFinished:boolean,
		oldfeedbackbodyprinted:string[]
	}
	constructor(props: Readonly<{}>) { 
		super(props);
		this.state = {
			feedbackbodyprinted:[],
			statementsFinished:0,
			isFinished:false,
			oldfeedbackbodyprinted:[]
		}
//		this.typeWriter = this.typeWriter.bind(this);
//		this.componentDidMount = this.componentDidMount.bind(this);
	}
	componentDidMount() {
		console.log("componenetDidMount FeedbackBody");
//		this.handleType(true);
	}
	handleType = (justReset:boolean) => {
		console.log("componenetDidMount");
		loggy(this.state.statementsFinished,"this.state.statementsFinished");
		loggy(this.props.feedbackbody.length,"this.props.feedbackbody.length");
		if(this.state.statementsFinished<this.props.feedbackbody.length){
			console.log("inside, about to do the update");
			const s = this.state.statementsFinished;
			const dataText = this.props.feedbackbody[s].text;
			let feedbackbodyprinted  = [...this.state.feedbackbodyprinted];//shallow copy this item
			
			let text = feedbackbodyprinted[s];
			text = dataText.substring(0, justReset?1:text.length + 1);
			feedbackbodyprinted[s] = text;

			this.setState({
				feedbackbodyprinted
			});
			
			if(text===dataText){
				this.setState({
					//isFinished:true
					statementsFinished:s+1
				})
				if(s===this.props.feedbackbody.length-1){
					this.setState({
						isFinished:true,
						oldfeedbackbodyprinted:feedbackbodyprinted
					})
				} else {
					setTimeout(()=>{this.handleType(true)}, 30);
				}	
				
			} else {
				setTimeout(()=>{this.handleType(false)}, 30);
			}	
		}
		else {
			this.setState({
				isFinished:true
			})
		}
	};
	handleNewText = () => {
		console.log("handleNewText")
		this.setState({
			feedbackbodyprinted:[],
			statementsFinished:0,
			isFinished:false,
		//	text:''
		})
		this.handleType(true)
	};
	render() {   
/*		console.log("rendering");
		loggy(this.state.feedbackbodyprinted,"this.state.feedbackbodyprinted");
		loggy(this.state.oldfeedbackbodyprinted,"this.state.oldfeedbackbodyprinted");
		loggy(this.state.isFinished,"this.state.isFinished");
		let printedMatchesIp = true;
		if(this.props.feedbackbody.length!==this.state.feedbackbodyprinted.length){
			printedMatchesIp = false;
		}
		else{
			for(let f=0; f<this.props.feedbackbody.length;f++){
				if(this.props.feedbackbody[f].text!==this.state.feedbackbodyprinted[f]){
					printedMatchesIp = false;
					break;//not nesc since not set to true if they do
				}
			}
		}
		if(this.state.isFinished && !printedMatchesIp){//!arraysMatch(this.state.feedbackbodyprinted,this.state.oldfeedbackbodyprinted)){
			console.log("if condition met");
			this.handleNewText();
		}
*/		//this.typeWriter()		
		let feedbackbodydisplayarray = [];
		//generate formatted feedbackbody if available
		//if(this.props.feedback.body!==undefined){
			//feedback has been acquired
			//let sortedfeedback = ;//sorting the feedback has been passed to the server. 
			if(this.props.feedbackbody!==undefined&&this.props.feedbackbody!==null){
				//let num_feedback_items = sortedfeedback.length;
				let num_feedback_items = this.props.feedbackbody.length;
//				let num_feedback_items = this.state.feedbackbodyprinted.length;
				//for(let f=0;f<Math.min(3,num_feedback_items);f++){//cutting down of feedback to 3 has been passed to the server. 
				for(let f=0;f<num_feedback_items;f++){
					feedbackbodydisplayarray.push(
						<p 
							key={f}
							className = {"presentationfeedback "+this.props.feedbackbody[f].type+(this.props.faded?" faded":"")}
							//style={{whiteSpace:'pre-wrap'}}
//							>{this.state.feedbackbodyprinted[f]}
							>{this.props.feedbackbody[f].text}
						</p>
//					<Typer
//						key={f}
//						className = {"presentationfeedback "+this.props.feedbackbody[f].type+(this.props.faded?" faded":"")}
//						dataText = {this.props.feedbackbody[f].text}
//					/>		
					)
				}
			}
		//}
		return(
			<span>
				{feedbackbodydisplayarray}
			</span>
		)
	}
}
class Typer extends React.Component {
	props!:{
		key:number,
		className:string,
		dataText: string
	}
	state:{
		text:string,
		isFinished:boolean
	}
	constructor(props: Readonly<{}>) {
		super(props);
	
		this.state = {
		text: '',
		isFinished:false,
		}
	}
	
	componentDidMount() {
		this.handleType(true);
	}
	
	handleType = (justReset:boolean) => {
		const {text}  = this.state;
		this.setState({
			text: this.props.dataText.substring(0, justReset?1:text.length + 1),
		});
	
		if(this.state.text===this.props.dataText){
			this.setState({
				isFinished:true
			})
		} else {
			setTimeout(()=>{this.handleType(false)}, 30);
		}
		//setTimeout(() => this.setState({ isDeleting: true }), 500);
		
		//} else if (isDeleting && text === '') {
		
		//this.setState({
		//	isDeleting: false,
		//	loopNum: loopNum + 1
		//});
		
		//}	
	};
	handleNewText = () => {
		this.setState({
			isFinished:false,
			text:''
		})
		this.handleType(true)
	};
	render() {   
		if(this.state.isFinished && this.state.text!==this.props.dataText){
			this.handleNewText();
		}
	//	<h1>{ this.props.heading }&nbsp;
	//	<span>{ this.state.text }</span>
	//	<span id="cursor"/>
	//	</h1>
		return (
			<p className={this.props.className}>{ this.state.text }</p>
		)	
		}
		
	
}

class SymbolButtons extends React.Component {
	props!: {
		fieldindex:number,
		addSymbol:(fieldindex:number, symbol:string)=>void,
		showsymbols:boolean,
	}
	state:{
		hidebuttons:boolean
	}
	constructor(props: Readonly<{}>) { 
		super(props);
		this.state = {
			hidebuttons:false,
		}
	}
	render(){
		let symbolPanelSymbols = symbolPanelSymbolsShared;
		//let symbolPanelSymbols = ["√","÷","×","²"];
		let buttons = [];
		for(let s = 0; s<symbolPanelSymbols.length; s++){
			buttons.push(
				<button 
					key = {s}
					style={{fontSize:25,minWidth:"1em"}} 
					onClick={(e)=>{e.preventDefault();this.props.addSymbol(this.props.fieldindex,symbolPanelSymbols[s]);}}>
					{symbolPanelSymbols[s]}
				</button>
			);
		}
		return(
			<span hidden={!this.props.showsymbols}>
			{/*	<button
					className="button symboltoggle"
					onClick={(e)=>{e.preventDefault();this.setState({hidebuttons:!this.state.hidebuttons})}}>
					÷×
			</button>*/}
				<span hidden={this.state.hidebuttons}>
					{buttons}
				</span>
			</span>
		)
	}
}
class InputRadioComponent extends React.Component {
	props!: {
		inputradio: InputRadio,
		fieldindex: number,
		fieldvalue: (string|undefined),
		onChange: (newvalue:string,fieldindex:number,type:FieldType)=>void,
		disabled: boolean,
		type: FieldType,
	}
	isItChecked(i:number):boolean{
	//	console.log("i "+i);
		console.log("this.props.fieldvalue: "+JSON.stringify(this.props.fieldvalue));
	//	console.log("this.props.inputradio.options[i].text: "+this.props.inputradio.options[i].text);
	//	console.log("therefore checked: "+(this.props.fieldvalue === this.props.inputradio.options[i].text));
		return (this.props.fieldvalue === this.props.inputradio.options[i].text);
	}
	render(){
		//console.log("InputRadio"+this.props.inputradio);
		console.log("Inside radio component this.props.fieldvalue: "+this.props.fieldvalue);
		var answeroptions = [];//place holder for option components to go in
		for(let i=0;i<this.props.inputradio.options.length; i++){//***this zero needs to change when there are more than one input_radios */
			answeroptions.push(
				<span key={""+this.props.fieldindex+i}>
					<button
						className={("option "+(this.isItChecked(i)?"pressed":"notpressed"))}
						onClick={()=>this.props.onChange(this.props.inputradio.options[i].text,this.props.fieldindex,this.props.type)}
						//onClick={()=>this.onChange(this.props.inputradio.options[i].text,this.props.fieldindex,this.props.type,i)}
						>{this.props.inputradio.options[i].text}
					</button>
					<br/>	
				</span>				
			);
		}
		return(
			<div style={{textAlign:"center"}}>
				<div className="optionsholder">
					{answeroptions}
				</div>
			</div>
		)
	}
	
}
class InputCheckboxComponent extends React.Component {
	props!: {
		inputradio: InputRadio,
		fieldindex: number,
		fieldvalue: (string|undefined),
		onChange: (newvalue:string,fieldindex:number,type:FieldType,fieldpartnumber?:number)=>void,
		disabled: boolean,//don't think this is used anywhere, I don't really know what it means
		type: FieldType,
		isdisplayed: boolean,
		hidden?:boolean,
	}
	constructor(props:Readonly<InputCheckboxComponent["props"]>){
		super(props);
		this.onChange = this.onChange.bind(this)
		this.isItChecked = this.isItChecked.bind(this)
		this.componentDidMount = this.componentDidMount.bind(this)
	}
	componentDidMount(){
		//document.addEventListener("keydown", (e)=>{if(this.props.isdisplayed){this.handleKeyPress(e)}});
	}
	onChange(newvalue:any,fieldindex:number,type:FieldType,optiontoggled:number){
		if(this.props.fieldvalue!=null){
			let fieldvalue = JSON.parse(this.props.fieldvalue);//since I store this array as a string so it is like all the other values
			if(this.props.inputradio.oneOptionOnly===true){
				for(let o=0;o<fieldvalue.length;o++){
					fieldvalue[o]=false;
				}
				fieldvalue[optiontoggled] = true;
			} else {
				fieldvalue[optiontoggled] = !fieldvalue[optiontoggled];
			}
			let stringifiedfieldvalue = JSON.stringify(fieldvalue);
			if(type==="input_self_mark"){
				this.props.onChange(stringifiedfieldvalue,fieldindex,type,1)
			} else {//TODO possibly add error checking here. 
				this.props.onChange(stringifiedfieldvalue,fieldindex,type)
			}
		}else{
			console.log("InputCheckboxComponent>onChange>found field value to be null");
			throw "InputCheckboxComponent>onChange>found field value to be null";
		}
	}
	//handleKeyPress = (event:React.KeyboardEvent<HTMLInputElement>) => {
	handleKeyPress = (event:React.KeyboardEvent|KeyboardEvent) => {
		let element_in_focus = document.activeElement;
		if(element_in_focus===null){
			for (let i = 0; i<this.props.inputradio.options.length; i++){
				let key = (i+1);
				if (event.key == key.toString()) {
					this.onChange(this.props.inputradio.options[i].text,this.props.fieldindex,this.props.type,i)
				}
			}
		}
		else {
			console.log("handleKeyPress: "+element_in_focus.tagName);
			if(!(element_in_focus.tagName==="TEXTAREA"||element_in_focus.tagName==="INPUT")){
				//event.preventDefault(); 
				for (let i = 0; i<this.props.inputradio.options.length; i++){
					let key = (i+1);
					if (event.key == key.toString()) {
						this.onChange(this.props.inputradio.options[i].text,this.props.fieldindex,this.props.type,i)
					}
				}
			}
		}
	};
	isItChecked(optionindex:number):boolean{
	//	console.log("i "+i);
	//	console.log("this.props.fieldvalue: "+JSON.stringify(this.props.fieldvalue));
		//	console.log("this.props.inputradio.options[i].text: "+this.props.inputradio.options[i].text);
		//	console.log("therefore checked: "+(this.props.fieldvalue === this.props.inputradio.options[i].text));
		let checked = false;
		if(this.props.fieldvalue!=null){
			if(this.props.fieldvalue.length>0){
				let fieldvalue = JSON.parse(this.props.fieldvalue);
			//	console.log("this.props.fieldvalue: "+JSON.stringify(fieldvalue));
			//	console.log("optionindex: "+optionindex);
			//	console.log("fieldvalue[optionindex]: "+fieldvalue[optionindex]);
				checked=fieldvalue[optionindex];
				//for (let i = 0; i<fieldvalue.length; i++){
					//console.log("considering ")
					//if(fieldvalue[i] === this.props.inputradio.options[optionindex].text){
					//	checked = true;
					//}
				//}
			}else {	console.log("InputCheckboxComponent>isItChecked fieldvalue.length!>0");			}
		} else {
			console.log("InputCheckboxComponent>isItChecked fieldvalue is undefined");
			//throw "InputCheckboxComponent>isItChecked fieldvalue is undefined";
		}
		return checked;
	}
	render(){
		//console.log("InputRadio"+this.props.inputradio);
		//console.log("Inside InputCheckboxComponent this.props.fieldvalue: "+this.props.fieldvalue);
		
			var answeroptions = [];//place holder for option components to go in
			for(let i=0;i<this.props.inputradio.options.length; i++){//***this zero needs to change when there are more than one input_radios */
				answeroptions.push(
					<span key={i}> 
						<button
							className={("option "+(this.isItChecked(i)?"pressed":"notpressed")+((this.props.type==="input_self_mark")?" ms":""))}
							onClick={(e)=>{e.preventDefault(); this.onChange(this.props.inputradio.options[i].text,this.props.fieldindex,this.props.type,i)}}
							tabIndex={-1}
							>{this.props.inputradio.options[i].text}<span className="MClabel">{i+1}</span>
						</button>
						<br/>	
					</span>
						
				);
			}
			return(
				<div onKeyDown={(e)=>{if(this.props.isdisplayed){this.handleKeyPress(e)}}}
					style={{textAlign:"center"}}
					onKeyPress={this.handleKeyPress}
					hidden={this.props.hidden===true}//this happens in self mark qquestions
				>
					<div className={("optionsholder"+((this.props.type==="input_self_mark")?" ms":""))}>
						{answeroptions}
					</div>
				</div>
			)
		
	}
}
class ReportProblemComponent extends React.Component {
	props!:{
		Q_ID:number,
		formvalues:(string|undefined)[],
		A_ID:number,
		feedback:{head:string[],body:FeedbackObject[][]},
		handleOpen:()=>void,
		handleClose:()=>void,
		showdialog:boolean,
	}
//	state:{
//	}
	constructor(props: Readonly<{}>){
		super(props);
//		this.state = {
//			showdialog:false,
//		}
//		this.handleOpen = this.handleOpen.bind(this);
//		this.handleClose = this.handleClose.bind(this);
	}
	render(){
		return(
			<div >
				<div hidden = {!this.props.showdialog}
				>
					<MakeReportContainer
						Q_ID={this.props.Q_ID}
						handleClose={this.props.handleClose}
						formvalues = {this.props.formvalues}
						A_ID = {this.props.A_ID}
						feedback = {this.props.feedback}
						hidden = {!this.props.showdialog}
					/>
				</div>
			</div>
		)
	}
}
class MakeReportContainer extends React.Component {
	props!:{
		Q_ID?:number,
		handleClose:()=>void,
		formvalues:(string|undefined)[],
		A_ID:number,
		feedback:{head:string[],body:FeedbackObject[][]},
		hidden:boolean,
	}
	state:{
		previous_comments:Report[],//{str:string}[],
		comment_text:string,
		timeout:any,
		comment_sent:boolean,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			previous_comments:[],
			comment_text:'',
			timeout:null,
			comment_sent:false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.saveComment = this.saveComment.bind(this);
		this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.componentDidUpdate = this.componentDidUpdate.bind(this);
	}
	componentDidMount(){
		console.log('MakeReportContainer componentDidMount fetching dialog');
		if(this.props.hidden===false){
			this.refreshComments();
		}
	}
	componentDidUpdate(prevProps:this["props"]){
		if(prevProps.hidden===true &&this.props.hidden===false){
			this.refreshComments();
		}
	}
	refreshComments(){
		let url = '/getreports';
		let	data = {
			Q_ID:this.props.Q_ID, 
		};
		fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
			}).then(res => res.json())
			.then(response => {
				console.log('Success:', JSON.stringify(response));
				let latest_comment_str:string = response.comment.str;
				let previous_comments:Report[] = response.previous_comments;
				this.setState({
					comment_text:response.comment.str,
					previous_comments:response.previous_comments,
				})
			})
			.catch(error => console.error('Error:', error));
	}
	handleChange(e:React.ChangeEvent<HTMLTextAreaElement>){
		clearTimeout(this.state.timeout);
		this.setState({
			timeout:setTimeout(
				()=>{this.saveComment()}, 
				1000 //wait 1000 milliseconds
			)
		});
		console.log("just set new timeout");
		this.setState({comment_text:e.target.value})
	}
	saveComment() {
		console.log('saving comment');
		let url = '/savereport';
		let	data = {
			Q_ID:this.props.Q_ID, 
			comment_text:this.state.comment_text,
			formvalues:this.props.formvalues,
			A_ID:this.props.A_ID,
			feedback:this.props.feedback,
		};
			fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
				}).then(res => res.json())
				.then(response => {console.log('Success:', JSON.stringify(response));this.setState({comment_sent:true})})
				.catch(error => console.error('Error:', error));
	}
	handleDialogSubmit(e:React.MouseEvent<HTMLButtonElement>){
		e.preventDefault();
		//TODO: Dialog needs to be remembered when question is loaded/when dialog is toggled.
		var url = '/submitreport';
		var data = {
			Q_ID:this.props.Q_ID, 
			comment_text:this.state.comment_text,
			formvalues:this.props.formvalues,
			A_ID:this.props.A_ID,
			feedback:this.props.feedback,
			//resolvedbyasker:this.state.resolvedbyasker,//TODO
		};
		fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
				}).then(res => res.json())
				.then(response => {
					this.refreshComments();
					this.setState({comment_text:''})
					console.log('Success:', JSON.stringify(response))
				})
				.catch(error => console.error('Error:', error));
	}
	render(){
		let previous_comments = [];
		for(let c = 0; c<this.state.previous_comments.length; c++){
			previous_comments.push(
				<p 
					className="submittedreport"
					>{this.state.previous_comments[c].str}
				</p>
			)
		}
		return(
			<div 
				//className="MakeReportContainer"
				className={"DeleteDialog"}
				//style={{display:"inline"}}
				>
				<div 
					className="MakeReportHeader">
					<h3>Report a problem</h3>
					<button //hidden={true}//
						className="button innavigationbar close"
						style={{
							float:"right",
							display:"inline-block",
							marginTop:"-2em",
						}}
						onClick={this.props.handleClose}
						>x
					</button>
				</div>
				<hr></hr>
				<div
					className="submittedreportscontainer"
					>{previous_comments}
				</div>
				<hr></hr>
				<div
					className="reportinputarea"
					>
					<textarea 
						className="reportinput"
						// rows={3}
						// cols={60}
						value = {this.state.comment_text}
						onChange = {this.handleChange}
						placeholder = "Ask a question, suggest an improvement or report a problem."
						maxLength={2000}
						>
					</textarea>
					<button //hidden={true}//
						className="reportsubmitbutton"
						onClick={this.handleDialogSubmit}
						style={{
							border:"none",
							marginLeft:"0.5em",
							maxWidth:"2.5em",
							background:"none",
							marginTop:"-0.2em",
						}}
						><img src={SEND_ICON}/>
					</button>
				</div>
				<br/>
			</div>
			//<span hidden={!this.state.comment_sent}>Comment received. You can still edit your comment.</span>
		)
	}
}
//class DialogComponent extends React.Component {//NO LONGER WORKS SEE BELOW because I adapted the back end to handle reports instead. easy to have both. 
//	props!:{
//		Q_ID?:number,
//		is_iframe:boolean,
//	//	dialog:Dialog,
//	}
//	state:{
//		showdialog:boolean,
//		comment_text:string,
//		timeout:any,
//		comment_sent:boolean,
//	}
//	constructor(props: Readonly<{}>){
//		super(props);
//		this.state = {
//			showdialog:false,
//			comment_text:'',
//			timeout:null,
//			comment_sent:false,
//		};
//		this.handleToggle = this.handleToggle.bind(this);
//		this.handleChange = this.handleChange.bind(this);
//		this.saveComment = this.saveComment.bind(this);
//		//this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
//	}
//	componentDidMount(){
//		console.log('DiologComponent componentDidMount fetching dialog');
//		let url = '/getdialog';
//		let	data = {
//			Q_ID:this.props.Q_ID, 
//		};
//			fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
//				}).then(res => res.json())
//				.then(response => {console.log('Success:', JSON.stringify(response));this.setState({comment_text:response.comment.comment_text})})
//				.catch(error => console.error('Error:', error));
//	}
//	handleToggle(e:React.MouseEvent<HTMLButtonElement>){
//		e.preventDefault();
//		this.setState({showdialog:!this.state.showdialog});
//	}
//	handleChange(e:React.ChangeEvent<HTMLTextAreaElement>){
//		clearTimeout(this.state.timeout);
//		this.setState({
//			timeout:setTimeout(
//				()=>{this.saveComment()}, 
//				1000 //wait 1000 milliseconds
//			)
//		});
//		console.log("just set new timeout");
//		this.setState({comment_text:e.target.value})
//	}
//	saveComment() {
//		console.log('saving comment');
//		let url = '/savecomment';//!!!!!!!!!!!!!!!!!!!!NO LONGER WORKS
//		let	data = {
//			Q_ID:this.props.Q_ID, 
//			comment_text:this.state.comment_text,
//		};
//			fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
//				}).then(res => res.json())
//				.then(response => {console.log('Success:', JSON.stringify(response));this.setState({comment_sent:true})})
//				.catch(error => console.error('Error:', error));
//	}
//	/*handleDialogSubmit(e:React.MouseEvent<HTMLButtonElement>){
//		e.preventDefault();
//		//TODO: Dialog needs to be remembered when question is loaded/when dialog is toggled.
//		var url = '/submitcomment';//no longer works because I adapted this to mean submit report. 
//		var data = {
//			Q_ID:this.props.Q_ID, 
//			comment_text:this.state.comment_text,
//			//resolvedbyasker:this.state.resolvedbyasker,//TODO
//		};
//		fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
//				}).then(res => res.json())
//				.then(response => console.log('Success:', JSON.stringify(response)))
//				.catch(error => console.error('Error:', error));
//	}*/
//	render(){
//		return(
//			<span style={{display:"inline"}}>
//				<button 
//					className={"button faded"+(this.props.is_iframe?" iframe":"")}
//					onClick={this.handleToggle}
//					>?
//				</button>
//				<div hidden = {!this.state.showdialog}>
//					<textarea 
//						rows={3}
//						cols={60}
//						value = {this.state.comment_text}
//						onChange = {this.handleChange}
//						placeholder = "Ask a question, suggest an improvement or report a problem."
//						>
//					</textarea>
//					<button hidden={true}//onClick={this.handleDialogSubmit}
//					>Submit Feedback</button>
//					<br/>
//					<span hidden={!this.state.comment_sent}>Comment received. You can still edit your comment.</span>
//				</div>
//			</span>
//		)
//	}
//}
export class AddClassComponent extends React.Component {
	props!:{
		autosend?:boolean,
		fetchClasses:()=>void,
		onlyeditting:boolean,
		cohort?:Cohort,
		classname?:string,
	}
	state:{
		showdialog:boolean,
		textvalue:string,
		timeout:any,
		sent:boolean,
		makingclass:false,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			showdialog:false,
			textvalue:'',
			timeout:null,
			sent:false,
			makingclass:false
		};
		this.handleToggle = this.handleToggle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		//this.saveComment = this.saveComment.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleToggle(e:(React.MouseEvent<HTMLHeadingElement>|React.MouseEvent<HTMLButtonElement>)){
		e.preventDefault();
		this.setState({showdialog:!this.state.showdialog});
	}
	handleChange(e:React.ChangeEvent<HTMLInputElement>){
	/*	clearTimeout(this.state.timeout);
		this.setState({
			timeout:setTimeout(
				()=>{this.saveComment()}, 
				1000 //wait 1000 milliseconds
			)
		});
		console.log("just set new timeout");
		*/
		this.setState({
			textvalue:e.target.value,
			sent:false
		})
	}
	/*saveComment() {
		console.log('saving');
		let url = '/savecomment';//no longer works anyway
		let	data = {
			Q_ID:this.props.Q_ID, 
			comment_text:this.state.comment_text,
		};
			fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
				}).then(res => res.json())
				.then(response => {console.log('Success:', JSON.stringify(response));this.setState({comment_sent:true})})
				.catch(error => console.error('Error:', error));
	}*/
	handleSubmit(e:React.MouseEvent<HTMLButtonElement>){
		e.preventDefault();
		this.setState({makingclass:true})
		var url = '/modifyRecord';
		var data;
		if(this.props.onlyeditting===true){
			//let newrecord = this.props.cohort;
			//newrecord.name = this.state.textvalue
			data = {
				action:"edit",
				record_type:"classe",//need the e on so classe+s gives classes
				record:{
					...this.props.cohort,
					name:this.state.textvalue,
				}
				//resolvedbyasker:this.state.resolvedbyasker,//TODO
			};
		}
		else{
			data = {
				action:"createnew",
				record_type:"classe",//need the e on so classe+s gives classes
				record:{
					name:this.state.textvalue,
				}
				//resolvedbyasker:this.state.resolvedbyasker,//TODO
			};
		}
		fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
				}).then(res => res.json())
				.then(response => {console.log('Success:', JSON.stringify(response));this.props.fetchClasses()})
				.then(nothing =>
					this.setState({
						makingclass:false,
						showdialog:false,
					})
					)
				.catch(error => console.error('Error:', error));
	}
	render(){
		let submitbutton = this.state.makingclass?
			(this.props.onlyeditting?"changing class name...":"creating class..."):<button onClick={this.handleSubmit}
		>Submit</button>;
		let togglebutton = this.props.onlyeditting?
			<p onClick={this.handleToggle}>Current class name: {this.props.classname}</p>
			:
			<MyStyledButton
				variant="contained"
				className="MuiButtonBase-root MuiButton-root MuiButton-outlined makeStyles-headerButton-4 MuiButton-outlinedPrimary MuiButton-outlinedSizeLarge MuiButton-sizeLarge"
				onClick={this.handleToggle}
				>Create Class
			</MyStyledButton>
			
		return(
			<span>
				{togglebutton}
				<div hidden = {!(this.state.showdialog||this.props.onlyeditting)}>
					<input
						type="text" 
						value = {this.state.textvalue}
						onChange = {this.handleChange}
						placeholder = {this.props.onlyeditting?"New class name":"Class name"}
						style = {{marginLeft:"1em"}}
						>
					</input>
					{submitbutton}
					<br/>
					<p hidden={!this.state.sent}>Class created with name: {this.state.textvalue}</p>
				</div>
			</span>
		)
	}
	//<span hidden={!this.state.sent}>Comment received. You can still edit your comment.</span>
}
export class QuestionAnswersComponent extends React.Component{
	props!:{
		pupilstoshow:PupilData[],
		pupilsnames:PupilNames[],
		P_index:number|undefined,
		sorted_indices:number[],
		question:QuestionData,
		mode:string
		anonymise:boolean|undefined,
		compact:boolean,
		q:number,
		feedbacktoshow:{head:string[],body:FeedbackObject[][]}[],
		showFeedback:(formvalues:(string|null|undefined)[], Q_ID:number,record_index:number)=>void,
		changeLocalAttemptScore:(attempt:Attempt, field:number, scorechange:number)=>void,
	}
	render(){
		let pupilstoshow = this.props.pupilstoshow;
		let pupilsnames = this.props.pupilsnames;
		let rows = [];
		let question = this.props.question;
		let mode = this.props.mode;
		let q = this.props.q
		for(let po=0;po<pupilstoshow.length;po++){
			let pupil = pupilstoshow[0]; //if just displaying one pupil
			let pupilnames = pupilsnames[0]; //if just displaying one pupil
			if(this.props.P_index===undefined) {// if displaying one question
				pupil = pupilstoshow[this.props.sorted_indices[po]];
				pupilnames = pupilsnames[this.props.sorted_indices[po]];
			}
			rows.push(
				<div key={""+question.Q_ID+"_"+po}
					hidden={mode!=="Q"}
					>{this.props.anonymise?(pupil.attempts.length===0?<div/>:<div><br/></div>):toCamelCase(pupilnames.firstname) + " "+toCamelCase(pupilnames.lastname)}
				</div>
			); //<span>anonymous student</span>
			//	let out_of = this.props.classassignmentdata.questions[this.props.Q_ID].stats.out_of;
			for(let f=0; f<question.field_desc.length;f++){
				if(!question.field_desc[f].isinput){
					continue;
				} else {
					let previous_answer = undefined
					let previous_score = 0;
					let max_score_so_far = 0;
					let show_this_attempt = true;
					for(let a=0; a<pupil.attempts.length; a++){//TODO I think the order of this and the for loop above should switch but I am unsure of what to do with teh previous_answer thing. 
						//let score = pupil.attempts[a].score;
						let score = getattemptmanscore(pupil.attempts[a]);
						if(score===undefined) throw "score is undefined"
						let scoref = score[f];
						if(scoref===null||pupil.attempts[a].maxscore[f]===undefined){
							throw "pupil score should not be null";
						}else{
							if(this.props.compact===false){ show_this_attempt = true;}
							else if(scoref>max_score_so_far) {show_this_attempt = true;}
							else if(''+pupil.attempts[a].useranswer[f]==='') {show_this_attempt = true;max_score_so_far=0;}
							else if(''+previous_answer==='') {show_this_attempt = true;}
							else if(pupil.attempts[a].first===1) {show_this_attempt = true;max_score_so_far=0;}
							else if(a===pupil.attempts.length-1) {show_this_attempt = true;}
							else if(question.field_desc[f].type==="checkbox") {show_this_attempt = true;}
							//if(pupil.attempts[a].maxscore[f]!==undefined){
								if((previous_answer===pupil.attempts[a].useranswer[f])&&previous_score===scoref){
									show_this_attempt = false
								}
								if(show_this_attempt){
									//	console.log("This attempt is the same as the previous one (this just hides duplicate answers that are adjacent)"); //TODO I don't want this handled here, I just don't want a double submit in the first place
								//	} else {
										let display_answer = ''+pupil.attempts[a].useranswer[f];//We have the beginning so an error is thrown if we even change the type of useranswer[f] e.g. to be an object, otherwise we will get js overwriting /pass by reference problems.
										//I thought that I might make it so that the checkbox things are visible. But actually I don't have the question option text available. I would need to pull that through.
										if(display_answer!==undefined&&display_answer!==null){
											if(question.field_desc[f].type==="checkbox"){
												display_answer = formatcheckboxanswer(display_answer);
											} else if(question.field_desc[f].type==="input_numerical_twopart"){
												display_answer = formatnumericalanswer(display_answer);
											} else if(question.field_desc[f].type==="input_self_mark"){
												display_answer = formatselfmarkanswer(display_answer);
											} else if(question.field_desc[f].type==="input_text_long"){
												display_answer = formatlongtextanswer(display_answer);
											}
										} else {
											//TODO think about what this means
										}
										let oldscore = pupil.attempts[a].score;
										let display_score = "";
										if(oldscore===undefined){
										} else {
											let fields_score = getattemptmanscore({score:oldscore,manscore:pupil.attempts[a].manscore});
											display_score = ''+fields_score[f]+"/"+pupil.attempts[a].maxscore[f];
										}
										//let percentage = Number(score[f])/Number(pupil.attempts[a].maxscore[f])
										let percentage = Number(score[f])/Number(pupil.attempts[a].maxscore[f])
										let correctness = percentage===0?"incorrect"
										:percentage===1?"correct"
										:"incomplete";
										rows.push(
											<div key={""+question.Q_ID+"_"+po+"_"+f+"_"+a}
												className={"pupil_answer "+correctness}
												onClick={(e)=>{
													e.preventDefault();
												//	console.log("div.target = "+e.target+" div.currentTarget= "+e.currentTarget);
												//	console.log(e.target != e.currentTarget); This works, you can use it as an if statement to prevent this from responding to people clicking on buttons on top of this div.
													this.props.showFeedback(pupil.attempts[a].useranswer,question.Q_ID,mode==="Q"?po:q)
												}}
												>
													<span 
														className="useranswerouter"
													//style={{display:"inline",marginRight:"4em"}}
													>
															{display_answer}
													</span>
													{display_answer.length===0?<span></span>:
														<span 
															className="manualAdjust"
														//	style={{float:"right"}}
															>{display_score}
															<button
																onClick={(e)=>{
																	e.stopPropagation(); 
																	e.preventDefault(); 
																	console.log("attempt "+ JSON.stringify(pupil.attempts[a],null,4));
																	changeAttemptScore(pupil.attempts[a],f,1)
																	this.props.changeLocalAttemptScore(pupil.attempts[a],f,1);
																}}//,this.props.class.ID)}}
																>➕{/*&#10133;*/}
															</button>
															<button
																onClick={(e)=>{
																	e.stopPropagation(); 
																	e.preventDefault(); 
																	changeAttemptScore(pupil.attempts[a],f,-1);
																	this.props.changeLocalAttemptScore(pupil.attempts[a],f,-1);
																}}
																>➖{/*&#10134;*/}
															</button>
														</span>
													}
											</div>
										)
								//	}
								} else {
									//rows.push(
									//	<div key={""+question.Q_ID+"_"+po+"_"+f+"_"+a}
									//		className={"pupil_answer mini "+correctness}
									//	//	onClick={e=>{e.preventDefault();
									//	//		this.showFeedback(pupil.attempts[a].useranswer,question.Q_ID,mode==="Q"?po:q)
									//	//	}}
									//		>__
									//	</div>
									//)
								}
								previous_answer = pupil.attempts[a].useranswer[f];
								previous_score = scoref;
								max_score_so_far = Math.max(max_score_so_far,scoref);
								show_this_attempt = false;
								//<tr><td
								//</td></tr>
						}
					}

					if(this.props.feedbacktoshow[mode==="Q"?po:q]!==undefined){
						loggy(this.props.feedbacktoshow[mode==="Q"?po:q],"this.state.feedbacktoshow[po]");
						loggy(f,"f");
						loggy(question.field_desc,"question.field_desc");
						loggy(question.field_desc[f],"question.field_desc[f]");
						rows.push(
							<FeedbackBox
								key={""+question.Q_ID+"_"+(mode==="Q"?po:q)+"_"+f}
								faded={false}
								head={this.props.feedbacktoshow[mode==="Q"?po:q].head[f]}
								feedbackbody={this.props.feedbacktoshow[mode==="Q"?po:q].body[f]}
								isselfmark={question.field_desc[f].type==="input_self_mark"}
							/>
						)
					}
				}
			}
		}
		return(
			<div>
				{rows}
			</div>
		)
	}
}
export class QuestionAnswersComponentWrapper extends React.Component{
    //used in the question creater and also in the report viewer
    props!:{
        Q_ID:number,
        displayed:boolean,
        U_ID?:number, //this is for if called in the report place
    }
    state:{
		feedbacktoshow:{head:string[],body:FeedbackObject[][]}[],
        classassignmentdata:ClassAssignmentData|undefined,
        user?:User|null, //what is this used for
    }
    constructor(props: Readonly<{}>){
        super(props);
        this.state = {
			feedbacktoshow:[],
			classassignmentdata:undefined,
        }
		this.getandsetPupilAnswers = this.getandsetPupilAnswers.bind(this);
		this.fetchPupilAnswers = this.fetchPupilAnswers.bind(this);
		this.showFeedback = this.showFeedback.bind(this);
		this.changeLocalAttemptScore = this.changeLocalAttemptScore.bind(this);
    }
    componentDidMount(){
        this.getandsetPupilAnswers();
        this.getUserInfo();
    }
    componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
        if(prevProps.displayed==false&&this.props.displayed===true){
            //console.log("props found to have changed, so fetch question list");
            this.getandsetPupilAnswers();
        } else {
            //console.log("props were found to be unchanged")
        }
    }
    async getandsetPupilAnswers(){
        if(this.props.Q_ID!==undefined){
            let jsonout = await this.fetchPupilAnswers(this.props.Q_ID,this.props.U_ID);
            this.setState(
                {classassignmentdata:jsonout.data}
                )
        }
    }
    async fetchPupilAnswers(Q_ID:number,U_ID?:number):Promise<{data:ClassAssignmentData}>{
        let url = '/questionattemptdata';
        let data = {Q_ID:Q_ID,
            U_ID
            };
        let res = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
            })
        return res.json();
    }
    async getUserInfo():Promise<User>{
		console.log("getUserInfo called");
		let response = await fetch("/userinfo")
		let js_obj:{user:User,undefinedcookies?:boolean} = await response.json()
		console.log("...response from /userinfo: "+JSON.stringify(js_obj,null,4)+`
		setting as user (in state)`);
		//cookiesdisabled = js_obj.undefinedcookies;
		this.setState({
			user: js_obj.user,
			cookiesdisabled: js_obj.undefinedcookies
		})
		return js_obj.user
	}
    async showFeedback(formvalues:(string|null|undefined)[], Q_ID:number,record_index:number){
		console.log("showFeedback for "+record_index);
		loggy(formvalues,"formvalues");
		let feedbacktoshowtemp = this.state.feedbacktoshow;
		feedbacktoshowtemp = [];
		feedbacktoshowtemp[record_index] = await getFeedback(formvalues, Q_ID);
		this.setState({
			feedbacktoshow:feedbacktoshowtemp,
		})
	}
    changeLocalAttemptScore(attempt:Attempt, field:number, scorechange:number){
		if(this.state.classassignmentdata===undefined) throw "classassignmentdata undefined";
		let question_index = this.state.classassignmentdata.questions.findIndex(q=>q.Q_ID===attempt.Q_ID);
		let pupil_index = this.state.classassignmentdata.questions[question_index].pupils.findIndex(u=>u.U_ID===attempt.U_ID);
		let attempt_index = this.state.classassignmentdata.questions[question_index].pupils[pupil_index].attempts.findIndex(a=>a.A_ID===attempt.A_ID);
		let oldattempt = this.state.classassignmentdata.questions[question_index].pupils[pupil_index].attempts[attempt_index];
		if(this.state.user===undefined||this.state.user===null) throw "globaluser isn't defined or is null"
		let marker_ID:number = this.state.user.U_ID; //TODO want user info
		let marker_type:MarkerType = "admin";//TODO want user info
		let newState = update(this.state, 
			{
				classassignmentdata:{
					questions: {
						[question_index]: {
							pupils: {
								[pupil_index]:{
									attempts:{
										[attempt_index]:{
											$set:updateManscore(oldattempt,marker_ID,marker_type,scorechange,field)
										},
									},
								},
							},
						},
					},
				},
			}
		);
		this.setState(newState);
	}
    render(){
        if(this.props.Q_ID===undefined){
            console.log("this.props.Q_ID===undefined");
            return <p>Question hasn't been saved yet (note that if you are a question creator tool then submitting creates a question in the background but you are now editting a new question).</p>
        } else if(this.state.classassignmentdata===undefined){
            console.log("this.state.classassignmentdata===undefined")
            return <p>Data on previous answers hasn't loaded yet.</p>
        } else {
            let question = this.state.classassignmentdata.questions[0];
            if(question.pupils.length===0){
                return (<p>There are no pupils who have attempted this question yet</p>)
            } else {
                let countingarray = Array(question.pupils.length);
                for(let i = 0; i<countingarray.length; i++){
                    countingarray[i]=i;
                }
                return(
                    <QuestionAnswersComponent
                        pupilstoshow={question.pupils} //PupilData[]
                        pupilsnames={countingarray}
                        P_index={undefined}
                        sorted_indices={countingarray}
                        question={question}
                        mode={"Q"}
                        anonymise={true}
                        compact={false}
                        q={0}
                        feedbacktoshow={this.state.feedbacktoshow}
                        showFeedback={this.showFeedback}
                        changeLocalAttemptScore={this.changeLocalAttemptScore}
                    />
                )
            }
        }
    }
}
async function fetchQuestionAttemptData(Q_ID:number):Promise<ClassAssignmentData>{
	let response = await fetch('/questionattemptdata', {
		method: 'POST',
		body: JSON.stringify({
			Q_ID
		}),
		headers:{'Content-Type': 'application/json'	}
	}) //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	let js_obj = await response.json();
	return js_obj.data;
}



var arraysMatch = function (arr1:any[], arr2:any[]) {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

}
function setSelectionRange(input:any, selectionStart:number, selectionEnd:number) {
	if (input.setSelectionRange) {
	  input.focus();
	  input.setSelectionRange(selectionStart, selectionEnd);
	}
	else if (input.createTextRange) {
	  var range = input.createTextRange();
	  range.collapse(true);
	  range.moveEnd('character', selectionEnd);
	  range.moveStart('character', selectionStart);
	  range.select();
	}
  }
  
  function setCaretToPos (input:any, pos:number) {
	setSelectionRange(input, pos, pos);
  }
  interface QuestionsToPracticeProps {
	stats:ClassAssignmentData,
	questions:Question[],
	isdisplayed:boolean,
	updateQScore:()=>void,//(Q_ID:number,score:(null|number)[],maxscore:number[])=>void,
	updateQButtonColour:(Q_ID:number,score:(null|number)[],maxscore:number[])=>void,
	numQleft:number,
	W_ID:number,
	newQPercentages:(number|undefined)[],
}
export class QuestionsToPractice extends React.Component{
	props!:QuestionsToPracticeProps

	 //TODO should I be feeding in the worksheet to this component?
	state: {
		selectedQ_ID:number|undefined,
		first:1|undefined, //1 means it is the first attempt for this question since reset, undefined means that it is not. To be recorded in attempts.
		Qnum:number, //this counts how many questions the user has flicked through in summary questions, we use it to alter the key of the selected question component, so that it refreshes even when there is only one question left and the Q_ID doesn't change.
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			selectedQ_ID:undefined,
			first:1,
			Qnum:0,
		}
		this.setQuestion = this.setQuestion.bind(this);
	}
	componentDidMount(){
		console.log("QuestionsToPractice componentDidMount with W_ID: "+this.props.W_ID);
		this.setQuestion()
	}
	componentDidUpdate(prevProps:QuestionsToPracticeProps){//bit dodge but whatever
		console.log("QuestionsToPractice componentDidUpdate with W_ID: "+this.props.W_ID);
		if(this.props.isdisplayed){
			if(this.props.stats.pupilswsstats===undefined||prevProps.stats.pupilswsstats===undefined){
				throw "this.props.stats.pupilswsstats===undefined"
			} else {
				//let num_attempts = this.props.stats.pupilswsstats[0].num_attempts;
				//let prevNum_attempts = prevProps.stats.pupilswsstats[0].num_attempts;
				//let newanswersubmitted = num_attempts!==prevNum_attempts;
				console.log("this.props.newQPercentages "+this.props.newQPercentages);
				console.log("this.props.newQPercentages.length "+this.props.newQPercentages.length);
				let newanswersubmitted = this.props.newQPercentages.length>0;
				let justdisplayed = (prevProps.isdisplayed===false)&&(this.props.isdisplayed===true);
				let needtosetaquestion = ((this.state.selectedQ_ID===-1)&&this.props.numQleft>0);
				if(justdisplayed||(newanswersubmitted)||needtosetaquestion){
					console.log("Decided to call setQuestion since justdisplayed="+justdisplayed+" newanswersubmitted "+newanswersubmitted+" needtosetaquestion "+needtosetaquestion);
					this.setQuestion();
				}
			}
		}
	}
	setQuestion(){

		console.log("setQuestion called newQPercentages = "+this.props.newQPercentages);
		
		if(this.props.newQPercentages.length>0){
			
			console.log("in setQuestion decided to updateQScore, should be not zero "+this.props.newQPercentages.length);
			
			this.props.updateQScore(); //this is here so that we get the latest attempt data so we can pick the right next question.  
		
		} else { //only pick a question once we have the data, this will be run when the component is updated via props as a result of updateQScore()
			
			console.log("in setQuestion decided to updateQScore, (should be zero"+this.props.newQPercentages.length);
			
			let selectedQ_ID = pickQuestion(this.props.stats);
			if((this.props.numQleft>0)&&selectedQ_ID==-1){
				throw "this.props.numQleft>0 but couldn't find question to pick"
			}
			let newQnum = this.state.Qnum+1;
			this.setState({
				selectedQ_ID,
				first:1,
				Qnum:newQnum,
			})
			console.log("setQuestion: "+selectedQ_ID);
			console.log("newQnum: "+newQnum);
		}
	}
	render(){
		console.log("rendering QuestionsToPractice")
		if(this.state.selectedQ_ID===undefined){
			return(
				<p>Loading question to practise.</p>
			)
		} else if(this.state.selectedQ_ID===-1){
			return(
				<p>Could not find a question that needs practise.</p>
			)
		} else {
			if(this.props.questions===undefined){
				throw "this.props.questions is undefined in QuestionsToPractice, App.tsx"
			}
            let questiontoshow = this.props.questions.find(question=>question.Q_ID===this.state.selectedQ_ID);
			if(questiontoshow===undefined){
				console.log("this.state.selectedQ_ID: "+JSON.stringify(this.state.selectedQ_ID,null,4))
				console.log("this.props.questions: "+this.props.questions.length)
				console.log("questiontoshow: "+JSON.stringify(questiontoshow,null,4))
				throw "could not find question in QuestionsToPractice"
			}
			console.log("about to return QuestionsToPractice")

			let Qnum_inWS = getQnuminws(this.props.stats.questions,this.state.selectedQ_ID)

			return(
				//NOTE: You are looking at the questions to practice here
				<div
					className="questionstopractice"
					key={this.state.selectedQ_ID}
				>
					<Questionpage
						key = {""+questiontoshow.Q_ID+ this.state.Qnum}//just added without thinking, hopefully not a problem. 
						question = {questiontoshow}
						Qnum_inWS = {Qnum_inWS}
						action = "createnew"
						formvalues = {create_empty_formvalues(questiontoshow)}
					//	showSubmit = {true}
						isdisplayed = {true}//TODO get from above
						nextQuestion = {this.setQuestion}
						onQsubmit = {()=>this.setState({first:undefined})}
						showingfeedback = {false}
						first = {this.state.first}
						W_ID={this.props.W_ID}
						updateQButtonColour={this.props.updateQButtonColour}
						//inworksheet={true}//TODO surely this comes if we are passing in a W_ID?
						inquestionstopractice={true}
					/>
					<div
						className={"alignpreviousandnext"}>
					<button
						onClick={this.setQuestion}
						className={"button"}//+(this.state.nextQActive?" active":"")}
						//ref={this.nextQRef}
						//Next
						style={
							{float:"right",
							maxWidth:"15%"}
						}
						>{">>"}
					</button>
					</div>
				</div>
			)
		}
	}
}
/**
 * Finds the first index of the question in questions with Q_ID matching selectQ_ID
 * Returns 1+ that index, since that gives the question number for that question as would be presented to a user.
 * @param questions QuestionData[]
 * @param selectQ_ID number
 * @returns number
 */
function getQnuminws (questions:QuestionData[],selectedQ_ID:number):number{
	let selectedQindex = questions.findIndex((question)=>{
		return question.Q_ID===selectedQ_ID
	})
	if (!(selectedQindex>-1)) throw "question with selectedQ_ID not found"
	else {
		return selectedQindex +1
	}
}
function pickQuestion(stats:ClassAssignmentData):number{
	console.log("picking question");
	let questionsstats = stats.questions;
//	console.log("questionsstats: "+questionsstats.length);
	let resetnumber = getResetNumber(stats);
//	console.log("resetnumber: "+resetnumber);
	
	//ignore questions that we havn't ever gotten right
	let questionsCorrectlyAnswered = getCorrectlyAnsweredQs(questionsstats,resetnumber);
//	console.log("questionsCorrectlyAnswered: "+questionsCorrectlyAnswered.length);
	
	//ignore questions that we got right from day 1
	//TODO change to ignore questions that we got right after a first attempt
	let questionsToConsider = getQsNotYetDoneCorrectlyWoHelp(questionsCorrectlyAnswered,resetnumber);

//	console.log("questionsToConsider: "+questionsToConsider.length);
//	for (let q=0; q<questionsToConsider.length; q++){
//		let qstatshere = stats.questions.find((Q)=>Q.Q_ID===questionsToConsider[q].Q_ID);
//		if(qstatshere===undefined){
//			throw "qstatshere is undefed"
//		}
//		console.log("questionsToConsider: "+q+": "+questionsToConsider[q].Q_ID+", "+JSON.stringify(qstatshere.pupils[0].stats,null,4));
//	}
	
	//Pick the question that we attempted the longest ago
	if (questionsToConsider.length===0) {
		console.log("pickQuestion result: bestQ_ID: -1");
		return -1
	}
	else {
		let bestQ_ID = questionsToConsider[0].Q_ID;
		let bestQattempts = questionsToConsider[0].pupils[0].attempts
		let oldestlastmoddate = bestQattempts[bestQattempts.length-1].info.last_mod_date;
		for (let q = 0 ; q<questionsToConsider.length; q++){
			let qstats = questionsToConsider[q];
			let attempts = qstats.pupils[0].attempts;
			let lastattempt = attempts[attempts.length-1];
			let lastmoddate = lastattempt.info.last_mod_date;
			if(lastmoddate<oldestlastmoddate){
				bestQ_ID = qstats.Q_ID;
			}
		}
		console.log("pickQuestion result: bestQ_ID: "+bestQ_ID);
		return bestQ_ID;
	}

}
function getCorrectlyAnsweredQs(questionsstats:QuestionData[],resetnumber:number){
	return questionsstats.filter(questionstats=>{
		let qps = questionstats.pupils[0].stats;
		if(qps===undefined){throw "qps should be defined"}
		else {
			if(resetnumber>0){
				return qps.reset_percentage === 1
			} else {
				return qps.max_percentage === 1
			}
		}
	});
}
function getQsNotYetDoneCorrectlyWoHelp(questionsCorrectlyAnswered:QuestionData[],resetnumber:number){
	//New method:
	//Get questions that have a resetwoh_percentage < 1
	//But a reset_percentage ===1
	return questionsCorrectlyAnswered.filter(questionstats=>{
		let qpstats = questionstats.pupils[0].stats;
		if(qpstats===undefined){throw "qps should be defined"}
		else {
			//if they have reset, then we only look at resetwoh_percentage
			if(resetnumber>0){
				//return true if they haven't got it right without help yet
				return !(qpstats.resetwoh_percentage===1)
			} else {
				//if(qpstats.wohelp_score===undefined){ throw "qpstats.wohelp_score is undefined"}
				//if(questionstats.stats===undefined){ throw "questionstats.stats is undefined"}
				//if(questionstats.stats.tot_max_score===undefined){ throw "questionstats.stats.tot_max_score is undefined"}
				return !isCorrectwohelp(questionstats);
			}
		}
	});
	
	//OLD method:
	//why did I change it?
	//1) finds questions that have were initially answered incorrectly
	//2) 
	let questions_incorrect_on_very_first_attempt = questionsCorrectlyAnswered.filter(questionstats=>{
		let qpstats = questionstats.pupils[0].stats;
		if(qpstats===undefined){throw "qps should be defined"}
		else {
			//return qpstats.initial_percentage !== 1
			return qpstats.resetwoh_percentage !== 1
		}
	});
	let questionsToConsider = questions_incorrect_on_very_first_attempt.filter(questionstats=>{//questionsincorrectonanyfirstattempt
		let attempts = questionstats.pupils[0].attempts;
		let firsttimecorrectattempt = attempts.findIndex(attempt=>{
			if(attempt.score===undefined){throw "attempt.score is undefined"}
			return (attempt.first===1)&&(1===getpercentagescore(attempt.score,attempt.maxscore))
		})
		return !(firsttimecorrectattempt>-1)
	});
	return questionsToConsider
}
export function getResetNumber(pupilassignmentdata:ClassAssignmentData):number{
	let resetkey:number = 0;

	if(pupilassignmentdata.pupilswsstats!==undefined){
		if(pupilassignmentdata.pupilswsstats[0]!==undefined){
			if(pupilassignmentdata.pupilswsstats[0].reset_dates!==undefined){
				resetkey = pupilassignmentdata.pupilswsstats[0].reset_dates.length;
			}
		}
	}
	return resetkey;
}
export class SummaryPage extends React.Component{
	props!:{
		stats:ClassAssignmentData,
		questions:Question[],
		onQuestionSelect:(i:number)=>void,
		isdisplayed:boolean,
		updateQScore:()=>void,//Q_ID:number,score:(null|number)[],maxscore:number[])=>void,
		updateQButtonColour:(Q_ID:number,score:(null|number)[],maxscore:number[])=>void,
		// setPage:(page:PageName)=>void,
		W_ID:number,
        resetWorksheet:()=>void,
		newQPercentages:(number|undefined)[],
		globaluser:User|null|undefined,
    }
	render(){
		console.log("rendering SummaryPage");
		let globaluser = this.props.globaluser
		if(this.props.stats.pupilswsstats===undefined){
			return(
				<p>stats page not ready</p>
			)
		//} else if (this.props.stats.pupilswsstats[0].initial_percentage===undefined){
		//	return(
		//		<p>stats page not ready</p>
		//	)
		}else {
			let incompletequestionbuttons = [];
			let wohelpquestionbuttons = [];
			let whelpquestionbuttons = [];

			//loop through questions
			for(let i = 0; i<this.props.stats.questions.length; i++){
				let colourOfButton = "";
				let pstat = this.props.stats.questions[i].pupils[0].stats;
				if(pstat===undefined) throw "pstat is not defined"

				//set colour of button
				colourOfButton = colourNameFromPercent(pstat.max_percentage);
				let reset_number = getResetNumber(this.props.stats);
				if(reset_number>0){
					colourOfButton = colourNameFromPercent(pstat.reset_percentage);//.max_percentage);
				}

				let correctwohelp = isCorrectwohelp(this.props.stats.questions[i]);

				let correctwohelp_since_reset = (pstat.resetwoh_percentage === 1);

			//	console.log("pstat.resetwoh_percentage"+ pstat.resetwoh_percentage);
			//	console.log("pstat: "+ JSON.stringify(pstat,null,4));
			//	if(firsttimecorrectattempt){
			//		console.log("firsttimecorrectattempt: "+firsttimecorrectattempt);
			//		console.log("correctwohelp: "+correctwohelp);
			//	}

				if(colourOfButton!=="green"){
					incompletequestionbuttons.push(
						<QuestionButton
							key={"QB"+i}
							i={i}
							colourOfButton={colourOfButton}
							iscurrentquestion={true}
							onQuestionSelect={this.props.onQuestionSelect}
						/>
					)
				//} else if(correctwohelp){
				} else if(correctwohelp_since_reset||((reset_number==0)&&correctwohelp)){
					wohelpquestionbuttons.push(
						<QuestionButton
							key={"QB"+i}
							i={i}
							colourOfButton={colourOfButton}
							iscurrentquestion={true}
							onQuestionSelect={this.props.onQuestionSelect}
						/>
					)
				} else {//completed with help
					whelpquestionbuttons.push(
						<QuestionButton
							key={"QB"+i}
							i={i}
							colourOfButton={colourOfButton}
							iscurrentquestion={true}
							onQuestionSelect={this.props.onQuestionSelect}
						/>
					)
				}
			}
			let stats = this.props.stats.pupilswsstats[0];
			if(stats.initial_percentage===undefined||stats.max_percentage===undefined||stats.wohelp_percentage===undefined){
				throw "initial_percentage===undefined"+ "stats: "+JSON.stringify(stats,null,4)
			} else {
				let initial_percentage = Math.round(stats.initial_percentage*100);
				let max_percentage = Math.round(stats.max_percentage*100);
				let woh_percentage = Math.round(stats.wohelp_percentage*100);
				let reset_percentage = Math.round((stats.reset_percentage||0)*100);
				let resetwoh_percentage = Math.round((stats.resetwoh_percentage||0)*100);
				if(globaluser===null||globaluser==undefined){
					throw "globaluser is undefined or null"
				}
				let username = globaluser.username||"";
				let nonetopractce = (whelpquestionbuttons.length===0);
				let hasreset = false;
				let reset_dates = stats.reset_dates;
				if(reset_dates!==undefined){
					if(reset_dates.length>0){
						hasreset = true;
					}
				}
				console.log("About to return summary page")
				return(
					<div>
						<h2>Summary page</h2>
						<h3 style={{textAlign:"center"}}><u>{username}</u></h3>
						<h3>Scores</h3>
						<p>Your first attempt percentage was: {initial_percentage}%</p>
						<p>Your best attempt percentage was: {max_percentage}%</p>
						<p hidden={!((max_percentage-initial_percentage)>0)}>Well done! You improved by: {max_percentage-initial_percentage}%</p>
						<p>You've completed: {woh_percentage}%  without help!</p>
						<p hidden={!hasreset}>Since last reset, you've completed: {reset_percentage}%</p>
						<p hidden={!hasreset}>Since last reset, you've completed: {resetwoh_percentage}%  without help!</p>
						<br/>
						<h3>Incomplete questions</h3>
						<p hidden={!(incompletequestionbuttons.length===0)}>Well done! You have completed all of the questions.</p>
						{incompletequestionbuttons}
						<br hidden={(incompletequestionbuttons.length===0)}/>
						<br/>
						<h3>Questions completed without help</h3>
						{wohelpquestionbuttons}
						<br/>
						<br/>
						<h3>Questions completed with help</h3>
						<p hidden={!nonetopractce}>No questions to practise. Questions appear here if you have completed them before but required help to do so.</p>
						<div hidden={nonetopractce}>{whelpquestionbuttons}</div>
						<br hidden={nonetopractce}/>
						<br/>
						<h3 hidden={nonetopractce}>Questions to practise</h3>
						<p hidden={nonetopractce}>Try to answer the question below without help.</p>
						<div hidden={nonetopractce}>
							<QuestionsToPractice
								stats={this.props.stats}
								questions={this.props.questions} // possibly erroring here
								isdisplayed={this.props.isdisplayed}
								updateQScore={this.props.updateQScore}
								updateQButtonColour={this.props.updateQButtonColour}
								numQleft={whelpquestionbuttons.length}
								W_ID={this.props.W_ID}
								newQPercentages={this.props.newQPercentages}
							/>
						</div>
						<h3>Retry worksheet</h3>
						<p>Reset this worksheet so you can attempt it from scratch without seeing your previous answers. You will still be able to see scores on the exercises page and your teacher will still be able to see your existing work.</p>
						<button //style={{fontSize:"14px"}}
							className="button"
							onClick={(e)=>{e.preventDefault();this.props.resetWorksheet()}}
							>Reset
						</button>
                        <h3>Other exercises</h3>
						<p>Access other worksheets from the 'Questions' page.</p>
						<Link //style={{fontSize:"14px"}}
							className="button"
							// onClick={(e)=>{e.preventDefault();this.props.setPage("exercises")}} //Louis check
							to='/exercises'
							>Home
						</Link>
						<br/>
					</div>
				)
			}
		}
	}
}

/**
 * set correctwohelp if scored 100% on initial attempt or when .first === 1
 * in theory this is the same logic used:
 * to determine summary score in multiple places
 * this figure includes post and pre-reset attempts
 * @param questiondata QuestionData
 * @param pstat 
 * @returns 
 */
function isCorrectwohelp(questiondata:QuestionData){

	let pstat = questiondata.pupils[0].stats;
	if(pstat===undefined) throw "pstat is not defined"

	let attempts = questiondata.pupils[0].attempts;

	let firsttimecorrectattempt = attempts.findIndex(attempt=>{
	//	if(attempt.first===1){
	//		console.log("YAY: "+attempt.Q_ID+" attempt.score: "+attempt.score+" attempt.maxscore: "+attempt.maxscore);
	//	}
		if(attempt.score===undefined){throw "attempt.score should not be undefined"}
		return ((attempt.first===1)&&(1===getpercentagescore(attempt.score,attempt.maxscore)))
	})
	return (pstat.initial_percentage === 1)||(firsttimecorrectattempt>-1)//don't really understand what firstimecorrectattempt hmmm, it is just set above, when percentage score is 100 % and it is a first attempt, it returns that attempt. 
}
interface FlashCard {
	Q_ID:number,
	answered_correctly_first_time:boolean,
	just_attempted:boolean,
	num_attemps:number,
};
export class FlashcardWorksheetComponent extends React.Component{
	props!: {
		worksheet_ID:number
		globaluser:User|null|undefined,
	} //TODO should I be feeding in the worksheet to this component?
	state: {
		//worksheetanswers:string[][],
	//	pupilassignmentdata:ClassAssignmentData|undefined,
		questions:Question[],
		currentquestionindex:number,
		worksheet:Worksheet,
	//	pupilanswers?:PupilAnswers,
		nextQActive:boolean,
		flashcards:FlashCard[],
		deckorder:number[],
	}
	nextQRef:React.RefObject<HTMLButtonElement>
	constructor(props: Readonly<{}>)
	{
		super(props);
		this.nextQRef = React.createRef();
		this.state = {
			//worksheetanswers:string[][],
	//		pupilassignmentdata:undefined,
			questions:[],
			currentquestionindex:0,
			worksheet:create_empty_worksheet(), //TODO we havn't got the worksheet yet, surely we get it when the worksheet is clicked
			nextQActive:false, //determines if the nextbutton is visible
			flashcards:[],
			deckorder:[],
		}
		this.getworksheet = this.getworksheet.bind(this);
		this.getquestions = this.getquestions.bind(this);
	//	this.onQuestionSelect = this.onQuestionSelect.bind(this);
		this.isdisplayed = this.isdisplayed.bind(this);
	//	this.get_previous_answers_by_Q_IDs=this.get_previous_answers_by_Q_IDs.bind(this);
		this.nextquestion = this.nextquestion.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
	//	this.prevquestion = this.prevquestion.bind(this);
		this.updateQScore = this.updateQScore.bind(this);
		this.setButtonStatusNav = this.setButtonStatusNav.bind(this);
	}
	async componentDidMount() {
		await this.getworksheet();

	//	this.get_previous_answers_by_Q_IDs();
	//	let pupilassignmentdata = await fetchPupilAssignmentData(this.state.worksheet.ID);
	//	pupilassignmentdata=calcScores(pupilassignmentdata);
	//	this.setState({
	//		pupilassignmentdata
	//	})
	}
	//async updateQScore(Q_ID:number,score:number){
	//	let pupilassignmentdata = await fetchPupilAssignmentData(this.state.worksheet.ID);
	//	pupilassignmentdata=calcScores(pupilassignmentdata);
	//	this.setState({
	//		pupilassignmentdata
	//	})
	//}
	async updateQScore(Q_ID:number,score:(number|null)[],maxscore:number[]){
		console.log("updateQScore called in FlashcarWorksheetComponent");
		let percentage = getpercentagescore(score,maxscore);
		loggy(percentage,"percentage");
		let flashcardIndex = this.state.flashcards.findIndex((flashcard:FlashCard)=>{
			return flashcard.Q_ID===Q_ID;
		})
		if(percentage===1){
			this.setButtonStatusNav("nextQ","activate");
		//	this.setState({flashcards[]})
			if(this.state.flashcards[flashcardIndex].just_attempted===false){
				let tempdeck:number[] = copy(this.state.deckorder);
				let elementtomove = tempdeck.shift();
				loggy(elementtomove,"elementtomove");
				if(elementtomove===undefined) throw "elementtomove undefined"
				tempdeck.push(elementtomove);
				loggy(tempdeck,"tempdeck");
				let newState = update(this.state, {
					flashcards: {
						[flashcardIndex]: {
							answered_correctly_first_time:{$set:true},
							just_attempted:{$set:false},
						}
					},
					deckorder:{$set:tempdeck}
				})
				this.setState(newState);
			} else {
				let tempdeck:number[] = copy(this.state.deckorder);
				let elementtomove = tempdeck.shift();
				loggy(elementtomove,"elementtomove");
				if(elementtomove===undefined) throw "elementtomove undefined"
				tempdeck.splice(3,0,elementtomove);
				loggy(tempdeck,"tempdeck");
				let newState = update(this.state, {
					flashcards: {
						[flashcardIndex]: {
						//	answered_correctly_first_time:{$set:true},
							just_attempted:{$set:false},
						}
					},
					deckorder:{$set:tempdeck}
				})
				this.setState(newState);
			}
		} else {
			let newState = update(this.state, {
				flashcards: {[flashcardIndex]: {
				//	answered_correctly_first_time:{$set:false},
					just_attempted:{$set:true},
					}
				},
				nextQActive:{$set:false},//TODO possibly dodgy? why are we setting nextQActive to false when we updatethescore? because we do it when they have perentage<0
			})
			this.setState(newState);
		}
	}
	setButtonStatusNav(button:"submit"|"nextQ",action:"activate"|"deactivate",dontfocus?:boolean):void{
		console.log("1#########################################")
		if(button==="nextQ"){
			if(action==="activate"){
				if(this.nextQRef.current!=null&&dontfocus!==true){
					this.nextQRef.current.focus();
				}
				this.setState({nextQActive:true})
			} else {
				console.log("##################################################################")
				if(this.nextQRef.current!=null){
					this.nextQRef.current.blur();
				}
				this.setState({nextQActive:false})//this possibly slows things down?
			}
		} //else if(button==="prevQ"){
		//	this.setState({prevQActive:action==="activate"})
		//}
	}
//	handleScores(pupilassignmentdata:ClassAssignmentData){
//		pupilassignmentdata.questions.forEach((question)=>{
//			let attempts = question.pupils[0].attempts;
//		})
//	}
	async getworksheet(){
		try {
			let worksheet = await fetchworksheet(this.props.worksheet_ID);
			console.log("fetching questions we can't have the first question until below here");
			let output = await this.getquestions(worksheet);
			this.setState({
				flashcards:output.flashcards,
				questions:output.questions,
				deckorder:output.deckorder,
				worksheet:worksheet,
			});
		}
		catch (error) {
			console.error('Error:', error);
		}
	}
	//async get_previous_answers_by_Q_IDs(){
	//	try {
	//		console.log("fetching pupilsanswers");
	//		let pupilsanswers = await get_previous_answers_by_Q_IDs([],this.state.worksheet.Q_IDs);
	//		console.log("got pupilsanswers: "+JSON.stringify(pupilsanswers[0],null,4));
	//		console.log("got pupilsanswers: "+pupilsanswers[0]);
	//		//let pupilanswers = pupilsanswers[0]
	//		this.setState({pupilanswers:pupilsanswers[0]});//select the first pupils' answers since in the context of a worksheet we curently just want the current users answers.
	//		setTimeout(() => {console.log("fetched pupilsanswers and set state: "+this.state.pupilanswers);}, 500);
	//	}
	//	catch (error) {
	//		console.error('Error:', error);
	//		throw "tried and failed to get_previous_answers_by_Q_IDs"
	//	}
	//}
	async getquestions(worksheet:Worksheet):Promise<{questions:Question[],flashcards:FlashCard[],deckorder:number[]}> {
		let questions = await fetchquestionsbyID(worksheet.Q_IDs);
		let flashcards:FlashCard[] = [];
		let deckorder:number[]= [];
		for(let q=0; q<questions.length; q++){
			flashcards.push({
				Q_ID:questions[q].Q_ID,
				answered_correctly_first_time:false,
				just_attempted:false,
				num_attemps:0,
			});
			deckorder.push(q);
		}
		return{
			questions,
			flashcards,
			deckorder
		};
	}
	nextquestion(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		this.nextQuestion();
	}
	nextQuestion(){
	//	let newquestionindex = this.state.currentquestionindex;
		let newquestionindex = this.state.deckorder[0];
	//	newquestionindex++;
		if (newquestionindex>=this.state.questions.length){
			throw "trying to change questionindex to point beyond the set of questions, shouldn't have been able to click next question on last question"
		}
		else {
			let newState = update(this.state, {
				flashcards: {
					[newquestionindex]: {
						num_attemps:{$set:(this.state.flashcards[newquestionindex].num_attemps+1)},
					}
				},
				currentquestionindex:{$set:newquestionindex},
				nextQActive:{$set:false},
			})
			this.setState(newState);
		}
	}
//	prevquestion(e: React.MouseEvent<HTMLButtonElement>) {
//		e.preventDefault();
//		let newquestionindex = this.state.currentquestionindex;
//		newquestionindex--;
//		if (newquestionindex<0){
//			throw "trying to change questionindex to point beyond the set of questions, shouldn't have been able to click prev question on first question"
//		}
//		else {
//			this.setState({
//				currentquestionindex:newquestionindex,
//				nextQActive:false,
//			});
//		}
//	}
	//onQuestionSelect(e: React.MouseEvent<HTMLButtonElement>){
	//	this.setState({currentquestionindex:JSON.parse(e.currentTarget.name).questionindex})
	//}
//	onQuestionSelect(selectedquestion: number):any {
//		this.setState({
//			currentquestionindex:selectedquestion,
//			nextQActive:false,
//		})
//	  }
	isdisplayed(element:number){
		if (element===this.state.currentquestionindex){
			return'block'
		}else {
			return 'none'
		}
	}
	render(){
		//this.get_previous_answers_by_Q_IDs();//TODO allow them to see questions below their prev ans have loaded?
//		if(!this.state.pupilanswers||!this.state.pupilassignmentdata){
//			console.log("this.state.pupilanswers: "+this.state.pupilanswers);
//			return(<h2 className="center">Loading ...</h2>)
//		}
//		else{
			var questionbuttons=[];
			var questionpanel=[];
//			console.log("this.state.pupilanswers.questions[0]: "+this.state.pupilanswers.questions[0]);
			console.log("number of questions in worksheet: "+this.state.questions.length);
			console.log("this.state.currentquestionindex is: "+this.state.currentquestionindex);
//			console.log("disabled array: "+JSON.stringify(this.state.pupilanswers.disabled));
//			console.log("disabled array[0]: "+this.state.pupilanswers.disabled[0]);
//			let pdata = this.state.pupilassignmentdata;
//			if (pdata===undefined) throw "pdata is not defined"
			for(let i=0;i<this.state.questions.length;i++){
				console.log("WorksheetComponent>render>question i is: "+i);
//				console.log("formvalues used to create the question are: "+JSON.stringify(this.state.pupilanswers.questions[i].length===0?create_empty_formvalues(this.state.questions[i]):this.state.pupilanswers.questions[i],null,4));
				//TODO check the wierdness of the below console logs out, the empty string logs a nothing sometimes and other times as []
				//console.log("this.state.pupilanswers.questions[i] "+JSON.stringify(this.state.pupilanswers.questions[i]));
				//console.log("this.state.pupilanswers.questions[i] "+this.state.pupilanswers.questions[i]);
				//console.log("typeof(this.state.pupilanswers.questions[i])"+typeof(this.state.pupilanswers.questions[i]));
				//console.log("typeof([])"+typeof([]));
//				let colourOfButton = "";
//				let pstat = pdata.questions[i].pupils[0].stats;
//				if(pstat===undefined) throw "pstat is not defined"
//				colourOfButton = colourNameFromPercent(pstat.max_percentage);
//				questionbuttons[i] = <button className={
//											"button questionnumber " +
//											(this.state.currentquestionindex===i?"":"faded") +
//											colourOfButton
//										}
//										key={i}
//										onClick={() => this.onQuestionSelect(i)}
//										//>{"Q"+(i+1)+" "}
//										>{""+(i+1)+" "}
//										{/*+this.state.questions[i].name}*/}
//
//									</button>;
				//NOTE: You are looking at the Flashcard worksheet component here
				questionpanel[i] = 	<div
										key={i}
										style={{display:this.isdisplayed(i)}}>
										<Questionpage
											key={""+this.state.flashcards[i].num_attemps}
											question={this.state.questions[i]}//this.state.currentquestionindex]}
											formvalues={create_empty_formvalues(this.state.questions[i])}
										//	showingfeedback={this.state.pupilanswers.showingfeedback[i]}
										//	disabled={this.state.pupilanswers.disabled[i]}
										//	showSubmit={this.state.pupilanswers.isdraft[i]}
											isdisplayed={i===this.state.currentquestionindex}
											updateQScore={this.updateQScore} //when did this get commented out?
											updateQButtonColour={function (Q_ID: number, score: (number | null)[], maxscore: number[]): void {
												throw new Error('Function not implemented.');
											} }	
											inworksheet={true}
											setButtonStatusNav={this.setButtonStatusNav}
											inflashcardworksheet={true}
											//nextQactive={i===this.state.currentquestionindex?this.state.nextQActive:false}//this means that we only update the props for the active questionpage didn't work
											nextQactive={this.state.nextQActive}
											nextQuestion={this.nextQuestion}
											W_ID={this.props.worksheet_ID}
											//action={undefined}
										/>
									</div>;
			}
			let navigationbuttons = [];
			navigationbuttons[0] = <button
		//								className={"button faded"+(this.state.currentquestionindex===0?
		//									" disabled":"")}
		//								key={0}
		//								onClick={this.prevquestion}
		//							//	tabIndex={-1}
		//								//style={this.state.currentquestionindex===0?
		//								//	{display:"none"}:{}
		//								//	}
		//								>Previous
		//							</button>;
		//	navigationbuttons[1] = <button
										className={"button"+(this.state.currentquestionindex===this.state.questions.length-1?" disabled":"")+(this.state.nextQActive?" active":"")}
											//+(this.state.showSubmit?"":" faded")}
										key={1}
										onClick={this.nextquestion}
										ref={this.nextQRef}
									//	tabIndex={-1}
										//style={this.state.currentquestionindex===this.state.questions.length-1?
										//	{display:"none"}:{}
										//	}
										style={
											{float:"right",
											maxWidth:"15%"}
										}
										>{">>"}
									</button>;
			let numcompleted = 0;
			for(let c = 0; c<this.state.questions.length;c++){
				if(this.state.flashcards[c].answered_correctly_first_time){
					numcompleted++;
				}
			}
			let completeness = "Completed "+numcompleted+"/"+this.state.questions.length;

			//console.log("this.state: "+JSON.stringify(this.state));
			//console.log("this.state.currentquestionindex: "+this.state.currentquestionindex);
			//console.log("first question in list: "+JSON.stringify(this.state.questions[1]));
			//console.log("first questionsloaded in list: "+JSON.stringify(this.state.questionsloaded[1]));
			if(this.state.questions[this.state.currentquestionindex]!=null){
				console.log("going to display question: Q"+(this.state.currentquestionindex+1));
				return(//<h1>{this.state.worksheet.name}</h1>
					<div className="center">
						{questionpanel}
						<div className="alignpreviousandnext" hidden={!this.state.nextQActive}>
							{navigationbuttons}
						</div>
						{/*<div className="questionbuttonholder">
							{questionbuttons}
						</div>*/}
						<p>
						{completeness}
						</p>
					</div>
				)
			}else{
				return(
					<p>loading question, this should be so fast that you can't even see this, check your internet. It is also possible that the question could not be found.</p>
				)
			}
		//}
	}
	//loop through Q_IDs fetching questions, set questionsloaded to true once loaded
	//display a question if loaded and selected.
}
function symbolFromPercent(max_percentage:number|undefined|null):string{
	let displaytext = '';
	if(max_percentage===null||max_percentage===undefined){
		displaytext = "-";
	}else{
		if(max_percentage===0){
			displaytext = "✗";//"x";
		} else if(max_percentage>0&&max_percentage<1){
			displaytext = "o";
		} else if(max_percentage===1){
			displaytext = "✓";
		} else {
			throw "error calculating max_percentage score";
		}
	}
	return displaytext;
}
function colourNameFromPercent(max_percentage:number|undefined|null):string{
	let displaytext = '';
	if(max_percentage===null||max_percentage===undefined){
		displaytext = "";
	}else{
		if(max_percentage===0){
			displaytext = "red";
		} else if(max_percentage>0&&max_percentage<1){
			displaytext = "yellow";
		} else if(max_percentage===1){
			displaytext = "green";
		} else {
			throw "error calculating max_percentage score";
		}
	}
	return displaytext;
}
/**
 * first output is textcolor
 * second output is backgroundColor
 * third output is blackwhitetextcolor (to go on background color)
 * @param percentage 
 * @returns 
 */
function coloursFromPercent(percentage:number|undefined|null):[string,string,string]{
	let textcolor = "";
	let backgroundColor = "";
	let blackwhitetextcolor = "";
	if(percentage===null||percentage===undefined){
		textcolor = "grey";
		backgroundColor = "grey";
		blackwhitetextcolor = "black";
	}else{
		if(percentage===0){
		//	displaytext = "x";
			textcolor = "darkred";
			blackwhitetextcolor = "grey"//"whitesmoke"
			backgroundColor = "DarkRed";
		} else if(percentage>0&&percentage<0.25){
		//	displaytext = "o";
			textcolor = "#FF0000";//red
			blackwhitetextcolor ="black";//
			backgroundColor = "#FF0000";
		} else if(percentage>=0.25&&percentage<0.5){
		//	displaytext = "o";
			textcolor = "#FF6900";
			blackwhitetextcolor ="black";//
			backgroundColor = "#FF6900";//reddy orange
		} else if(percentage>=0.5&&percentage<0.75){
		//	displaytext = "o";
			textcolor = "yellow";
			blackwhitetextcolor ="black";//
			backgroundColor = "#FFD300";//organy yellow, #F7FF00
		} else if(percentage>=0.75&&percentage<1){
		//	displaytext = "o";
			textcolor = "#9FFF00";
			blackwhitetextcolor ="black";//
			backgroundColor = "#9FFF00";//yellowy green
		} else if(percentage===1){
		//	displaytext = "/";
			textcolor = "#00FF00";//"green";
			blackwhitetextcolor ="black";//
			backgroundColor = "#00FF00";
		} else {
			throw "error calculating percentage score: "+percentage;
		}
	}
	return [textcolor,backgroundColor,blackwhitetextcolor];
}
class QuestionButton extends React.Component{
	props!:{
		i:number,
		colourOfButton:ButtonColour|string,
		iscurrentquestion:boolean, //this.state.currentquestionindex===i
		onQuestionSelect:(i:number)=>void,
	}
	render(){
		let i = this.props.i;
		return(
			<button
				className={
					"button questionnumber " +
					(this.props.iscurrentquestion?"":"faded") +
					this.props.colourOfButton
				}
				key={i}
				onClick={() => this.props.onQuestionSelect(i)}
				>{""+(i+1)+" "}
			</button>
		)
	}
}
async function fetchworksheet(w_id: Number): Promise<Worksheet> {
	console.log('fetching worksheet');
	var url = '/fetchworksheet';
	var data = {W_ID:w_id};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj;
}
class Exercisebutton extends React.Component {
	props!:{
		worksheet:Worksheet,
		fn:ExerciseButtonFunctions,
		myWsStats?:PupilWsStats,
	}
	render(){
		//console.log("logging from inside the button");
		//console.log("name: "+this.props.worksheet.name);
		
		return(
			<div style={{display:"block", width:"100%"}}>
				<Link to={`/worksheet/${this.props.worksheet.ID}/${this.props.worksheet.type || ''}`} style= {{textDecoration : 'none', color:'inherit'}}>				
					<button className="button exercise" type="submit" form={"W_" + this.props.worksheet.ID}>
						<span className="pen-icon">✎</span>
						<span className='worksheet-name'>
							{this.props.worksheet.name}
						</span>
						<Exercisebuttonscoredisplaybar
							myWsStats={this.props.myWsStats}
							/>
					</button>
				</Link>
				
				{/* <button
					className="button exercise"
					type="submit"
					form={"W_"+this.props.worksheet.ID}
					onClick={()=>this.props.fn.selectWorksheet(this.props.worksheet.ID,(this.props.worksheet.type||'original'))}
					
					>
					<span className="pen-icon">✎</span>
					<span className="worksheet-name">{this.props.worksheet.name}</span>
					<Exercisebuttonscoredisplaybar
						myWsStats={this.props.myWsStats}
					/>
				</button> */}
				<br/>
			</div>
		)
	}
}

class Exercisebuttonscoredisplaybar extends React.Component{
	props!:{
		myWsStats?:PupilWsStats,
	}
	render(){
		let [pupil_worksheet_max_percentage, pupil_worksheet_wohelp_percentage, pupil_worksheet_resetwoh_percentage] = get_pupil_worksheet_percentages(this.props.myWsStats);
		return(
			<ProgressBar
				blue_percent={pupil_worksheet_resetwoh_percentage}
				green_percent={pupil_worksheet_wohelp_percentage}
				yellow_percent={pupil_worksheet_max_percentage}
			/>
		)
	}
}
	
interface ProgressBarProps {
blue_percent: number;
green_percent: number;
yellow_percent: number;
}

interface ProgressBarState {}


class ProgressBar extends React.Component<ProgressBarProps> {
	render() {
	  const { blue_percent, green_percent, yellow_percent } = this.props;
	  const blue = Math.min(Math.max(blue_percent, 0), 1);
	  const green = Math.min(Math.max(green_percent, 0), 1);
	  const yellow = Math.min(Math.max(yellow_percent, 0), 1);
  
	  const blueWidth = blue * 100;
	  const greenWidth = green * 100; // Updated to reflect total width
	  const yellowWidth = yellow * 100;
	  const greyWidth = 100; // Full width, as it's the background
  
	  return (
		<div style={{ width: '96%', height: '0.4em', backgroundColor: '#e0e0e0', borderRadius: '0.2em', position: 'relative', overflow: 'hidden', marginTop: '3px' }}>
		  <div 
		  	style={{ width: `${greyWidth}%`, height: '100%', backgroundColor: '#e0e0e0', position: 'absolute', left: '0', top: '0', borderRadius:'inherit' }}
			title={`Incomplete: ${(greyWidth-yellowWidth).toFixed(0)}%`}>
			</div>
		  <div 
		  	style={{ width: `${yellowWidth}%`, height: '100%', backgroundColor: 'rgb(245, 247, 111)', position: 'absolute', left: '0', top: '0', borderRadius:'inherit' }}
			title={`Completed total: ${yellowWidth.toFixed(0)}%`}>
			</div>
		  <div 
		  	style={{ width: `${greenWidth}%`, height: '100%', backgroundColor: 'rgb(137, 243, 134)', position: 'absolute', left: '0', top: '0', borderRadius:'inherit' }} 
			title={`Completed without help: ${greenWidth.toFixed(0)}%`}>
			</div>
			<div 
		  	style={{ width: `${blueWidth}%`, height: '100%', backgroundColor: 'rgb(182, 138, 252)', position: 'absolute', left: '0', top: '0', borderRadius:'inherit' }} 
			title={`Completed without help after reset: ${blueWidth.toFixed(0)}%`}>
			</div>
		</div>
	  );
	}
  }
  
		  
class Exercisebuttonscoredisplay extends React.Component{
	props!:{
		myWsStats?:PupilWsStats,
	}
	render(){
		let [pupil_worksheet_max_percentage, pupil_worksheet_wohelp_percentage, pupil_worksheet_resetwoh_percentage] = get_pupil_worksheet_percentages(this.props.myWsStats);
	
		let maxscoredisplay = pupil_worksheet_max_percentage>0?
			<span
				style={{color:coloursFromPercent(pupil_worksheet_max_percentage)[0],
					backgroundColor: "lightgrey",
					borderRadius: "0.3em",
					paddingLeft: "0.2em",
					paddingRight: "0.2em",
					marginLeft: "0.5em",
					//backgroundColor:backgroundColorCom
				}}
				>{Math.round(pupil_worksheet_max_percentage*100)} %
			</span>
			:<span></span>
		let wohelpscoredisplay = 
			pupil_worksheet_wohelp_percentage>0?
			<span
				style={{color:coloursFromPercent(pupil_worksheet_wohelp_percentage)[0],
					backgroundColor: "lightgrey",
					borderRadius: "0.3em",
					paddingLeft: "0.2em",
					paddingRight: "0.2em",
					marginLeft: "0.5em",
					//backgroundColor:backgroundColorCom
				}}
				>{Math.round(pupil_worksheet_wohelp_percentage*100)} %
			</span>
			:
			<span></span>
		let resetwohelpscoredisplay = 
			pupil_worksheet_resetwoh_percentage>0?
			<span
				style={{color:coloursFromPercent(pupil_worksheet_resetwoh_percentage)[0],
					backgroundColor: "lightgrey",
					borderRadius: "0.3em",
					paddingLeft: "0.2em",
					paddingRight: "0.2em",
					marginLeft: "0.5em",
					//backgroundColor:backgroundColorCom
				}}
				>{Math.round(pupil_worksheet_resetwoh_percentage*100)} %
			</span>
			:
			<span></span>
		return (
			<div>
				{maxscoredisplay}
				{wohelpscoredisplay}
				{resetwohelpscoredisplay}
			</div>
		)
	}
}
function get_pupil_worksheet_percentages(myWsStats:PupilWsStats|undefined): [number,number,number]{
	let pupil_worksheet_max_percentage:number = 0;
	if(myWsStats!==undefined){
		if(myWsStats.stats!==undefined){
			if(myWsStats.stats.max_percentage!==undefined){
				pupil_worksheet_max_percentage = myWsStats.stats.max_percentage;
			}//this.state.classassignmentdata.pupilworksheetstats[p].
		}
	}
	let pupil_worksheet_wohelp_percentage:number = 0;
	if(myWsStats!==undefined){
		if(myWsStats.stats!==undefined){
			if(myWsStats.stats.wohelp_percentage!==undefined){
				pupil_worksheet_wohelp_percentage = myWsStats.stats.wohelp_percentage;
			}//this.state.classassignmentdata.pupilworksheetstats[p].
		}
	}
	let pupil_worksheet_resetwoh_percentage:number = 0;
	if(myWsStats!==undefined){
		if(myWsStats.stats!==undefined){
			if(myWsStats.stats.reset_dates!==undefined){
				if(myWsStats.stats.reset_dates.length>0){
					if(myWsStats.stats.resetwoh_percentage!==undefined){
						pupil_worksheet_resetwoh_percentage = myWsStats.stats.resetwoh_percentage;
					}//this.state.classassignmentdata.pupilworksheetstats[p].
				}
			}
		}
	}
	return [pupil_worksheet_max_percentage, pupil_worksheet_wohelp_percentage, pupil_worksheet_resetwoh_percentage]
}

interface ExerciseButtonFunctions {
	selectWorksheet:(W_ID:number,worksheet_type:WorksheetType)=>void,
}
class Exercisesbody extends React.Component {
	props!:{
		exerciseButtonFunctions:ExerciseButtonFunctions,
		worksheetselectiontype:"notassigned"|"assigned",
		namesofclassesimin?:string[],
		mywssstats:PupilWsStats[]|undefined,
		showcompleted:boolean,
	//	selectWorksheet:(e:any)=>void,

	}
	state:{
		worksheets:Worksheet[],
		worksheetsloaded:boolean,
		refreshtimeout:any,
		lengthoftimeout:number,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			worksheets:[],
			worksheetsloaded:false,
			refreshtimeout:null,
			lengthoftimeout:2000,
		}
		this.getworksheets = this.getworksheets.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.componentWillUnmount = this.componentWillUnmount.bind(this);
	}
	componentDidMount() {
		this.getworksheets();
	}
	componentWillUnmount(){
		clearTimeout(this.state.refreshtimeout);
	}
	async getworksheets(){
		let worksheetsassignedtome = await fetchmyworksheets();
		if(this.props.worksheetselectiontype==="assigned"&&this.state.lengthoftimeout<(1000*60*60)){
			this.setState({
				worksheets:worksheetsassignedtome,
				worksheetsloaded:true,
				lengthoftimeout:this.state.lengthoftimeout*1.4,
				refreshtimeout:setTimeout(
					()=>{this.getworksheets()},
					this.state.lengthoftimeout //wait 1000 milliseconds
				)
			});
		}
		else if(this.props.worksheetselectiontype==="notassigned"){
			let worksheets = await fetchpublicworksheets();
			worksheets = worksheets.filter((w:Worksheet)=>worksheetsassignedtome.findIndex((wa:Worksheet)=>wa.ID===w.ID)===-1);
			this.setState({worksheets:worksheets,worksheetsloaded:true});
		}
		else throw "worksheetselectiontype not set"
	}
//	async getClassesImIn(){ //just copied and pasted from above, and started re-naming
//		let classes_im_in = await classes_im_in();
//		this.setState({worksheets:worksheets,worksheetsloaded:true});
//	}
	render(){
		if(this.state.worksheetsloaded){
			let displayedworksheets: Worksheet[];
			// Filter worksheets based on completion status
			if (this.props.showcompleted) {
				displayedworksheets = this.state.worksheets;
			  } else {
				console.log('Trying to filter by completion and display only non-completed')
				//console.log(this.props.mywssstats)

				//TODO the below needs to go into a function and needs to be made way more efficient, I think it loops through all worksheets squared.
				displayedworksheets = this.state.worksheets.filter(
					(w: Worksheet) =>
					  !this.props.mywssstats || // If mywssstats is falsy (undefined or null), include the worksheet
					  !this.props.mywssstats.some(
						(wss: PupilWsStats) => {
						  const meetsCondition = wss.W_ID === w.ID && wss.stats && wss.stats.wohelp_percentage === 1;
						//  if (meetsCondition) {
						//  }
						//  else {
						//	console.log('no completed exercises found')
						//  }
						  return meetsCondition;
						  }
					  )
				  );
			  }
			let exercisesbody:React.ReactNode = null;
			let exercisebuttons = displayedworksheets.map((w) => (//style={{ margin: '10px' }}
				<div key={w.ID} >
					<Exercisebutton
					worksheet={w}
					fn={this.props.exerciseButtonFunctions}
					myWsStats={
						this.props.mywssstats
						? this.props.mywssstats.find(
							(wss: PupilWsStats) => wss.W_ID === w.ID
							)
						: undefined
					}
					/>
				</div>
				));
			if (this.state.worksheets.length===0){
				if(this.props.worksheetselectiontype==="assigned"){
					if(this.props.namesofclassesimin===undefined){
						throw "namesofclassesimin should not be undefined when worksheetselectiontype===assigned"
					} else if(this.props.namesofclassesimin.length===0){
						exercisesbody = <p>You have not joined any classes yet.</p>
					}
					else{
						exercisesbody = <p>The class(es) you have joined haven't been assigned any work yet. <br/>
						{(this.props.namesofclassesimin.length>1)?"The classes you have joined are: ":"The class you have joined is: "}{stringarraytolist(this.props.namesofclassesimin)} </p>// Use the code your teacher provided or use <strong>1234</strong> if you are not joining a class.</p>;
					}

				} else if(this.props.worksheetselectiontype==="notassigned"){
					exercisesbody = <p>There are no more worksheets available.</p>
				}
			}
			else {exercisesbody = (
				<div style={exercisesGridStyle}>
					{exercisebuttons}
				</div>
			);}
			return(
				<div>
					{exercisesbody}
				</div>
			)
		}else{
			return(
				<p>Loading ...</p>
			)
		}

	}
}
let exercisesGridStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Adjust the minmax min value as needed
	gap: '10px', // Adjust the space between grid items
	//justifyContent: 'center',
	//padding: '20px'
	columnGap: '20px',
	marginBottom: '10px'
};

type AssignmentViewWorksheetProps = {
	class:Cohort,
	//closeAssignmentView:(e:any)=>void,
	selectQToView:(Q_index:number)=>void,
	selectPToView:(P_index:number)=>void,
	classassignmentdata:ClassAssignmentData|undefined,
	worksheet:Worksheet|undefined,
	assignmentname:string,
	sorted_indices:number[]
	updateTempPasswords:(p:number,newpassword:string)=>void,
	anonymise:boolean, //should this have a ? because it could be undefined as not set in state
	extrastudentdata:boolean,
	onChangeSortType:(new_sort_type:AssignmentSortType)=>void,
	refreshAssignmentData:()=>void,
  	fullPath:String,
	toggleExtraStudentData:()=>void,
	toggleAnonymise:()=>void,
	//C_ID:number,//could actually just pass class in
	//handleButtonClick:(e:any)=>void,
} & RouteComponentProps
interface AssignmentViewWorksheetState {
	removingpupil:boolean[],
	promotingpupil:boolean[],
	showingdialog:boolean,
	showingpromotedialog:boolean,
	selecteduser_index?:number,
	showadvanced:boolean,
	showKey:boolean,
}
class AssignmentViewWorksheet extends React.Component<AssignmentViewWorksheetProps, AssignmentViewWorksheetState> {
	constructor(props: AssignmentViewWorksheetProps){
		super(props);
		this.state = {
			showingdialog:false,
			showingpromotedialog:false,
			removingpupil:Array(props.class.Pupils.length).fill(false),
			promotingpupil:Array(props.class.Pupils.length).fill(false),
			selecteduser_index:undefined,
			showadvanced:false,
			showKey:false,
		};
		this.passwordReset = this.passwordReset.bind(this);
		this.userUnlock = this.userUnlock.bind(this);
		this.onClickRemoveUser = this.onClickRemoveUser.bind(this);
		this.removeUserConfirmed = this.removeUserConfirmed.bind(this);
		this.removeUser = this.removeUser.bind(this);
		this.onClickPromoteUser = this.onClickPromoteUser.bind(this);
		this.promoteUserConfirmed = this.promoteUserConfirmed.bind(this);
		this.promoteUser = this.promoteUser.bind(this);
		this.toggleShowAdvanced = this.toggleShowAdvanced.bind(this);
		this.toggleKeyVisibility = this.toggleKeyVisibility.bind(this);
	}
	async passwordReset(p:number){
		let U_ID = this.props.class.Pupils[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let newpassword = await resetPassword(U_ID,this.props.class.ID);
		if (!newpassword) throw "new password returned false"
		this.props.updateTempPasswords(p,newpassword);
	}
	async userUnlock(p:number){
		let U_ID = this.props.class.Pupils[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let success = await unlockUser(U_ID,this.props.class.ID);
		if (!success) throw "unlock failed"
		//this.props.updateTempPasswords(p,newpassword);//TODO make it so that the unlock buttons aren't everywhere
	}
	async removeUser(){
		let p = this.state.selecteduser_index;
		if(p===undefined){throw "p should not be undefined when removing user"}
		let newState = update(this.state,
			{
				showingdialog:{$set:false},
				removingpupil: {
					[p]: {$set:true},
				},
			}
		)
		this.setState(newState);
		let U_ID = this.props.class.Pupils[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let success = await removeUserFromClass(U_ID,this.props.class.ID);
		if (!success) throw "remove failed"

		else {
			this.props.refreshAssignmentData();
			this.setState({removingpupil: Array(this.props.class.Pupils.length).fill(false)});
		}
	}
	async promoteUser(){
		let p = this.state.selecteduser_index;
	//	console.log("promoteUser p:"+p);
		if(p===undefined){throw "p should not be undefined when promoting user"}
		let newState = update(this.state,
			{
				showingpromotedialog:{$set:false},
				promotingpupil: {
					[p]: {$set:true},
				},
			}
		)
		this.setState(newState);
		let U_ID = this.props.class.Pupils[p].U_ID;
	//	console.log(U_ID)//TODO should send through U_ID not p.
		let success = await promoteUserFromClass(U_ID,this.props.class.ID);
		if (!success) throw "promote failed"

		else {
			this.props.refreshAssignmentData();
			this.setState({
				promotingpupil: Array(this.props.class.Pupils.length).fill(false)
			});
		}
	}
	onClickRemoveUser(p:number){
		this.setState({
			showingdialog:true,
			selecteduser_index:p,
		})
	}
	toggleShowAdvanced(){
		this.setState(prevState => ({
			showadvanced: !prevState.showadvanced // Toggles the visibility of the key
		}));
	}
	removeUserConfirmed(confirmed:boolean){
		if(confirmed){
			this.removeUser();
		} else {
			this.setState({showingdialog:false})
		}
	}
	onClickPromoteUser(p:number){
		this.setState({
			showingpromotedialog:true,
			selecteduser_index:p,
		})
	}
	promoteUserConfirmed(option:number){
		//console.log("promoteUserConfirmed :"+option);
		if(option===1){//this seems to be because the options numbers a wierd, they start at 1. 
			this.promoteUser();
		} else if (option===2){
			this.setState({showingpromotedialog:false})
		} else {
			throw "unknown option"
		}
	}
	toggleKeyVisibility(){
		this.setState(prevState => ({
			showKey: !prevState.showKey // Toggles the visibility of the key
		}));
		console.log("set show key to: "+ this.state.showKey)
	}
	render(){
		const { history, match } = this.props;
		var header = [];
		var rows = [];
		//console.log("classassignmentdata: "+JSON.stringify(this.props.classassignmentdata,null,4));
		if((this.props.worksheet!=undefined)&&(this.props.classassignmentdata!=undefined)){
			if(this.props.classassignmentdata.questions.length===0){ //this is untested
				return(
					<div>
						There are no questions in this worksheet.
					</div>
				)
			}
			if(!this.props.anonymise){
				header.push( <th className="freezecol pointer" key={"lastname"} onClick={(e)=>this.props.onChangeSortType("lastname")}>Name</th>);
				header.push( <th className="pointer" key={"presence"} >Present</th>);//onClick={(e)=>this.props.onChangeSortType("lastname")}
			};
			header.push( <th style={{cursor:"pointer"}} key={"comtitle"} onClick={(e)=>this.props.onChangeSortType("final")}>%Com</th> );
			if(this.state.showadvanced){
				header.push( <th style={{cursor:"pointer"}} key={"inititle"} onClick={(e)=>this.props.onChangeSortType("initial")}>%Ini</th> );
				header.push( <th style={{cursor:"pointer"}} key={"Sumheader"} onClick={(e)=>this.props.onChangeSortType("summary")}>%Sum</th> );
				//	header.push( <th key={"Adjheader"} onClick={(e)=>this.props.onChangeSortType("adjusted")}>%Adj</th> );
				header.push( <th style={{cursor:"pointer"}} key={"Accheader"} onClick={(e)=>this.props.onChangeSortType("accuracy")}>%Acc</th> );
			}
			//Q1 Q2 Q3
			for(let i=0;i<this.props.worksheet.Q_IDs.length;i++){
				//header[i+2]= <th key={i+2}>Q{i+1}</th>;
				header.push(
					<th key={header.length}
						style={{minWidth:"1.3em", cursor:"pointer"}}
						onClick={(e)=>{
							e.preventDefault();
							this.props.selectQToView(i);
							history.push(`${this.props.fullPath}/byquestion`);
						}}
						>{i+1}
					</th>
				);
			}
			if(!this.props.anonymise && this.props.extrastudentdata){
				header.push( <th key={"username"} className={"notprintable"}>Username</th> );
				header.push( <th key={"password"} className={"notprintable"}>Password</th> );
				header.push( <th key={"remove"} className={"notprintable"}>Remove</th> );
				header.push( <th key={"promote"} className={"notprintable"}>Class teacher</th> );
			};
			let pupilswsstats = this.props.classassignmentdata.pupilswsstats;
			let pupilsnames = this.props.classassignmentdata.pupilsnames;
			if(pupilswsstats===undefined)throw "pupilswsstats is undefined"
			if(pupilsnames===undefined)throw "pupilsnames is undefined"
			//each pupil
			for(let po=0;po<pupilsnames.length;po++){
				let p = this.props.sorted_indices[po]; //po
			//	console.log("sorted_indices length: "+this.props.sorted_indices.length+" pupils.length: "+this.props.classassignmentdata.questions[0].pupils.length);
			//	let p = po;
				let row = [];//where the data for this pupil is put before being added to rows[]
				if(!this.props.anonymise){
					row.push( <th
						className="freezecol pointer"
						key={"lastnamevalues"}
						onClick={e=>{e.preventDefault();
							this.props.selectPToView(po);
							history.push(`${this.props.fullPath}/bypupil`);
						}}
						>{this.props.anonymise?"":toCamelCase(pupilsnames[p].firstname) + " "+toCamelCase(pupilsnames[p].lastname)}
					</th> );
				};
				let pupilPswsstats = pupilswsstats[p];
				
				if(pupilPswsstats===undefined){
					throw "pupilPswsstats undefined p = "+p+" po = "+po+" pupilsnames.length: "+pupilsnames.length+" pupilswsstats.length = "+pupilswsstats.length;
				}
				//console.log("pupilPswsstats: "+JSON.stringify(pupilPswsstats));
				let	pupil_worksheet_initial_percentage = pupilPswsstats.initial_percentage||0;//this.state.classassignmentdata.pupilworksheetstats[p].
				let	pupil_worksheet_adjusted_percentage = pupilPswsstats.adjusted_percentage||0;//this.state.classassignmentdata.pupilworksheetstats[p].
				let	pupil_worksheet_attempted_adjusted_percentage = pupilPswsstats.attempted_adjusted_percentage||0;//this.state.classassignmentdata.pupilworksheetstats[p].
				let	pupil_worksheet_max_percentage = pupilPswsstats.max_percentage||0;//this.state.classassignmentdata.pupilworksheetstats[p].
				let	pupil_worksheet_wohelp_percentage = pupilPswsstats.wohelp_percentage||0;//this.state.classassignmentdata.pupilworksheetstats[p].
		//		}
				let [textcolorIri,backgroundColorIri,blackwhitetextcolorIri]=coloursFromPercent(pupil_worksheet_initial_percentage); //Display colour
				let [textcolorAdj,backgroundColorAdj,blackwhitetextcolorAdj]=coloursFromPercent(pupil_worksheet_adjusted_percentage); //Display colour
				let [textcolorAcc,backgroundColorAcc,blackwhitetextcolorAcc]=coloursFromPercent(pupil_worksheet_attempted_adjusted_percentage); //Display colour
				let [textcolorCom,backgroundColorCom,blackwhitetextcolorCom]=coloursFromPercent(pupil_worksheet_max_percentage); //Display colour
				let [textcolorSum,backgroundColorSum,blackwhitetextcolorSum]=coloursFromPercent(pupil_worksheet_wohelp_percentage); //Display colour
				let presence = 0;
				let presence_text = "";
				let now = new Date();
				let last_mod_date = pupilswsstats[p].last_mod_date;
				if(last_mod_date===undefined){
					last_mod_date=new Date();
				}
				if((now.getTime()-last_mod_date.getTime())/(1000*60*60*24)>7){
					presence = 0.0;//0;
					presence_text = ">week";
				} else if((now.getTime()-last_mod_date.getTime())/(1000*60*60)>1){
					presence = 0.20//7;
					presence_text = ">hour";
				} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>10){
					presence = 0.40//60;
					presence_text = ">10min";
				} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>5){
					presence = 0.60//10;
					presence_text = ">5min";
				} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>2){
					presence = 0.80//5;
					presence_text = ">2min";
				} else if((now.getTime()-last_mod_date.getTime())/(1000*60)<=2){
					presence = 1.00//2;
					presence_text = "now";
				} else { throw "last_mod_date is: "+last_mod_date}
				let [textcolorPres,backgroundColorPres,blackwhitetextcolorPres]=coloursFromPercent(presence); //Display colour
				if(!this.props.anonymise){
					row.push( <th
								key={"presence"}
								className="markbook-box-style"
								style={{backgroundColor:backgroundColorPres, color:blackwhitetextcolorPres}}//color:textcolor,
								//{JSON.stringify(pupilswsstats[p].last_mod_date)}
								>{presence_text}
					</th> );
				}
				row.push( <th //Com
					key={"Com"}
					className="markbook-box-style"
					style={{backgroundColor:backgroundColorCom,
						color:blackwhitetextcolorCom}}//color:textcolor,
					>{Math.round(pupil_worksheet_max_percentage*100)}
				</th> );
				if(this.state.showadvanced){
					row.push( <th //Ini
						key={"Ini"}
						className="markbook-box-style"
						style={{backgroundColor:backgroundColorIri,
							color:blackwhitetextcolorIri}}//color:textcolor,
						>{Math.round(pupil_worksheet_initial_percentage*100)}
					</th> );
					row.push( <th
						key={"Sum"}
						className="markbook-box-style"
						style={{backgroundColor:backgroundColorSum,
							color:blackwhitetextcolorSum}}//color:textcolor,
						>{Math.round(pupil_worksheet_wohelp_percentage*100)}
					</th> );
			//		row.push( <th //Adj
			//					key={"Adj"}
			//					style={{backgroundColor:backgroundColorAdj}}//color:textcolor,
			//					>{Math.round(pupil_worksheet_adjusted_percentage*100)}
			//				</th> );
					row.push( <th //Acc
								key={"Acc"}
								className="markbook-box-style"
								style={{backgroundColor:backgroundColorAcc,
									color:blackwhitetextcolorAcc}}//color:textcolor,
								>{Math.round(pupil_worksheet_attempted_adjusted_percentage*100)}
							</th> );
				}

				//each question
				for(let q=0;q<this.props.classassignmentdata.questions.length;q++){
					let stats = this.props.classassignmentdata.questions[q].pupils[p].stats;
					if(stats===undefined)throw "stats is undefined"
					let adjusted_percentage = stats.adjusted_percentage
					let max_percentage = stats.max_percentage;
					let [textcolor,backgroundColor,blackwhitetextcolor]=coloursFromPercent(adjusted_percentage); //Display colour
					let displaytext = symbolFromPercent(max_percentage); //Display text
					//create element of table
						row.push(
							<th
								key={row.length}//{q+2}
								className="markbook-box-style"
								style={{backgroundColor,
								width:"1.3em",
								color:blackwhitetextcolor,
							//	borderRadius:"1em",
							}}//color:textcolor,
								>{displaytext}
							</th>
						) ;
				}
				if(!this.props.anonymise && this.props.extrastudentdata){
					row.push(
						<th
							className={"notprintable"}
							key={0.5}
							>{pupilsnames[p].username}
						</th>
					);
					if(this.props.extrastudentdata){
						let temppasswords = this.props.classassignmentdata.temppasswords;
						if(temppasswords===undefined)throw "temppasswords is undefined"
						row.push(
							<th
								style={{whiteSpace:"nowrap"}}
								key={"psswrdreset"}
								className={"notprintable"}
								>{(temppasswords[p]===undefined||temppasswords[p]===null)?
									<button
										onClick={(e)=>{e.preventDefault();this.passwordReset(p)}}
										>reset
									</button>
									: temppasswords[p]
								}
								<button
									onClick={(e)=>{e.preventDefault();this.userUnlock(p)}}
									><i className="fa fa-unlock"/>
								</button>
							</th>
						);
						let delete_button = this.state.removingpupil[p]?"removing...":<button
							onClick={(e)=>{e.preventDefault();this.onClickRemoveUser(p)}}
							>❌
						</button>
						row.push(
							<th
								style={{whiteSpace:"nowrap"}}
								key={"remove"}
								className={"notprintable"}
								>
								{delete_button}
							</th>
						);
						let usertype = this.props.classassignmentdata.pupilsnames[p].usertype;
						let U_ID = this.props.class.Pupils[p].U_ID;
						let is_class_teacher = this.props.class.Teacher_IDs.findIndex((T_ID:number)=>T_ID===U_ID)>-1;

						let promote_button = this.state.promotingpupil[p]?"updating...":
						usertype==="pupil"?"pupil":
						is_class_teacher?"class teacher":
						<button
							onClick={(e)=>{e.preventDefault();this.onClickPromoteUser(p)}}
							>Add class teacher
						</button>;
						row.push(
							<th
								style={{whiteSpace:"nowrap"}}
								key={"promote"}
								className={"notprintable"}
								>
								{promote_button}
							</th>
						);
					}
				};
				//add pupil row to table
				rows.push(<tr key={rows.length}>{row}</tr>);
			}
		} else {
			header[0] = <th key="0">Loading table</th>;
		}


		return(
			<div>
				<div className="assignment_table_div">
					<table>
						<thead>
							<tr>
								{header}
							</tr>
						</thead>
						<tbody>
							{rows}
						</tbody>
					</table>
				</div>
				<div
					hidden = {!this.state.showingdialog}>
					<DeleteDialog
						onDeleteDialogResponse={this.removeUserConfirmed}
						record_type="pupil"
						/>
				</div>
				<div
					hidden = {!this.state.showingpromotedialog}>
					<Dialog
						onDialogResponse={this.promoteUserConfirmed}
						dialog_text="Are you sure you want to promote this pupil to a teacher of this class. That means they will be able to access the data in this class."
						buttons_text={["Yes","No"]}
						/>
				</div>
				<br/>
				<div>
					<TableKey
						showadvanced={this.state.showadvanced}
						toggleShowAdvanced={this.toggleShowAdvanced}
						toggleKeyVisibility={this.toggleKeyVisibility}
						showKey={this.state.showKey}
						toggleExtraStudentData={this.props.toggleExtraStudentData}
						extrastudentdata={this.props.extrastudentdata}
						toggleAnonymise={this.props.toggleAnonymise}
						anonymise={this.props.anonymise}
					/>
					<button 
						className="show-key-button"//"button innavigationbar"//
						onClick={this.toggleKeyVisibility}
						hidden={this.state.showKey}
						style={{
							display: this.state.showKey ? "none" : "flex",
							margin:"0",
							marginLeft:"0", 
							marginTop:"0",
							justifyContent: "space-between"
						}}
						>
						<span>Show Key</span>
						<table>
							<tr>
							<td className="markbook-box-style" style={{backgroundColor:"DarkRed",textAlign:"center",minWidth:"1.5em",color:"black"}}>✗</td>
							<td className="markbook-box-style" style={{backgroundColor:"#FFD300",textAlign:"center",minWidth:"1.5em",color:"black"}}>o</td>
							<td className="markbook-box-style" style={{backgroundColor:"#00FF00",textAlign:"center",minWidth:"1.5em",color:"black"}}>✓</td>
							</tr>
						</table>
					</button>
				</div>
				<br/>
			</div>
		)
	}
	//<td className="markbook-box-style" style={{backgroundColor:"grey",textAlign:"center",minWidth:"1.5em",color:"black"}}>-</td> 
}

interface TableKeyProps {
	showadvanced:boolean,
	extrastudentdata:boolean,
	toggleShowAdvanced:()=>void,
	toggleKeyVisibility:()=>void,
	toggleExtraStudentData:()=>void,
	toggleAnonymise:()=>void,
	showKey:boolean,
	anonymise:boolean,
}
export class TableKey extends React.Component<TableKeyProps> {

	constructor(props: TableKeyProps){
		super(props);
	}
	render(){
		let percentages = [0,0.2,0.4,0.6,0.8,1.0];
		return(
			<div>
				<button
						className="show-key-button"//"button innavigationbar"//
						style= {{textTransform:"none"}}
						onClick={(e) => { e.preventDefault(); this.props.toggleAnonymise(); }}
						>{this.props.anonymise ? 'Show names' : 'Hide names'}
					</button>

				<button 
						className="show-key-button"//"button innavigationbar"//
						// style={(this.props.showadvanced)?{display:"none"}:{}}
						onClick={this.props.toggleShowAdvanced }
						>{this.props.showadvanced ? "Hide advanced stats" : "Show advanced stats"}
					</button>

				<button
						className="show-key-button"//"button innavigationbar"//
						style= {{textTransform:"none"}}
						onClick={(e) => {
							e.preventDefault();
							this.props.toggleExtraStudentData();
						}}
						// startIcon={<ManageAccountsIcon/>}
						>{this.props.extrastudentdata ? 'Hide logins' : 'Show logins'}
					</button>
									
				<div 
				// className='key-header-container'
				hidden={!this.props.showKey}
				>
					<button
						className="show-key-button"//"button innavigationbar"//
						hidden={!this.props.showKey}
						onClick={this.props.toggleKeyVisibility}
						>Minimise Key
					</button>
				</div>
				<div
					className={"collapsableinfo"}
					hidden={!this.props.showKey}
				>
					<table>
						<tbody>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(undefined)[1],textAlign:"center",minWidth:"1.5em"}}>{symbolFromPercent(undefined)}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Not attempted.</td>
							</tr>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(percentages[0])[1],textAlign:"center"}}>{symbolFromPercent(percentages[0])}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >0% on all attempts.</td>
							</tr>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(percentages[3])[1],textAlign:"center"}}>{symbolFromPercent(percentages[3])}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Partially correct.</td>
							</tr>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(percentages[5])[1],textAlign:"center"}}>{symbolFromPercent(percentages[5])}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >100% on initial attempt.</td>
							</tr>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(percentages[3])[1],textAlign:"center"}}>{symbolFromPercent(percentages[5])}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >100% after some attempts.</td>
							</tr>
							<tr>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td className="markbook-box-style" style={{backgroundColor:coloursFromPercent(percentages[1])[1],textAlign:"center"}}>{symbolFromPercent(percentages[5])}</td>
								<td className="markbook-box-style" style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >100% after many non-scoring attempts.</td>
							</tr>
						</tbody>
					</table>
					<table>
						<tbody style={{verticalAlign:"top"}}>
							<tr>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td style={{textAlign:"center",minWidth:"1.5em"}}>Present</td>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >{"Indicates when the pupil last seen working on the worksheet: >week means last seen more than a week ago. >hour, >10min, >5min, >2min likewise. "}</td>
							</tr>
							<tr>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td style={{textAlign:"center",minWidth:"1.5em"}}>%Com</td>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Completion %: Percentage of marks scored for the worksheet using the best attempt for each question. You can ask pupils to reattempt questions until they reach a threshold % completion.</td>
							</tr>
							<tr style={this.props.showadvanced?{}:{display:"none"}}>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td style={{textAlign:"center",minWidth:"1.5em"}}>%Ini</td>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Initial %: Percentage of marks scored for the worksheet using the first attempt for each question. This will best reflect attainment in a traditional assessment.</td>
							</tr>
							<tr style={this.props.showadvanced?{}:{display:"none"}}>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td style={{textAlign:"center",minWidth:"1.5em"}}>%Sum</td>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Summary %: Percentage of marks scored without help i.e. on the first try without hints or after retrying from scratch using the summary page.</td>
							</tr>
							<tr style={this.props.showadvanced?{}:{display:"none"}}>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td style={{textAlign:"center",minWidth:"1.5em"}}>%Acc</td>
								<td style={{textAlign:"center",minWidth:"1em"}}></td>
								<td >Accuracy %: Percentage of marks scored for the attempted questions.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}
class TableKeyOld extends React.Component {
	render(){
		let percentages = [0,0.2,0.4,0.6,0.8,1.0];
	//	<li>Adj - Adjusted score - adjusted score as % of whole worksheet.</li>
	//	<li>Acc - Accuracy - adjusted score as % of marks attempted.</li>
		return(
			<div>
				<h2>Key</h2>
				<ul>
					<li>%Ini : Initial - % scored on first attempt, equivalent to a test score.</li>
					<li>%Com : Complete - % scored on best attempt, pupils can reattempt questions.</li>
					<li>{symbolFromPercent(percentages[0])} : 0% on best attempt.</li>
					<li>{symbolFromPercent(percentages[3])} : partial answer on best attempt.</li>
					<li>{symbolFromPercent(percentages[5])} : 100% on best attempt.</li>
					<li>The colours indicate how many attempts it took them to get the right answer.</li>
				</ul>
				<br/>
				<table style={{textAlign:"center"}}>
					<thead>
						<tr>
							<th>Percentage</th>
							<th>{Math.round(percentages[0]*100)}</th>
							<th>{Math.round(percentages[1]*100)}</th>
							<th>{Math.round(percentages[2]*100)}</th>
							<th>{Math.round(percentages[3]*100)}</th>
							<th>{Math.round(percentages[4]*100)}</th>
							<th>{Math.round(percentages[5]*100)}</th>
							<th>{'n/a'}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Adjusted score</td>
							<td style={{backgroundColor:coloursFromPercent(percentages[0])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[1])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[2])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[3])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[4])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[5])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(undefined)[1]}}></td>
						</tr>
						<tr>
							<td>Final score</td>
							<td>{symbolFromPercent(percentages[0])}</td>
							<td>{symbolFromPercent(percentages[1])}</td>
							<td>{symbolFromPercent(percentages[2])}</td>
							<td>{symbolFromPercent(percentages[3])}</td>
							<td>{symbolFromPercent(percentages[4])}</td>
							<td>{symbolFromPercent(percentages[5])}</td>
							<td>{symbolFromPercent(undefined)}</td>
						</tr>
						<tr>
							<td>Adjusted score</td>
							<td style={{backgroundColor:coloursFromPercent(percentages[0])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[1])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[2])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[3])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[4])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(percentages[5])[1]}}></td>
							<td style={{backgroundColor:coloursFromPercent(undefined)[1]}}>{symbolFromPercent(undefined)}</td>
						</tr>
					</tbody>
				</table>

			<p>The adjusted score is calculated by awarding marks scored in successive attempts as increasingly smaller marks. Marks scored on the first attempt are worth 1 each, marks scored on the second attempt are worth 0.5 each.</p>
			</div>
		)
	}
}
class QuestionResponsesViewNavigationButtons extends React.Component{
	props!:{
		ordered_position:number|undefined,//at this point it is ppp
		selectThingToView:(ordered_position:number)=>void,
		mode:string//"Q"|"Pupil"
		clearFeedback:()=>void,
	}
	render(){
		console.log("render QuestionResponsesViewNavigationButtons");
		if(this.props.ordered_position===undefined) throw "index should be undefined"
		else{
			let ordered_position = this.props.ordered_position|0;//TODO this is a hack because it is moaning for no reason.
			return(
				<span key="Q">
					<button
						className="button innavigationbar float"
						onClick={(e)=>{
							e.preventDefault();
							this.props.selectThingToView(ordered_position+1);
							this.props.clearFeedback();
						}}
						>Next {this.props.mode}
					</button>
					<button
						className="button innavigationbar float"
						onClick={(e)=>{
							e.preventDefault();
							this.props.selectThingToView(ordered_position-1);
							this.props.clearFeedback();
						}}
						>Prev {this.props.mode}
					</button>
				</span>
			)
		}
	}
}
interface GroupComponentProps {
	topic_table:Topic[],
	topic:Topic,
	exerciseButtonFunctions:ExerciseButtonFunctions,
	worksheets:Worksheet[],
	level:number,
	mywssstats:PupilWsStats[]|undefined,
	classes?:Cohort[],
	user:User,
	updateClasses:()=>void,
	parentlist:number[],
}
let twoColumnGridStyle = {
	display: 'grid',
	gridTemplateColumns: '1fr 1fr', // Forces a two-column layout
	gap: '10px', // Adjusts the space between grid items
	alignItems: 'center', // Vertically center-aligns the items
	columnGap: '20px', // Adjusts the space between the two columns
	marginBottom: '10px'
  };	  
class GroupComponent extends React.Component {
	props!:GroupComponentProps
	state:{
		expanded:boolean,
	}
	constructor(props: GroupComponentProps){
		super(props);
		this.state={
			expanded:props.level<1,//props.level<1,//can use props in state ehere because it doesn't change
		}
	}
	
	render(): React.ReactNode {
		let p = this.props;
		let children = [];
		for(let c= 0; c<p.topic.children.length; c++){
			let child_ID = p.topic.children[c].ID;
			let child_type = p.topic.children[c].type;
			if(child_type==="grp"){
				let child = p.topic_table.find((group:Topic)=>{return group.T_ID===child_ID});
				if(child===undefined) {console.log("missing group: "+child_ID);
				//throw "missing group"
				} else{
					if(this.props.parentlist.findIndex((p:number)=>p===child_ID)>-1){
						throw "circular reference with grp: "+child_ID+JSON.stringify(p.parentlist)
					}
					let newparentlist:number[] = [...this.props.parentlist,child_ID];
					children.push(
						<GroupComponent
							key={"grp"+child_type+child_ID}
							topic_table={p.topic_table}
							topic={child}
							exerciseButtonFunctions={p.exerciseButtonFunctions}
							worksheets={p.worksheets}
							level={p.level+1}
							mywssstats={this.props.mywssstats}
							classes={this.props.classes}
							user={this.props.user}
							updateClasses={this.props.updateClasses}
							parentlist={newparentlist}
						/>
					)
				}
			}
			else if(child_type==="ws"){
				let worksheet = p.worksheets.find((w:Worksheet)=>{return w.ID===child_ID})
				if (worksheet===undefined){
				//	console.log("ws "+child_ID.toString()+" not found")
				} else {
				//	console.log("found ws: "+worksheet.name);
					let W_ID = worksheet.ID;
					let assigncomponent = <span>
					</span>
					if (this.props.user.usertype !== "pupil"){
						if(this.props.classes!==undefined){
							assigncomponent = 
								<AssignComponent
									key={child_type+child_ID}
									classes={this.props.classes}
									W_ID={W_ID}
									updateClasses={this.props.updateClasses}
								/>
						}
					}
					children.push(
						<div style={exercisesGridStyle}>
						  <div style={{ justifySelf: 'start', minWidth: '150px', width: '100%' }}> {/* Button fills available width */}
							<Exercisebutton
							  key={"Ex"+child_type+child_ID}
							  worksheet={worksheet}
							  fn={this.props.exerciseButtonFunctions}
							  myWsStats={this.props.mywssstats ? this.props.mywssstats.find((wss: PupilWsStats) => wss.W_ID === W_ID) : undefined}
							/>
						  </div>
						  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> {/* View Worksheet and Assign next to each other */}
							<WorksheetViewer worksheet_ID={worksheet.ID} />
							{assigncomponent}
						  </div>
						</div>
					  );
					  
					  
				}
			}
			else throw "child_type not recognised"
		}
		return(
			<div>
				<p
					style={{cursor:"pointer"}}
					onClick={()=>{this.setState({expanded:!this.state.expanded})}}
					hidden={this.props.level===0}
					>{this.state.expanded?"˅ ":"> "}
					🖿 {p.topic.topic_name}
				</p>
				<div 
					style={{marginLeft:this.props.level===0?"0em":"1em"}}
					hidden={(!this.state.expanded)}
					>{children}
				</div>
			</div>
		)
	}
}
class AssignComponent extends React.Component {
	props!:{
	  classes:Cohort[],
	  W_ID:number,
	  updateClasses:()=>void,
	}
	state:{
	  showAll: boolean,
	}
	constructor(props:Readonly<{}>){
	  super(props);
	  this.state = {
		showAll: false,
	  }
	}
  
	toggleShowAll = () => {
	  this.setState({ showAll: !this.state.showAll });
	}
  
	render() {
	  let options = [];
	  let classesItsAssignedTo = [];
  
	  // Populate classesItsAssignedTo before creating the options
	  for (let o = 0; o < this.props.classes.length; o++) {
		let thisClass = this.props.classes[o];
		let isAssigned = thisClass.Assignments.findIndex((a: Assignment) => a.W_ID === this.props.W_ID) > -1;
  
		if (isAssigned) {
		  classesItsAssignedTo.push(thisClass.name);
		}
		
		// Add "assigned" text if it is already assigned
		let classNameWithStatus = thisClass.name + (isAssigned ? " (assigned)" : "");
  
		options.push(
		  <option 
			key={o} 
			value={thisClass.ID} 
			style={isAssigned ? { backgroundColor: '#d3f3d3' } : {}}
		  >
			{classNameWithStatus}
		  </option>
		);
	  }

	  // Add the "Assign" option after we know how many classes are assigned
	  options.unshift(
		<option key={-1} disabled selected hidden value={-1}>
		  Assign ({classesItsAssignedTo.length})
		</option>
	  );
  
	//  let displayedClasses = this.state.showAll ? classesItsAssignedTo : classesItsAssignedTo.slice(0, 3);
	//  let remainingCount = classesItsAssignedTo.length - 3;
  
	  return (
		<div style={{ display: "inline" }}>
		  <label hidden>Set assignment</label>
		  <select
			className="button innavigationbar"//"SelectClassToAssign"
			name="setassignmentselect"
			value={-1}
			onChange={e => {
			  setAssignment(Number(e.target.value), this.props.W_ID);
			  this.props.updateClasses();
			}}
			style={{width:"7em"}}
		  >
			{options}
		  </select>
  
		  {/* <div className="assignedClasses">
			{displayedClasses.map((className, index) => (
			  <p key={index} className="classToken">
				{className}
			  </p>
			))}
			{remainingCount > 0 && !this.state.showAll && (
			  <button onClick={this.toggleShowAll} className="showMoreButton">
				+ {remainingCount} more...
			  </button>
			)}
			{this.state.showAll && remainingCount > 0 && (
			  <button onClick={this.toggleShowAll} className="showLessButton">
				Show less
			  </button>
			)}
		  </div> */}
		</div>
	  )
	}
  }

export async function getworksheet(worksheet_ID:number):Promise<[Question[], Worksheet]>{
	console.log("get worksheet called with worksheet_ID: "+worksheet_ID);
	try {
		let worksheet = await fetchworksheet(worksheet_ID);
		//this.setState({worksheet:worksheet});
		//console.log("fetching questions we can't have the first question until below here");
		let questions = await getquestions(worksheet);
		return [questions, worksheet]
	}
	catch (error) {
		throw "Error in getworksheet(), was unable to get worksheet or questions"
		console.error('Error:', error);
	}
}

export async function getquestions(worksheet:Worksheet):Promise<Question[]> {
	let questions = await fetchquestionsbyID(worksheet.Q_IDs);
	return questions;
	//let questions = await fetchquestionsbyID(worksheet.Q_IDs);
	//this.setState({questions:questions});
}

export class WorksheetComponent extends React.Component{
	props!: {
		worksheet_ID:number
		//setPage:(page:PageName)=>void, //Louis check 
		globaluser:User|null|undefined,
	} //TODO should I be feeding in the worksheet to this component?
	state: {
		//worksheetanswers:string[][],
		pupilassignmentdata:ClassAssignmentData|undefined,
		questions:Question[],
		currentquestionindex:number,
		worksheet:Worksheet,
		pupilanswers?:PupilAnswers,
		nextQActive:boolean,
		newQPercentages:(number|undefined)[]
	}
	nextQRef:React.RefObject<HTMLButtonElement>
	constructor(props: Readonly<{}>)
	{
		super(props);
		this.nextQRef = React.createRef();
		this.state = {
			//worksheetanswers:string[][],
			pupilassignmentdata:undefined,
			questions:[],
			currentquestionindex:0,
			worksheet:create_empty_worksheet(), //TODO we havn't got the worksheet yet, surely we get it when the worksheet is clicked
			nextQActive:false,
			newQPercentages:[],
		}
		this.onQuestionSelect = this.onQuestionSelect.bind(this);
		this.isdisplayed = this.isdisplayed.bind(this);
		this.get_previous_answers_by_Q_IDs=this.get_previous_answers_by_Q_IDs.bind(this);
		this.nextquestion = this.nextquestion.bind(this);
		this.nextQuestion = this.nextQuestion.bind(this);
		this.prevquestion = this.prevquestion.bind(this);
		this.updateQScore = this.updateQScore.bind(this);
		this.setButtonStatusNav = this.setButtonStatusNav.bind(this);
		this.resetWorksheet = this.resetWorksheet.bind(this);
		this.updateQButtonColour = this.updateQButtonColour.bind(this);
	}
	async componentDidMount() {
		console.log("WorksheetComponent did mount");
		let [questions, worksheet] = await getworksheet(this.props.worksheet_ID);
		let pupilsanswers = await this.get_previous_answers_by_Q_IDs();
		let pupilassignmentdata = await fetchPupilAssignmentData(this.props.worksheet_ID);
	//	console.log("componentDidMount pupilassignmentdata question number: "+pupilassignmentdata.questions.length);
	//	console.log("componentDidMount getworksheetsheet question number: "+this.state.questions.length);
	//	console.log("componentDidMount before calcScores pupilassignmentdata.pupilswsstats: "+pupilassignmentdata.pupilswsstats);
	//	if(pupilassignmentdata.pupilswsstats!==undefined){
	//		console.log("componentDidMount reset_dates: "+pupilassignmentdata.pupilswsstats[0].reset_dates);
	//	}
		pupilassignmentdata=calcScores(pupilassignmentdata);
	//	console.log("after calcScores pupilassignmentdata.pupilswsstats: "+pupilassignmentdata.pupilswsstats);
	//	if(pupilassignmentdata.pupilswsstats!==undefined){
	//		console.log("reset_dates: "+pupilassignmentdata.pupilswsstats[0].reset_dates);
	//	}
		
		this.setState({
			pupilanswers:pupilsanswers[0],
			pupilassignmentdata,
			questions,
			worksheet,
		})
	}

	async updateQScore(Q_ID?:number,score?:(number|null)[],maxscore?:number[]){
		console.log("updateQScore called with Q_ID, score and mascore: "+Q_ID+" "+score+" "+maxscore);
		let pupilassignmentdata = await fetchPupilAssignmentData(this.state.worksheet.ID);
		pupilassignmentdata=calcScores(pupilassignmentdata);
		this.setState({
			pupilassignmentdata,
			newQPercentages:[],
		})
		console.log("after updateQScore this.state.newQPercentages: "+this.state.newQPercentages);
	}
	updateQButtonColour(Q_ID:number,score:(number|null)[],maxscore:number[]){
		//only really want to do this if it is a ...
		console.log("updateQButtonColour");
		console.log("state: "+this.state.worksheet.ID);
		let newpercentage = getpercentagescore(score,maxscore);
		if(this.state.pupilassignmentdata==undefined) throw "this.state.pupilassignmentdata undefined in updateQButtonColour"
		let Qindex = this.state.pupilassignmentdata.questions.findIndex((q)=>q.Q_ID===Q_ID)
		let existingnewpercentage = this.state.newQPercentages[Qindex];
		let needtoupdate = existingnewpercentage===undefined;
		if(existingnewpercentage!==undefined){
			needtoupdate = newpercentage>existingnewpercentage
		}
		console.log("this.state.newQPercentages before updateQButtonColour: "+this.state.newQPercentages);
		if(needtoupdate){//only want to update new percentage if it is larger, since it represents maximum value
			let newState = update(this.state, 
				{
					newQPercentages:{
						[Qindex]: {
							$set:newpercentage
						}
					}
				}
			)
			this.setState(newState);
			console.log("state.newQPercentages after updateQButtonColour: "+newState.newQPercentages);
		}
	}
    async resetWorksheet(){
		console.log("resetWorksheet called");
		//this.get_previous_answers_by_Q_IDs();
		let pupilassignmentdata = await resetWorksheet(this.props.worksheet_ID);
		let pupilsanswers = await this.get_previous_answers_by_Q_IDs();//obviously must come after pupilassignmentdata
		pupilassignmentdata=calcScores(pupilassignmentdata);
		this.setState({
			pupilassignmentdata,
			pupilanswers:pupilsanswers[0],
			newQPercentages:[],
			//resetkey:this.state.resetkey+1,this will be pulled from pupilassignment data
		})
		//console.log("pupilassignmentdata.pupilswsstats[0].reset_percentage "+JSON.stringify(pupilassignmentdata,null,4));
	}
	setButtonStatusNav(button:"submit"|"nextQ",action:"activate"|"deactivate",dontfocus?:boolean):void{
		console.log("1#########################################")
		if(button==="nextQ"){
			if(action==="activate"){
				if(this.nextQRef.current!=null&&dontfocus!==true){
					this.nextQRef.current.focus();
				}
				this.setState({nextQActive:true})
			} else {
				console.log("##################################################################")
				if(this.nextQRef.current!=null){
					this.nextQRef.current.blur();
				}
				this.setState({nextQActive:false})//this possibly slows things down?
			}
		} //else if(button==="prevQ"){
		//	this.setState({prevQActive:action==="activate"})
		//}
	}
	//handleScores(pupilassignmentdata:ClassAssignmentData){
	//	pupilassignmentdata.questions.forEach((question)=>{
	//		let attempts = question.pupils[0].attempts;
	//	})
	//}
	async get_previous_answers_by_Q_IDs(){
		try {
			//console.log("fetching pupilsanswers");
			let pupilsanswers = await get_previous_answers_by_Q_IDs([],this.state.worksheet.Q_IDs,this.props.worksheet_ID);//possibly pass in 'isassessment' at this end
			if(pupilsanswers[0].questions[0]===undefined){
				throw "get_previous_answers_by_Q_IDs() returned no answers to first question"
			}
			//console.log("got pupilsanswers: "+JSON.stringify(pupilsanswers[0],null,4));
			//console.log("got pupilsanswers: "+pupilsanswers[0]);
			//let pupilanswers = pupilsanswers[0]
			//this.setState({pupilanswers:pupilsanswers[0]});//select the first pupils' answers since in the context of a worksheet we curently just want the current users answers.
			//setTimeout(() => {console.log("fetched pupilsanswers and set state: "+JSON.stringify(this.state.pupilanswers,null,4));}, 500);
			return pupilsanswers;
		}
		catch (error) {
			console.error('Error:', error);
			throw "tried and failed to get_previous_answers_by_Q_IDs"
		}
	}
	
	nextquestion(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		this.nextQuestion();
	}
	nextQuestion(){
		let newquestionindex = this.state.currentquestionindex;
		newquestionindex++;
		//if (newquestionindex>=this.state.questions.length){
		if (newquestionindex>this.state.questions.length){
			throw "trying to change questionindex to point beyond the set of questions, shouldn't have been able to click next question on last question"
		}
		else {
			this.setState({
				currentquestionindex:newquestionindex,
				nextQActive:false,
			});
		}
	}
	prevquestion(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		let newquestionindex = this.state.currentquestionindex;
		newquestionindex--;
		if (newquestionindex<0){
			throw "trying to change questionindex to point beyond the set of questions, shouldn't have been able to click prev question on first question"
		}
		else {
			this.setState({
				currentquestionindex:newquestionindex,
				nextQActive:false,
			});
		}
	}
	//onQuestionSelect(e: React.MouseEvent<HTMLButtonElement>){
	//	this.setState({currentquestionindex:JSON.parse(e.currentTarget.name).questionindex})
	//}
	onQuestionSelect(selectedquestion: number):any {
		this.setState({
			currentquestionindex:selectedquestion,
			nextQActive:false,
		})
	  }
	isdisplayed(element:number){
		if (element===this.state.currentquestionindex){
			return'block'
		}else {
			return 'none'
		}
	}
	render(){
		//this.get_previous_answers_by_Q_IDs();//TODO allow them to see questions below their prev ans have loaded?
		console.log("rendering WorksheetComponent");
		if(!this.state.pupilanswers||!this.state.pupilassignmentdata){
			console.log("rendering WorksheetComponent, this.state.pupilanswers: "+this.state.pupilanswers);
			return(<h2 className="center">Loading ...</h2>)
		}
		else{
			console.log("rendering WorksheetComponent, pupilanswers and pupilassignmentdata is defined");
			var questionbuttons=[];
			var questionpanel=[];
		//	console.log("this.state.pupilanswers.questions[0]: "+this.state.pupilanswers.questions[0]);
		//	console.log("number of questions in worksheet: "+this.state.questions.length);
		//	console.log("this.state.currentquestionindex is: "+this.state.currentquestionindex);
		//	console.log("disabled array: "+JSON.stringify(this.state.pupilanswers.disabled));
		//	console.log("disabled array[0]: "+this.state.pupilanswers.disabled[0]);
			let pdata = this.state.pupilassignmentdata;
			if (pdata===undefined) throw "pdata is not defined"
			for(let i=0;i<this.state.questions.length;i++){
			//	console.log("WorksheetComponent>render>question i is: "+i);
			//	console.log("formvalues used to create the question are: "+JSON.stringify(this.state.pupilanswers.questions[i].length===0?create_empty_formvalues(this.state.questions[i]):this.state.pupilanswers.questions[i],null,4));
				//TODO check the wierdness of the below console logs out, the empty string logs a nothing sometimes and other times as []
				//console.log("this.state.pupilanswers.questions[i] "+JSON.stringify(this.state.pupilanswers.questions[i]));
				//console.log("this.state.pupilanswers.questions[i] "+this.state.pupilanswers.questions[i]);
				//console.log("typeof(this.state.pupilanswers.questions[i])"+typeof(this.state.pupilanswers.questions[i]));
				//console.log("typeof([])"+typeof([]));
				let colourOfButton = "";
			//	if(pdata===undefined) throw "pdata is not defined"
			//	if(pdata.questions===undefined) throw "pdata.questions is not defined"
			//	if(pdata.questions[i]===undefined) throw "pdata.questions[i] is not defined"
			//	if(pdata.questions[i].pupils===undefined) throw "pdata.questions[i].pupils is not defined"
			//	if(pdata.questions[i].pupils[0]===undefined) throw "pdata.questions[i].pupils.[0] is not defined"
				let pstat = pdata.questions[i].pupils[0].stats;
				if(pstat===undefined) throw "pstat is not defined"
				let colourpercentage = pstat.max_percentage;
				if(getResetNumber(this.state.pupilassignmentdata)>0){
					colourpercentage = pstat.reset_percentage;
				}
				let newpercentage = this.state.newQPercentages[i];
				if(newpercentage!==undefined){
					if(colourpercentage==undefined){
						colourpercentage = newpercentage;
					} else if (newpercentage>colourpercentage){
						colourpercentage = newpercentage
					}
				}
				colourOfButton = colourNameFromPercent(colourpercentage);
				questionbuttons[i] = 	<QuestionButton
											key={i}
											i={i}
											colourOfButton={colourOfButton}
											iscurrentquestion={this.state.currentquestionindex===i}
											onQuestionSelect={this.onQuestionSelect}
										/>;
				let isassessment = this.state.worksheet.type==="assessment";
				//let showingfeedback = isassessment?false:this.state.pupilanswers.showingfeedback[i];//don't need this here, feedback should not be shown because we don't call in previous answer with feedback and never set showing feedback to true.
				let showingfeedback = this.state.pupilanswers.showingfeedback[i];//don't need this here, feedback should not be shown because we don't call in previous answer with feedback and never set showing feedback to true.
				//This is the normal worksheet component
				questionpanel[i] = 	<div
										key={""+i}//+"."+getResetNumber(this.state.pupilassignmentdata)}
										style={{display:this.isdisplayed(i)}}>
										<Questionpage
											key={""+i}//+"."+getResetNumber(this.state.pupilassignmentdata)}
											resetnumber={getResetNumber(this.state.pupilassignmentdata)}
											questionarrayposition = {i}
											Qnum_inWS={i+1}
											question={this.state.questions[i]}//this.state.currentquestionindex]}
											formvalues={this.state.pupilanswers.questions[i].length===0?create_empty_formvalues(this.state.questions[i]):this.state.pupilanswers.questions[i]}
											showingfeedback={showingfeedback}
											disabled={this.state.pupilanswers.disabled[i]}
											first={this.state.pupilanswers.first[i]}
										//	showSubmit={isassessment?false:this.state.pupilanswers.isdraft[i]}
											showSubmit={isassessment===true?false:true}
											brightenSubmit={this.state.pupilanswers.isdraft[i]}
											isassessment={isassessment}
											isdisplayed={i===this.state.currentquestionindex}
											//updateQScore={this.updateQScore}
											updateQButtonColour={this.updateQButtonColour}
											inworksheet={true}
											setButtonStatusNav={this.setButtonStatusNav}
											//nextQactive={i===this.state.currentquestionindex?this.state.nextQActive:false}//this means that we only update the props for the active questionpage didn't work
											nextQactive={this.state.nextQActive}
											nextQuestion={this.nextQuestion}
											W_ID={this.props.worksheet_ID}
											//action={undefined}
										/>
									</div>;
			}
			let summary_i = this.state.questions.length;
			if(this.state.worksheet.type!=="assessment"){
				questionbuttons.push(
					<button
						className={
							"button questionnumber " +
							(this.state.currentquestionindex===summary_i?"":"faded") +
							""//"colourOfButton"
						}
						key={""+summary_i}
						onClick={() => this.onQuestionSelect(summary_i)}
						>{""+"summary"+""}
					</button>
				);
				questionpanel.push(
					<div
						key={summary_i}
						style={{display:this.isdisplayed(summary_i)}}>
						<SummaryPage
							stats = {pdata}
							onQuestionSelect={this.onQuestionSelect}
							questions={this.state.questions}
							isdisplayed={summary_i===this.state.currentquestionindex}
							updateQScore={this.updateQScore}
							updateQButtonColour={this.updateQButtonColour}
							// setPage={this.props.setPage}
							W_ID={this.props.worksheet_ID}
                            resetWorksheet={this.resetWorksheet}
							newQPercentages={this.state.newQPercentages}
							globaluser={this.props.globaluser}
						/>
					</div>
				)
			} else {
				questionpanel.push(
					<div
						key={summary_i}
						style={{display:this.isdisplayed(summary_i)}}
						>
						<h2>End of assessment</h2>
						<p>Go back through the questions checking your answers, improving them until you run out of time.</p>
					</div>
				);
			}
			let navigationbuttons = [];
			navigationbuttons[0] = 	<div
										key={"Previous"}
										style={{"display":(this.state.currentquestionindex===0?"none":"inline")}}
										//hidden={true}//(this.state.currentquestionindex==summary_i)}
										>
										<button
											className={"button faded"+(this.state.currentquestionindex===0?
												" disabled":"")}
											key={0}
											onClick={this.prevquestion}
										//	tabIndex={-1}
											style={
												{float:"left",
												maxWidth:"15%"}
												//this.state.currentquestionindex===0?{display:"none"}:{}
												}
												//Previous"}
											>{"<<"}
										</button>
									</div>;
			navigationbuttons[1] = <div
										key={"Next"}
										style={{"display":(this.state.currentquestionindex==summary_i?"none":"inline")}}
										//hidden={true}//(this.state.currentquestionindex==summary_i)}
										>
										<button
											//className={"button"+(this.state.currentquestionindex===this.state.questions.length-1?" disabled":"")+(this.state.nextQActive?" active":"")}
											className={"button"+(this.state.currentquestionindex===this.state.questions.length?" disabled":"")+(this.state.nextQActive?" active":"")}
												//+(this.state.showSubmit?"":" faded")}
											key={1}
											onClick={this.nextquestion}
											ref={this.nextQRef}
										//	tabIndex={-1}
											style={
												{float:"right",
												maxWidth:"15%"}
												//this.state.currentquestionindex===this.state.questions.length-1?{display:"none"}:{}
												}
												//"Next 
											>{">>"}
										</button>
									</div>	;

			//console.log("this.state: "+JSON.stringify(this.state));
			//console.log("this.state.currentquestionindex: "+this.state.currentquestionindex);
			//console.log("first question in list: "+JSON.stringify(this.state.questions[1]));
			//console.log("first questionsloaded in list: "+JSON.stringify(this.state.questionsloaded[1]));
			//if(this.state.questions.length===this.state.currentquestionindex){
			if((this.state.questions.length===this.state.currentquestionindex)||(this.state.questions[this.state.currentquestionindex]!=null)){
				console.log("going to display question: Q"+(this.state.currentquestionindex+1));
				return(//<h1>{this.state.worksheet.name}</h1>
					<div className="center worksheet">
						<Link
							className="exit_button"
							// onClick={(e)=>{e.preventDefault();this.props.setPage("exercises")}} // Louis check this
							to='/exercises'
							>Exit
						</Link>
						{questionpanel}
						<div className="alignpreviousandnext">
							{/*<br></br>*/}
							{navigationbuttons}
						</div>
						<div className="questionbuttonholder">
							{/*<br></br>*/}
							{questionbuttons}
						</div>
						<div style={{ textAlign: 'center', marginTop:'0.7em' }}>{this.state.worksheet.name}</div> 
					</div>
				)
			}else{
				return(
					<p>loading question, this should be so fast that you can't even see this, check your internet. It is also possible that the question could not be found.</p>
				)
			}
		}
	}
	//loop through Q_IDs fetching questions, set questionsloaded to true once loaded
	//display a question if loaded and selected.
}
async function fetchClasses():Promise<Cohort[]>{
	//gets the classes that I am the teacher of
	console.log("fetching classes");
	let response = await fetch("/myclasses")
	let js_obj:Cohort[] = await response.json();
	return js_obj;
}
export class ExercisesPage extends React.Component {
	props!:{
		exerciseButtonFunctions:ExerciseButtonFunctions,
	//	selectWorksheet:(e:any)=>void,
		// setPage:(pagename:PageName)=>void,
		user:User,
	}
	state:{
		namesofclassesimin:string[],
		mywssstats:PupilWsStats[]|undefined,
		classes?:Cohort[],
		selected_assignments_to_set?:number[],
		showcompleted: boolean,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			namesofclassesimin:["finding classes..."],
			mywssstats:undefined,
			selected_assignments_to_set:[],//TODO what is this? I copied from elsewhere but no idea if it does anything.
			showcompleted:false,
		}
		this.updateClasses=this.updateClasses.bind(this);

	}
	async componentDidMount(){
		console.log("componentDidMount for ExercisesPage");
		let namesofclassesimin:string[] = await fetchClassesImin();
		let rawmywssstats:(PupilWsStats|undefined)[] = (await fetchPupilsWssStats())[0].pupilwssstats;
		console.log("fetchPupilsWssStats sent")
		let mywssstats:PupilWsStats[] = rawmywssstats.filter(function(x) {
			return x != null;
	   }) as PupilWsStats[];//bit dodge but w/e
		let classesImTeacherOf = await fetchClasses();
		this.setState({
			namesofclassesimin:namesofclassesimin,
			mywssstats:mywssstats,
			classes:classesImTeacherOf,
			selected_assignments_to_set:Array(classesImTeacherOf.length), //should fill array so that I don't have undefined
			showcompleted: false,
		})
	}
	async updateClasses(){
		let classesImTeacherOf = await fetchClasses();
		this.setState({
			classes:classesImTeacherOf,
		});
	}
	
	//This contains the title Exercises & gets exercises for this user, then calls something to display the exercises
	render(){
		let showtheshowcompletedexercisesbutton = false;
		if(this.state.mywssstats!==undefined){
			if(this.state.mywssstats.length>9){
				showtheshowcompletedexercisesbutton = true;
			}
		}
		return(
			<div 
				className = "center" 
				//style={{textAlign: "center"}}
				>
				<h1 style={{textAlign:"center"}}
					>Exercises page</h1>
				<h2>Your assignments</h2>
				<Exercisesbody
					exerciseButtonFunctions={this.props.exerciseButtonFunctions}
					worksheetselectiontype="assigned"//selectWorksheet={this.props.selectWorksheet}
					namesofclassesimin={this.state.namesofclassesimin}
					mywssstats={this.state.mywssstats}
					showcompleted = {this.state.showcompleted}
				/>
				<br/>
				<div hidden={!showtheshowcompletedexercisesbutton}>
					<MyStyledButton
						variant="contained"//"button"
						onClick={(e) => {
						e.preventDefault();
						console.log('Toggle button clicked. showcompleted:', !this.state.showcompleted);
						this.setState({ showcompleted: !this.state.showcompleted });
						}}
						>
							{this.state.showcompleted ? 'Hide Completed Exercises' : 'Show Completed Exercises'}
					</MyStyledButton>
					<br/>
					<br/>
				</div>

						
				<MyStyledLink //style={{fontSize:"14px"}}
					// onClick={(e)=>{e.preventDefault();this.props.setPage("user_page")}}
					to='/myaccount'
				>
					<div><span className="MuiButton-label">Join {this.state.namesofclassesimin.length>0?"Another ":"A "}Class
					</span>
					</div>
				</MyStyledLink>
				<br/>
				<hr className="dividerstyle" />
				<h2	style={{marginTop:"0.5em"}}
					>All exercises</h2>
				<GroupManagerPage
					exerciseButtonFunctions={this.props.exerciseButtonFunctions}
					mywssstats={this.state.mywssstats}
					user={this.props.user}
					classes={this.state.classes}
					updateClasses={this.updateClasses}
				/>
			</div>
		)
		//<p>- or -</p>
		/*}	<Exercisesbody
				exerciseButtonFunctions={this.props.exerciseButtonFunctions}
				worksheetselectiontype="notassigned"//selectWorksheet={this.props.selectWorksheet}
			/>
	*/
		//<JoinClassPage/>
	}
}
class GroupManagerPage extends React.Component {
	props!:{
		exerciseButtonFunctions:ExerciseButtonFunctions,
		mywssstats:PupilWsStats[]|undefined,
		classes?:Cohort[],
		user:User,
		updateClasses:()=>void,
	//	setPage:(pagename:PageName)=>void,
	}
	state:{
		worksheets:Worksheet[],
		worksheetsloaded:boolean,
//		refreshtimeout:any,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			worksheets:[],
			worksheetsloaded:false,
//			refreshtimeout:null,
		}
		this.getworksheets = this.getworksheets.bind(this);
//		this.componentDidMount = this.componentDidMount.bind(this);
	}
//	componentWillUnmount(){
//		clearTimeout(this.state.refreshtimeout);
//	}
	async getworksheets(){
		let worksheets = await fetchpublicworksheets();
		//worksheets = worksheets.filter((w:Worksheet)=>worksheetsassignedtome.findIndex((wa:Worksheet)=>wa.ID===w.ID)===-1);
		this.setState({worksheets:worksheets,worksheetsloaded:true});
	}
	componentDidMount() {
		this.getworksheets();
	}
	render(){
		if(!this.state.worksheetsloaded){//TODO should probably make some wrapper for getting worksheets since this code is duplicated in Exercisesbody
			return(
				<p>Loading ...</p>
			)
		}else{
			console.log("topic_table.length"+topic_table.length);
			return(
				<div>
					<GroupComponent
						key={0}
						topic_table={topic_table}
						topic={topic_table[0]}
						exerciseButtonFunctions={this.props.exerciseButtonFunctions}
						worksheets={this.state.worksheets}
						level={0}
						mywssstats={this.props.mywssstats}
						classes={this.props.classes}
						user={this.props.user}
						updateClasses={this.props.updateClasses}
						parentlist={[0]}
					/>
				</div>
			)
		}
	}
}
interface TeacherHomePageProps extends RouteComponentProps {
	globaluser: User | null | undefined;
}
export class TeacherHomePage extends React.Component<TeacherHomePageProps> {
	state:{
		classes:Cohort[],
		openedassignment_ID?:number,
		openedclass_ID?:number,
		assignmentclicked:boolean,
		byPupilView:boolean,
		byQuestionView:boolean,
		pupilclicked:number|undefined,
		questionclicked:number|undefined,
		selected_assignments_to_set:number[],
		publicworksheets:Worksheet[],//{worksheet_name:string, W_ID:number, type:WorksheetType|undefined}[],
		admin:boolean,
		fullscreen:boolean,
	}
	constructor(props: TeacherHomePageProps){
		super(props);
		this.state={
			classes: [] as Cohort[], //will be populated by fetchClasses
			//openedassignment_ID:null,
			//openedclass_ID:null,
			assignmentclicked:false,
			byPupilView:false,
			byQuestionView:false,
			pupilclicked:undefined,
			questionclicked:undefined,
			publicworksheets:[],
			selected_assignments_to_set:[],//TODO what is this? Louis: This is 
			admin:false,
			fullscreen:false,
		}
		this.onAssignmentbuttonclicked=this.onAssignmentbuttonclicked.bind(this);
		// this.onSetAssignmentbuttonclicked=this.onSetAssignmentbuttonclicked.bind(this);
		this.closeAssignmentView=this.closeAssignmentView.bind(this);
		this.fetchClasses=this.fetchClasses.bind(this);
		this.fetchPublicWorksheetTitles=this.fetchPublicWorksheetTitles.bind(this);
		this.updateViewBasedOnURL=this.updateViewBasedOnURL.bind(this);
		this.updateQuestionClicked=this.updateQuestionClicked.bind(this);
		this.updatePupilClicked=this.updatePupilClicked.bind(this);
	//	this.deleteClass=this.deleteClass.bind(this);
	}
	componentDidMount(){
		this.fetchClasses();
		this.fetchPublicWorksheetTitles();
	}
	componentDidUpdate(prevProps:TeacherHomePageProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
		  console.log('PupilManagerPage URL has changed');
		  this.updateViewBasedOnURL();
		  // Perform any necessary actions
		}
	}
	updateViewBasedOnURL() {
        const { location } = this.props;
        const pathSegments = location.pathname.split('/');
        console.log('updating based on url')
        // Logic to determine state based on URL
        const byQuestionView = pathSegments.includes('byquestion');
        const byPupilView = pathSegments.includes('bypupil');
		let questionclicked = this.state.questionclicked;
    	let pupilclicked = this.state.pupilclicked;
		if (byQuestionView){
			pupilclicked = undefined; 
		}
		if (byPupilView){
			questionclicked = undefined;
		}
        this.setState({ byQuestionView, byPupilView, pupilclicked, questionclicked});
    } 
	fetchClasses(){ //probably should ahve this functino elsewhere. 
		console.log("fetching classes");
		fetch("/myclasses") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
		.then(response => response.json())
		.then(js_obj => this.setState({
			classes:js_obj,
			selected_assignments_to_set:Array(js_obj.length) //should fill array so that I don't have undefined
		}));
	}
	fetchPublicWorksheetTitles(){
		console.log("fetchPublicWorksheetTitles");
		fetch("/fetchPublicWorksheetTitles") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
		.then(response => response.json())
		.then(js_obj => this.setState({
			publicworksheets:js_obj.publicworksheets
		}));
	}
	onAssignmentbuttonclicked(Class_ID:number,Assignment_ID:number){
		this.setState({
			openedassignment_ID:Assignment_ID,
			openedclass_ID:Class_ID,
			assignmentclicked:true,
			fullscreen:true,
		});
		this.props.history.push(`classes/assignmentview/${Class_ID}/${Assignment_ID}`);
	}
//	onAssignmentbuttonclicked(e: React.MouseEvent<HTMLButtonElement>){
//		console.log("Button clicked with: "+JSON.stringify(e.currentTarget.name,null,4));
//		this.setState({
//			openedassignment_ID:JSON.parse(e.currentTarget.name).Assignment_ID,
//			openedclass_ID:JSON.parse(e.currentTarget.name).Class_ID,
//			assignmentclicked:true,
//		});
//		console.log("setstate successful");
//	}
	onSwitchAssignmentbuttonclicked(direction:"next"|"prev"){
		//we know the class ID and the assignment ID. We want to go to the next assignment. Can we just increment the assignment ID, they could have deleted it. We actually want to go to the next assignment in the list. 
		//So we want to get the class, 
		if(this.state.classes===undefined) throw "classes undefined"
		if(this.state.openedclass_ID===undefined) throw "openedclass_ID undefined"
		let thisclass = this.state.classes.find((cohort)=>{return cohort.ID===this.state.openedclass_ID});
		if(thisclass===undefined) throw "thisclass undefined"
		let assignments = thisclass.Assignments;
		//then find the position of the assignment in the array, 
		if(this.state.openedassignment_ID===undefined) throw "openedassignment_ID undefined"
		let assignmentPosition = assignments.findIndex((assignment:Assignment) => {return assignment.Assignment_ID===this.state.openedassignment_ID});
		//then go to the next position,
		let newAssignmentPosition = assignmentPosition;
		if (direction==="next"){
			if(newAssignmentPosition<assignments.length-1) newAssignmentPosition ++;//if we go beyond the end of the array then go back
		} else if (direction==="prev"){
			if(newAssignmentPosition>0) newAssignmentPosition --; //if we go beyond the end of the array then go back		
		} else {
			throw "must specificy next or prev"
		}
		//get the ID then set it.
		let newAssignment = assignments[newAssignmentPosition];
		let newAssignmentID = newAssignment.Assignment_ID
		this.setState({
			openedassignment_ID:newAssignmentID,
		});
		let pushtoURL = `/classes/assignmentview/${this.state.openedclass_ID}/${newAssignmentID}`;
		if (this.state.byPupilView) {
			pushtoURL += "/bypupil";
		} else if (this.state.byQuestionView) {
			pushtoURL += "/byquestion";
		}

		this.props.history.push(pushtoURL);
	}
	closeAssignmentView(){
		console.log("Assignment closed");
		this.setState({
			openedassignment_ID:null,
			openedclass_ID:null,
			assignmentclicked:false,
		});
	}
	updateQuestionClicked(questionclicked:number|undefined) {
		console.log('setting questionclicked to: ' + questionclicked)
		this.setState({ questionclicked });
	}
	updatePupilClicked(pupilclicked:number|undefined) {
		this.setState({ pupilclicked });
	}
	class():Cohort{
		console.log("PupilManagerPage is getting a class to give to assignmentview");
		if(this.state.classes!=null){
			var openedclass = this.state.classes.find(clas=>clas.ID===this.state.openedclass_ID);
			if(openedclass!=null){
				console.log("found a class");
				return openedclass
			} else{
				console.log("could not find class matching this ID");
				throw "could not find class matching this ID";
			}
		} else {
			console.log("tried to open a class when there was no class");
			throw "tried to open a class when there was no class";
		}

	}
	onToggleFullScreen(){
		this.setState({fullscreen:!this.state.fullscreen});
	}
	render(){
		const { history, match, location, globaluser } = this.props;
		const { classes, fullscreen, publicworksheets, selected_assignments_to_set } = this.state;
		const hideprevnextassignmentbuttons = !this.state.assignmentclicked //|| this.state.byPupilView || this.state.byQuestionView
		// var pupilmanagerbody=<p>There has been an error</p>;
		// if(!this.state.classes){
		// 	console.log("classes not loaded"+JSON.stringify(this.state.classes));
		// 	pupilmanagerbody = <p>Loading classes...</p>;
		// } else {
		// 	console.log("classes loaded");//+JSON.stringify(this.state.classes));
		// 	if(!this.state.assignmentclicked){
		// 		console.log("assignment not clicked");
		// 		pupilmanagerbody = <div>
		// 								<ClassHomePage
		// 									classes={this.state.classes}
		// 									onAssignmentbuttonclicked={this.onAssignmentbuttonclicked}
		// 									setAssignmentSelected={this.onSetAssignmentbuttonclicked}
		// 									publicworksheets={this.state.publicworksheets}
		// 									selected_assignments_in_dropdowns={this.state.selected_assignments_to_set}
		// 									//deleteClass={this.deleteClass}
		// 									fetchClasses={this.fetchClasses}
		// 								//	toggleLive={this.toggleLive}
		// 								/>
		// 								<br/>
		// 								<AddClassComponent 
		// 									fetchClasses={this.fetchClasses}
		// 									onlyeditting={false}
		// 								/>
		// 							</div>
		// 	} // } else {
			// 	console.log("assignment clicked");
			// 	if((this.state.openedassignment_ID!=null)&&(this.state.openedclass_ID!=null)){
			// 		console.log("assignment clicked, openedassignment set, openedclass set will attempt to render AssignmentView");
			// 		pupilmanagerbody = <span>
			// 							<AssignmentView
			// 								closeAssignmentView={this.closeAssignmentView}
			// 								Assignment_ID={this.state.openedassignment_ID}
			// 								//class={this.class()}
			// 								class_ID={this.state.openedclass_ID}
			// 								admin={this.state.admin}
			// 								globaluser={this.props.globaluser}
			// 							/>
			// 							</span>
			// 	}else{
			// 		console.log("either opened assignment_ID or openedclass_ID are not true");
			// 		console.log("this.state.openedassignment_ID"+this.state.openedassignment_ID);
			// 		console.log("this.state.openedclass_ID"+this.state.openedclass_ID);
			// 		throw "tried to open assignment view but didnt have this.state.openedassignment_ID or this.state.openedclass_ID";
			// 	}
			// }
		// }
		return(//Pupil manager body will take worksheet and class as props, eventually the teacher will have a list or classes to view
			<div className="center">
				<h1
				style={{textAlign:"center"}}
				hidden={this.state.assignmentclicked}
				>Teacher Home
				</h1>
				<h2> Your Classes</h2>
				{/* <span
					hidden = {globaluser!=undefined?globaluser.usertype!=="superuser":false}>
					<button
						className="button innavigationbar float"
						onClick={(e)=>{e.preventDefault();this.setState({admin:!this.state.admin})}}
						>Admin
					</button>
				</span> */}
					{/* <ClassHomePage
					classes={classes}
					onAssignmentbuttonclicked={this.onAssignmentbuttonclicked}
					setAssignmentSelected={this.onSetAssignmentbuttonclicked}
					publicworksheets={publicworksheets}
					selected_assignments_in_dropdowns={selected_assignments_to_set}
					fetchClasses={this.fetchClasses}
					/> */}
					<ClassButtonList classes={classes} />
					<br />
					<AddClassComponent fetchClasses={this.fetchClasses} onlyeditting={false} />
					<br />
					{/* <hr className="dividerstyle" />
					<h2>Help</h2>
					<p>Contact mark@mymarkingmachine.com if you want help or have have feedback.</p> */}
					{/* <PupilManagerInstructions assignmentclicked={this.state.assignmentclicked} />		 */}
			</div>
		)
	}
}
interface ClassButtonListProps {
    classes: Cohort[];
    // onAssignmentbuttonclicked: (Class_ID: number, Assignment_ID: number) => void;
    // setAssignmentSelected: (class_index: number, W_ID: number) => void;
    // publicworksheets: Worksheet[];
    // selected_assignments_in_dropdowns: number[];
    //	deleteClass:(class_index:number)=>void,
	// fetchClasses:()=>void,
	//	toggleLive:(class_index:number,class_ID:number,assignment_index:number,assignment_ID:number)=>void,
}
class ClassButtonList extends React.Component<ClassButtonListProps> {
    constructor(props: ClassButtonListProps) {
        super(props);
    }

    render() {
        const displayedclasses = this.props.classes;
		let classesbody: React.ReactNode = null;
		let classButtons = displayedclasses.slice().reverse().map((c) => (
			<div key={c.ID}><ClassButton class={c}/></div>
		));
		if (this.props.classes.length===0){
			classesbody = <p>You don't have any classes yet. To get started, click on 'Create Class' below.</p>
		}
		else {
			classesbody = (
			<div style={exercisesGridStyle}>
				{classButtons}
			</div>
		);
	}
		return(
				<div>
					{classesbody}
				</div>
			)
	}
}
class ClassButton extends React.Component {
	props!:{
		class:Cohort,
	}
	render(){
		const c = this.props.class
		return(
			<div style={{display:"block", width:"100%"}}>
				<Link to={`/classes/${c.ID}`}>
					<button className="button exercise"
					>{c.name} <span style={{color:"lightgrey", fontSize:"smaller", fontWeight:"lighter", float:"right"}}>{getPupilNumber(c)==0?"":getPupilNumber(c)}</span>
                    </button>
				</Link>
			</div>
		)
	}
}

class PupilManagerInstructions extends React.Component {
	props!:{
		assignmentclicked:boolean,
	}
	state:{
		settingupaclass:boolean,
		settingassignments:boolean,
		completingassignments:boolean,
		viewingpupilwork:boolean,
		forgottencredentials:boolean,
		showvideo:boolean,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			settingupaclass:false,
			settingassignments:false,
			completingassignments:false,
			viewingpupilwork:false,
			forgottencredentials:false,
			showvideo:true,
		}
	//	this.onAssignmentbuttonclicked=this.onAssignmentbuttonclicked.bind(this);

	}
	render(){
		if (!this.props.assignmentclicked){
			return(
				<div>
					<h2>Instructions</h2>
					<h3
						onClick={()=>{this.setState({showvideo:!this.state.showvideo})}}
						>
						{this.state.showvideo?"˅ ":"> "}
						Teacher walk through video</h3>
					<div
						className={"collapsableinfo"}
						hidden={!this.state.showvideo}>
						<div
							className="video-responsive">
							<iframe
								id="iframe1"
								width="560"
								height="315"
								src='https://www.youtube.com/embed/dTdgvfAI2Nc?wmode=opaque&modestBranding=1&rel=0'
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							></iframe>
						</div>
						<a href="https://youtu.be/dTdgvfAI2Nc"  target="_blank">https://youtu.be/dTdgvfAI2Nc</a>
					</div>
					<h3
						hidden={this.props.assignmentclicked}
						onClick={()=>{this.setState({settingupaclass:!this.state.settingupaclass})}}
						>
						{this.state.settingupaclass?"˅ ":"> "}
						Create class
					</h3>
					<div
						//style={{""}}
						className={"collapsableinfo"}
						hidden={(!this.state.settingupaclass)||this.props.assignmentclicked}>
						<ol>
							<li>Click 'Create Class'.</li>
							<li>Name the class e.g. Mr Robinson 11A/Sc.</li>
							<li>Click 'Submit'.</li>
							<li>A button for that class will appear above, that will take you to your new class page.</li>
							<li>Within the class page you can set assignments, view markbooks and manage members.</li>
							<li>Within the class page, after you set an assignment you can 'Show join class instructions' which students use to join your class.</li>
						</ol>
						<br/>
					</div>
					<h3
						hidden={this.props.assignmentclicked}
						onClick={()=>{this.setState({settingassignments:!this.state.settingassignments})}}
						>
						{this.state.settingassignments?"˅ ":"> "}
						Set work
					</h3>
					<div
						className={"collapsableinfo"}
						hidden={(!this.state.settingassignments)||this.props.assignmentclicked}>
						<ol>
							<li>Within the page for a specific class, click on the 'select a worksheet' dropdown in column 'Set Assignment'.</li>
							<li>Click on the worksheet that you want to assign e.g. "Equations GCSE Physics".</li>
							<li>You should see this worksheet appear as a button under the column 'Assignment'.</li>
							<li>The worksheet now appears on the 'Exercises' page for all pupils in your class.</li>
							<li>Tell your pupils to do the assignment in the same way as you would with other work. Below is a template set of instructions to give them if they have already registered. See the above section if this is the first time for them.</li>
						</ol>
						<p>Instructions for pupils (edit key info)</p>
						<ol>
							<li>Visit <a>mymarkingmachine.com</a></li>
							<li>Log in with your school username or school email.</li>
							<li>Click on <s>Put your worksheet here</s> and complete the questions.</li>
							<li>Use the feedback given to improve your answer before moving onto the next question.</li>
							<li>Let me know immediately if you have difficulty logging in. You can email me at <s>Your email here</s> .</li>
						</ol>
						<br/>
					</div>
					<h3
						onClick={()=>{this.setState({completingassignments:!this.state.completingassignments})}}
						hidden={this.props.assignmentclicked}
						>
						{this.state.completingassignments?"˅ ":"> "}
						Try questions
					</h3>
					<div
						className={"collapsableinfo"}
						hidden={(!this.state.completingassignments)||this.props.assignmentclicked}>
						<ul>To try out an assignment, you need to assign it to a class. You could make a trial class for this purpose.</ul>
						<ul>Click on the 'Questions' tab in the top left of the screen and you will see your assignments there.</ul>
						<ul>Constructive feedback is encouraged!</ul>
					</div>
	
					<h3
						onClick={()=>{this.setState({viewingpupilwork:!this.state.viewingpupilwork})}}
						>
						{this.state.viewingpupilwork?"˅ ":"> "}
						View markbook
					</h3>
					<div
						className={"collapsableinfo"}
						hidden={!this.state.viewingpupilwork}>
						<ul hidden={this.props.assignmentclicked}>Come to this page by clicking the Pupil Manager tab at the top of the screen.</ul>
						<ul hidden={this.props.assignmentclicked}>Click on the assignment that you would like to view in the 'View Assignment Markbook' column.</ul>
						<ul>Once in the markbook you can:
							<ul>
								<li>click a pupil's name to that pupil's work or </li>
								<li>click a question number to view the class' work for that question.</li>
								<li>click '%Com' etc to sort by these columns.</li>
								<li>click 'Anonymise' to toggle anonymity.</li>
								<li>click '⤆⤇' to widen the display.</li>
							</ul>
						</ul>
						<ul hidden={this.props.assignmentclicked}>The 'Back' button in the top right brings you back to this page.</ul>
					</div>
	
					<h3
						onClick={()=>{this.setState({forgottencredentials:!this.state.forgottencredentials})}}
						>
						{this.state.forgottencredentials?"˅ ":"> "}
						Manage users in the class including password reset
					</h3>
					<div
						className={"collapsableinfo"}
						hidden={!this.state.forgottencredentials}>
						<ul>Enter any markbook and click show login details, then scroll to the far right of the markbook to:
							<ul>
								<li>view a pupil's username</li>
								<li>unlock their account</li>
								<li>reset their password</li>
								<li>tell them their password if they havn't changed it since last reset</li>
								<li>remove them from the class</li>
							</ul>
						</ul>
						<ul>Contact me if your password needs resetting. I can be reached on Twitter @mark_robo or via email: mark.p.r.robinson@googlemail.com</ul>
						<ul>To make my life easier you could join the class of another teacher so they can reset your password.</ul>
						<ul>A reset by email is coming. One step at a time.</ul>
					</div>
				</div>
			)
		}
		else {
			return null
		}
	}
}
interface ClassPageProps extends RouteComponentProps{
	class_ID: number,
	globaluser: User | null | undefined;
}
export class ClassPageblank extends React.Component<ClassPageProps> {
	constructor(props: ClassPageProps){
		super(props);
	}
	render(){
		const title = this.props.class_ID
		return(
		<h2>{title}
		</h2>
		) 

	}
}
//the below component seems to be a sort of AssignmentView but we already have a component called AssignmentView
//ClassPage is a the appropriate name for what is currently called ClassHomePage
export class ClassPage extends React.Component<ClassPageProps> {
	state:{
		classes:Cohort[],
		openedassignment_ID?:number,
		openedclass_ID?:number,
		assignmentclicked:boolean,
		byPupilView:boolean,
		byQuestionView:boolean,
		pupilclicked:number|undefined,
		questionclicked:number|undefined,
		selected_assignments_to_set:number[],
		publicworksheets:Worksheet[],//{worksheet_name:string, W_ID:number, type:WorksheetType|undefined}[],
		admin:boolean,
		fullscreen:boolean,
	}
	constructor(props: ClassPageProps){
		super(props);
		this.state={
			classes: [] as Cohort[], //will be populated by fetchClasses
			//openedassignment_ID:null,
			//openedclass_ID:null,
			assignmentclicked:false,
			byPupilView:false,
			byQuestionView:false,
			pupilclicked:undefined,
			questionclicked:undefined,
			publicworksheets:[],
			selected_assignments_to_set:[],//TODO what is this? Louis: This is 
			admin:false,
			fullscreen:false,
		}
		this.onAssignmentbuttonclicked=this.onAssignmentbuttonclicked.bind(this);
		this.onSetAssignmentbuttonclicked=this.onSetAssignmentbuttonclicked.bind(this);
		this.closeAssignmentView=this.closeAssignmentView.bind(this);
		this.fetchClasses=this.fetchClasses.bind(this);
		this.fetchPublicWorksheetTitles=this.fetchPublicWorksheetTitles.bind(this);
		this.updateViewBasedOnURL=this.updateViewBasedOnURL.bind(this);
		this.updateQuestionClicked=this.updateQuestionClicked.bind(this);
		this.updatePupilClicked=this.updatePupilClicked.bind(this);
	//	this.deleteClass=this.deleteClass.bind(this);
	}
	componentDidMount(){
		this.fetchClasses();
		this.fetchPublicWorksheetTitles();
	}
	componentDidUpdate(prevProps:ClassPageProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
		  console.log('ClassPage URL has changed');
		  this.updateViewBasedOnURL();
		  // Perform any necessary actions
		}
	}
	updateViewBasedOnURL() {
        const { location } = this.props;
        const pathSegments = location.pathname.split('/');
        console.log('updating based on url')
        // Logic to determine state based on URL
        const byQuestionView = pathSegments.includes('byquestion');
        const byPupilView = pathSegments.includes('bypupil');
		let questionclicked = this.state.questionclicked;
    	let pupilclicked = this.state.pupilclicked;
		if (byQuestionView){
			pupilclicked = undefined; 
		}
		if (byPupilView){
			questionclicked = undefined;
		}
        this.setState({ byQuestionView, byPupilView, pupilclicked, questionclicked});
    } 
	fetchClasses(){ //probably should ahve this functino elsewhere. 
		console.log("fetching classes");
		fetch("/myclasses") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
		.then(response => response.json())
		.then(js_obj => this.setState({
			classes:js_obj,
			selected_assignments_to_set:Array(js_obj.length) //should fill array so that I don't have undefined
		}));
	}
	fetchPublicWorksheetTitles(){
		console.log("fetchPublicWorksheetTitles");
		fetch("/fetchPublicWorksheetTitles") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
		.then(response => response.json())
		.then(js_obj => this.setState({
			publicworksheets:js_obj.publicworksheets
		}));
	}
	onSetAssignmentbuttonclicked(classindex:number,W_ID:number){
		//what is classindex
		//what is selected_assignments_to_set
		console.log("onSetAssignmentbuttonclicked with classindex: "+classindex+" and W_ID: "+W_ID);
		if(!(this.state.selected_assignments_to_set===undefined||this.state.classes===undefined)){
			if(this.state.selected_assignments_to_set.length>classindex||this.state.classes.length>classindex){//this is pretty confusing
				let temp = this.state.selected_assignments_to_set;
				temp[classindex] = -1;//W_ID;
				this.setState({
					selected_assignments_to_set:temp
				})
			//	let editted_class_record = this.state.classes[classindex];
			//	editted_class_record.Assignments[0]. .push(W_ID);
			//	var url = '/modifyRecord';
			//	var data = {action:edit,
			//			ID:this.state.classes[classindex].ID,//using record instead of 'worksheet' as trying to build generic function on App.js side.
			//			record_type:"classe",
			//			record:this.state.worksheets[index],
			//			};
				let url = '/set_assignment';
				let data = {
					class_ID: this.props.class_ID,
					W_ID :W_ID,
				}
				fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
				.then(res => res.json())
				.then(res => this.fetchClasses())
			} else {throw "trying to onSetAssignmentbuttonclicked when classindex >= this.state.selected_assignments_to_set.length"}
		} else {throw "trying to onSetAssignmentbuttonclicked when selected_assignments_to_set is undefined"}
	}

	onAssignmentbuttonclicked(Class_ID:number,Assignment_ID:number){
		this.setState({
			openedassignment_ID:Assignment_ID,
			openedclass_ID:Class_ID,
			assignmentclicked:true,
			fullscreen:true,
		});
		this.props.history.push(`${Class_ID}/assignmentview/${Assignment_ID}`);
	}
//	onAssignmentbuttonclicked(e: React.MouseEvent<HTMLButtonElement>){
//		console.log("Button clicked with: "+JSON.stringify(e.currentTarget.name,null,4));
//		this.setState({
//			openedassignment_ID:JSON.parse(e.currentTarget.name).Assignment_ID,
//			openedclass_ID:JSON.parse(e.currentTarget.name).Class_ID,
//			assignmentclicked:true,
//		});
//		console.log("setstate successful");
//	}
	onSwitchAssignmentbuttonclicked(direction:"next"|"prev"){
		//we know the class ID and the assignment ID. We want to go to the next assignment. Can we just increment the assignment ID, they could have deleted it. We actually want to go to the next assignment in the list. 
		//So we want to get the class, 
		if(this.state.classes===undefined) throw "classes undefined"
		if(this.state.openedclass_ID===undefined) throw "openedclass_ID undefined"
		let thisclass = this.state.classes.find((cohort)=>{return cohort.ID===this.state.openedclass_ID});
		if(thisclass===undefined) throw "thisclass undefined"
		let assignments = thisclass.Assignments;
		//then find the position of the assignment in the array, 
		if(this.state.openedassignment_ID===undefined) throw "openedassignment_ID undefined"
		let assignmentPosition = assignments.findIndex((assignment:Assignment) => {return assignment.Assignment_ID===this.state.openedassignment_ID});
		//then go to the next position,
		let newAssignmentPosition = assignmentPosition;
		if (direction==="next"){
			if(newAssignmentPosition<assignments.length-1) newAssignmentPosition ++;//if we go beyond the end of the array then go back
		} else if (direction==="prev"){
			if(newAssignmentPosition>0) newAssignmentPosition --; //if we go beyond the end of the array then go back		
		} else {
			throw "must specificy next or prev"
		}
		//get the ID then set it.
		let newAssignment = assignments[newAssignmentPosition];
		let newAssignmentID = newAssignment.Assignment_ID
		this.setState({
			openedassignment_ID:newAssignmentID,
		});
		let pushtoURL = `/classes/${this.state.openedclass_ID}/assignmentview/${newAssignmentID}`;
		if (this.state.byPupilView) {
			pushtoURL += "/bypupil";
		} else if (this.state.byQuestionView) {
			pushtoURL += "/byquestion";
		}

		this.props.history.push(pushtoURL);
	}
	closeAssignmentView(){
		console.log("Assignment closed");
		this.setState({
			openedassignment_ID:null,
			openedclass_ID:null,
			assignmentclicked:false,
		});
	}
	updateQuestionClicked(questionclicked:number|undefined) {
		console.log('setting questionclicked to: ' + questionclicked)
		this.setState({ questionclicked });
	}
	updatePupilClicked(pupilclicked:number|undefined) {
		this.setState({ pupilclicked });
	}
	class():Cohort{
		console.log("PupilManagerPage is getting a class to give to assignmentview");
		if(this.state.classes!=null){
			var openedclass = this.state.classes.find(clas=>clas.ID===this.state.openedclass_ID);
			if(openedclass!=null){
				console.log("found a class");
				return openedclass
			} else{
				console.log("could not find class matching this ID");
				throw "could not find class matching this ID";
			}
		} else {
			console.log("tried to open a class when there was no class");
			throw "tried to open a class when there was no class";
		}

	}
	onToggleFullScreen(){
		this.setState({fullscreen:!this.state.fullscreen});
	}
	render(){
		const { history, match, location, globaluser } = this.props;
		const { classes, fullscreen, publicworksheets, selected_assignments_to_set } = this.state;
		const classIDtodisplay = this.props.class_ID;
		const hideprevnextassignmentbuttons = !this.state.assignmentclicked //|| this.state.byPupilView || this.state.byQuestionView
		
		const { errorElement, classToDisplay } = getClassToDisplay(classes, classIDtodisplay);
		if (errorElement) {
			return errorElement;
		}
 
		// var pupilmanagerbody=<p>There has been an error</p>;
		// if(!this.state.classes){
		// 	console.log("classes not loaded"+JSON.stringify(this.state.classes));
		// 	pupilmanagerbody = <p>Loading classes...</p>;
		// } else {
		// 	console.log("classes loaded");//+JSON.stringify(this.state.classes));
		// 	if(!this.state.assignmentclicked){
		// 		console.log("assignment not clicked");
		// 		pupilmanagerbody = <div>
		// 								<ClassHomePage
		// 									classes={this.state.classes}
		// 									onAssignmentbuttonclicked={this.onAssignmentbuttonclicked}
		// 									setAssignmentSelected={this.onSetAssignmentbuttonclicked}
		// 									publicworksheets={this.state.publicworksheets}
		// 									selected_assignments_in_dropdowns={this.state.selected_assignments_to_set}
		// 									//deleteClass={this.deleteClass}
		// 									fetchClasses={this.fetchClasses}
		// 								//	toggleLive={this.toggleLive}
		// 								/>
		// 								<br/>
		// 								<AddClassComponent 
		// 									fetchClasses={this.fetchClasses}
		// 									onlyeditting={false}
		// 								/>
		// 							</div>
		// 	} // } else {
			// 	console.log("assignment clicked");
			// 	if((this.state.openedassignment_ID!=null)&&(this.state.openedclass_ID!=null)){
			// 		console.log("assignment clicked, openedassignment set, openedclass set will attempt to render AssignmentView");
			// 		pupilmanagerbody = <span>
			// 							<AssignmentView
			// 								closeAssignmentView={this.closeAssignmentView}
			// 								Assignment_ID={this.state.openedassignment_ID}
			// 								//class={this.class()}
			// 								class_ID={this.state.openedclass_ID}
			// 								admin={this.state.admin}
			// 								globaluser={this.props.globaluser}
			// 							/>
			// 							</span>
			// 	}else{
			// 		console.log("either opened assignment_ID or openedclass_ID are not true");
			// 		console.log("this.state.openedassignment_ID"+this.state.openedassignment_ID);
			// 		console.log("this.state.openedclass_ID"+this.state.openedclass_ID);
			// 		throw "tried to open assignment view but didnt have this.state.openedassignment_ID or this.state.openedclass_ID";
			// 	}
			// }
		// }
		return(//Pupil manager body will take worksheet and class as props, eventually the teacher will have a list or classes to view
			<div className="center"
				style={this.state.fullscreen?{maxWidth:"95vw", width:"95vw"}:{}}>
				<span
					hidden = {globaluser!=undefined?globaluser.usertype!=="superuser":false}>
					<button
						className="button innavigationbar float"
						onClick={(e)=>{e.preventDefault();this.setState({admin:!this.state.admin})}}
						>Admin
					</button>
				</span>
				<Switch>
				<Route
					path={`${match.path}/assignmentview/:assignmentID`}
					render={(props) => (
						<div>
							<button
								className="button innavigationbar float"  //⤄"⛶"
								style={{display:(!this.state.assignmentclicked)?"none":""}}
								onClick={(e)=>{e.preventDefault();this.setState({fullscreen:!this.state.fullscreen})}}
								>{this.state.fullscreen?"⤇⤆":"⤆⤇"}
							</button>
							<button
								className="button innavigationbar float"  //⤄"⛶"
								style={{display:(hideprevnextassignmentbuttons)?"none":""}}
								onClick={(e)=>{e.preventDefault();this.onSwitchAssignmentbuttonclicked("next")}}
								>Next
							</button>
							<button
								className="button innavigationbar float"  //⤄"⛶"
								style={{display:(hideprevnextassignmentbuttons)?"none":""}}
								onClick={(e)=>{e.preventDefault();this.onSwitchAssignmentbuttonclicked("prev")}}
								>Prev
							</button>
							<AssignmentView
								closeAssignmentView={() => props.history.push(`${match.path}`)}
								updateViewBasedOnURL={this.updateViewBasedOnURL}
								updateQuestionClicked={this.updateQuestionClicked}
								updatePupilClicked={this.updatePupilClicked}
								byPupilView={this.state.byPupilView}
								byQuestionView={this.state.byQuestionView}
								pupilclicked={this.state.pupilclicked}
								questionclicked={this.state.questionclicked}
								Assignment_ID={parseInt(props.match.params.assignmentID)}//{this.state.openedassignment_ID}
								class_ID={classIDtodisplay}
								admin={this.state.admin}
								history={history}
								location={location}
								match={match}
								globaluser={globaluser}
							/>
						</div>)}
				/>
				<Route
					path={`${match.path}`}
					render={() => (
					<div>
						{/* <ClassHomePage
							classes={classes}
							history={this.props.history} 
							classIDtodisplay={classIDtodisplay}
							onAssignmentbuttonclicked={this.onAssignmentbuttonclicked}
							setAssignmentSelected={this.onSetAssignmentbuttonclicked}
							publicworksheets={publicworksheets}
							selected_assignments_in_dropdowns={selected_assignments_to_set}
							fetchClasses={this.fetchClasses}
						/> */}
						<ClassPageHeader 
							classtodisplay={classToDisplay}
						/>
						<ClassHomePageNew
							classes={classes}
							history={this.props.history} 
							classIDtodisplay={classIDtodisplay}
							onAssignmentbuttonclicked={this.onAssignmentbuttonclicked}
							setAssignmentSelected={this.onSetAssignmentbuttonclicked}
							publicworksheets={publicworksheets}
							selected_assignments_in_dropdowns={selected_assignments_to_set}
							fetchClasses={this.fetchClasses}
						/>
					</div>
					)}
          		/>
				</Switch>		
			</div>
		)
	}
}
export default withRouter(ClassPage);

interface ClassHomePageProps {
    classes: Cohort[];
	classIDtodisplay: number;
    onAssignmentbuttonclicked: (Class_ID: number, Assignment_ID: number) => void;
    setAssignmentSelected: (class_index: number, W_ID: number) => void;
    publicworksheets: Worksheet[];
    selected_assignments_in_dropdowns: number[];
    //	deleteClass:(class_index:number)=>void,
	fetchClasses:()=>void,
	//	toggleLive:(class_index:number,class_ID:number,assignment_index:number,assignment_ID:number)=>void,
	history: History<unknown>;
}

// Define an interface for your state
interface ClassHomePageState { //not sure that pupilassingmentlist should have routecomponents, I'm just trying to pass down history
    showingdeleteclassdialog: boolean;
    classisdeleting: boolean[];
    selectedclass: number | undefined;
    classesexpanded: boolean[];
    classdetailsdata: PupilWssStats[][];
    groupsexpanded: (string[] | undefined)[];
    selectedjoinclass: number | null;
	showingemptyclasspopup: boolean;
    assignmentstoggled: Record<number, string>; // Correctly type this as a map of number to string
}
class ClassHomePage extends React.Component<ClassHomePageProps, ClassHomePageState> {
	constructor(props: ClassHomePageProps){
		super(props);
		this.state={
			showingdeleteclassdialog:false,
			classisdeleting:Array(props.classes.length).fill(false),
			selectedclass:undefined,
			classesexpanded:Array(props.classes.length).fill(false),
			classdetailsdata:Array(props.classes.length),
			groupsexpanded:[],
			selectedjoinclass:null,
			showingemptyclasspopup: false,
			assignmentstoggled: {},
		}
		this.deleteClassCaller=this.deleteClassCaller.bind(this);
		this.ondeleteClassClicked=this.ondeleteClassClicked.bind(this);
		this.onDeleteDialogResponse=this.onDeleteDialogResponse.bind(this);
		this.toggleClassDetail=this.toggleClassDetail.bind(this);
		this.recordgroupexpansion=this.recordgroupexpansion.bind(this);
		this.showPopupForClass=this.showPopupForClass.bind(this);
		this.showPopupForEmptyClass=this.showPopupForEmptyClass.bind(this);
		this.closePopup=this.closePopup.bind(this);
	}
	async deleteClass(classindex:number) {
		if((this.props.classes!==undefined)&&(this.props.classes.length>classindex)){
			let url = '/modifyRecord';
			let data = {
				action:"delete",
				ID:this.props.classIDtodisplay,
				record_type:"classe",
			};
			fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
			.then(res => res.json())
			.then(res => this.props.fetchClasses())
			.then(nothing => {
				let newState = update(this.state,
					{
						classisdeleting: {
							[classindex]: {$set:false},
						}
					}
				)
				this.setState(newState);
			})
		}
		else {throw "this.state.classes is undefined or too short"}
	}
		
	showPopupForClass = (class_index: number) => { //updates selectedjoinclass and assignmentstoggled
		let selectedclassname = this.props.classes[class_index].name;
		console.log(selectedclassname)
		fetch("/worksheetsassignedtoclass", {
			method: 'POST',
			body: JSON.stringify({ classname: selectedclassname }),
			headers: { 'Content-Type': 'application/json' }
		})
		.then(res => res.json())
		.then(classassignments => {
            let firstAssignmentName = "Ask your teacher which exercise to begin with";
            if (classassignments.length > 0) {
                firstAssignmentName = classassignments[0].name; // Assuming 'name' is the desired property
            }
			else {
				console.log('no live assignments in this class')
				this.showPopupForEmptyClass();
				return
			}
            // Directly update the state with the new information
            let newAssignmentsToggled = { ...this.state.assignmentstoggled };
            newAssignmentsToggled[class_index] = firstAssignmentName;

            this.setState({
                selectedjoinclass: class_index,
                assignmentstoggled: newAssignmentsToggled
            });
        })
        .catch(error => {
            console.error('Error fetching class assignments:', error);
            let newAssignmentsToggled = { ...this.state.assignmentstoggled };
            newAssignmentsToggled[class_index] = "Failed to fetch assignments, please try again.";

            this.setState({
                assignmentstoggled: newAssignmentsToggled
            });
        });
    }
	showPopupForEmptyClass(){
		this.setState({showingemptyclasspopup:true})
	}
	setisLive(classindex:number,class_ID:number,assignment_index:number,assignment_ID:number,islive:boolean){
		//This modifies backend islive for the assignment but has no effect on the state of the component
		if((this.props.classes!==undefined)&&(this.props.classes.length>classindex)){
			let url = '/modify_assignment';
			let data = {
				class_ID:class_ID,
				assignment_ID,
				islive,
			};
			fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
			.then(res => res.json())
			.then(res => this.props.fetchClasses())
		//	.then(nothing => {
		//		let newState = update(this.state,
		//			{
		//				classisdeleting: {
		//					[classindex]: {$set:false},
		//				}
		//			}
		//		)
		//		this.setState(newState);
		//	})
		}
		else {throw "this.state.classes is undefined or too short"}
	}
	deleteClassCaller(class_index:number){
		let newState = update(this.state,
			{
				classisdeleting: {
					[class_index]: {$set:true},
				},
				selectedclass: {$set:undefined},
				showingdeleteclassdialog: {$set:false}
			}
		)
		this.setState(newState);
		this.deleteClass(class_index);
	}
	ondeleteClassClicked(class_index:number){
		let newState = update(this.state,
			{
				showingdeleteclassdialog: {$set:true},
				selectedclass: {$set:class_index}
			}
		)
		this.setState(newState);
	}
	onDeleteDialogResponse(deleteconfirmed:boolean){
		if(deleteconfirmed){
			if(this.state.selectedclass===undefined){
				throw "selectedclass should not be undefined when deleting a class"
			} else {
				this.deleteClassCaller(this.state.selectedclass); //LOUIS TODO needs to change
				this.props.history.push('/teacherhome')
			}
		} else{
			this.setState({
				selectedclass:undefined,
				showingdeleteclassdialog:false,
			})
		}
	}
	async toggleClassDetail(class_index:number){//TODO need to change to classID for robustness when reordering possibly
		let expandingdetails = !this.state.classesexpanded[class_index];
		let newState = update(this.state,
			{
				classesexpanded: {
					[class_index]: {
						$set:expandingdetails
					},
				}
			}
		)
		this.setState(newState);
		let class_ID = this.props.classIDtodisplay;
		//let pupils = clas.Pupils;
		//let pupilIDs = pupils.map((p)=>p.U_ID);
		//let W_IDs = clas.Assignments.map((a)=>a.W_ID);
		if(expandingdetails){
			let classdetails = await fetchPupilsWssStats({C_ID:class_ID})
			let anothernewState = update(this.state,
				{
					classdetailsdata: {
						[class_index]: {
							$set:classdetails,
						}
					}
				}
			)
			this.setState(anothernewState);
		}
	}
	recordgroupexpansion(class_index:number,currentnode:TreeNode){
		let list = copy(this.state.groupsexpanded[class_index]);
		let groupid = currentnode.ID as string;
		if(list===undefined){
			list=[groupid];
		} else {
			let groupindex = list.findIndex((id:string)=>id===groupid)
			if(groupindex===-1){
				list.push(groupid)
			} else if(groupindex>-1){
				list=[...list.slice(0,groupindex),...list.slice(groupindex+1)]
			}
		}
		let anothernewState = update(this.state,
			{
				groupsexpanded: {
					[class_index]: {
						$set:list,
					}
				}
			}
		)
		this.setState(anothernewState);
	}
	closePopup() {
		this.setState({
            selectedjoinclass: null,
			showingemptyclasspopup: false,
        });
	}
	render(){
		if(this.props.publicworksheets.length===0 || this.props.classes.length === 0){
			return <p>Loading Class...</p>
		} else {
			const { errorElement, classToDisplay } = getClassToDisplay(this.props.classes, this.props.classIDtodisplay);

			if (errorElement) {
				return errorElement;
			}

			var tablerows=[];
			//for(let i=0;i<c.length;i++){
				
			for(let i=0;i>-1;i--){//🔍 
				//<AssignmentDropdown
				//			setAssignmentSelected = {this.props.setAssignmentSelected}
				//			public_worksheets = {this.props.publicworksheets}
				//			classindex = {i}
				//			selected_assignment = {this.props.selected_assignments_in_dropdowns[i]}
				//		/>
				let assignment_dropdown = <span>
						<DropdownTreeSelect 
							data={getexercisestree(this.props.publicworksheets,topic_table,classToDisplay.Assignments,this.state.groupsexpanded[i])} 
							mode={"hierarchical"}
							texts={{placeholder:"Select assignment"}}
							onNodeToggle={(currentnode:TreeNode)=>{
								this.recordgroupexpansion(i,currentnode)
							}}
							//onAction={onAction} 
							onChange={(currentnode:TreeNode)=>{
								console.log("hi");
								console.log("clicked on node: "+JSON.stringify(currentnode,null,4));
								if(!currentnode.isws) {throw "trying to assign a group";}
								this.props.setAssignmentSelected(i,(currentnode.value as unknown as number))
							}} 
							inlineSearchInput={true}
							// showDropdown='always'
						/>
					</span>
					//I'm just looking into putting the exercise navigator in this location. 
					//Looking at the prop requirements and where to get them from, very useful having two Common windows open. x
				//let assignment_dropdown = <GroupManagerPage
				//		exerciseButtonFunctions={this.props.exerciseButtonFunctions}
				//		mywssstats={this.state.mywssstats}
				//		user={this.props.user}
				//		classes={this.state.classes}
				//		updateClasses={this.updateClasses}
				//	/>
				let assignments = [];
				//for(let j=0;j<c[i].Assignments.length;j++){
				for(let j=classToDisplay.Assignments.length-1;j>-1;j--){
					let assignment = classToDisplay.Assignments[j];
				//	console.log("publicworksheets"+JSON.stringify(this.props.publicworksheets,null,4));
					let worksheet = this.props.publicworksheets.find((worksheet:Worksheet)=>{
						return worksheet.ID===assignment.W_ID
					});
					if (worksheet===undefined) throw "worksheet for assignment not found"+JSON.stringify(assignment,null,4);
					let islive = assignment.islive;
					if(assignment.islive===undefined){
						islive = worksheet.type!=="assessment";
					}
					assignments.push(
						<tr>
							<th>
								<button
									className = "markbookbutton"
									key = {assignments.length}
									onClick = {()=>{this.props.onAssignmentbuttonclicked(classToDisplay.ID,classToDisplay.Assignments[j].Assignment_ID)}}
									//onClick = {this.props.handleClick}
									//name = {JSON.stringify({
									//	Class_ID:c[i].ID,
									//	Assignment_ID:c[i].Assignments[j].Assignment_ID,
									//})}
									//style={{float:"left"}}
									>{classToDisplay.Assignments[j].name}
									{/* <span className='worksheet-name'>
										<Link to={`/classes/assignmentview/${c[i].ID}/${c[i].Assignments[j].Assignment_ID}`} style= {{textDecoration : 'none', color:'inherit'}}

										>{c[i].Assignments[j].name}
										</Link>
									</span> */}
								</button>
							</th>
							<th
								style={{verticalAlign:"text-top"}}>
								<label className="switch"
									style={{float:"right"}}
								>
									<input className="slider" type="checkbox" checked={islive} onChange={(e)=>{this.setisLive(i,classToDisplay.ID,j,classToDisplay.Assignments[j].Assignment_ID,!islive)}}/>
									<span className="slider"></span>
								</label>
							</th>
						</tr>
					)
				//	assignments.push(
				//		<br key = {assignments.length}/>
				//	)
				}

				if(assignments.length===0){
					assignments[0]=<span>No assignments set</span>
				}
				else {
					// assignments.push(
					// <button className="markbookbutton"
					// 		onClick={() => this.showPopupForClass(0)}
					// 		//style= {{color:'#000939'}}
					// >Show join class instructions
					// </button>
					// )
				}
				tablerows.push(//⇲
					<tr key = {tablerows.length}
						className="zebra_table_body">
						{/* <td>
							<AddClassComponent 
								fetchClasses={this.props.fetchClasses}
								onlyeditting={true}
								cohort={classtodisplay}
								classname={classtodisplay.name}
							/>
						</td> */}
						{/* <td>{getPupilNumber(classtodisplay)}</td> */}
						<td>{assignment_dropdown}</td>
						<td>
							<div> 
								<button
									onClick={()=>this.toggleClassDetail(0)}
									className="markbookbutton summary"
									hidden={assignments.length<2}
									>Markbook summary ⇲
								</button>
								<table style={{width:"100%"}}>
									<tbody>{assignments}</tbody>									
								</table>
							</div>
						</td>
						{/* <td>
							<span style={{cursor:"pointer"}} onClick={()=>navigator.clipboard.writeText(classtodisplay.class_code)}>{classtodisplay.class_code}</span>
						</td> */}
						{/* <td style={{verticalAlign:"top",paddingTop:"0.2em",textAlign:"center"}}>{delete_button}</td> */}
					</tr>
				)
				if(this.state.classesexpanded[0]){
					tablerows.push(
						<tr key = {tablerows.length}
							className="zebra_table_body">		
						</tr>
					);
					tablerows.push(
						<tr key = {tablerows.length}
							className="zebra_table_body">
							<td colSpan={6}>
								<div hidden = {!this.state.classesexpanded[0]}>
									<ClassOverview
										class={classToDisplay}
										onAssignmentbuttonclicked={this.props.onAssignmentbuttonclicked}
										visible={this.state.classesexpanded[0]}
										classdetails={this.state.classdetailsdata[0]}
										classindex={0}
										toggleClassDetail={this.toggleClassDetail}
									/>
								</div>
							</td>
						</tr>
					)
				}
			}
			// Conditionally render the PopupClassInstructions component based on selectedjoinclass

			const popup = this.state.selectedjoinclass !== null ? (
				<PopupClassInstructions
					selectedClassCode={classToDisplay.class_code}
					firstAssignmentToggled={this.state.assignmentstoggled[this.state.selectedjoinclass]}
					closePopup={this.closePopup}
				/>
			) : null;
			const popup2 = this.state.showingemptyclasspopup ? (
				<PopupEmptyClassInstructions
					closePopup={this.closePopup}
				/>
			) : null;
			return(
				<div style={{ display: 'flex', flexDirection: 'column', maxHeight:'95vh', minHeight:"60vh", maxWidth:"95vh"}}>
					
					<ClassPageHeader 
						classtodisplay={classToDisplay}
					/>
					
					<table>
						<thead>
							<tr className="zebra_table_head">
								{/* <th>Class name</th> */}
								{/* <th>Size</th> */}
								<th>Set work</th>
								<th>Markbooks</th>
								{/* <th>Class Code</th> */}
								{/* <th>Delete Class</th> */}
							</tr>
						</thead>
						<tbody style={{verticalAlign:"text-top"}}>
							{tablerows}
						</tbody>
					</table>
					<br></br>
					<div>
						<button 
							className="markbookbutton"
							hidden={classToDisplay.Assignments.length===0}
							onClick={() => this.showPopupForClass(0)}
							>Show join class instructions
						</button>
						<br></br>
						<br></br>
						<button
							className="markbookbutton"
							onClick={(e)=>{e.preventDefault();this.ondeleteClassClicked(0)}}
						>Delete Class ❌
						</button>
						{/* <MarkbookButtonList
							class={classtodisplay}
							publicworksheets={this.props.publicworksheets}
							onAssignmentbuttonclicked = {this.props.onAssignmentbuttonclicked}
							showPopupForClass={this.showPopupForClass}
							fetchClasses={this.props.fetchClasses}
							>
						</MarkbookButtonList> */}
					</div>
					{popup}
					{popup2}
					<div
						hidden = {!this.state.showingdeleteclassdialog}>
						<DeleteDialog
							onDeleteDialogResponse={this.onDeleteDialogResponse}
							record_type="class"
							/>
					</div>
				</div>
				//this is just a commen
			)
		}
	}
}

function getClassToDisplay(classes:Cohort[], classID:number) {
    try {
        const selectedClasses = selectClassToDisplay(classes, classID);
        if (selectedClasses.length === 0 || !selectedClasses[0]) {
            return { errorElement: <div>No class found with the provided ID.</div> };
        }
        return { classToDisplay: selectedClasses[0] };
    } catch (error:any) {
        console.error(error);
        return { errorElement: <div>Error: {error.message}</div> };
    }
}


function selectClassToDisplay (classes: Cohort[], classIDtodisplay:number): Cohort[] {
	console.log("selectClassToDisplay called with classIDtodisplay:", classIDtodisplay);
	console.log("Available classes:", classes);

	if (!classes || classes.length === 0) {
		console.warn("Classes array is empty or undefined");
		return [];
	}

	const classtodisplay = classes.find(clas => clas.ID === classIDtodisplay);

	if (!classtodisplay) {
		console.error("Couldn't extract class from list of classes using ID:", classIDtodisplay);
		throw new Error("Couldn't extract class from list of classes using ID");
	}

	return [classtodisplay];
}

interface MarkbookButtonListProps {
    class: Cohort;
	publicworksheets: Worksheet[];
	onAssignmentbuttonclicked: (Class_ID: number, Assignment_ID: number) => void;
	showPopupForClass: (class_index: number) => void;
	fetchClasses:()=>void,

}
let markbookGridStyle = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Adjust the minmax min value as needed
	gap: '10px', // Adjust the space between grid items
	//justifyContent: 'center',
	//padding: '20px'
	columnGap: '20px'
};
class MarkbookButtonList extends React.Component<MarkbookButtonListProps> {
    constructor(props: MarkbookButtonListProps) {
        super(props);
		this.setisLive = this.setisLive.bind(this);
    }

	setisLive(classindex:number,class_ID:number,assignment_index:number,assignment_ID:number,islive:boolean){
		//This modifies backend islive for the assignment but has no effect on the state of the component
		if(this.props.class!==undefined){
			let url = '/modify_assignment';
			let data = {
				class_ID:class_ID,
				assignment_ID,
				islive,
			};
			fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
			.then(res => res.json())
			.then(res => this.props.fetchClasses())
		}
		else {throw "this.state.classes is undefined or too short"}
	}
    render() {
		let markbooksbody = {};
		let markbooks = [];
		const classtodisplay = this.props.class
		for(let j=classtodisplay.Assignments.length-1;j>-1;j--){
			let assignment = classtodisplay.Assignments[j];
		//	console.log("publicworksheets"+JSON.stringify(this.props.publicworksheets,null,4));
			let worksheet = this.props.publicworksheets.find((worksheet:Worksheet)=>{
				return worksheet.ID===assignment.W_ID
			});
			if (worksheet===undefined) throw "worksheet for assignment not found"+JSON.stringify(assignment,null,4);
			let islive = assignment.islive;
			if(assignment.islive===undefined){
				islive = worksheet.type!=="assessment";
			}
			markbooks.push(
				<tr>
					<th>
						<button
							className = "markbookbutton"
							key = {assignment.Assignment_ID}
							onClick = {()=>{this.props.onAssignmentbuttonclicked(classtodisplay.ID,classtodisplay.Assignments[j].Assignment_ID)}}
							>{classtodisplay.Assignments[j].name}
						</button>
					</th>
					<th
						style={{verticalAlign:"text-top"}}>
						<label className="switch"
							style={{float:"right"}}
						>
							<input className="slider" type="checkbox" checked={islive} onChange={(e)=>{this.setisLive(0,classtodisplay.ID,j,classtodisplay.Assignments[j].Assignment_ID,!islive)}}/>
							<span className="slider"></span>
						</label>
					</th>
				</tr>
			)
		}

		if(markbooks.length===0){
			markbooks[0]=<span>No assignments set</span>
		}
		else {
			markbooks.push(
			<button className="markbookbutton"
					onClick={() => this.props.showPopupForClass(0)}
					//style= {{color:'#000939'}}
			>Show join class instructions
			</button>
			)
		}
	return(
		<table style={{width:"100%"}}>
			<tbody>{markbooks}</tbody>									
		</table>
		)
	}
}
interface PopupClassInstructionsProps {
	selectedClassCode: string;
	closePopup: () => void;
	firstAssignmentToggled: string; // If you plan to use it, ensure to include it in the rendering logic or remove it if unnecessary.
}
interface PopupEmptyClassInstructionsProps {
	closePopup: () => void;
}
export class PopupClassInstructions extends React.Component<PopupClassInstructionsProps> {
    constructor(props: PopupClassInstructionsProps) {
        super(props);
    }

    render(){
		//we want to intelligentlly put {this.props.firstAssignmentToggled} into the exercises to be assigned.
        return(
            <div className="popup">
                <p>
                    <ol style={{ padding: '0 20px' , lineHeight: '2'}}>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Go to <b>mymarkingmachine.com</b>
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Click <b>Get Started</b>
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Enter username (first part of email)
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Enter your school email
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Enter class code: <b>{this.props.selectedClassCode}</b>
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Click on assignment: <b>Teacher: click to edit.</b>
							</li>
                        <li contentEditable style={{ marginBottom: '10px', fontSize: '36px' }}>Start answering questions – use feedback to help
							</li>
                    </ol>
                </p>
				<div style= {{
					margin:'0'
				}}>
					<button className='close-popup-button' onClick={this.props.closePopup} style={{
						top: '5em',
						right: 'auto',
					}}
					>×
					</button>
				</div>
				
            </div>
        );
    }
}
export class PopupEmptyClassInstructions extends React.Component<PopupEmptyClassInstructionsProps> {
    constructor(props: PopupEmptyClassInstructionsProps){
        super(props);
    }

    render(){
        return(
            <div className="popup" style={{
                width: 'auto', // Smaller popup
                maxHeight: '200px', // Maximum height of the popup 
            }}>
                <p>
                    This class has no assignments currently assigned to it.<br></br><br></br>
                    Use the toggle next to the assignments which appear under the column 'Markbooks'.
                </p>
				<div>
                	<button className='close-popup-button' onClick={this.props.closePopup}
					>×
					</button>
				</div>
            </div>
        )
    }
}


export class ClassOverview extends React.Component {
	props!:{
		class:Cohort,
		onAssignmentbuttonclicked:(Class_ID:number,Assignment_ID:number)=>void,
		visible:boolean,
		classdetails:PupilWssStats[]|undefined,
		classindex:number,
		toggleClassDetail:(classindex:number)=>void,
	}

	state!: {
		selected: number
	}

	constructor(props: any) {
		super(props);
		this.state = { selected: 1 };
		this.changeSelected = this.changeSelected.bind(this);
	}

	changeSelected(event: any): void {
		this.setState({ selected: event.target.value });
	}

	render(){
		if(this.props.classdetails===undefined){
			return(
				<div>
					<h3>Markbook Summary</h3>
					<p>Loading data...</p>
				</div>
			)
		}
		console.log("here is this.props.classdetails" + JSON.stringify(this.props.classdetails, null, 4))
		let assignments = this.props.class.Assignments;
		let assignmentnames = [];
		for(let a = assignments.length-1; a>-1; a--){
			assignmentnames.push(
			<td style={{writingMode: "vertical-rl",
				transform: "rotate(180deg)",
				width:"1.65em",
				maxWidth:"1.65em",
				cursor:"pointer",
				//maxHeight: "10em"
			}}
			onClick = {()=>{this.props.onAssignmentbuttonclicked(this.props.class.ID,this.props.class.Assignments[a].Assignment_ID)}}
			>{assignments[a].name.substr(0,25)+(assignments[a].name.length>25?"..":"")}
			{/* <span className='worksheet-name'>
				<Link to={`/classes/assignmentview/${this.props.class.ID}/${assignments[a].Assignment_ID}`} style= {{textDecoration : 'none', color:'inherit'}}

				>{assignments[a].name.substr(0,25)+(assignments[a].name.length>25?"..":"")}
				</Link>
			</span> */}
			</td>)
		}
		let pupilrows = [];
		let pupils = this.props.class.Pupils;
		//let sortedindices = new Array(pupils.length);
		//for (let i=0; i<pupils.length; i++){
		//	sortedindices[i]=pupils.length-i-1;
		//}
		let sortedindices = sortedindeces(pupils,"surname");
		//makeSortOrder()
		for(let pi=0; pi<pupils.length; pi++){//need to sort by last name
			let p = sortedindices[pi];
			let pupildata = [];
			for(let a = assignments.length-1; a>-1; a--){
				let stats = this.props.classdetails[p].pupilwssstats[a];
				if(stats==null){
					pupildata.push(
						<td key={a}>-</td>
					)
				} else {
					if(stats.stats!=null){
						if(stats.stats.initial_percentage===undefined||stats.stats.max_percentage===undefined){
							throw "initial_percentage===undefined"+ "stats: "+JSON.stringify(stats,null,4)
						}
						//let percentage = stats.stats.max_percentage
						//let max_percentage = Math.round(percentage*100);
						let initial_percentage = Math.round(stats.stats.initial_percentage*100);
						let max_percentage = Math.round(stats.stats.max_percentage*100);
						let reset_percentage = Math.round((stats.stats.reset_percentage||0)*100);
						//let resetwoh_percentage = Math.round((stats.stats.resetwoh_percentage||0)*100);
						let wohelp_percentage = Math.round((stats.stats.wohelp_percentage||0)*100);
						//check out: AssignmentViewWorksheet for more nice code for this

						let toDisplay: string;
						let colourOfCell
						switch (this.state.selected) {
							case 0:
								toDisplay = "" + initial_percentage;
								colourOfCell = coloursFromPercent(stats.stats.initial_percentage);
								break;
							case 1:
								toDisplay = "" + max_percentage;
								colourOfCell = coloursFromPercent(stats.stats.max_percentage);
								break;
							case 2:
								toDisplay = "" + reset_percentage;
								colourOfCell = coloursFromPercent(stats.stats.reset_percentage);
								break;
							case 3:
								toDisplay = "" + wohelp_percentage;
								colourOfCell = coloursFromPercent(stats.stats.wohelp_percentage);
								//colourOfCell = coloursFromPercent(stats.stats.resetwoh_percentage);
								break;
							default:
								toDisplay = "e";
								colourOfCell = coloursFromPercent(0);
						}

						pupildata.push(
							<td key={a} 
								style={{
									backgroundColor:colourOfCell[1],
									maxWidth:"1.65em",
									width:"1.65em",
									minWidth:"1.65em",
									fontSize:"smaller",
									textAlign:"center",
									borderRadius:"0.3em",
								}}>{toDisplay}</td>
						)
					} else {
						pupildata.push(
							<td key={a}
								style={{
									textAlign:"center",
									borderRadius:"0.3em",
								}}
							>-</td>
						)
					}
				}
			}
			pupilrows.push(
						//{toCamelCase(pupils[p].firstname) + " "+toCamelCase(pupilsnames[p].lastname.substring(0,3))}
						//{pupils[p].surname}
				<tr key={p}>
					<td key={"lastname"}>
						<span style={{whiteSpace: "nowrap",maxWidth: "10em",overflow:"hidden"}}>
							{toCamelCase(this.props.classdetails[p].firstname)+" "+toCamelCase(this.props.classdetails[p].lastname)}
						</span>
					</td>
					{pupildata}
				</tr>
			)
		}
		return(
			//<span style={{float:"right"}} onClick={()=>{this.props.toggleClassDetail(this.props.classindex)}}>close</span>
			<div //hidden={!this.props.visible} 
			style={{overflowX:"scroll"}}>
				<h3 
					onClick={()=>{this.props.toggleClassDetail(this.props.classindex)}}
					style={{cursor:"pointer"}}
					>Markbook Summary</h3>
					
					<Select
						value={this.state.selected}
						label="Stats"
						onChange={this.changeSelected}
					>
						<MenuItem value={0}>Inital %</MenuItem>
						<MenuItem value={1}>Completion %</MenuItem>
						{/* <MenuItem value={2}>Reset Percentage</MenuItem> */}
						<MenuItem value={3}>Summary %</MenuItem>
					</Select>
				<table>
					<thead>
						<td style={{verticalAlign:"bottom", fontWeight:"bold"}}>Students</td>
						{assignmentnames}
					</thead>
					<tbody>
						{pupilrows}
					</tbody>
				</table>
			</div>
		)
	}
}
function sortedindeces(arraytosort:{surname:string}[],sortproperty:"surname"):number[]{
	let indexsortingobjectarray:{
		index:number,
		sort_parameter:number|string|undefined,
		param_is_string:boolean
	}[] = [];
	for (let p=0; p<arraytosort.length; p++){
		indexsortingobjectarray.push({
			index : p,
			param_is_string:false,
			sort_parameter:undefined,
		})
	}
	for (let p=0; p<arraytosort.length; p++){
		indexsortingobjectarray[p].sort_parameter=arraytosort[p][sortproperty];
		indexsortingobjectarray[p].param_is_string = true;
	};
	indexsortingobjectarray.sort(compare_sort_parameter);
	let sorted_indices:number[]=[];
	for (let p = 0; p<indexsortingobjectarray.length; p++){
		let index = indexsortingobjectarray[p].index;
		if (index===undefined)throw "index is undefined in makeSortOrder";
		sorted_indices[p]=index;
	}
	return sorted_indices
}
export class DeleteDialog extends React.Component {
	props!:{
		onDeleteDialogResponse:(delteconfirmed:boolean)=>void,
		record_type:"class"|"pupil"
	}
	render(){
		return(
			<div className="DeleteDialog">
				<p>Are you sure you want to remove this {this.props.record_type}?</p>
				<button
					className="button"
					onClick={(e)=>{e.preventDefault(); this.props.onDeleteDialogResponse(true)}}
					>Remove {this.props.record_type}
				</button>
				<button
					className="button"
					onClick={(e)=>{e.preventDefault(); this.props.onDeleteDialogResponse(false)}}
					>Cancel
				</button>
			</div>
		)
	}
}
export class Dialog extends React.Component {
	props!:{
		//onDeleteDialogResponse:(delteconfirmed:boolean)=>void,
		onDialogResponse:(option:number)=>void,
		dialog_text:string,
		buttons_text:string[]
		//record_type:"class"|"pupil"
		//allow inputs of text and an array of button options.
	}
	render(){
		let buttons:JSX.Element[] = [];
		for (let b = 0; b<this.props.buttons_text.length; b++){
			buttons.push(
				<button
					key = {b}
					className="button"
					onClick={(e)=>{e.preventDefault(); this.props.onDialogResponse(b+1)}}
					>{this.props.buttons_text[b]}
				</button>
			);
		}
		return(
			<div className="DeleteDialog">
				<p style={{whiteSpace:"pre-wrap"}}>{this.props.dialog_text}</p>
				{buttons}
			</div>
		)
	}
}
class AssignmentDropdown extends React.Component {
	props!:{
		setAssignmentSelected:(classindex:number,W_ID:number)=>void,
		public_worksheets:Worksheet[],//{worksheet_name:string, W_ID:number}[],
		classindex:number,//what exactly is this?
		selected_assignment:number,
	}
	render(){
		let options = [];
		options.push(
			<option key={-1} disabled selected hidden value={-1}> Select a worksheet </option>
		)
		for ( let o = 0; o < this.props.public_worksheets.length ; o++){
			options.push(
				<option key={o} value={this.props.public_worksheets[o].ID}
				>{this.props.public_worksheets[o].name}</option>
			)
		}
		return(
			<span>
				<label hidden>Set assignment</label>
				<select
					style ={{width:"18em"}}
					name="setassignmentselect"
					value={this.props.selected_assignment}
					onChange={e=>{//e.preventDefault();
						console.log("onChange set assignment select");
						this.props.setAssignmentSelected(this.props.classindex,Number(e.target.value))}}
					>
					{options}
				</select>
			</span>
		)
	}
}
interface AssignmentViewProps extends RouteComponentProps {
	Assignment_ID:number,
	class_ID:number,
	closeAssignmentView:()=>void,
	updateViewBasedOnURL: ()=>void,
	updateQuestionClicked: (questionclicked:number|undefined) => void,
	updatePupilClicked: (pupilclicked:number|undefined) => void,
	admin:boolean,
	globaluser:User|null|undefined,
	byQuestionView: boolean,
	byPupilView:boolean,
	questionclicked:number|undefined,
	pupilclicked:number|undefined;
}
class AssignmentView extends React.Component<AssignmentViewProps> {
	state:{
		// questionclicked:number|undefined, // TODO problem that it's in state and props?
		// pupilclicked:number|undefined,
		classassignmentdata:ClassAssignmentData|undefined,
		worksheet?:Worksheet,
		sorted_indices:number[],
		anonymise:boolean,
		extrastudentdata:boolean,
		refreshtimeout:any,
		sort_type:AssignmentSortType,
		sort_reversed:boolean,
		component_is_mounted:boolean,
		clas:null|Cohort,
		compact:boolean,
	}
	constructor(props: AssignmentViewProps){
		super(props);
		this.state={
			// questionclicked:undefined,
			// pupilclicked:undefined,
			classassignmentdata:undefined,
			sorted_indices:[],
			anonymise:false,
			extrastudentdata:false,
			refreshtimeout:null,
			sort_type:"lastname",
			sort_reversed:false,
			component_is_mounted:true,
			clas:null,
			compact:true,
			//removingpupil:Array(props.classes.length).fill(false),

		}
		this.selectQToView=this.selectQToView.bind(this);
		this.selectPToView=this.selectPToView.bind(this);
		// this.pressBack=this.pressBack.bind(this);
		//this.makeSortOrder=this.makeSortOrder.bind(this);
		this.assignmentname=this.assignmentname.bind(this);
		this.updateTempPasswords=this.updateTempPasswords.bind(this);
		this.toggleAnonymise=this.toggleAnonymise.bind(this);
		this.refreshAssignmentData=this.refreshAssignmentData.bind(this);
		this.onChangeSortType=this.onChangeSortType.bind(this);
		this.changeLocalAttemptScore=this.changeLocalAttemptScore.bind(this);
		this.downloadcsv=this.downloadcsv.bind(this);
		this.toggleExtraStudentData=this.toggleExtraStudentData.bind(this);
	}
	async componentDidMount(){
		this.refreshAssignmentData();
		this.props.updateViewBasedOnURL();
	}
	async componentWillUnmount(){
		clearTimeout(this.state.refreshtimeout);
		this.setState({component_is_mounted:false});
	}
	componentDidUpdate(prevProps: AssignmentViewProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
			console.log('url has changed')
            this.props.updateViewBasedOnURL();
			this.refreshAssignmentData();
        }
    }
	async refreshAssignmentData(){

		console.log("refreshAssignmentData called, about to fetch /viewassignment");
		
		clearTimeout(this.state.refreshtimeout);

		let [worksheet,classassignmentdata,clas] = await fetchClassAssignmentData(this.props.Assignment_ID,this.props.class_ID);
		throwErrIfOpUndefined(worksheet,classassignmentdata,clas);
		//TODO the above should probably fail more gracefully, if for whatever reason we fail to get a response, we should ask again a couple of times and then tell the user that something is broken
		
		if(classassignmentdata!==undefined){
			classassignmentdata = calcScores(classassignmentdata);
			console.log("calcScores completed "+classassignmentdata);
		} else {
			//we shouldn't ever get here due to the error checking above
			console.log("class assignmentdata somehow undefined");
			throw "class assignmentdata somehow undefined"
		}

		console.log("about to make sort order"+classassignmentdata);

		let sort_params = makeSortOrder(classassignmentdata,{state_sort_reversed:this.state.sort_reversed, state_sort_type:this.state.sort_type});
	//	let refreshtimeout = setTimeout(
	//		()=>{
	//				this.refreshAssignmentData()
	//		},
	//		10000 //wait 1000 milliseconds
	//	)
		let setnewtimeout = true;
		if(this.props.class_ID===19||!this.state.component_is_mounted){//we don't want auto refresh for the whole table because it is too intense.
			console.log("clear Timeout, probably because the this.state.component_is_mounted is false");
			clearTimeout(this.state.refreshtimeout);
			//clearTimeout(refreshtimeout);
			setnewtimeout = false;
		}
		this.setState({
			sorted_indices:sort_params.sorted_indices,
			sort_type:sort_params.sort_type,
			sort_reversed:sort_params.sort_reversed,
			worksheet,
			classassignmentdata,
			refreshtimeout:setnewtimeout?setTimeout(
				()=>{
						this.refreshAssignmentData()
				},
				10000 //wait 1000 milliseconds
			):null,
			clas,
		});
	}
	
	// pressBack(){
	// 	if(this.state.questionclicked!==undefined){
	// 		this.setState({questionclicked:undefined})
	// 	} else if (this.state.pupilclicked!==undefined){
	// 		this.setState({pupilclicked:undefined})
	// 	} else {
	// 		this.props.closeAssignmentView();
	// 	}
	// }
	toggleAnonymise(){
		let new_anonymise = !this.state.anonymise;
		let new_sort_type = this.state.sort_type;
		let new_sort_reversed = this.state.sort_reversed;
		if(new_anonymise&&new_sort_type==="lastname"){
			new_sort_type = "final";
			new_sort_reversed = true;
		}
		let sort_params;
		if(this.state.classassignmentdata!==undefined){
			sort_params = makeSortOrder(this.state.classassignmentdata,{new_sort_type,sort_reversed:new_sort_reversed,state_sort_reversed:this.state.sort_reversed, state_sort_type:this.state.sort_type});
			this.setState({
				sorted_indices:sort_params.sorted_indices,	
				anonymise:new_anonymise,
				sort_type:new_sort_type,
				sort_reversed:new_sort_reversed,
			})
		}
	}
	toggleExtraStudentData(){
		let new_extrastudentdata = !this.state.extrastudentdata
		this.setState({
			extrastudentdata: new_extrastudentdata,
		})
	}

	assignmentname(Assignment_ID:number):string{
		if(this.state.clas===null){throw "clas should not be null when running assignmentname()"}
		var assignment = this.state.clas.Assignments.find(clas=>clas.Assignment_ID===Assignment_ID);
		if(assignment){
			return assignment.name
		}else{
			throw "couldn't find assignment";
		}
	}
	onChangeSortType(new_sort_type:AssignmentSortType){
		if(this.state.classassignmentdata===undefined){
			this.refreshAssignmentData();
		} else{
			let new_sort_reversed = this.state.sort_reversed;
			if(new_sort_type===this.state.sort_type){
				new_sort_reversed = !new_sort_reversed;
			} else if(this.state.sort_type==="lastname"){
				new_sort_reversed = true;
			} else if(new_sort_type==="lastname"){
				new_sort_reversed = false;
			}
		//	this.setState({
		//		sort_type:new_sort_type,
		//		sort_reversed:new_sort_reversed,
		//	})
			let sort_params = makeSortOrder(this.state.classassignmentdata,{new_sort_type,sort_reversed:new_sort_reversed,state_sort_reversed:this.state.sort_reversed, state_sort_type:this.state.sort_type});
			this.setState({
				sorted_indices:sort_params.sorted_indices,
				sort_type:sort_params.sort_type,
				sort_reversed:sort_params.sort_reversed,
			})
		}
	}
	selectQToView(Q_index:number){
		if(this.state.classassignmentdata===undefined) throw "classassignment data should be defined"

		if(Q_index<0){
			console.log("Q_index <0 given")
		} else if(Q_index>=this.state.classassignmentdata.questions.length){
			console.log("Q_index is tto big");
		} else this.props.updateQuestionClicked(Q_index);
	}
	selectPToView(PPP:number){
		//P_index is 0 for the first in the alphabet/sorted_indices, but this pupil could be any number in the list of pupils, so we convert it before setting pupilclicked

		if(this.state.classassignmentdata===undefined) throw "classassignment data should be defined"
		if(this.state.classassignmentdata.pupilswsstats===undefined) throw "classassignment.pupilswsstats data should be defined"

		if(PPP<0){
			console.log("P_index <0 given")
		} else if(PPP>=this.state.classassignmentdata.pupilswsstats.length){
			console.log("P_index is too big");
		} else this.props.updatePupilClicked(this.state.sorted_indices[PPP]);
	}
	// closeResponsesView(){
	// 	this.setState({questionclicked:undefined})
	// }
	updateTempPasswords(pupil_index:number,newpassword:string){
		if(this.state.classassignmentdata===undefined) throw "classassignmentdata should be defined"
		let temppasswordstemp = this.state.classassignmentdata.temppasswords;
		if(temppasswordstemp===undefined) throw "temppasswordstemp data should be defined"
		temppasswordstemp[pupil_index]=newpassword;
		this.setState({classassignmentdata:{
			...this.state.classassignmentdata,
			temppasswords:temppasswordstemp,
		}});
	}
	changeLocalAttemptScore(attempt:Attempt, field:number, scorechange:number){
		if(this.state.classassignmentdata===undefined) throw "classassignmentdata undefined";
		let question_index = this.state.classassignmentdata.questions.findIndex(q=>q.Q_ID===attempt.Q_ID);
		let pupil_index = this.state.classassignmentdata.questions[question_index].pupils.findIndex(u=>u.U_ID===attempt.U_ID);
		let attempt_index = this.state.classassignmentdata.questions[question_index].pupils[pupil_index].attempts.findIndex(a=>a.A_ID===attempt.A_ID);
		let oldattempt = this.state.classassignmentdata.questions[question_index].pupils[pupil_index].attempts[attempt_index];
		if(this.props.globaluser===undefined||this.props.globaluser===null) throw "globaluser isn't defined or is null"
		let marker_ID:number = this.props.globaluser.U_ID; //TODO want user info
		let marker_type:MarkerType = "teacher";//TODO want user info
		let newState = update(this.state, 
			{
				classassignmentdata:{
					questions: {
						[question_index]: {
							pupils: {
								[pupil_index]:{
									attempts:{
										[attempt_index]:{
											$set:updateManscore(oldattempt,marker_ID,marker_type,scorechange,field)
										},
									},
								},
							},
						},
					},
				},
			}
		);
		this.setState(newState);
	}
	downloadcsv(){
		console.log("downloadcsv");
		let csvContent = "";//"data:text/csv;charset=utf-8,";
		if(this.state.classassignmentdata===undefined){throw "classassignmentdata not defined"}
		if(this.state.worksheet===undefined) throw "worksheet is undefined"
		let worksheet_name = this.state.worksheet.name;
		if(this.state.clas===null) throw "clas is null"
		let class_name = this.state.clas.name;
		csvContent += classdatatocsv(this.state.classassignmentdata,worksheet_name,class_name);
		console.log(csvContent);

	//	let blob = new Blob(["data:text/csv;charset=utf-8,%EF%BB%BF"+csvContent],{type:'text/csv'});//
	//	let url = window.URL.createObjectURL(blob);
		let url = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
		let a = document.createElement('a');
		a.setAttribute('hidden','');
		a.setAttribute('href',url);
		a.setAttribute('download',"mmm_"+class_name+"_"+worksheet_name+'_download.csv');
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

	//	var link = window.document.createElement("a");
	//	link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
	//	link.setAttribute("download", "upload_data.csv");
	//	link.click();

	//	var encodedUri = encodeURI(csvContent);
	//	window.open(encodedUri);
	}
	render(){
		let assignmentviewtodisplay = <span>Loading... The markbook can take up to 30 seconds to load if all the questions in the worksheet have been updated.</span>;
		let displaying_responses = false;

		//if question or pupil selected
		if(this.props.byQuestionView || this.props.byPupilView){
			//if data is present
			if(this.state.classassignmentdata!==undefined&&this.state.clas!==null){
				displaying_responses = true;
				assignmentviewtodisplay = <QuestionResponsesView
											// closeResponsesView={this.closeResponsesView}
											//class={this.props.class}
											class={this.state.clas}
											Q_index={this.props.questionclicked}
											P_index={this.props.pupilclicked}
											selectQToView={this.selectQToView}
											selectPToView={this.selectPToView}
											classassignmentdata={this.state.classassignmentdata}
											worksheet={this.state.worksheet}
											assignmentname={this.assignmentname(this.props.Assignment_ID)}
											sorted_indices={this.state.sorted_indices}
											admin={this.props.admin}
											anonymise={this.state.anonymise}
											compact={this.state.compact}
											changeLocalAttemptScore={this.changeLocalAttemptScore}
										/>;
			} else {
				throw "class assigment data undefined"
			}

		//pupil or question not selected
		}else{

			//surely we should be checking for classassignment data below
			if(this.state.clas!==null){
				const { match, class_ID, Assignment_ID } = this.props;
				const fullPath = `${match.url}/assignmentview/${Assignment_ID}`; //TODO surely this can be done more elegantly using withRouter
				console.log("this.state.clas is not null, displaying worksheet")
				assignmentviewtodisplay = <AssignmentViewWorksheet
											//closeAssignmentView={this.props.closeAssignmentView}
											//class={this.props.class}
											class={this.state.clas}
											selectQToView={this.selectQToView}
											selectPToView={this.selectPToView}
											classassignmentdata={this.state.classassignmentdata}
											worksheet={this.state.worksheet}
											assignmentname={this.assignmentname(this.props.Assignment_ID)}
											sorted_indices={this.state.sorted_indices}
											updateTempPasswords={this.updateTempPasswords}
											anonymise={this.state.anonymise}
											extrastudentdata={this.state.extrastudentdata}
											onChangeSortType={this.onChangeSortType}
											refreshAssignmentData={this.refreshAssignmentData}
											fullPath={fullPath}
											{...this.props}
											toggleExtraStudentData={this.toggleExtraStudentData}
											toggleAnonymise={this.toggleAnonymise}
										/>;
			}
			else{
				console.log("this.state.clas is null");
			}
		}
		return(
		<div>
      <span>
          <button
          className="button innavigationbar float"
          style={{ display: displaying_responses ? "block" : "none", textTransform:"none"}}
          onClick={(e) => { e.preventDefault(); this.setState({ compact: !this.state.compact }); }}
        >{this.state.compact ? "Detail" : "Compact"}
          </button>
          {/* <button
          className="button innavigationbar float"
          style= {{textTransform:"none"}}
          onClick={(e) => { e.preventDefault(); this.toggleAnonymise(); }}
        >{this.state.anonymise ? 'Show names' : 'Hide names'}
        </button>
        <button
          className="button innavigationbar float"
          style= {{textTransform:"none"}}
          onClick={(e) => {
            e.preventDefault();
            this.toggleExtraStudentData();
          }}
          // startIcon={<ManageAccountsIcon/>}
          >{this.state.extrastudentdata ? 'Hide logins' : 'Show logins'}
        </button> */}
        <button
            className="button innavigationbar float" //⤄"⛶"
            style={{ display: (this.state.classassignmentdata) ? "" : "none",textTransform:"none" }}
            onClick={(e) => { e.preventDefault(); this.downloadcsv(); }}
          >csv
        </button>
        {/*<MuiButton
              className="button innavigationbar float"
              onClick={(e) => { e.preventDefault(); this.pressBack(); }}
          startIcon={<ArrowBack />}
          >
          </MuiButton>*/}
        </span>
		<div className="classheaderbar">
			<h3 className="classtitleinheader">
				{this.state.clas === null ? "Loading class" : this.state.clas.name}
			</h3>
			<h3 className="classcodeinheader">
				{this.state.clas === null ? "" : "Class code: " + this.state.clas.class_code}
			</h3>
		</div>
		<h3 className="assignmentnameinheader">
			{this.state.clas === null ? "" : "" + this.assignmentname(this.props.Assignment_ID)}
		</h3>
        <div className="printable">
        	{assignmentviewtodisplay}
        </div>
    </div>
		)

	//	<div
	//				//hidden={!displaying_responses}
	//				style={{"display":displaying_responses?"inline":"none"}}>
	//
	//			</div>
	}
}
function throwErrIfOpUndefined(worksheet:Worksheet,classassignmentdata:ClassAssignmentData,clas:Cohort){
	if(classassignmentdata===undefined){
		console.log("refreshAssignmentData error: classassignmentdata recieved from fetchClassAssignmentData is undefined");
		throw "refreshAssignmentData error: classassignmentdata recieved from fetchClassAssignmentData is undefined"
	}
	if(clas===undefined){
		console.log("refreshAssignmentData error: clas recieved from fetchClassAssignmentData is undefined");
		throw "refreshAssignmentData error: clas recieved from fetchClassAssignmentData is undefined"
	}
	if(worksheet===undefined){
		console.log("refreshAssignmentData error: worksheet recieved from fetchClassAssignmentData is undefined");
		throw "refreshAssignmentData error: worksheet recieved from fetchClassAssignmentData is undefined"
	}
	if(classassignmentdata===null){
		console.log("refreshAssignmentData error: classassignmentdata recieved from fetchClassAssignmentData is null");
		throw "refreshAssignmentData error: classassignmentdata recieved from fetchClassAssignmentData is null"
	}
	if(clas===null){
		console.log("refreshAssignmentData error: clas recieved from fetchClassAssignmentData is null");
		throw "refreshAssignmentData error: clas recieved from fetchClassAssignmentData is null"
	}
	if(worksheet===null){
		console.log("refreshAssignmentData error: worksheet recieved from fetchClassAssignmentData is null");
		throw "refreshAssignmentData error: worksheet recieved from fetchClassAssignmentData is null"
	}
}
function makeSortOrder(classassignmentdata:ClassAssignmentData,parameters:{new_sort_type?:AssignmentSortType,state_sort_type:AssignmentSortType,state_sort_reversed:boolean, sort_reversed?:boolean}):
({
	sorted_indices:number[],
	sort_type:AssignmentSortType,
	sort_reversed:boolean,
}){
	let new_sort_type = parameters.new_sort_type;
	let sort_reversed = parameters.sort_reversed;
	let state_sort_type = parameters.state_sort_type;
	let state_sort_reversed = parameters.state_sort_reversed;
	//Create and set sort array
	let pupilarraytosort:PupilNames[] = [];
	let pupilswsstats = classassignmentdata.pupilswsstats;
	//pupilarraytosort = pupilarraytosort.concat(classassignmentdata.questions[0].pupils);//TODO main pupil array should come from server and be used here
	//possibly the concat is to copy the array.
	pupilarraytosort = pupilarraytosort.concat(classassignmentdata.pupilsnames);//TODO main pupil array should come from server and be used here
	//	for (let p=0; p<pupilarraytosort.length; p++){
	//		pupilarraytosort[p].index = p;
	//	}
	let indexsortingobjectarray:{
		index:number,
		sort_parameter:number|string|undefined,
		param_is_string:boolean
	}[] = [];
	for (let p=0; p<pupilarraytosort.length; p++){
		indexsortingobjectarray.push({
			index : p,
			param_is_string:false,
			sort_parameter:undefined,
		})
	}
	switch (new_sort_type||state_sort_type){
		case "lastname":
			for (let p=0; p<pupilarraytosort.length; p++){
					indexsortingobjectarray[p].sort_parameter=pupilarraytosort[p].lastname;
					indexsortingobjectarray[p].param_is_string = true;
				};
				break;
				case "initial":
					if(pupilswsstats===undefined) throw "pupilswsstats is undefined";
					for (let p=0; p<pupilarraytosort.length; p++){
						indexsortingobjectarray[p].sort_parameter=pupilswsstats[p].initial_percentage;
					};
					break;
					case "final":
						if(pupilswsstats===undefined) throw "pupilswsstats is undefined";
						for (let p=0; p<pupilarraytosort.length; p++){
							indexsortingobjectarray[p].sort_parameter=pupilswsstats[p].max_percentage;
						};
						break;
						case "adjusted":
							if(pupilswsstats===undefined) throw "pupilswsstats is undefined";
							for (let p=0; p<pupilarraytosort.length; p++){
								indexsortingobjectarray[p].sort_parameter=pupilswsstats[p].adjusted_percentage;
							};
							break;
							case "accuracy":
								if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
								for (let p=0; p<pupilarraytosort.length; p++){
									indexsortingobjectarray[p].sort_parameter=((pupilswsstats[p].adjusted_final_score||0)/(pupilswsstats[p].attempted_out_of||100));
								}
								break;
								case "summary":
									if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
									for (let p=0; p<pupilarraytosort.length; p++){
										indexsortingobjectarray[p].sort_parameter=pupilswsstats[p].wohelp_percentage;
									}
									break;
	}
	indexsortingobjectarray.sort(compare_sort_parameter);

//	switch (this.state.sort_type){
//		case "lastname":
//			for (let p=0; p<pupilarraytosort.length; p++){
//				indexsortingobjectarray[p].sort_parameter=pupilarraytosort[p].lastname;
//			}
//			indexsortingobjectarray.sort(compare_lastname);
//		case "initial":
//			if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
//			pupilswsstats.sort(compare_initial);
//		case "adjusted":
//			if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
//			pupilswsstats.sort(compare_adj);
//		case "final":
//			if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
//			pupilswsstats.sort(compare_final);
//		case "accuracy":
//			if(pupilswsstats===undefined) throw "pupilswsstats is undefined"
//			pupilswsstats.sort(compare_acc);
//	}
	let sorted_indices:number[]=[];
	if(sort_reversed===undefined){
		sort_reversed=state_sort_reversed;
	}
	indexsortingobjectarray = sort_reversed?indexsortingobjectarray.reverse():indexsortingobjectarray;
	for (let p = 0; p<indexsortingobjectarray.length; p++){
		let index = indexsortingobjectarray[p].index;
		if (index===undefined)throw "index is undefined in makeSortOrder";
		sorted_indices[p]=index;
	}
	return{
		sorted_indices,
		sort_type:new_sort_type||state_sort_type,
		sort_reversed:sort_reversed,
	};
}
class QuestionResponsesView extends React.Component {
	props!:{
		class:Cohort,
		Q_index:number|undefined,
		P_index:number|undefined,
		// closeResponsesView:()=>void,
		selectQToView:(Q_index:number)=>void,
		selectPToView:(PPP:number)=>void,
		classassignmentdata:ClassAssignmentData,
		worksheet:Worksheet|undefined,
		assignmentname:string,
		sorted_indices:number[],
		admin:boolean,
		anonymise?:boolean,
		compact:boolean,
		changeLocalAttemptScore:(attempt:Attempt, field:number, scorechange:number)=>void,
		//C_ID:number,//could actually just pass class in
		//handleButtonClick:(e:any)=>void,
	}
	state:{
//		sorted_indices:number[],
		feedbacktoshow:{head:string[],body:FeedbackObject[][]}[],
		//mode?:"Q"|"Pupil"
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
//			sorted_indices:[],
			feedbacktoshow:[],
		}
		this.showFeedback = this.showFeedback.bind(this);
	}
	async componentDidMount(){

	}
	async showFeedback(formvalues:(string|null|undefined)[], Q_ID:number,record_index:number){
		console.log("showFeedback for "+record_index);
		loggy(formvalues,"formvalues");
		let feedbacktoshowtemp = this.state.feedbacktoshow;
		feedbacktoshowtemp = [];
		feedbacktoshowtemp[record_index] = await getFeedback(formvalues, Q_ID);
		this.setState({
			feedbacktoshow:feedbacktoshowtemp,
		})
	}
	render(){
		let pupilname = <span></span>;
		let mode:""|"Q"|"Pupil" = "";
		if(this.props.Q_index!==undefined&&this.props.P_index===undefined){
			mode="Q";
			console.log("mode Q");
		} else if(this.props.P_index!==undefined&&this.props.Q_index===undefined){
			mode="Pupil";
			console.log("mode Pupil");
			pupilname = <p>{this.props.class.Pupils[this.props.P_index].surname}</p>
		} else throw "unclear which view mode we are in"
		let rows:any = [];
		let questionstoshow = this.props.classassignmentdata.questions;
		if ( this.props.Q_index!==undefined){
			questionstoshow = [questionstoshow[this.props.Q_index]];
		}
		questionstoshow.forEach((question,q)=>{
			//let question = this.props.classassignmentdata.questions[this.props.Q_index];
			let questionview =
					<QuestionView
						key={question.Q_ID}
						Q_ID={question.Q_ID}
					/>
			if(mode==="Pupil"){rows.push(
				<div key={q}>
					{""+(q+1)+". "+question.name}
					{questionview}
				</div>)}
			else {
				if(this.props.Q_index!==undefined&&this.props.P_index===undefined){//if we are viewing a question page...
					rows.push(
						<div key={question.Q_ID} className="questionviewholder">
							<p
							//	key={q}
								style={{display:"inline"}}
								>{""+(this.props.Q_index+1)+". "+question.name}
							</p>
							{questionview}
						</div>
					)} //formatting difference
				else {throw "Should be in Q mode."}
			}
		//	I don't use this feature
		//	if(this.props.admin===true&&!(globaluser!=undefined?globaluser.usertype!=="superuser":false)){
		//		rows.push(
		//			<span
		//				key={question.Q_ID}
		//				>
		//				<QuestionManagerPage
		//					singleQmode={true}
		//					Q_IDs={[question.Q_ID]}
		//				/>
		//			</span>
		//		)
		//	}
			let pupilsnames = this.props.classassignmentdata.pupilsnames;
			let pupilstoshow:PupilData[] = [];
		//	let pupilnames:PupilNames[] = [];
			if(this.props.P_index!==undefined){// if just displaying one pupil
				pupilstoshow = [question.pupils[this.props.P_index]];
				pupilsnames = [pupilsnames[this.props.P_index]];
			} else if(this.props.Q_index!==undefined){// if displaying one question
				pupilstoshow = question.pupils;
			}
			rows.push(
				<QuestionAnswersComponent
					pupilstoshow={pupilstoshow}
					pupilsnames={pupilsnames}
					P_index={this.props.P_index}
					sorted_indices={this.props.sorted_indices}
					question={question}
					mode={mode}
					anonymise={this.props.anonymise}
					compact={this.props.compact}
					q={q}
					feedbacktoshow={this.state.feedbacktoshow}
					showFeedback={this.showFeedback}
					changeLocalAttemptScore={this.props.changeLocalAttemptScore}
				/>
			)
		})
		return(
			<span>
				{pupilname}
				<span>
					<QuestionResponsesViewNavigationButtons
						ordered_position={mode==="Q"?this.props.Q_index:this.props.sorted_indices.indexOf(this.props.P_index||0)}//TODO handle this hack
						selectThingToView={mode==="Q"?this.props.selectQToView:this.props.selectPToView}
						mode={mode}
						clearFeedback={()=>this.setState({feedbacktoshow:[]})}
					/>
				</span>
				<div>
					{rows}
				</div>
			</span>
		)
	}
}
export class QuestionView extends React.Component{
	props!:{
		Q_ID:number,
		key:number
	}
	state:{
		open:boolean
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			open:false
		};
		//this.passwordReset = this.passwordReset.bind(this);
	}
	render(){
		console.log("rendering QuestionView");
		return(
			<span key="Q">
				<button
					className="button innavigationbar"
					onClick={(e)=>{
						e.preventDefault();
						this.setState({open:!this.state.open})
					}}
					>{this.state.open?"hide Q":"view Q"}
				</button>
				<span hidden={!this.state.open}>
					<QuestionpagebyID
						Q_ID={this.props.Q_ID}
						action="view"
						isdisplayed={true}
					/>
				</span>
			</span>
		)
	}
}
class About extends React.Component{
	props!: {};
	state:{
		activetab:number,
	}
	constructor(props: Readonly<{}>){
		super(props)
		this.state={
			activetab:0,
		}
		this.openTab=this.openTab.bind(this);
		this.isdisplayed=this.isdisplayed.bind(this);
	}
	openTab(activatedtab: number):any {
		this.setState({activetab:activatedtab})
		//var i;
		//var x = document.getElementsByClassName("city");
		//for (i = 0; i < x.length; i++) {
		//  x[i].style.display = "none";
		//}
		//document.getElementById(cityName).style.display = "block";
	  }
	isdisplayed(element:number){
		if (element===this.state.activetab){
			return'block'
		}else {
			return 'none'
		}
	}
	render(){//w3-bar w3-black
		let tabtitles = [
			"Aims",
			"How",
			"Founder",
			"Contact",
			"Collaborate",
		];
		return (//the cool thing in the onClick below means that params can be passed in directly and don't need to go into the name
			<div className="center">
				<h1>About My Marking Machine</h1>
				<span>
					<button className="tabs" onClick={() => this.openTab(0)}>{tabtitles[0]}</button>

					<button className="tabs" onClick={() => this.openTab(3)}>{tabtitles[3]}</button>

				</span>
				<div key={0} style={{display:this.isdisplayed(0)}}>
					<h2>{tabtitles[0]}</h2>

					<ul>
						<li>To provide tuition-quality instruction in STEM for all pupils regardless of their circumstance.</li>
						<li>To improve outcomes for learners whilst reducing administrative load on teachers.</li>
						<li>To support subject knowledge development for teachers of STEM.</li>
					</ul>
					<br/>
					<h2>How</h2>
					<p className="little">This is achieved using a state of the AI learning assistant which provides live feedback on pupils' answers as demonstrated below:</p>
					{/*
						<ul>
							<li>integrated teaching and questioning,</li>
							<li>live feedback on pupils’ answers,</li>
							<li>integrated videos and home-suitable practical activities.</li>
						</ul>
						<p className="little">
						An example of the dialogue users have with AI is shown in the video. The feedback given is designed to match the feedback I would give if I saw that answer. My aim is precision and accuracy of both understanding and scientific communication.
						</p>
						<p className="little">
						The tool has been constructed using my ten years of experience as a tutor and five as a teacher. This includes time working at both the highest achieving and the 4th most value adding secondary school physics departments in the UK. The stimulus for the project was the desire to provide each pupil with the personalised guidance needed maximum progress. Another aim is to help those without access to specialist teachers in learning concepts accurately starting from the foundation.
						</p>
					*/}
					<div className="video-responsive"><iframe width="560" height="315" src='https://www.youtube.com/embed/pwAXJMtlpng?modestBranding=1&rel=0' //'https://www.youtube.com/embed/MAlQ5l2EP70?start=32&end=113&modestBranding=1&rel=0'
					//frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					//allowfullscreen={true}
					//></iframe></div><a href="https://www.youtube.com/watch?v=MAlQ5l2EP70"  target="_blank">https://www.youtube.com/watch?v=MAlQ5l2EP70</a>
					></iframe></div><a href="https://youtu.be/pwAXJMtlpng"  target="_blank">https://youtu.be/pwAXJMtlpng</a>
					{/*					<br/>
										<h2>Current Project: Free F&M course</h2>
					<p className="little">
					The first course launches this month and will cover ‘Forces’ for beginners, it is most suitable for ages 11-15yr. Limited spaces are available so fill in the form to register your interest. Form: https://bit.ly/2VpCIGD
					</p>
					<p className="little">
					Thank you for supporting the project with your interest.
						</p>*/}

					{/*}
					<p>Using smart marking to give:</p>
					<ul style={{marginLeft:"40px"}}>
						<li>detailed, expert feedback,</li>
						<li>personalised to each user,</li>
						<li>instant and repeat marking,</li>
						<li>straight forward reporting.</li>
					</ul>*/}
				</div>

				<div key={1} style={{display:this.isdisplayed(1)}}>
					<h2>{tabtitles[1]}</h2>
					<p>Can work really be marked instantly?!</p>
					<ul style={{marginLeft:"40px"}}>
						<li>With traditional marking the student first writes the answer and then an expert reads and marks the answer.</li>
						<li>With mymarkingmachine, 10,000’s of different answers are marked in advance by the expert.</li>
						<li>Then when the pupil provides their answer, the system detects which of these 10,000’s of possible answers matches the new answer and provides the feedback that the expert pre-wrote to this kind of answer.</li>
						<li>The student can then edit their answer and the expert’s comment on that new answer is instantaneously provided.</li>
						<li>The feel of it is similar to that of a real-time dialog between the student and the expert.</li>
					</ul>
				</div>

				<div key={2} style={{display:this.isdisplayed(2)}}>
					<h2>{tabtitles[2]}</h2>
					<ul style={{marginLeft:"40px"}} hidden={true}>
						<li>Always driven to improve the world by problem solving.</li>
						<li>Trained as scientist, engineer, web developer and physics teacher.</li>
						<li>Shocked by teacher workload despite having studied a challenging degree, while competitively rowing, running one of the largest societies in the university and sitting on around a dozen committees. </li>
						<li>Resolved to solve the workload problem for self and others, and improve resources at the same time. </li>
						<li>Left teaching in 2019 to develop mymarkingmachine.com</li>
					</ul>
					<p className="smallerfont">“While teaching I found several time consuming tasks that were repetitive and in need of optimisation. Unfortunately the work load meant that it was difficult to make head way, a catch 22 situation. In 2019 I left teaching after four years to work full time on building the tools I wanted to have as a teacher. I learned to code over the summer of 2019 and started developing the website and marking logic in September. </p>
					<p className="smallerfont">I am motivated to support students, practising teachers and those entering teaching. For greatest impact, many features will need to be provided for free. However to sustain this project there will be premium features. </p>
					<p className="smallerfont">Currently I am ‘bootstrapping’, that means working down savings in the hope that I will recieve an income through the site or a grant before the savings run out.”</p>
					<p>Mark Robinson - founder and developer - <a target="_blank" href="https://www.linkedin.com/in/mark-robinson-2317a98a/">LinkedIn</a></p>
				</div>

				<div key={3} style={{display:this.isdisplayed(3)}}>
					<ContactPage/>
				</div>

				<div key={4} style={{display:this.isdisplayed(4)}}>
					<h2>{tabtitles[4]}</h2>
					<p>Looking for:</p>
					<ul style={{marginLeft:"40px"}}>
						<li>Feedback,</li>
						<li>Course leaders to trial worksheets,</li>
						<li>Ed-Tech companies to use technology via API</li>
						<li>Advisors in business, pedagogy and ethics,</li>
						<li>Second developer,</li>
						<li>Quality question banks,</li>
						<li>Funding,</li>
					</ul>
				</div>

			</div>
		);
	}

}
class ContactPage extends React.Component {
	render(){
		return(
			<div>
				<h2>Contact</h2>
				<p className="smallerfont">Please get in touch</p>
				<p className="smallerfont">Email: <a className="smallerfont" href="mailto:mark.p.r.robinson@googlemail.com">mark.p.r.robinson@googlemail.com</a> </p>
				<p className="smallerfont">Twitter: @mark_robo.</p>
			</div>
		)
	}
}
export class UserPage extends React.Component{
	props!:{
		user:User|undefined
	}
	render(){
		if(this.props.user===undefined){
			throw "tried to open UserPage with undefined user"
		}
		return(
			<span>
				<JoinClassPage/>
				<ChangePasswordPage
					user={this.props.user}/>
				<ChangeUsernamePage/>
			</span>
		)
	}
}
class JoinClassPage extends React.Component{
	props!:{
	}
	state:{
		class_code:string,
		message:string,
		namesofclassesimin:string[],
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			class_code:'',
			message:'',
			namesofclassesimin:["Loading class names..."]
			};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.updateclassesimin = this.updateclassesimin.bind(this);
	}
	async componentDidMount(){
		this.updateclassesimin();
	}
	async updateclassesimin(){
		let namesofclassesimin:string[] = await fetchClassesImin();
		this.setState({namesofclassesimin:namesofclassesimin})
	}
	onChange(e:React.ChangeEvent<HTMLInputElement>){
		let fieldname = e.target.name;
		let fieldvalue = e.target.value;
		this.setState({[fieldname]:fieldvalue});
	}
	onSubmit(e:React.FormEvent<HTMLFormElement>){
		e.preventDefault();
		console.log("onSubmit called");
		var url = '/joinclass'; //TODO put this in a separate function.
		var data = {class_code:this.state.class_code};
		fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
		.then(res => res.json())
		.then(result => {
		//	console.log("response: "+JSON.stringify(result));
			this.setState({message:result.message});
			this.updateclassesimin();
		})
		.catch(error => console.error('Error:', error));

	}
	render(){
		let classesyouareinstring:string = '';
		if(this.state.namesofclassesimin.length >0){
			classesyouareinstring = "Classes you are in: "+stringarraytolist(this.state.namesofclassesimin)
		}
		return(
			<div className = "center" style={{textAlign: "center"}}>
				<h2>Join Class</h2>
				<form
					onSubmit={this.onSubmit}
					autoComplete="off"
				>
					<div>
						<label hidden>Class code</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.class_code}
							type="text"
							name="class_code"
							placeholder={"Enter class code"}
							spellCheck={false}
						/>
					</div>
					<br/>
					<div>
						<input
							className="button"
							type="submit"
							value="Join class"
							/>
						<span hidden={this.state.message.length===0}> {this.state.message} </span>
					</div>
				</form>
				<p>{classesyouareinstring}</p>
			</div>
		)
	}
}
class ChangePasswordPage extends React.Component{
	props!:{
		user:User
	}
	state:{
		timeout:any,
		formvalid:boolean,
		formerrormessage:string,
		values:{
			currentpassword:string,
			newpassword1:string,
			newpassword2:string,
		},
		formErrors:{
			currentpassword:string,
			newpassword1:string,
			newpassword2:string,
		},
		valid:{
			currentpassword:boolean,
			newpassword1:boolean,
			newpassword2:boolean,
		}
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			timeout:null,
			formvalid:false,
			formerrormessage:'',
			values:{
				currentpassword:'',
				newpassword1:'',
				newpassword2:'',
			},
			formErrors:{
				currentpassword:'',
				newpassword1:'',
				newpassword2:'',
			},
			valid:{
				currentpassword:false,
				newpassword1:false,
				newpassword2:false,
			}
		}
		this.onChangePasswordAttempt = this.onChangePasswordAttempt.bind(this);
		this.validateField = this.validateField.bind(this);
		this.validateAllFields = this.validateAllFields.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	onChange(e:React.ChangeEvent<HTMLInputElement>){
		let fieldname = e.target.name;
		let fieldvalue = e.target.value;
		let fieldvalues:any = this.state.values;
		fieldvalues[fieldname]=fieldvalue;
		this.setState(
			//{[fieldname]:fieldvalue},
			{values:fieldvalues},
			() => { this.timeouttovalidateField(fieldname,fieldvalue)}
		);
	}
	timeouttovalidateField(fieldname:string,fieldvalue:string){
		let formErrors:any = this.state.formErrors;
		clearTimeout(this.state.timeout);
			// Make a new timeout set to go off in 800ms
			this.setState({
				timeout:setTimeout(
					()=>{	//console.log(''+JSON.stringify(,null,4));
					this.validateField(fieldname,fieldvalue)
				}, formErrors[fieldname].length===0? 1000:100 //wait 1000 milliseconds if they are currently valid, but only 200 if they are currently invalid
				)
			});
	}
	async validateField(fieldname:string,fieldvalue:string){
		console.log("validateField called with fieldname: "+fieldname+" and fieldvalue: "+fieldvalue);
		let fieldValidationErrors = this.state.formErrors;
		let valid = this.state.valid;
		//let emailValid = this.state.valid.emailAddress;//TODO [fieldname]
		//let usernameValid = this.state.valid.username;
		let isnowvalid=false;
		switch(fieldname) {
			case 'currentpassword':
				if(!(fieldvalue.length>=1)){
					fieldValidationErrors[fieldname] = 'Current password is required';
					valid[fieldname] = false;
				} else {
					fieldValidationErrors[fieldname] = '';
					valid[fieldname] = true;
					isnowvalid=true;
				}
				break
			case 'newpassword1':
				if(!(fieldvalue.length>=8)){//TODO - some password checking to go here
					console.log("password1 is too short");
					fieldValidationErrors.newpassword1 = 'Password is too short';
					valid.newpassword1 = false;
				}
				else if(this.props.user.usertype==="pupil"&&(checkPassStrength(fieldvalue)==="too weak")){
					console.log("Password is good enough for pupil");
					fieldValidationErrors.newpassword1 = 'Password is '+ "weak";//+', make it longer or add a variety of numbers, letters and punctuation';
					valid.newpassword1 = true;
					isnowvalid=true;
				}
				else if(!(checkPassStrength(fieldvalue)==="strong"||checkPassStrength(fieldvalue)==="good"||checkPassStrength(fieldvalue)==="ok")){
					console.log("Password is too weak");
					fieldValidationErrors.newpassword1 = 'Password is '+ checkPassStrength(fieldvalue);//+', make it longer or add a variety of numbers, letters and punctuation';
					valid.newpassword1 = false;
				}
				else{
					fieldValidationErrors.newpassword1 = checkPassStrength(fieldvalue);
					valid.newpassword1 = true;
					isnowvalid=true;
				}
				//check to see if passwords match
				if (fieldvalue!==this.state.values.newpassword2){
					console.log("Passwords do not match")		;
					fieldValidationErrors.newpassword2 = 'Passwords do not match';
					valid.newpassword2=false;
				}
				else{
					console.log("Passwords match");
					fieldValidationErrors.newpassword2 = '';
					valid.newpassword2=true;
					isnowvalid=true;
				}
				break;
			case 'newpassword2':
				//check to see if passwords match
				if (fieldvalue!==this.state.values.newpassword1){
					console.log("Passwords do not match")		;
					fieldValidationErrors.newpassword2 = 'Passwords do not match';
					valid.newpassword2=false;
				}
				else{
					console.log("Passwords match");
					fieldValidationErrors.newpassword2 = '';
					valid.newpassword2=true;
					isnowvalid=true;
				}
				break;
			default:
				break;
		}
		this.setState({formErrors: fieldValidationErrors,
						valid: valid,
					  }//, this.validateForm//TODO validate form (used to grey out register button)
					  );
		if(isnowvalid){//if the field has been found to be valid after validation (this could only happen onChange, since the validate form doesn't try to validate already valid fields)
			console.log("something has just been made to be valid, so we will call validateForm if there is currently an error message");
			//revalidate form to try to get rid of the error message at the bottom.
			if(this.state.formerrormessage.length>0){this.validateForm()}else{console.log("there wasn't an error message, nvm")}
		}
	}
	validateAllFields(){
		console.log("validateAllFields called");
		//validate each field thought to be invalid and thus present the error messages.
		for (let fieldname in this.state.values){
			let values:any = this.state.values;
			let fieldvalue = values[fieldname];
			let valid:any = this.state.valid;
			if(!valid[fieldname]){//don't validate those that are true else, there will be a circular reference.
				this.validateField(fieldname,fieldvalue);
			}
		}
	}
	validateForm(){
		console.log("validateForm called");
		//assume valid
		let formvalid = true
		//set valid=false if any field is valid=false
		for (let fieldname in this.state.valid){
			let valid:any = this.state.valid;
			console.log("fieldname: "+fieldname+" is "+valid[fieldname]?"valid":"invalid");
			if(!valid[fieldname]){
				formvalid =  false;
			}
		}
		console.log("formvalid is: "+formvalid);
		let formerrormessage = formvalid?'':"Form not sent, respond to messages above";
		this.setState({formvalid,formerrormessage});
		return formvalid;
	}
	onChangePasswordAttempt(e: React.FormEvent<HTMLFormElement>){
		e.preventDefault();
		console.log('onChangePasswordAttempt called');
		this.validateAllFields();
		if(this.validateForm()){
			//send validated form to server
			console.log("form determined to be valid, sending to server");
			var url = '/changepassword';//attempt';
			var data = {formvalues:this.state.values};
			fetch(url, {
				method: 'POST', // or 'PUT'
				body: JSON.stringify(data), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then(res => res.json())
			.then(result =>
				{
					if(result.success===true){
						//this.props.onSuccessfulLogin(); //I think that the server redirects to '/' i.e. the homepage
						console.log("changepassword response received");
						if(result.currentpasswordcorrect===true){
							this.setState({formerrormessage:"Password has been changed"});
						} else{
							this.setState({formerrormessage:"Incorrect current password"});
						}
					}
					else {
						console.log("changepassword unsuccessful");
						this.setState({formerrormessage:"The server has had trouble processing this request"});
					}
				}
			)
			//.then(response => console.log('Success:', JSON.stringify(response)))
			.catch(error => console.error('Error:', error));
		}else{
			//form is invalid so don't send form to server
		}
	}


	render(){
		return(
			<div className = "center" style={{textAlign: "center"}}>
				<h2>Change Password</h2>
				<form
					onSubmit={this.onChangePasswordAttempt}
					autoComplete="on"
				>
					<div>
						<label hidden>Current Password</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.values.currentpassword}
							type="password"
							name="currentpassword"
							autoComplete="current-password"
							placeholder={"Current password"}
							spellCheck={false}
						/>
						<span hidden={this.state.formErrors.currentpassword.length===0}> {this.state.formErrors.currentpassword} </span>
					</div>
					<br/>
					<div>
						<label hidden>Type Password</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.values.newpassword1}
							type="password"
							name="newpassword1"
							autoComplete="new-password"
							placeholder={"Enter new password"}
							spellCheck={false}
						/>
						<span hidden={this.state.formErrors.newpassword1.length===0}> {this.state.formErrors.newpassword1} </span>
					</div>
					<br/>
					<div>
						<label hidden>Retype password</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.values.newpassword2}
							type="password"
							name="newpassword2"
							autoComplete="new-password"
							placeholder={"Re-type new password"}
							spellCheck={false}
						/>
						<span hidden={this.state.formErrors.newpassword2.length===0}> {this.state.formErrors.newpassword2} </span>
					</div>
					<br/>
					<div>
						<input
							className="button"
							type="submit"
							value="Change password"
							/>
						<span hidden={this.state.formerrormessage.length===0}> {this.state.formerrormessage} </span>
					</div>
				</form>
			</div>
		)
	}
}

class ChangeUsernamePage extends React.Component{
	props!:{

	}
	state:{
		timeout:any,
		formvalid:boolean,
		formerrormessage:string,
		values:{
			currentpassword:string,
			username:string,
		},
		formErrors:{
			currentpassword:string,
			username:string,
		},
		valid:{
			currentpassword:boolean,
			username:boolean,
		}
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			timeout:null,
			formvalid:false,
			formerrormessage:'',
			values:{
				currentpassword:'',
				username:'',
			},
			formErrors:{
				currentpassword:'',
				username:'',
			},
			valid:{
				currentpassword:false,
				username:false,
			}
		}
		this.onChangeUsernameAttempt = this.onChangeUsernameAttempt.bind(this);
		this.validateField = this.validateField.bind(this);
		this.validateAllFields = this.validateAllFields.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	onChange(e:React.ChangeEvent<HTMLInputElement>){
		let fieldname = e.target.name;
		let fieldvalue = e.target.value;
		let fieldvalues:any = this.state.values;
		fieldvalues[fieldname]=fieldvalue;
		this.setState(
			//{[fieldname]:fieldvalue},
			{values:fieldvalues},
			() => { this.timeouttovalidateField(fieldname,fieldvalue)}
		);
	}
	timeouttovalidateField(fieldname:string,fieldvalue:string){
		let formErrors:any = this.state.formErrors;
		clearTimeout(this.state.timeout);
			// Make a new timeout set to go off in 800ms
			this.setState({
				timeout:setTimeout(
					()=>{	//console.log(''+JSON.stringify(,null,4));
					this.validateField(fieldname,fieldvalue)
				}, formErrors[fieldname].length===0? 1000:100 //wait 1000 milliseconds if they are currently valid, but only 200 if they are currently invalid
				)
			});
	}
	async validateField(fieldname:string,fieldvalue:string){
		console.log("validateField called with fieldname: "+fieldname+" and fieldvalue: "+fieldvalue);
		let fieldValidationErrors = this.state.formErrors;
		let valid = this.state.valid;
		//let emailValid = this.state.valid.emailAddress;//TODO [fieldname]
		//let usernameValid = this.state.valid.username;
		let isnowvalid=false;
		switch(fieldname) {
			case 'currentpassword':
				if(!(fieldvalue.length>=1)){
					fieldValidationErrors[fieldname] = 'Current password is required';
					valid[fieldname] = false;
				} else {
					fieldValidationErrors[fieldname] = '';
					valid[fieldname] = true;
					isnowvalid=true;
				}
				break
			case 'username':
				if(!(fieldvalue.replace(/\s/g, '').length>=5)){
					fieldValidationErrors[fieldname] = 'Username must be 5 or more characters long excluding spaces';
					valid[fieldname] = false;
				} else {
					fieldValidationErrors[fieldname] = '';
					valid[fieldname] = true;
					isnowvalid=true;
				}
				break
			default:
				break;
		}
		this.setState({formErrors: fieldValidationErrors,
						valid: valid,
					  }//, this.validateForm//TODO validate form (used to grey out register button)
					  );
		if(isnowvalid){//if the field has been found to be valid after validation (this could only happen onChange, since the validate form doesn't try to validate already valid fields)
			console.log("something has just been made to be valid, so we will call validateForm if there is currently an error message");
			//revalidate form to try to get rid of the error message at the bottom.
			if(this.state.formerrormessage.length>0){this.validateForm()}else{console.log("there wasn't an error message, nvm")}
		}
	}
	validateAllFields(){
		console.log("validateAllFields called");
		//validate each field thought to be invalid and thus present the error messages.
		for (let fieldname in this.state.values){
			let values:any = this.state.values;
			let fieldvalue = values[fieldname];
			let valid:any = this.state.valid;
			if(!valid[fieldname]){//don't validate those that are true else, there will be a circular reference.
				//console.log(fieldname+" is not valid because "+this.state., so calling validatefield on it")
				this.validateField(fieldname,fieldvalue);
			}
		}
	}
	validateForm(){
		console.log("validateForm called");
		//assume valid
		let formvalid = true
		//set valid=false if any field is valid=false
		for (let fieldname in this.state.valid){
			let valid:any = this.state.valid;
			console.log("fieldname: "+fieldname+" is "+valid[fieldname]?"valid":"invalid");
			if(!valid[fieldname]){
				formvalid =  false;
			}
		}
		console.log("formvalid is: "+formvalid);
		let formerrormessage = formvalid?'':"Form not sent, respond to messages above";
		this.setState({formvalid,formerrormessage});
		return formvalid;
	}
	onChangeUsernameAttempt(e: React.FormEvent<HTMLFormElement>){
		e.preventDefault();
		console.log('onChangeUsernameAttempt called');
		this.validateAllFields();
		if(this.validateForm()){
			//send validated form to server
			console.log("form determined to be valid, sending to server");
			var url = '/changeusername';//attempt';
			var data = {formvalues:this.state.values};
			fetch(url, {
				method: 'POST', // or 'PUT'
				body: JSON.stringify(data), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then(res => res.json())
			.then(result =>
				{
					if(result.success===true){
						//this.props.onSuccessfulLogin(); //I think that the server redirects to '/' i.e. the homepage
						console.log("changeusername response received");
						if(result.currentpasswordcorrect===true){
							this.setState({formerrormessage:"Changeusername has been changed"});
						} else{
							this.setState({formerrormessage:"Incorrect current password"});
						}
					}
					else {
						console.log("changeusername unsuccessful");
						this.setState({formerrormessage:"The server has had trouble processing this request"});
					}
				}
			)
			//.then(response => console.log('Success:', JSON.stringify(response)))
			.catch(error => console.error('Error:', error));
		}else{
			//form is invalid so don't send form to server
		}
	}


	render(){
		return(
			<div className = "center" style={{textAlign: "center"}}>
				<h2>Change Username</h2>
				<form
					onSubmit={this.onChangeUsernameAttempt}
					autoComplete="on"
				>
					<div>
						<label hidden>Current Password</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.values.currentpassword}
							type="password"
							name="currentpassword"
							autoComplete="current-password"
							placeholder={"Current password"}
							spellCheck={false}
						/>
						<span hidden={this.state.formErrors.currentpassword.length===0}> {this.state.formErrors.currentpassword} </span>
					</div>
					<br/>
					<div>
						<label hidden>Type Username</label>
						<input //value={this.state.password}
							onChange={this.onChange}
							value={this.state.values.username}
							type="text"
							name="username"
							autoComplete="off"
							placeholder={"Enter new username"}
							spellCheck={false}
						/>
						<span hidden={this.state.formErrors.username.length===0}> {this.state.formErrors.username} </span>
					</div>
					<br/>
					<div>
						<input
							className="button"
							type="submit"
							value="Change username"
							/>
						<span hidden={this.state.formerrormessage.length===0}> {this.state.formerrormessage} </span>
					</div>
				</form>
			</div>
		)
	}
}
