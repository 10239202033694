//const myapppath = "./my-app/src/";//"../../my-app/src/";
import React, { ChangeEvent, FormEvent, MouseEvent } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
//import "./my-app/src/App.css";
import { optionalCallExpression } from '@babel/types';
import { any, array } from 'prop-types';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import classes from '*.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
//import ImageUploader from 'react-images-upload';
//import {EmptyConcept, loggy} from './Common';//constants
//import {Concept,ImageBespoke,InputText,InputRadio,QuestionInfo,Question} from './Common';//interfaces
//import {create_default_radio,create_default_input_text, create_default_image, create_default_option,create_empty_formvalues,modifyArray} from './Common';//functions
import {modifyArray, QuestionAnswersComponent, QuestionAnswersComponentWrapper} from '../../Common';//functions
import {Questionpage,QuestionpagebyID} from '../../Common';//classes
import {create_empty_FeedbackObject, FeedbackObject,  InputSelfMark, getfieldtypes, compare_priority, 
    Concept, create_default_image, create_default_input_text, create_default_option, create_default_radio, 
    create_default_self_mark, create_empty_formvalues, FeedbackType, FieldDesc, FieldType, FieldTypes, 
    field_type_desc_names, FoundNotFound, InputRadio, QuestionListInfo, field_type_desc_names_public, ClassAssignmentData, Attempt, MarkerType, User, create_default_input_hybrid} from '../../my_interfaces';
import {create_default_checkbox_answer, create_default_checkbox_option_answer, create_default_field_elements_answers, 
    create_default_filterAll, create_default_hybrid_answer, create_default_radio_answer, create_default_radio_option_answer, create_default_selfmark_answer, 
    create_default_text_long_answer, create_default_text_long_answer_marking_point, FetchFilterResponse, FieldElementsAnswers, 
    FilterAll, FilterInfo, FilterPosition, getLongTextAnalysis, InputCheckboxAnswer, InputRadioAnswer, InputTextLongAnswer, KTFilter, LongAnswerMarkingPoint, LongTextAnalysis, NANDType, ORobj, QuestionAnswers, 
    QuestionAnswersNoInfo, QuestionEntryPageProps, TokenisedNANDType } from './marking_interfaces';
import {loggy, copy, calcScores, getFeedback, updateManscore} from '../../shared_functions';
import {handlePunctuation, extractBraceContents, toggleAllKeyTermsFilters} from './secret_shared_functions';
import update from 'immutability-helper'; //https://github.com/kolodny/immutability-helper

//var globaluseradmin:User|null|undefined = null;//TODO this is dreadful!! there should be no gloabl variables in a react website.

function get_checkbox_score(checkbox:InputCheckboxAnswer|InputRadioAnswer,score_change:number):number{
    let score = 0;
    for(let c=0;c<checkbox.options.length;c++){
        score+=checkbox.options[c].iscorrect?1:0;
    }
    score+=score_change;
    score=Math.max(score,1);
//    console.log(score);
    return score;
}
async function pullFilter(ID:number): Promise<FilterAll>{
//    console.log("pullFilter from thesaurus ID: "+ID);
    var url = '/pullfilterbyID';
    var data = {ID};
    let response = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}}).then(res => res.json())
 //   .then(response => {
   //     console.log('Success:', JSON.stringify(response));
        return response;
 //   })
 //   .catch(error => console.error('Error:', error));//TODO how am I suppose to handle this
}
async function fetchFilter(filter:FilterAll|undefined,name:string):Promise<{ID:number,matches_thesaurus:boolean}>{
    //called when user clicks find //(in future when user asks for a structural match)
    name = handlePunctuation(name);
//    console.log("fetchFilter from thesaurus: "+name+" filter input may be undefined");
    var url = '/fetchFilter';
    var data = {
        name,
        filter
    };
    let response:FetchFilterResponse = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}}).then(res => res.json())
//    .then(response => {
//        console.log('Success:', JSON.stringify(response));
//    loggy(response,"response");
    return {ID:response.ID,matches_thesaurus:response.matches_thesaurus};
//    })
//    .catch(error => console.error('Error:', error));
}
function handleMixedContents(filter:FilterAll):FilterAll{
    //check to see if mixed e.g. I have just built a 'this {$0} is {mixed}'
    //If mixed, then store structure and curley_bracket_contents_array
    //In generate key terms this is used, I am not super clear how. 
//    console.log("handleMixedContents");
    if(filter.name.indexOf("{")>-1){
        //console.log("string contains braces");
        //convert to structured form
        let output = extractBraceContents(filter.name,"{","}",true,false); //string is also edited
        //loggy(output,"output");
        let curley_bracket_contents_array = output.bracket_contents_array;
        let contains_dollars = false;
        let contains_text = false;
        for(let c = 0 ; c<curley_bracket_contents_array.length; c++){
            if(curley_bracket_contents_array[c].slice(0,2)==="{$"){
                contains_dollars=true;
            } else {
                contains_text=true;
            }   
        }
        if(contains_text&&contains_dollars){
            filter.structure = handlePunctuation(output.string);
            filter.curley_bracket_contents_array = curley_bracket_contents_array;
        }
    }else{
        //no structure found
    }
    return filter;
}
async function generateKeyTermsMP(markingpoint:LongAnswerMarkingPoint):Promise<LongAnswerMarkingPoint>{
//    console.log("generateKeyTermsMP");
    //this removes any auto-filters so I am sending less information to the backend.
    for (let f=0; f<markingpoint.filters.length; f++) {
        if(markingpoint.filters[f].auto_generated===true){
            markingpoint.filters.splice(f,1);
            f--;
        //    continue;//need this in case it was the last one. What? The last one left. TODO possible bug?
        }
    }
    var url = '/generateKeyTerms';
    var data = {
        markingpoint
    };
    let response:LongAnswerMarkingPoint = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}}).then(res => res.json())
//    console.log("response: "+response);
    return response;
}
function toggleAllKeyTerms(mode:string,condition:string|undefined,input_longs:InputTextLongAnswer[],field_desc:FieldDesc[]):InputTextLongAnswer[]{//input_longs could also be numerical two_part
    for(let f = 0 ; f < field_desc.length ; f++){
        if (field_desc[f].type==="input_text_long"||field_desc[f].type==="input_numerical_twopart"||field_desc[f].type==="input_hybrid"){
        //    let fieldtype:FieldType = (field_desc[f].type==="input_text_long")?"input_text_long":"input_numerical_twopart";
            let instance = field_desc[f].instance;
            for (let mp = 0 ; mp < input_longs[instance].markingpoints.length ; mp ++){
                //this.show_hide_mp(f,mp,mode);
                let filters = input_longs[instance].markingpoints[mp].filters;
                filters = toggleAllKeyTermsFilters(filters,mode,condition);
            }
        }
    }
    return input_longs
}
interface QuestionEntryPageState {
    concepts:Concept[],
    name:string,
    field_elements:FieldElementsAnswers,
    floatingfilterinfo:FilterInfo|null,
    showingHelp:boolean,
    hintOnly:boolean,
    long_text_analysis:(LongTextAnalysis|undefined|null)[]|undefined //its an array because there is one for eahc field 
    showingviewofquestion:boolean,
    showingviewofanswers:boolean,
}
function getfilter (field_elements:FieldElementsAnswers,fieldindex:number,filterindex:number,mp:number):FilterAll{
    let instance = field_elements.field_desc[fieldindex].instance;
//    let type:FieldType = field_elements.field_desc[fieldindex].type;
//    let field_type:FieldTypes = getfieldtypes(type);
//    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "onThesaurusAction called on wrong kind of type";
    return JSON.parse(JSON.stringify(field_elements.answers.input_text_longs[instance].markingpoints[mp].filters[filterindex])) as FilterAll;
}
export class QuestionEntryPage extends React.Component<QuestionEntryPageProps> {
    static defaultProps = {
        action:"createnew",
        name:'',
        field_elements:create_default_field_elements_answers(),
        hintOnly:false,
        Q_ID:undefined,
        key:-1,//when creating a new question, no key (or any props are provided)
    }
    state:QuestionEntryPageState;
    constructor(props: QuestionEntryPage["props"]) {
        super(props);
    //	console.log("constructing state for question creator in action: "+props.action+" name: "+props.name);
        this.state={
            concepts:[],//refers to all concepts (not specifically those in the question) empty and then fetched?hopefully
            name:props.name, //yes this is horrible, it is here because when we edit a question, we input the initial values through props, but change them through state, a better way may be to input the Q_ID through props and then on component did mount set the state based on a question fetch. TODO
            field_elements:	props.field_elements,
            floatingfilterinfo:null,
            showingHelp:false,
            hintOnly:props.hintOnly,
            long_text_analysis:undefined,
            showingviewofquestion:false,
            showingviewofanswers:false,
        //    filters_found:undefined,
        //    tokenised_answer:[],
        //    processed_text:'',
        };
        this.onSubmitQ = this.onSubmitQ.bind(this);
        this.add_field = this.add_field.bind(this);
        this.addoption = this.addoption.bind(this);
        this.modifyFilter = this.modifyFilter.bind(this);
        this.onChangeinfo_text = this.onChangeinfo_text.bind(this);
        this.onChangeFieldMax = this.onChangeFieldMax.bind(this);
        this.fieldControl = this.fieldControl.bind(this);
        this.modifyInstances = this.modifyInstances.bind(this);
        this.modifyDescInstanceRefs = this.modifyDescInstanceRefs.bind(this);
        //this.onChangedinput_text_word = this.onChangeinput_text_word.bind(this); don't think this is needed since one doesn't change any text when creating this
        this.onChangeoption_text= this.onChangeoption_text.bind(this);
        this.onChangeoption_concept= this.onChangeoption_concept.bind(this);
        this.onChangeoption_feedback= this.onChangeoption_feedback.bind(this);
        this.onChangeoption_iscorrect= this.onChangeoption_iscorrect.bind(this);
        this.onChangetitle= this.onChangetitle.bind(this);
        this.onChangeinfo_image = this.onChangeinfo_image.bind(this);
        this.onChangephrase = this.onChangephrase.bind(this);
        this.onChangekeyterm = this.onChangekeyterm.bind(this);
        this.addTerm = this.addTerm.bind(this);
        this.onResetQuestion = this.onResetQuestion.bind(this);
        this.onChangeMPMax = this.onChangeMPMax.bind(this);
        this.modifyMarkingPoint = this.modifyMarkingPoint.bind(this);
        this.generateKeyTerms = this.generateKeyTerms.bind(this);
        this.show_hide_mp = this.show_hide_mp.bind(this);
        this.toggleAllKeyTerms = this.toggleAllKeyTerms.bind(this);
        this.toggleChildren = this.toggleChildren.bind(this);
        this.toggleSymbols = this.toggleSymbols.bind(this);
        this.toggleMultiSelect = this.toggleMultiSelect.bind(this);
        this.updateFeedback = this.updateFeedback.bind(this);
        this.updateFeedbackCheckbox = this.updateFeedbackCheckbox.bind(this);
        this.onSetFeedbackType = this.onSetFeedbackType.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.modifyAND = this.modifyAND.bind(this);
        this.modifyFilter = this.modifyFilter.bind(this);
        this.markFilterAsManual = this.markFilterAsManual.bind(this);
        this.onThesaurusAction = this.onThesaurusAction.bind(this);
        this.getStructuralName = this.getStructuralName.bind(this);
        this.toggleIsFloaty = this.toggleIsFloaty.bind(this);
        this.toggleDisplayFilter = this.toggleDisplayFilter.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.togglehintOnly = this.togglehintOnly.bind(this);
        this.onChangeHintText = this.onChangeHintText.bind(this);
        this.setSecretSauce = this.setSecretSauce.bind(this);
        this.toggleviewofquestion = this.toggleviewofquestion.bind(this);
        this.toggleviewofanswers = this.toggleviewofanswers.bind(this);
        
    //	console.log("QuestionEntryPage has props.Q_ID: "+props.Q_ID+" no state Q_ID");
    }
    //Not sure how the following didn't bug out before. It has now been replaced with keys in the props. 
    /*componentDidUpdate(prevProps: this["props"], prevState: this["state"]){//TODO* Add a comment here, what is this for?
        if(prevProps!==this.props){
            this.setState({
                name:this.props.name,
                field_elements:	this.props.field_elements,
            })
        }
    }*/
    markFilterAsManual(index:{fieldindex:number,filterindex:number,mp:number}){//I want to put into this function the making manual of a specified filter when a change happens to the filter. 
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let types = getfieldtypes(type) as "input_text_longs"|"input_numerical_twoparts"|"input_hybrids";
        //let types:FieldTypes = type==="input_text_long"?"input_text_longs":"input_numerical_twoparts";
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    [types]:{//input_text_longs:{
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{
                                            auto_generated:{$set:false},
                                        //    ID:{$set:undefined},
                                        //    matches_thesaurus:{$set:undefined}                                
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
    }
    onResetQuestion(){
    //    console.log("resetQuestion called");
        if(this.props.Q_ID===undefined){
            this.setState({
                name:'',
                field_elements:create_default_field_elements_answers(),
            })
        } else {
            throw "reseting question with existing question is not good"
        }
    }
    add_field(e: React.ChangeEvent<HTMLSelectElement>){//https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
        let type:FieldType = e.target.value as FieldType;//TODO: add FiledType to this. 
        if(!field_type_desc_names.includes(type)) throw "add_field type should be of type FieldType";
    //    console.log("trying to add element of type: "+type);
        let isinput = false;
        let indexofnew = undefined;
        let tempfe = this.state.field_elements;
        //let tempans = this.state.field_elements.answers;
        if(type === "info_text"){
            indexofnew = this.state.field_elements.info_texts.length;
            //let tempinfo_texts = this.state.field_elements.info_texts;//must copy in order to push
            tempfe.info_texts.push('');
            //this.setState({field_elements:{...this.state.field_elements, info_texts:tempinfo_texts}});
            //this.setState({
            //	field_elements:{...this.state.field_elements, 
            //	info_texts:[...this.state.field_elements.info_texts,'']}
            //});
        }
        else if(type === "radio"){ 
            indexofnew = this.state.field_elements.input_radios.length;//become field_desc.instance, it is the index of the field in its typed array e.g. would be 1 if there are 2 of that type//will be saved at the end
            isinput = true;
            //let tempinput_radios = this.state.field_elements.input_radios;//must copy in order to push TODO***, no, look above
            tempfe.input_radios.push(create_default_radio());
            tempfe.answers.input_radios.push(create_default_radio_answer());
            
            //this.setState({field_elements:{...this.state.field_elements, input_radios:tempinput_radios}});
        } 
        else if(type === "checkbox"){
            indexofnew = this.state.field_elements.input_checkboxes.length;
            isinput = true;
            //let tempinput_checkboxes = this.state.field_elements.input_checkboxes;//must copy in order to push TODO***, no, look above
            tempfe.input_checkboxes.push(create_default_radio());//TODO change name to default MC
            tempfe.answers.input_checkboxes.push(create_default_checkbox_answer());//TODO change name to default MC
        //	this.setState({
        //		field_elements:{
        //			...this.state.field_elements, 
        //			input_checkboxes:tempinput_checkboxes
        //		}
        //	});
        }
    /*    else if(type == "input_text_word"){
            indexofnew = this.state.field_elements.input_text_words.length;
            isinput = true;
        //	let tempinput_text_words = this.state.field_elements.input_text_words;//must copy in order to push TODO***, no, look above
            tempfe.input_text_words.push(create_default_input_text());//create_default_radio());//TODO change name to default MC
            tempfe.answers.input_text_words.push(create_default_word_answer());//TODO change name to default MC
        //	this.setState({
        //		field_elements:{
        //			...this.state.field_elements, 
        //			input_text_words:tempinput_text_words//input_text_words:[...this.state.field_elements.input_text_words,'']
        //		}
        //	});
        }
    */    
        else if(type === "input_text_long"){
            indexofnew = this.state.field_elements.answers.input_text_longs.length;
            isinput = true;
        //	let tempinput_text_longs = this.state.field_elements.input_text_longs;//must copy in order to push TODO***, no, look above
            tempfe.input_text_longs.push(create_default_input_text());
            tempfe.answers.input_text_longs.push(create_default_text_long_answer());
        //	this.setState({
        //		field_elements:{
        //			...this.state.field_elements, 
        //			input_text_longs:tempinput_text_longs
        //		}
        //	});
        }
        else if(type === "input_hybrid"){
            indexofnew = this.state.field_elements.answers.input_hybrids.length;
            isinput = true;
            tempfe.input_hybrids.push(create_default_input_hybrid());
            tempfe.answers.input_hybrids.push(create_default_hybrid_answer());
        }
        else if(type === "input_self_mark"){
            indexofnew = this.state.field_elements.answers.input_self_marks.length;
            isinput = true;
            tempfe.input_self_marks.push(create_default_self_mark());
            tempfe.answers.input_self_marks.push(create_default_selfmark_answer());
        }
        else if(type === "input_numerical_twopart"){
        //    indexofnew = this.state.field_elements.input_numerical_twoparts.length;
            indexofnew = this.state.field_elements.answers.input_text_longs.length;//not an error, they share the answers thing.
            isinput = true;
            tempfe.input_numerical_twoparts.push(create_default_input_text());//this may need to be different.
        //    tempfe.answers.input_numerical_twoparts.push(create_default_text_long_answer());//This is going to be the same for simplicity. 
            tempfe.answers.input_text_longs.push(create_default_text_long_answer());//not an error, shared answers array.
        }
        else if(type === "info_image"){
            indexofnew = this.state.field_elements.info_images.length;
        //	let tempinfo_images = this.state.field_elements.info_images;//must copy in order to push
            tempfe.info_images.push(create_default_image());
        //	this.setState({field_elements:{...this.state.field_elements, info_images:tempinfo_images}});
        }
        else {
            console.log("add_field cannot add element of type: "+type+" since it is not known.");
            throw "add_field cannot add element of type: "+type+" since it is not known.";
        }
        var tempfield_desc = this.state.field_elements.field_desc;
        tempfield_desc.push({
            isinput:isinput, 
            type:type, 
            instance:indexofnew, 
            maxscore:type==="input_self_mark"?4:isinput?1:0,
        });
        tempfe.field_desc=tempfield_desc;
        this.setState({field_elements:tempfe}); //should change all of this so that I set a variable that then gets set using the prev state update thing. 
        //this.setState({field_elements:{...this.state.field_elements, field_desc:tempfield_desc}});
        //formvalues get set here: create_empty_formvalues()
    }
    addoption(index:{fieldindex:number,optionindex?:number,mp?:number},type:FieldType,remove:boolean) {	
    //    console.log("addoption called");
    //this will be called inside a radio creator component
        //let fieldindex = (JSON.parse(e.currentTarget.name).fieldindex);
    //    console.log("fieldindex: "+index.fieldindex);
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
    //    console.log("instance: "+instance);
        let tempfe = this.state.field_elements;
        if(type==="radio"){
            if(remove===false){

            //    console.log("tempinput_radios: "+JSON.stringify(tempfe.input_radios));
                tempfe.input_radios[instance].options.push(create_default_option());//TODO*** this is bad because I am trying to change state directly
            //    console.log("tempinput_radios: "+JSON.stringify(tempfe.input_radios));
                //I think that it won't like the line below because I am changes tempfe.answers, which is actually a reference to the state. Since tempfe is only a shallow copy
                tempfe.answers.input_radios[instance].options.push(create_default_radio_option_answer());//TODO*** this is bad because I am trying to change state directly
            } else {
                tempfe.input_radios[instance].options.pop();
                tempfe.answers.input_radios[instance].options.pop();
            }
            //this.setState(prevState => ({//I think that because I am using typescript it doesn't like this prevState since it is a property with no type
            //	field_elements:{
                //		...prevState. field_elements,//["field_elements"],//this hack won't work
                //		input_radios: tempinput_radios
                //	}
                //}))
            //this.setState({field_elements:{...this.state.field_elements, input_radios: tempfe.input_radios}});
        }else if(type==="checkbox"||type==="input_self_mark"||type==="input_hybrid"){
            let fieldtypereference = getfieldtypes(type) as "input_self_marks"|"input_checkboxes"|"input_hybrids";
            if(remove===false){
                tempfe[fieldtypereference][instance].options.push(create_default_option());//TODO*** this is bad because I am trying to change state directly
                tempfe.answers[fieldtypereference][instance].options.push(create_default_checkbox_option_answer(false));//TODO*** this is bad because I am trying to change state directly
            }else {
                tempfe[fieldtypereference][instance].options.pop();
                tempfe.answers[fieldtypereference][instance].options.pop();
            }
        }else{
        //    console.log("QuestionEntryPage>Addoption cannot find type: "+type);
            throw "QuestionEntryPage>Addoption cannot find type";
        }
        this.setState({field_elements:tempfe});
        //if((remove===true)&&(type==="input_self_mark"||type==="checkbox")){
        //This update the max score based on the number of options. I don't think I want this for hybrid. 
        if((type==="input_self_mark"||type==="checkbox")){
            let fieldtypereference = getfieldtypes(type) as "input_self_marks"|"input_checkboxes"|"input_hybrids";
            //let deletedoptionindex = this.state.field_elements.answers[fieldtypereference][instance].options.length-1;
            //let score_change = this.state.field_elements.answers[fieldtypereference][instance].options[deletedoptionindex].iscorrect?-1:0;
            let new_max_score = get_checkbox_score(this.state.field_elements.answers[fieldtypereference][instance],0);//score_change);//Not sure how this works, but it currently works :-?
            let newState = update(this.state, {
                field_elements: {
                    field_desc: {
                        [index.fieldindex]:{
                            //set max score
                            maxscore:{$set:new_max_score}
                        }
                    }
                }
            });
            this.setState(newState);
        }
    //    console.log("just set state in Question Entry Page");
    }
    async onThesaurusAction(index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"}){
        console.log("onThesaurusAction called with parameters: "+JSON.stringify(index));
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
    //    let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let filter = getfilter(this.state.field_elements,index.fieldindex,index.filterindex,index.mp);
    //    let ischild = filter.ischild;
        if(index.action==="createnew"||index.action==="edit"){    //createnew is add, //edit is push
            if(index.action==="createnew"&&filter.ID!==undefined&&filter.ID!==-1){//if I have asked to create new and I have looked for it and I didn't not find it
                throw "this filter is already in the thesaurus, cannot "+index.action+" onThesaurusAction "+filter.name;
            } else {
                filter.matches_thesaurus=true;
                filter.name = handlePunctuation(filter.name);
                filter = handleMixedContents(filter); //@move this to app.ts only relevent for createnew since if editting it means that the names already match so we wouldn't need to add this stuff. 
                    //the handleMixedContents can go on the other side. It would be nice to see on this side though if something came from a mixed filter or not. 
                    //maybe I should just be able to see the name of the Template filter at a click. 
                filter.ID = await modifyFilterInDB(filter,index.action);
                if(filter.ischild) filter.auto_generated=true;
            }
        } else if (index.action==="fetch"){  
            let obj = await fetchFilter(filter,filter.name);
            filter.ID = obj.ID;
            filter.matches_thesaurus = obj.matches_thesaurus;
            loggy(filter.matches_thesaurus,"filter.matches_thesaurus");
        } else if (index.action==="pull"){
            if(filter.ID===undefined||filter.ID===null){throw "filter.ID should be defined and not null"}
            else {filter = await pullFilter(filter.ID);}
        //    filter.ischild = ischild;
            if(filter.ischild) filter.auto_generated=true;
            filter.displaynameonly=false;
            filter.hidden=false;
            loggy(filter.matches_thesaurus,"filter in onThesaurusAction");
        }
    //    tempfe.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex] = filter;
    //    let field_type:FieldTypes = getfieldtypes(type);
    //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do thesaurus action on: "+field_type;
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    //[field_type]:{//
                    input_text_longs:{
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{$set:filter}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
    //    this.setState({field_elements:tempfe});
        loggy(this.state.field_elements.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex].matches_thesaurus,"after setState");
    }
    getStructuralName(index:{fieldindex:number,filterindex:number,mp:number,uniqueparams?:boolean}){
        let unique_params = false;
        if(index.uniqueparams===true){
            unique_params=true;
        }
        console.log("getStructuralName called with parameters: "+JSON.stringify(index));
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let filter = getfilter(this.state.field_elements,index.fieldindex,index.filterindex,index.mp);
    //    let tempfe = this.state.field_elements;
    //    let filter = tempfe.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex];
        if(filter.structure!==undefined){
            filter.name = filter.structure;
            //let obj = await fetchFilter(filter,filter.structure);
            filter.ID = null;
            filter.matches_thesaurus = false;//obj.matches_thesaurus;
        } else if(filter.name.indexOf("{")>-1){
            console.log("string contains braces");
            //convert to structured form
            let output = extractBraceContents(filter.name,"{","}",true,unique_params); //string is also edited
            //    loggy(output,"output");
            //    let curley_bracket_contents_array = output.bracket_contents_array;
            let structure_string = handlePunctuation(output.string);    
            //look up structured form
            //let obj = await fetchFilter(filter,structure_string);
            filter.name = structure_string;
            filter.ID = null;
            filter.matches_thesaurus = false;
        }
    //    tempfe.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex] = filter;
    //    this.setState({field_elements:tempfe});
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    //[getfieldtypes(type)]:{//
                    input_text_longs:{
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{$set:filter}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
    }
    async getStructureFilter(index:{fieldindex:number,filterindex:number,mp:number}){
        //when is this called? I don't think it is used. Maybe I was building it for a button that just grabs the structural match in one button. 
            //would need it to cope with the doulbe use of the same parameter. TODO: add this to devsugs, low priority. 
        //check filter.structure to look up a mixed match in the thesaurus if there is one. 
        //converts filter.name to structural to look up structural match
        //finally looks to see if there is an exact match//hmm, seems to me that exact match should come first, 
        console.log("getStructureFilter called with parameters: "+JSON.stringify(index));
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let filter = getfilter(this.state.field_elements,index.fieldindex,index.filterindex,index.mp);
        let string = filter.name;
        if(filter.structure!==undefined){ //I think that this means that there is a mixed match
            let obj = await fetchFilter(filter,filter.structure);
            //filter.ID = obj.ID;
            //filter.matches_thesaurus = obj.matches_thesaurus;
            this.getStructuralName({fieldindex:index.fieldindex,filterindex:index.filterindex,mp:index.mp})
            this.onThesaurusAction({fieldindex:index.fieldindex,filterindex:index.filterindex,mp:index.mp,action:"fetch"})
            this.onThesaurusAction({fieldindex:index.fieldindex,filterindex:index.filterindex,mp:index.mp,action:"pull"})
        } else if(string.indexOf("{")>-1){
                console.log("string contains braces");
                //convert to structured form
                let output = extractBraceContents(string,"{","}",true,false); //string is also edited
            //    loggy(output,"output");
            //    let curley_bracket_contents_array = output.bracket_contents_array;
                let structure_string = handlePunctuation(output.string);    
                //look up structured form
                let obj = await fetchFilter(filter,structure_string);
                filter.ID = obj.ID;
                filter.matches_thesaurus = obj.matches_thesaurus;
        }
        if(filter.matches_thesaurus===false){
            let obj = await fetchFilter(filter,string);
            filter.ID = obj.ID;
            filter.matches_thesaurus = obj.matches_thesaurus;
        }
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    //[getfieldtypes(type)]:{//
                    input_text_longs:{//I don't understand why this is hard coded. 
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{$set:filter}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);

        //try to fetch the filter, if it fetches, then mark it as exact. 
            //if it is marked as exact, there shouldn't be a [$m] button next to it. 
        //if the filter has a structure, fetch that and pull it. [$s]
        //if the filter does not have a structure, convert to braces and try to pull that. 
    }
    modifyFilter(index:{fieldindex:number,filterindex?:number,mp:number},modType:string) {
        console.log("modifyFilter called "+"with fieldindex: "+index.fieldindex);
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let tempfe = this.state.field_elements;
    //    let field_type:FieldTypes = getfieldtypes(tempfe.field_desc[index.fieldindex].type);
    //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "onThesaurusAction/modifyFilter called on wrong kind of type";
        let tempfilters = tempfe.answers.input_text_longs[instance].markingpoints[index.mp].filters;
        if(modType==="addnew"){    
            console.log("adding new");
            let new_filter:FilterAll = create_default_filterAll();
            new_filter.auto_generated = false;//i.e. this has been manually created. 
            tempfilters.push(new_filter);
        } else if(index.filterindex===undefined){
            throw "a request was made to modifyFilter but filterindex is undefined"
        } else{
            tempfilters = modifyArray(tempfilters,index.filterindex,modType);
        }
        this.setState({field_elements:tempfe});
        console.log("just set state in Question Entry Page");
    }
    modifyAND(index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string) {//ANDindex is always 0 for NOT
        console.log("modifyAND called "+"with fieldindex: "+index.fieldindex);
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let filter = getfilter(this.state.field_elements,index.fieldindex,index.filterindex,index.mp);
    //    let tempfe = this.state.field_elements;
    //    let tempfilter = tempfe.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex];
        let target_array = [];
        filter.matches_thesaurus=false;//possibly the wrong place
        if(index.nandtype==="ANDNOT"){
            target_array = filter.ANDNOTs;
        } else if(index.nandtype==="AND"){
            target_array = filter.ANDs;
        } else if(index.nandtype==="NOTOVERLAP"){
            target_array = filter.NOTOVERLAPs;
        } else if(index.nandtype==="ALSO"){
            target_array = filter.ALSOs;
        } else{ throw "didn't recognise nandtype: "+index.nandtype}
        if(modType==="add"){    
            console.log("adding new");
            //if(index.NOT===true&&target_array.length>0)    {throw "only 1 NOT allowed per filter"}
            target_array.push([{str:''}]);
        } else if(index.filterindex===undefined){
            throw "a request was made to modifyAND but filterindex is undefined"
        } else{
            target_array = modifyArray(target_array,index.ANDindex,modType);
            filter.auto_generated = false;
        }
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    //[getfieldtypes(type)]:{//
                    input_text_longs:{
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{$set:filter}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
        //    this.setState({field_elements:tempfe});
        console.log("just set state in Question Entry Page");
    }
    //    removeOption(fieldindex:number,filterindex:number){
    //
    //    }
    onChangephrase(e:React.ChangeEvent<HTMLInputElement>,fieldindex:number,optioninstance:number,type:FieldType,mp?:number){
        //I kept this here because I anticipated its use at some point, I can't remember what for now.
    }
    onChangekeyterm(e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,filterindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType}){
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let type = this.state.field_elements.field_desc[index.fieldindex].type;
        let filter = getfilter(this.state.field_elements,index.fieldindex,index.filterindex,index.mp);//TODO should be called filter index
        filter.auto_generated=false;
        filter.matches_thesaurus=false;
    //    if(index.isNOT===true){
        if(index.nandtype==="ANDNOT"){
            //is NOT
            filter.ANDNOTs[index.ANDindex][0].str=e.target.value;
        } else if(index.nandtype==="NOTOVERLAP"){
            filter.NOTOVERLAPs[index.ANDindex][0].str=e.target.value;
        } else if(index.nandtype==="ALSO"){
            filter.ALSOs[index.ANDindex][0].str=e.target.value;
        } else if (index.nandtype==="AND"&&index.synonymindex!=undefined){
        //    if(!isstatement){
        //        tempinput_text_longs[instance].markingpoints[mp].keyterms[keytermindex].ANDs[0][synonymindex]=e.target.value;//TODO*** this is bad because I am trying to change state directly
        //    } else {
                filter.ANDs[index.ANDindex][index.synonymindex].str=e.target.value;//TODO*** this is bad because I am trying to change state directly
        //    }
        }
        else {
        //    if(!isstatement){
        //        tempinput_text_longs[instance].markingpoints[mp].keyterms[keytermindex].text=e.target.value;//TODO*** this is bad because I am trying to change state directly
        //    }else {
                throw "multiple possible errors, could be that nandtype is not recognised or it could be that should be using the change option text for this bit for now"; //TODO** change it to be here
        //    }
        }
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    //[getfieldtypes(type)]:{//
                    input_text_longs:{
                        [instance]:{
                            markingpoints:{
                                [index.mp]:{
                                    filters:{
                                        [index.filterindex]:{$set:filter}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
    //    this.setState({field_elements:{
    //        ...this.state.field_elements, 
    //        answers:{
    //            ...this.state.field_elements.answers,
    //            input_text_longs:tempinput_text_longs
    //        } 
    //    }});
    }
    //addTerm(e: React.MouseEvent<HTMLButtonElement>, fieldindex:number,isstatement:boolean,remove:boolean,keytermindex?:number){
    addTerm(e: React.MouseEvent<HTMLButtonElement>, index:{ANDindex:number,fieldindex:number,mp:number,filterindex?:number,issynonym?:boolean},isstatement:boolean,remove:boolean){
        //adds synonyms to statements and to terms
        //also adds terms - this is a bit confusing, should be broken down. TODO
        e.preventDefault();
        console.log("addTerm called with: "+index+" isstatement: "+isstatement+" remove: "+remove);
        let instance = this.state.field_elements.field_desc[index.fieldindex].instance;
    //    let type = this.state.field_elements.field_desc[index.fieldindex].type;
    //    let field_type:FieldTypes = getfieldtypes(type);
    //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
        //let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
        if (index.filterindex!=undefined){
            if(index.issynonym){
                let filter:FilterAll = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[instance].markingpoints[index.mp].filters[index.filterindex]));
                filter.matches_thesaurus = false;
                console.log("synonym added or removed");
            //    if(isstatement){
                    if(remove){
                        filter.ANDs[index.ANDindex].pop();
                    }//TODO*** this is bad because I am trying to change state directly
                    else{
                        filter.ANDs[index.ANDindex].push({str:""});
                    }//TODO*** this is bad because I am trying to change state directly
            //    } else {
            //        if(remove){tempinput_text_longs[instance].markingpoints[index.mp].keyterms[index.keytermindex].ANDs[0].pop();}
            //        else{tempinput_text_longs[instance].markingpoints[index.mp].keyterms[index.keytermindex].ANDs[0].push("");}//TODO*** this is bad because I am trying to change state directly
            //    }
                let newState = update(this.state, {
                    field_elements: {
                        answers: {
                            //[getfieldtypes(type)]:{//
                            input_text_longs:{
                                [instance]:{
                                    markingpoints:{
                                        [index.mp]:{
                                            filters:{
                                                [index.filterindex]:{$set:filter}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState(newState);
            //    this.setState({field_elements:{
            //        ...this.state.field_elements, 
            //        answers:{
            //            ...this.state.field_elements.answers,
            //            input_text_longs:tempinput_text_longs
            //        } 
            //    }});
                console.log("state being set");
            } else{
            //    if(isstatement){
                    throw "this is meant only for removing terms"
            //    }
            //    else{
            //        console.log("removing a key term");
            //        if(remove){tempinput_text_longs[instance].markingpoints[index.mp].keyterms.splice(index.keytermindex,1);}
            //    }
            }
        }
        else {
            console.log("key term/statement added")
        //    if(isstatement){
                throw "addoption should have been used for this use case for now";//TODO** fix this
        //    } else {
        //        if(remove){tempinput_text_longs[instance].markingpoints[index.mp].keyterms.pop();}//TODO*** this is bad because I am trying to change state directly
        //        else{tempinput_text_longs[instance].markingpoints[index.mp].keyterms.push(create_keyterm(''));}//TODO*** this is bad because I am trying to change state directly
        //    }I
        }
    }                    
    onChangeinfo_text(e: React.ChangeEvent<HTMLInputElement>,fieldindex:number){
        //let fieldindex = (JSON.parse(e.target.name));
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        let tempinfo_texts = this.state.field_elements.info_texts;
        tempinfo_texts[fieldinstance] = e.target.value;
        this.setState({field_elements:{
            ...this.state.field_elements,
            info_texts:tempinfo_texts
        }});
        //console.log('info_texts in state are: '+JSON.stringify(this.state.field_elements.info_texts));
    }
    onChangeFieldMax(newvalue:number,fieldindex:number,weighting:boolean){
        let tempfield_desc = this.state.field_elements.field_desc;
        if(weighting){
            tempfield_desc[fieldindex].weighting = Number(newvalue);
        } else {
            tempfield_desc[fieldindex].maxscore = Number(newvalue);
        }
        this.setState({field_elements:{
            ...this.state.field_elements,
            field_desc:tempfield_desc
        }});
    }
    onChangeMPMax(newvalue:number,fieldindex:number,mp:number){
        let fd = this.state.field_elements.field_desc[fieldindex];
    //    let temp_input_text_longs = this.state.field_elements.answers.input_text_longs;
    //    temp_input_text_longs[fd.instance].markingpoints[mp].maxscore = newvalue;
    //    this.setState({field_elements:{
    //        ...this.state.field_elements,
    //        answers:{...this.state.field_elements.answers,
    //            input_text_longs:temp_input_text_longs}
    //    }});
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    input_text_longs:{//[getfieldtypes(fd.type)]:{//this is on change marking point max mp only exist in input_text-Longs
                        [fd.instance]:{
                            markingpoints:{
                                [mp]:{
                                    maxscore:{$set:newvalue}
                                }
                            }
                        }
                    }
                }
            }
        })
        this.setState(newState);
    }
    fieldControl(e: React.ChangeEvent<HTMLInputElement>,fieldindex:number,action:string){
        e.preventDefault();
        //let tempfe = {...this.state.field_elements};
        let tempfe = JSON.parse(JSON.stringify(this.state.field_elements));//replace with update

        let newinstanceref = -1;
        if(action==="delete"||action==="remove"||action==="copy"){
            [tempfe, newinstanceref] = this.modifyInstances(fieldindex,action,tempfe);
            if (action==="delete"||action==="remove"){
                tempfe = this.modifyDescInstanceRefs(fieldindex, tempfe);
            }
        }
        tempfe.field_desc = modifyArray(tempfe.field_desc,fieldindex,action);
        if(action==="copy"){
            tempfe.field_desc[tempfe.field_desc.length-1].instance = newinstanceref;
        }
        //make new desc row point to the new instance. 
        console.log("fieldControl, settingstate");
        this.setState({field_elements:tempfe});
    }
    modifyDescInstanceRefs(fieldindex:number,tempfe:FieldElementsAnswers){
        let temp_descs = tempfe.field_desc;
        let field_desc = temp_descs[fieldindex];
        let type = field_desc.type;
        let instance = field_desc.instance;//TODO properly handle the deleting of fields
        //update the field_desc instance numbers
        for (let f = 0; f<temp_descs.length; f++){
            if(temp_descs[f].type===type){
                if(temp_descs[f].instance>instance){
                    temp_descs[f].instance--;
                }
            }
        }
        return tempfe;
    }
    modifyInstances(fieldindex:number,action:string,tempfe:FieldElementsAnswers):[FieldElementsAnswers, number]{
        console.log("modifyInstances called");
        let type = tempfe.field_desc[fieldindex].type;
        let field_type:FieldTypes = getfieldtypes(type);
        if (field_type==="input_numerical_twoparts"){field_type="input_text_longs"}//TODO this is dodgy
        let instance = tempfe.field_desc[fieldindex].instance;
        let newinstanceref = -1;
        //can shrink the below significantly using types
        tempfe[field_type] = modifyArray(tempfe[field_type],instance,action);
        if(field_type!=="info_images"&&field_type!=="info_texts"){
            tempfe.answers[field_type] = modifyArray(tempfe.answers[field_type],instance,action);
        }
        newinstanceref = tempfe[field_type].length-1;
    //    switch(type){ 
    //        case "info_text":
    //            tempfe.info_texts = modifyArray(tempfe.info_texts,instance,action);
    //            console.log("tempfe.info_texts: "+tempfe.info_texts);
    //            newinstanceref = tempfe.info_texts.length-1;
    //            break;
    //        case "info_image":
    //            tempfe.info_images = modifyArray(tempfe.info_images,instance,action);
    //            newinstanceref = tempfe.info_images.length-1;
    //            break;
    //        case "radio":
    //            tempfe.input_radios = modifyArray(tempfe.input_radios,instance,action);
    //            tempfe.answers.input_radios = modifyArray(tempfe.answers.input_radios,instance,action);
    //            newinstanceref = tempfe.input_radios.length-1;
    //            break;
    //        case "checkbox":
    //            tempfe.input_checkboxes = modifyArray(tempfe.input_checkboxes,instance,action);
    //            tempfe.answers.input_checkboxes = modifyArray(tempfe.answers.input_checkboxes,instance,action);
    //            newinstanceref = tempfe.input_checkboxes.length-1;
    //            break;
    //    //    case "input_text_word":
    //    //        tempfe.input_text_words = modifyArray(tempfe.input_text_words,instance,action);
    //    //        tempfe.answers.input_text_words = modifyArray(tempfe.answers.input_text_words,instance,action);
    //    //        newinstanceref = tempfe.input_text_words.length-1;
    //    //        break;
    //        case "input_text_long":
    //            tempfe.input_text_longs = modifyArray(tempfe.input_text_longs,instance,action);
    //            tempfe.answers.input_text_longs = modifyArray(tempfe.answers.input_text_longs,instance,action);
    //            newinstanceref = tempfe.input_text_longs.length-1;
    //            break;
    //        case "input_numerical_twopart":
    //            tempfe.input_numerical_twoparts = modifyArray(tempfe.input_text_longs,instance,action);
    //            tempfe.answers.input_text_longs = modifyArray(tempfe.answers.input_text_longs,instance,action);
    //            newinstanceref = tempfe.input_text_longs.length-1;
    //            break;
    //        default: throw "did not recognise type";
    //    }
        return [tempfe, newinstanceref]

        //console.log("fieldControl, settingstate");
        //this.setState({field_elements:{
        //    ...this.state.field_elements,
        //    field_desc:array,
        //}});
    }


    //onChangeoption_text(e:React.ChangeEvent<HTMLInputElement>,fieldindex:number,optioninstance:number,type:string,mp?:number){
    onChangeoption_text(value:string,fieldindex:number,optioninstance:number,type:FieldType,mp?:number){
        //console.log("this is the event: "+JSON.stringify(e));*** for some reason it doesn't like this line
        //name={"["+this.props.fieldindex+","+i+"]"} for reference
        //let fieldindex=(JSON.parse(e.target.name))[0];//TODO change to being a function with parameters
        //let optioninstance=(JSON.parse(e.target.name))[1];
        //let fieldindex = e.target.key;//is stored in the table cell
        //console.log("fieldindex: "+fieldindex);
        //console.log("onChangeoption_text optioninstance: "+optioninstance);
        //console.log("state: "+this.state.field_elements.field_desc);
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;

        //getfieldtypes(type)
        if(type==="radio"){
            let tempinput_radios = this.state.field_elements.input_radios;
            tempinput_radios[fieldinstance].options[optioninstance].text = value;
            this.setState({field_elements:{
                ...this.state.field_elements,
                input_radios:tempinput_radios
            }});
        }else if(type==="checkbox"){
            let tempinput_checkboxes = this.state.field_elements.input_checkboxes;
            tempinput_checkboxes[fieldinstance].options[optioninstance].text = value;
            this.setState({field_elements:{
                ...this.state.field_elements,
                input_checkboxes:tempinput_checkboxes
            }});
        }else if(type==="input_self_mark"){
            let tempinput_self_marks = this.state.field_elements.input_self_marks;
            tempinput_self_marks[fieldinstance].options[optioninstance].text = value;
            this.setState({field_elements:{
                ...this.state.field_elements,
                input_self_marks:tempinput_self_marks
            }});
        }else if(type==="input_hybrid"&&mp===undefined){
            let temp = this.state.field_elements.input_hybrids;
            temp[fieldinstance].options[optioninstance].text = value;
            this.setState({field_elements:{
                ...this.state.field_elements,
                input_hybrids:temp
            }});
    /*    }else if(type=="input_text_word"){
            let tempinput_text_words = this.state.field_elements.answers.input_text_words;
            tempinput_text_words[fieldinstance].options[optioninstance].text = e.target.value;
            this.setState({field_elements:{
                ...this.state.field_elements,
                answers:{
                    ...this.state.field_elements.answers,
                    input_text_words:tempinput_text_words
                }            }});
    */    }else if(type==="input_text_long"||type==="input_numerical_twopart"||type==="input_hybrid"&&mp!==undefined){
            let field_type:FieldTypes = getfieldtypes(type);
            if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts"&&field_type!=="input_hybrids") throw "can't do addTerm action on: "+field_type;
            if(mp===undefined){throw "mp must be given for input_text_long or input_numerical_twoparts or input_hybrids"}
            else{
                let newState = update(this.state, {
                    field_elements: {
                        answers: {
                            [field_type]:{
                                [fieldinstance]:{
                                    markingpoints:{
                                        [mp]:{
                                            filters:{
                                                [optioninstance]:{
                                                    name:{$set:value},
                                                    auto_generated:{$set:false},
                                                    ID:{$set:undefined},
                                                    matches_thesaurus:{$set:undefined}                                
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState(newState);
            //    let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
            //    let filter = copy(tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance]);
            //    filter.name = e.target.value;
            //    filter.auto_generated = false;
            //    filter.ID = undefined;
            //    filter.matches_thesaurus = undefined;
            //    this.setState((prevState:QuestionEntryPageState)=>({
            //        field_elements:{
            //        ...prevState.field_elements,
            //        answers:{
            //            ...prevState.field_elements.answers,
            //            input_text_longs:[
            //                prevState.field_elements.answers.input_text_longs.slice(0,fieldinstance),
            //                {
            //                    ...prevState.field_elements.answers.input_text_longs[fieldinstance],
            //                    markingpoints:{
            //                        ...prevState.field_elements.answers.input_text_longs[fieldinstance].markingpoints,
            //                        [mp]:{
            //                            ...prevState.field_elements.answers.input_text_longs[fieldinstance].markingpoints[mp],
            //                            filters:{
            //                                ...prevState.field_elements.answers.input_text_longs[fieldinstance].markingpoints[mp].filters,
            //                                optioninstance:{
            //                                    ...prevState.field_elements.answers.input_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance],
            //                                    name:value,
            //                                    auto_generated:false,
            //                                    ID:undefined,
            //                                    matches_thesaurus:undefined,
            //                                }
            //                            }
            //                        }
            //                    }
            //                },
            //                prevState.field_elements.answers.input_text_longs.slice(fieldinstance+1),
            //            ]
            //        }
            //    }}));
            }
        }else{
            console.log("ERROR: onChangeoption_text could not recognise type: "+type);
            throw "ERROR: onChangeoption_text could not recognise type"+type;
        }
        //console.log('input_radios in state are: '+JSON.stringify(this.state.field_elements.input_radios));
    }
    onChangeoption_concept(e:React.ChangeEvent<HTMLInputElement>,fieldindex:number,optioninstance:number,conceptinstance:number,mp?:number){//e: React.ChangeEvent<HTMLSelectElement>){
        //let fieldindex=(JSON.parse(e.target.name).fieldindex);
        //let optioninstance=(JSON.parse(e.target.name).optioninstance);
        //let conceptinstance = (JSON.parse(e.target.name).conceptinstance);
        let type = this.state.field_elements.field_desc[fieldindex].type;
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        console.log("Change to "+type+": "+fieldinstance+" option: "+optioninstance+" concept: "+conceptinstance);

        if(type==="radio"){
            let tempinput_radios = this.state.field_elements.answers.input_radios;
            tempinput_radios[fieldinstance].options[optioninstance].concept[conceptinstance] = parseInt(e.target.value, 10);
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_radios:tempinput_radios
                }
            }});			
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.input_radios[fieldinstance].options[optioninstance]));
        } else if (type==="checkbox"){
            let tempinput_checkboxes = this.state.field_elements.answers.input_checkboxes;
            tempinput_checkboxes[fieldinstance].options[optioninstance].concept[conceptinstance] = parseInt(e.target.value, 10);
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_checkboxes:tempinput_checkboxes
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.input_checkboxes[fieldinstance].options[optioninstance]));
        } else if (type==="input_self_mark"){
            let tempinput_self_marks = this.state.field_elements.answers.input_self_marks;
            tempinput_self_marks[fieldinstance].options[optioninstance].concept[conceptinstance] = parseInt(e.target.value, 10);
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_self_marks:tempinput_self_marks
                }
            }});
    //    } else if (type==="input_hybrid"){ // not actually sure about this, think it would probably go into input_text_long style. 
    //        let temp = this.state.field_elements.answers.input_hybrids;
    //        temp[fieldinstance].options[optioninstance].concept[conceptinstance] = parseInt(e.target.value, 10);
    //        this.setState({field_elements:{	...this.state.field_elements,
    //            answers:{...this.state.field_elements.answers,
    //                input_hybrids:temp
    //            }
    //        }});
    /*    } else if (type==="input_text_word"){
            let tempinput_text_words = this.state.field_elements.answers.input_text_words;
            tempinput_text_words[fieldinstance].options[optioninstance].concept[conceptinstance] = parseInt(e.target.value, 10);
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_text_words:tempinput_text_words
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers.input_text_words[fieldinstance].options[optioninstance]));
    */    } else if (type==="input_text_long"){//||type==="input_numerical_twopart"){
            if(mp===undefined){throw "mp must be defined for input_text_long or input_numerical_twopart"}
            else{
                let field_type:FieldTypes = getfieldtypes(type);
                if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
            //    let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
            //    let filter = tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance];
            //    filter.concept[conceptinstance] = parseInt(e.target.value, 10);
            //    filter.auto_generated = false;
                let newState = update(this.state, {
                    field_elements: {
                        answers: {
                            [field_type]:{
                                [fieldinstance]:{
                                    markingpoints:{
                                        [mp]:{
                                            filters:{
                                                concept:{[conceptinstance]:{$set:parseInt(e.target.value, 10)}},
                                                auto_generated:{$set:false}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState(newState);
            //    this.setState({field_elements:{	...this.state.field_elements,
            //        answers:{...this.state.field_elements.answers,
            //            input_text_longs:tempinput_text_longs
            //        }
            //    }});
                //console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers[field_type][fieldinstance].markingpoints[mp].filters[optioninstance]));
            }
            
        }else {
            console.log("ERROR: onChangeoption_concept could not recognise type: "+type);
            throw "ERROR: onChangeoption_concept could not recognise type"+type;
        }		
    }
    onChangeHintText(value:string,fieldindex:number,type:FieldType){
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        let field_type:FieldTypes = getfieldtypes(type);
        let newState = update(this.state, {
            field_elements: {
            //    answers: {
            //        [field_type]:{
            //            [fieldinstance]:{
            //                statichint:{
            //                    $set:value
            //                }
            //            }
            //        }
            //    },
                [field_type]:{
                    [fieldinstance]:{
                        statichint:{
                            $set:value
                        }
                    }
                }
            }
        })
        this.setState(newState);
    }

    onChangeoption_feedback(e:React.ChangeEvent<HTMLInputElement>,fieldindex:number,optioninstance:number,type:FieldType,mp?:number,showwhen?:string){//e: React.ChangeEvent<HTMLInputElement>){
        //let fieldindex=(JSON.parse(e.target.name).fieldindex);
        //let optioninstance=(JSON.parse(e.target.name).optioninstance);
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        console.log("Change to "+type+": "+fieldinstance+" option: "+optioninstance);
        if(type==="radio"){
            let tempinput_radios = this.state.field_elements.answers.input_radios;
            if(showwhen==="found"){
                tempinput_radios[fieldinstance].options[optioninstance].feedback.text = e.target.value;
            }
            else if (showwhen==="notfound"){
                tempinput_radios[fieldinstance].options[optioninstance].feedback_if_not_found.text = e.target.value;
            }
            else throw "shown when: "+showwhen+ " not recognised";
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_radios:tempinput_radios
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers.input_radios[fieldinstance].options[optioninstance]));
        } else if(type==="checkbox"){
            let tempinput_checkboxes = this.state.field_elements.answers.input_checkboxes;
            if(showwhen==="found"){
                tempinput_checkboxes[fieldinstance].options[optioninstance].feedback.text = e.target.value;
            }
            else if (showwhen==="notfound"){
                tempinput_checkboxes[fieldinstance].options[optioninstance].feedback_if_not_found.text = e.target.value;
            }
            else throw "shown when: "+showwhen+ " not recognised";
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_checkboxes:tempinput_checkboxes
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers.input_checkboxes[fieldinstance].options[optioninstance]));
        } else if(type==="input_self_mark"){
            let tempinput_self_marks = this.state.field_elements.answers.input_self_marks;
            if(showwhen==="found"){
                tempinput_self_marks[fieldinstance].options[optioninstance].feedback.text = e.target.value;
            }
            else if (showwhen==="notfound"){
                tempinput_self_marks[fieldinstance].options[optioninstance].feedback_if_not_found.text = e.target.value;
            }
            else throw "shown when: "+showwhen+ " not recognised";
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_self_marks:tempinput_self_marks
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers.input_self_marks[fieldinstance].options[optioninstance]));
        } else if(type==="input_hybrid"){
            let temp = this.state.field_elements.answers.input_hybrids;
            if(showwhen==="found"){
                temp[fieldinstance].options[optioninstance].feedback.text = e.target.value;
            }
            else if (showwhen==="notfound"){
                temp[fieldinstance].options[optioninstance].feedback_if_not_found.text = e.target.value;
            }
            else throw "shown when: "+showwhen+ " not recognised";
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_hybrids:temp
                }
            }});
            console.log('updated option in state is now: '+
            JSON.stringify(temp[fieldinstance].options[optioninstance]));
    
    /*    } else if(type==="input_text_word"){
            let tempinput_text_words = this.state.field_elements.answers.input_text_words;
            tempinput_text_words[fieldinstance].options[optioninstance].feedback.text = e.target.value;
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_text_words:tempinput_text_words
                }
            }});
            console.log('updated option in state is now: '+JSON.stringify(this.state.field_elements.answers.input_text_words[fieldinstance].options[optioninstance]));
    */   } else if(type==="input_text_long"){//||type==="input_numerical_twopart"){//why is the numerical two part bit ignored do I also ignore hybrid, maybe it is a hardcoded input_long...
            if(mp===undefined||showwhen===undefined){throw "mp and showwhen must be defined for input_text_long or input_numerical_twopart"}
            else{   
            //    let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
            //    let field_type:FieldTypes = getfieldtypes(type);
            //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
                let filter:FilterAll = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance]));
                filter.auto_generated=false;
                filter.matches_thesaurus=false;
                if(showwhen==="found"){
                    filter.feedback_if_found.text = e.target.value;
                }
                else if (showwhen==="notfound"){
                    filter.feedback_if_not_found.text = e.target.value;
                }
                else throw "shown when: "+showwhen+ " not recognised";
            //    this.setState({field_elements:{	...this.state.field_elements,
            //        answers:{...this.state.field_elements.answers,
            //            input_text_longs:tempinput_text_longs
            //        }
            //    }});
                let newState = update(this.state, {
                    field_elements: {
                        answers: {
                            input_text_longs:{
                            //[field_type]:{
                                [fieldinstance]:{
                                    markingpoints:{
                                        [mp]:{
                                            filters:{
                                                [optioninstance]:{$set:filter}                               
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState(newState);
                
                console.log('updated option in state is now: '+JSON.stringify(filter));
            }
        } else{
            console.log("ERROR: onChangeoption_feedback could not recognise type: "+type);
            throw "ERROR: onChangeoption_feedback could not recognise type"+type;
        }
    }
    onChangeoption_iscorrect(e:any,fieldindex:number,optioninstance:number,type:FieldType,mp?:number){//e: React.ChangeEvent<HTMLInputElement>){
        //identify component from its name
        //let fieldindex=(JSON.parse(e.target.name).fieldindex);
        //let optioninstance=(JSON.parse(e.target.name).optioninstance);
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;//TODO*** this is copying an object from state, it is bad
        //console.log("Change to radio: "+fieldinstance+" option: "+optioninstance);
        if(type==="radio"){
            let tempinput_radios = this.state.field_elements.answers.input_radios;
            for(let i=0;i<this.state.field_elements.input_radios[fieldinstance].options.length;i++){
                tempinput_radios[fieldinstance].options[i].iscorrect = (i===optioninstance)?true:false;
            }
            this.setState({field_elements:{	...this.state.field_elements,
                answers:{...this.state.field_elements.answers,
                    input_radios:tempinput_radios
                }
            }});
        } else if (type==="checkbox"||type==="input_self_mark"||(type==="input_hybrid"&&mp===undefined)){//note that hybrid can have the option iscorrect changed and also the filter marks changed
        //    let tempinput_checkboxes = this.state.field_elements.answers.input_checkboxes;
        //    tempinput_checkboxes[fieldinstance].options[optioninstance].iscorrect = !tempinput_checkboxes[fieldinstance].options[optioninstance].iscorrect;
            let fieldtypereference = getfieldtypes(type) as "input_self_marks"|"input_checkboxes"|"input_hybrids"
            let score_change = type==="input_hybrid"?0:this.state.field_elements.answers[fieldtypereference][fieldinstance].options[optioninstance].iscorrect?-1:1;//don't change the score for hybrid
            let new_max_score = get_checkbox_score(this.state.field_elements.answers[fieldtypereference][fieldinstance], score_change);
            let newState = update(this.state, {
                field_elements: {
                    answers: {
                        [fieldtypereference]:{
                            [fieldinstance]:{
                                options:{
                                    [optioninstance]:{
                                        //set is correct
                                        iscorrect:{$apply: (currentvalue:boolean)=>!currentvalue}//worried about this
                                    }
                                }
                                
                            }
                        }
                    },
                    field_desc: {
                        [fieldindex]:{
                            //set max score
                            maxscore:{$set:new_max_score}
                        }
                    },
                    [fieldtypereference]:{
                        [fieldinstance]:{
                            oneOptionOnly:{$set:new_max_score<2}
                        }
                    }
                    
                }
            })
            this.setState(newState);
        //    this.state.field_elements.field_desc[fieldindex].maxscore = new_max_score
        //    this.setState({field_elements:{	...this.state.field_elements,
        //        answers:{...this.state.field_elements.answers,
        //            input_checkboxes:tempinput_checkboxes
        //        }
        //    }});
    //    } else if (type==="input_text_word"){
    //        let tempinput_text_words = this.state.field_elements.answers.input_text_words;
    //        tempinput_text_words[fieldinstance].options[optioninstance].iscorrect = !tempinput_text_words[fieldinstance].options[optioninstance].iscorrect;
    //        this.setState({field_elements:{	...this.state.field_elements,
    //            answers:{...this.state.field_elements.answers,
    //                input_text_words:tempinput_text_words
    //            }
    //        }});
        } else if (type==="input_text_long"||type==="input_numerical_twopart"||(type==="input_hybrid"&&mp!==undefined)){//hybrid has options AND filters
            if(mp===undefined){throw "mp must be defined for input_text_long or input_numerical_twopart"}
            else{
            //    let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
            //    tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance].scoring = e.target.value;//!tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance].iscorrect;
                //tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance].iscorrect = !tempinput_text_longs[fieldinstance].markingpoints[mp].filters[optioninstance].iscorrect;
            //    this.setState({field_elements:{	...this.state.field_elements,
            //        answers:{...this.state.field_elements.answers,
            //            input_text_longs:tempinput_text_longs
            //        }
            //    }});
                let field_type:FieldTypes = getfieldtypes(type);
                if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
                let newState = update(this.state, {
                    field_elements: {
                        answers: {
                            [field_type]:{
                                [fieldinstance]:{
                                    markingpoints:{
                                        [mp]:{
                                            filters:{
                                                [optioninstance]:{
                                                    scoring:{$set:e.target.value} //TODO check that this works!
                                                }                               
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                this.setState(newState);
            }
        } else {
            console.log("ERROR: onChangeoption_iscorrect could not recognise type: "+type);
            throw "ERROR: onChangeoption_iscorrect could not recognise type"+type;
        }
    }
    onChangetitle(e: React.ChangeEvent<HTMLInputElement>){
        this.setState({name:e.target.value});
    }
    onChangeinfo_image(selectorFiles: FileList, fieldindex:number){
        console.log(selectorFiles);
        console.log(JSON.stringify(selectorFiles));
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        let tempinfo_images = this.state.field_elements.info_images;
        //tempinfo_images[fieldinstance] = selectorFiles[0];//e.target.value;
        tempinfo_images[fieldinstance].file = selectorFiles[0];//e.target.value;
        tempinfo_images[fieldinstance].modified = true;
        tempinfo_images[fieldinstance].name = selectorFiles[0].name;
        this.setState({field_elements:{
            ...this.state.field_elements,
            info_images:tempinfo_images
        }});
    }
    modifyMarkingPoint(fieldindex:number,modType:string,mp?:number){
        console.log("modifyMarkingPoint called with fieldindex: "+fieldindex+" mp: "+mp+" modType: "+modType);
    //    let field_type:FieldTypes = getfieldtypes(this.state.field_elements.field_desc[fieldindex].type);
        let field_type = "input_text_longs";
    //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
    //    console.log("fe."+field_type+" "+JSON.stringify(this.state.field_elements[field_type][this.state.field_elements.field_desc[fieldindex].instance],null,4));
        let fieldinstance = this.state.field_elements.field_desc[fieldindex].instance;
        let temp_markingpoints = this.state.field_elements.answers.input_text_longs[fieldinstance].markingpoints;
        if(modType==="addnew"){
            console.log("adding new");
            temp_markingpoints.push(create_default_text_long_answer_marking_point());
        } else if(mp===undefined||mp===-1){
            throw "a request was made to modifyMarkingPoint but mp is undefined or -1"
        } else{
            if (modType==="delete"){
                console.log("deleting markingpoint");
                temp_markingpoints.splice(mp,1);
            } else if (modType==="copy"){
                console.log("copying markingpoint");
                temp_markingpoints.push(JSON.parse(JSON.stringify(temp_markingpoints[mp])));//TODO:replace with update
            } else if (modType==="moveup"||modType==="movedown"){
                temp_markingpoints = modifyArray(temp_markingpoints,mp,modType);
            }
        }
        //TODO Ahh, this is disgusting ggg
        //let field_type:FieldTypes = getfieldtypes(type);
        if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
        let newState = update(this.state, {
            field_elements: {
                answers: {
                    [field_type]:{
                        [fieldinstance]:{
                            markingpoints:{$set:temp_markingpoints}
                        }
                    }
                }
            }
        })
        this.setState(newState); 
    //    console.log("fe."+field_type+" "+JSON.stringify(this.state.field_elements[field_type][fieldinstance],null,4));
    }
    onSubmitQ = (e: React.MouseEvent<HTMLButtonElement>) => { //e: { preventDefault: () => void; }//***This code is super similar for both submitting answer and Q */
        e.preventDefault();
        this.submitQuestion();
    }
    async submitQuestion(){
        console.log('submitting Q with Q_ID: '+this.props.Q_ID+' name: '+this.state.name);
        var url = '/modify_question_db';
        console.log("submitting edit or new Q with Q_ID: "+this.props.Q_ID);
        let questiondata:QuestionAnswersNoInfo = {
            field_elements:this.state.field_elements,// Object.assign({}, this.state.formvalues),
            name:this.state.name,
            Q_ID:this.props.Q_ID,
            hintOnly:this.state.hintOnly,
        };
        var data = {action:this.props.action,
                    question:questiondata,
                    Q_ID:this.props.Q_ID,
                    };//{MC_answer:this.state.MC_answer};         // get our form data out of state
        //fd.append('data',JSON.stringify(data));
        try {
            let response = await fetch(url, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers:{
                    'Content-Type': 'application/json'//"multipart/form-data"
                }
            });
            let json_object = await response.json();
            console.log("json_object: "+JSON.stringify(json_object));
            let newQ_ID = await json_object.Q_ID;
            console.log("new Q_ID: "+newQ_ID);
            let newfilenames = await json_object.newfilenames;
            console.log("newfilenames: "+newfilenames);
                let fd = new FormData;
                //add all the images to files, what?
                for (let i = 0; i<this.state.field_elements.info_images.length;i++){
                    //upload the modified images
                    if (this.state.field_elements.info_images[i].modified){
                        //if(this.state.field_elements.info_images[i].file!=''){ //just made this change to remove an error but not sure if things will work.
                        let file_variable = this.state.field_elements.info_images[i].file;
                        if(file_variable!=null){
                            fd.append('info_images',
                                file_variable,
                                //""+newQ_ID+"_"+i+"_"+newfilenames[i]);//this.state.field_elements.info_images[i].file.name);
                                newfilenames[i]);//this.state.field_elements.info_images[i].file.name);
                        } else {
                            throw "image labelled as modified but there is no image there";
                        }
                    }
                }
                let response2 = await fetch('/upload_images',//TODO what is going on here?
                    {
                        method: 'POST',
                        body: fd,
                    }
                );

                //Set modified status of images to false TODO (needs to depend on the success criteria of the above)
                let tempinfo_images = this.state.field_elements.info_images;
                for (let i = 0; i<this.state.field_elements.info_images.length;i++){
                        tempinfo_images[i].modified = false;
                        if(newfilenames[i]!=undefined){
                            tempinfo_images[i].name = newfilenames[i];
                        }
                }
                this.setState({field_elements:{
                    ...this.state.field_elements,
                    info_images:tempinfo_images
                }});
                    
                //.then(resdata => this.setState({feedback:resdata}))
                console.log('Success submitting Q:'+ JSON.stringify(json_object)+"now calling parent's submit()");
            // TODO: aidan says yuck
            if (this.props.onSubmit) {//if you were called by the question manager page (or other page that wants to know when you submit)
                this.props.onSubmit();//get question manager to called the function to update table of questions from server's database
            }
            if (this.props.addQuestion) {//if you were called by the question selector page
                this.props.addQuestion(newQ_ID);
            }
            console.log("after the parent's submit I think Q_ID is: "+this.props.Q_ID+" note that if we are in quesiton creator rather than question manager, this may be undefined, I think");
        }
        catch (error) {
            console.error('Error:', error);
        }
    }
    componentDidMount(){
        fetch("/concepts") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
        .then(response => response.json())
        .then(js_obj => this.setState({concepts:js_obj}));
    }
    async generateKeyTerms(index:{fieldindex:number,mp:number}){
        console.log("generateKeyTerms called with index: "+JSON.stringify(index,null,4));

    //    let field_type:FieldTypes = getfieldtypes(this.state.field_elements.field_desc[index.fieldindex].type);
    //    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts") throw "can't do addTerm action on: "+field_type;
        
        //Copy variables
        let input_long:InputTextLongAnswer = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[this.state.field_elements.field_desc[index.fieldindex].instance]));
        let response = await generateKeyTermsMP(input_long.markingpoints[index.mp]);
        if(response.filters===undefined){
            throw "generateKeyTermsMP failed"
        } else {
            input_long.markingpoints[index.mp] = response;
            let fe = this.state.field_elements;
            fe.answers.input_text_longs[this.state.field_elements.field_desc[index.fieldindex].instance] = input_long;
            //loggy(input_long,"input_long");
            this.setState({field_elements:fe});    //TODO: this is dodgy.
        //    this.toggleAllKeyTerms("show");
        //    this.toggleAllKeyTerms("hide","auto");
        //    this.toggleAllKeyTerms("minimise","auto");
        }
    }    
    
    show_hide_mp(fieldindex:number,mp:number,mode:string){
        console.log("show_hide_mp called with filedindex: "+fieldindex+" and mp: "+mp+" and mode: "+mode);
        //find place in the keyterms that would place
        let input_long:InputTextLongAnswer = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[this.state.field_elements.field_desc[fieldindex].instance]));
        if      (mode==="hide")     {input_long.markingpoints[mp].hidden = true;    console.log("setting mp: "+mp+" to hidden=true");}
        else if (mode==="show")     {input_long.markingpoints[mp].hidden = false;   console.log("setting mp: "+mp+" to hidden=false");}
        else if (mode==="toggle")   {input_long.markingpoints[mp].hidden = (!input_long.markingpoints[mp].hidden)||true; console.log("setting mp: "+mp+" to hidden="+(!input_long.markingpoints[mp].hidden)||true);}
        else throw "show_hide_mp mode not recognised: "+mode;
        //input_long.markingpoints[mp].hidden = !input_long.markingpoints[mp].hidden||true;//not actually old anymore
        let fe = this.state.field_elements;
        fe.answers.input_text_longs[this.state.field_elements.field_desc[fieldindex].instance] = input_long;
        this.setState({field_elements:fe});
    }
    toggleHelp(){
        this.setState({showingHelp:!this.state.showingHelp});
    }
    toggleAllKeyTerms(mode:string,type:FieldType,condition?:string){
        console.log("toggleAllKeyTerms called with mode: "+mode);
        //TODO, maybe this is the slow bit. 
        let input_longs:InputTextLongAnswer[] = type==="input_hybrid"?JSON.parse(JSON.stringify(this.state.field_elements.answers.input_hybrids)):
        JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs));
        input_longs = toggleAllKeyTerms(mode,condition,input_longs,this.state.field_elements.field_desc)
    // I think that the below doesn't really do anything / doesn't work possibly based on the way that the thing works, would need to pass in type really. 
    //    let input_numerical_twoparts:InputTextLongAnswer[] = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_numerical_twoparts));
    //    console.log(JSON.stringify(input_numerical_twoparts));
    //    if(input_numerical_twoparts!=undefined){
    //        input_numerical_twoparts = toggleAllKeyTerms(mode,condition,input_numerical_twoparts,this.state.field_elements.field_desc)
    //    }
        let fe = this.state.field_elements;
        fe.answers.input_text_longs = input_longs;
    //    fe.answers.input_numerical_twoparts = input_numerical_twoparts;
        this.setState({field_elements:fe});
    }
    toggleChildren(param:{fieldindex:number,mp:number,filterindex:number,mode?:string,condition?:string}){
        let fieldinstance = this.state.field_elements.field_desc[param.fieldindex].instance;
        let tempfe = this.state.field_elements;
        let tempfilters = this.state.field_elements.answers.input_text_longs[fieldinstance].markingpoints[param.mp].filters;
        let tempfilter = tempfilters[param.filterindex]; 
        if(param.condition==="children"||param.condition==="kids"){
            let tokenisedNANDTypes:TokenisedNANDType[]=["Tokenised_ANDs","Tokenised_ANDNOTs","Tokenised_NOTOVERLAPs","Tokenised_ALSOs"];
        //    let NANDTypesplural:("ANDs"|"ANDNOTs"|"NOTOVERLAPs"|"ALSOs")[] = ["ANDs","ANDNOTs","NOTOVERLAPs","ALSOs"];
            for (let nandtype = 0; nandtype<tokenisedNANDTypes.length; nandtype++){//loop through the NANDTypes
                let NAND = tempfilter[tokenisedNANDTypes[nandtype]];
                if(NAND===undefined) continue
                NAND.forEach((ORs:string[][]) =>{
                    ORs.forEach((tokenised_OR:string[]) =>{
                        tokenised_OR.forEach((token:string) =>{
                            tempfilters.forEach((filter:FilterAll) =>{           
                                //if (doesFilterMatchToken(filter,token)){
                                    //handlePunctuation(filter.name)===handlePunctuation(token)){//I think that the filter name should have already have had its punctuation handled. 
                                if (filter.name===token){
                                    //console.log("    \""+token+"\""+" exists as a filter")
                                    //filter.hidden=(param.mode==="hide")?true:false;
                                    filter.hidden=false;
                                    filter.displaynameonly=false;
                                    //filter.perm_hidden=(param.mode==="hide")?true:false;
                                }
                            })
                        })
                    })
                })
            }
        } else if(param.condition==="parents"){
            if(tempfilter.parentnames===undefined)throw "Shouldn't have been able to click show parents when parentnames is not defined"
            else{
                tempfilter.parentnames.forEach((parentname:string)=>{
                    tempfilters.forEach((filter:FilterAll)=>{
                        if (handlePunctuation(filter.name)===handlePunctuation(parentname)){//I think that the filter name should have already have had its punctuation handled. 
                            //filter.hidden=(param.mode==="hide")?true:false;
                            filter.hidden=false;
                            filter.displaynameonly=false;
                        }
                    })
                })
            }
        } else if(param.condition==="self"){
            tempfilter.hidden=true;
        } else {throw "condition "+param.condition+" not found"}
        this.setState({field_elements:tempfe});
    }
    setErrorMessage(index:{fieldindex:number,mp:number},errmsg:string){//mysteriously this doesn't do anything. 
        console.log("setErrorMessage called");//with index: "+JSON.stringify(index,null,4));
        let input_long:InputTextLongAnswer = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[this.state.field_elements.field_desc[index.fieldindex].instance]));
        input_long.markingpoints[index.mp].errormessage = errmsg;
        input_long.markingpoints[index.mp].isvalid = false;
        let fe = this.state.field_elements;
        fe.answers.input_text_longs[this.state.field_elements.field_desc[index.fieldindex].instance] = input_long;
        console.log("fe.answers.input_text_longs: "+JSON.stringify(fe.answers.input_text_longs,null,4));//has desirable fe here
        this.setState({field_elements:fe});//then just doesn't set it. 
    }
    toggleSymbols(fieldindex:number){
        let instance = this.state.field_elements.field_desc[fieldindex].instance;
        let tempfe = this.state.field_elements;
        if(this.state.field_elements.field_desc[fieldindex].type==="input_text_long"){
            tempfe.input_text_longs[instance].showsymbols = !this.state.field_elements.input_text_longs[instance].showsymbols;
        } else if (this.state.field_elements.field_desc[fieldindex].type==="input_self_mark"){
            tempfe.input_self_marks[instance].showsymbols = !this.state.field_elements.input_self_marks[instance].showsymbols;
        } else if (this.state.field_elements.field_desc[fieldindex].type==="input_hybrid"){
            tempfe.input_hybrids[instance].showsymbols = !this.state.field_elements.input_hybrids[instance].showsymbols;
        }
        this.setState({field_elements:tempfe});
    }
    toggleMultiSelect(fieldindex:number){
        let instance = this.state.field_elements.field_desc[fieldindex].instance;
        let tempfe = this.state.field_elements;
        tempfe.input_checkboxes[instance].oneOptionOnly = !this.state.field_elements.input_checkboxes[instance].oneOptionOnly;
        this.setState({field_elements:tempfe});
    }
    updateFeedback(fieldindex:number){
        console.log("updateFeedback called");//with index: "+JSON.stringify(index,null,4));
    /*    let input_long:InputTextLongAnswer = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_text_longs[this.state.field_elements.field_desc[fieldindex].instance]));
        for (let mp = 0 ; mp < input_long.markingpoints.length ; mp ++){
            let mpscore = input_long.markingpoints[mp].maxscore;
            for (let s = 0 ; s < input_long.markingpoints[mp].filters.length ; s ++){
                let statement = input_long.markingpoints[mp].filters[s];
                statement.feedback_if_found = statement.iscorrect?create_empty_FeedbackObject():statement.feedback;
                statement.feedback_if_not_found = statement.iscorrect?statement.feedback:create_empty_FeedbackObject();
                statement.scoring = (mpscore==0)?"0":(statement.iscorrect?"+1":"-1");
            }
        }
        let fe = this.state.field_elements;
        fe.answers.input_text_longs[this.state.field_elements.field_desc[fieldindex].instance] = input_long;
        console.log("fe.answers.input_text_longs: "+JSON.stringify(fe.answers.input_text_longs,null,4));//has desirable fe here
        this.setState({field_elements:fe});//then just doesn't set it. should've noted what the error was
    */
    }
    updateFeedbackCheckbox(fieldindex:number){
        console.log("updateFeedbackCheckbox called");
        let input_checkbox:InputCheckboxAnswer = JSON.parse(JSON.stringify(this.state.field_elements.answers.input_checkboxes[this.state.field_elements.field_desc[fieldindex].instance]));
        for (let o = 0 ; o < input_checkbox.options.length ; o ++){ 
            let statement = input_checkbox.options[o];
            statement.feedback_if_not_found = statement.iscorrect?statement.feedback:create_empty_FeedbackObject();
            statement.feedback = statement.iscorrect?create_empty_FeedbackObject():statement.feedback;
        }
        let fe = this.state.field_elements;
        fe.answers.input_checkboxes[this.state.field_elements.field_desc[fieldindex].instance] = input_checkbox;
        console.log("fe.answers.input_checkboxes: "+JSON.stringify(fe.answers.input_checkboxes,null,4));//has desirable fe here
        this.setState({field_elements:fe});//then just doesn't set it. should've noted what the error was
    }
    onSetFeedbackType(index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback_prop:{type?:FeedbackType,priority?:number}){
        let fieldinstance = this.state.field_elements.field_desc[index.fieldindex].instance;
        let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;

        //reference the correct piece of feedback with feedback_state
        let tempfilter = tempinput_text_longs[fieldinstance].markingpoints[index.mp].filters[index.filterindex];
        
        let feedback_state:FeedbackObject = create_empty_FeedbackObject();
        if(index.showwhen==="found"){feedback_state = tempfilter.feedback_if_found}
        else if(index.showwhen==="notfound"){feedback_state = tempfilter.feedback_if_not_found}
        else {throw "index.showwhen: "+index.showwhen+" not recognised"}

        if(feedback_prop.type!==undefined){
            feedback_state.type = feedback_prop.type;
        } else if(feedback_prop.priority!==undefined){
            feedback_state.priority = feedback_prop.priority;
        }

        tempfilter.matches_thesaurus=false;
        tempfilter.auto_generated=false;

        this.setState({field_elements:{
            ...this.state.field_elements,
            answers:{
                ...this.state.field_elements.answers,
                input_text_longs:tempinput_text_longs
            }
        }});
    } 
    toggleIsFloaty(filterinfo:FilterInfo){
        if(JSON.stringify(this.state.floatingfilterinfo)!==JSON.stringify(filterinfo)){
            this.setState({floatingfilterinfo:filterinfo})
        } else {
            this.setState({floatingfilterinfo:null})
        }
    }
    toggleDisplayFilter(filterinfo:FilterInfo){
        let fieldinstance = this.state.field_elements.field_desc[filterinfo.fieldindex].instance;
        let tempinput_text_longs = this.state.field_elements.answers.input_text_longs;
        let tempfilter = tempinput_text_longs[fieldinstance].markingpoints[filterinfo.mp].filters[filterinfo.filterindex];
        
        if(tempfilter.displaynameonly===undefined){
            tempfilter.displaynameonly=true;
        } else {
            tempfilter.displaynameonly=!tempfilter.displaynameonly;
        }

        this.setState({field_elements:{
            ...this.state.field_elements,
            answers:{
                ...this.state.field_elements.answers,
                input_text_longs:tempinput_text_longs
            }
        }});
    }
    togglehintOnly(){
        let newhintOnly = false;
        if(this.state.hintOnly===undefined){
            newhintOnly = true;
        }
        if(this.state.hintOnly===false){
            newhintOnly = true;
        }
        this.setState({hintOnly:newhintOnly})
    }
    setSecretSauce(secret_sauce:any){
        console.log("setSecretSauce"+JSON.stringify(secret_sauce,null,4));
        this.setState({
            long_text_analysis:secret_sauce,
        //    filters_found:secret_sauce.filters_found,
        //    processed_text:secret_sauce.processed_text,
        //    tokenised_answer:secret_sauce.tokenised_answer,
        })
    }
    toggleviewofquestion(){
        this.setState({
            showingviewofquestion:!this.state.showingviewofquestion
        })
    }
    toggleviewofanswers(){
    //    if(this.state.showingviewofanswers===false){
    //        let response = await fetch("/Qattempts") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
    //        let js_obj:ClassAssignmentData = await response.json()
    //        js_obj = calcScores(js_obj);
    //        this.setState({
    //            qattempts:js_obj
    //        });
    //    }
        console.log("toggling view of answers: "+this.state.showingviewofanswers+" to "+this.state.showingviewofanswers);
        this.setState({
            showingviewofanswers:!this.state.showingviewofanswers
        })
    }
    render(){
    //    console.log("rendering QuestionEntryPage");
    //    console.log("long_text_analysis: "+JSON.stringify(this.state.long_text_analysis,null,4));//+"tokenised_answer: "+JSON.stringify(this.state.tokenised_answer,null,1))
    //    console.log("render QuestionEntryPage");
        //let concepts = [];
    //    console.log("QuestionEntryPage renders QuestionEntryBody and QuestionPage with Q_ID: "+this.props.Q_ID);
        let newquestion:QuestionAnswers = {
            Q_ID:this.props.Q_ID,
            name:this.state.name,
            field_elements:this.state.field_elements,
            hintOnly:this.state.hintOnly,
            info:{
                status:"draft",
                //creator:undefined,

            } //TODO** this is pretty bad, it is because the server sets this value
        }
        let longtextfunctions = {
            generateKeyTerms:this.generateKeyTerms,
            FilterControlsfn:{
                modifyFilter:this.modifyFilter,
                onThesaurusAction:this.onThesaurusAction,
                toggleChildren:this.toggleChildren,
                getStructuralName:this.getStructuralName,
                onChangeoption_iscorrect:this.onChangeoption_iscorrect,
                toggleIsFloaty:this.toggleIsFloaty,
                toggleDisplayFilter:this.toggleDisplayFilter,
            },
            FilterEditterfn:{
                addTerm:this.addTerm,
                addoption:this.addoption,
                modifyAND:this.modifyAND,
                onChangeoption_text:this.onChangeoption_text,
                onChangeoption_feedback:this.onChangeoption_feedback,
                onChangekeyterm:this.onChangekeyterm,
                onSetFeedbackType:this.onSetFeedbackType,
            }
        }
    //    let newfieldoptions:JSX.Element[] = [<option key={0}></option>];
//
    //    for (let o=0; 0<fieldtypeoptions.length; o++){
    //        newfieldoptions.push(
    //            <option key={o+1}>{fieldtypeoptions[o]}</option>
    //        )
    //    }
    
    
    //let options_in_select_menu = [<option key={0}>select question part to add</option>];
    //for (let o = 0; o<options_in_select_menu.length; o++){
    //    options_in_select_menu.push(<option key={o+1}>{field_type_desc_names[o]}</option>)
    //}
    //                        {options_in_select_menu}
        let field_select_input = 
        <select key={this.props.field_elements.field_desc.length} onChange={this.add_field} style={{fontSize:"large"}}>
            <option key={0} >select part type to add</option>
            <option key={1} value={field_type_desc_names[0]}>{field_type_desc_names_public[0]}</option>
            <option key={2} value={field_type_desc_names[1]}>{field_type_desc_names_public[1]}</option>
            <option key={4} value={field_type_desc_names[3]}>{field_type_desc_names_public[3]}</option>
            <option key={5} value={field_type_desc_names[4]}>{field_type_desc_names_public[4]}</option>
            <option key={6} value={field_type_desc_names[5]}>{field_type_desc_names_public[5]}</option>
            <option key={7} value={field_type_desc_names[6]}>{field_type_desc_names_public[6]}</option>
            <option key={8} value={field_type_desc_names[7]}>{field_type_desc_names_public[7]}</option>
        </select>

       // console.log(JSON.stringify(field_select_input));
        //<option key={3} value={field_type_desc_names[2]}>{field_type_desc_names_public[2]}</option>
        return(//*** */could put this inside the QuestionEntryBody and it runs on component did mount and changes the constructor value of ["concepts loading"] to the actual concepts
            <div className={"admin_page"}>
                <QuestionEntryBody 
                    concepts={this.state.concepts} 
                    name={this.state.name} 
                    field_elements={this.state.field_elements}
                    hintOnly={this.state.hintOnly}
                    togglehintOnly={this.togglehintOnly}
                    addoption={this.addoption}
                //    modifyFilter={this.modifyFilter}
                //    modifyAND={this.modifyAND}
                    add_field={this.add_field}
                    onChangeinfo_text={this.onChangeinfo_text}
                    onChangeFieldMax={this.onChangeFieldMax}
                    fieldControl={this.fieldControl}
                    onChangeoption_concept={this.onChangeoption_concept}
                    onChangeoption_text={this.onChangeoption_text}
                    onChangeoption_feedback={this.onChangeoption_feedback}
                    onChangeHintText={this.onChangeHintText}
                    onChangetitle={this.onChangetitle}
                    onChangeoption_iscorrect={this.onChangeoption_iscorrect}
                //    onChangekeyterm={this.onChangekeyterm}
                    addTerm={this.addTerm}
                //    removeOption={this.removeOption}
                    onChangeinfo_image={this.onChangeinfo_image}
                    onChangeMPMax={this.onChangeMPMax}
                    modifyMarkingPoint={this.modifyMarkingPoint}
                //    generateKeyTerms={this.generateKeyTerms}
                    toggleSymbols={this.toggleSymbols}
                    updateFeedback={this.updateFeedback}
                    updateFeedbackCheckbox={this.updateFeedbackCheckbox}
                //    onSetFeedbackType={this.onSetFeedbackType}
                //    onThesaurusAction={this.onThesaurusAction}
                //    toggleChildren={this.toggleChildren}
                    toggleMultiSelect={this.toggleMultiSelect}
                //    getStructuralName={this.getStructuralName}
                    LongTextfn={longtextfunctions}
                    long_text_analysis={this.state.long_text_analysis}
                />
                <div>
                    <br/>
                    <hr style={{borderTop: "dotted 3px"}} />
                </div>
                <div style={{marginTop:"1em",marginBottom:"1em"}}>
                    <label style={{color:"green", fontSize:"x-large", fontWeight:"bold"}}>
                        + New Question-part: 
                        {field_select_input}
                    </label>
                </div>
                <div className="floatingtools">
                    <FloatingFilter
                        filterinfo={this.state.floatingfilterinfo
                            //    mp:this.state.floatingfilterinfo.mp,
                            //    fieldindex:this.state.floatingfilterinfo.fieldindex,
                            //    filterindex:this.state.floatingfilterinfo.filterindex,
                            //    filter:this.state.field_elements.answers.input_text_longs[0].markingpoints[this.state.floatingfilterinfo.mp].filters[this.state.floatingfilterinfo.fieldindex],
                            ////instead of 0 above: this.state.field_elements.field_desc[this.state.floatingfilterinfo.fieldindex].instance
                            //}
                        }
                        field_elements={this.state.field_elements}

                //        hidden={this.state.floatingfilter===null}
                        LongTextfn={longtextfunctions}
                        long_text_analysis={floatingfilteranalysis(this.state.long_text_analysis,this.state.floatingfilterinfo)}
                    />
                    <HelpPage
                        hidden={!this.state.showingHelp}
                        toggleHelp={this.toggleHelp}
                    />
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("show","input_text_long")}}>Show Filters</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("hide","input_text_long"); this.toggleAllKeyTerms("minimise","input_text_long")}}>Hide Filters</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("hide","input_text_long","child")}}>Hide Children</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("hide","input_text_long","auto")}}>Hide Auto</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("show","input_text_long","scoring")}}>Show Scoring</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleAllKeyTerms("show","input_text_long","feedback")}}>Show Feedback</button><br/>
                    <button onClick={e=>{e.preventDefault(); this.toggleHelp()}}>Help</button><br/>
                    <button onClick={this.onSubmitQ}>Submit Question</button><br/>
                    <button hidden={(this.props.Q_ID!==undefined)?true:false} onClick={(e)=>{e.preventDefault();this.onResetQuestion()}}>Reset Question</button>
                </div>
                <hr/>
                <h2 onClick={this.toggleviewofquestion}>
                    Show/hide View of question
                </h2>
                <div hidden={!this.state.showingviewofquestion}>
                    <Questionpage 
                        key={1}
                        question={newquestion}
                        action="view"
                        inQedittor="itbetruewe'reintheQedittor" //this is a password to get the secret sauce
                        formvalues={create_empty_formvalues(newquestion)} //TODO*** shouldn't I put actual form values in here, I think these are just the initial values
                        isdisplayed={true}
                        setSecretSauce={this.setSecretSauce} 
                        updateQButtonColour={function (Q_ID: number, score: (number | null)[], maxscore: number[]): void {
                            throw new Error('Function not implemented.');
                        } }                    
                    />
                </div>
                <h2 onClick={()=>{console.log("hi");this.toggleviewofanswers()}}>
                    {this.state.showingviewofanswers?"Hide ":"Show "} pupil answers
                </h2>
                <div hidden={!this.state.showingviewofanswers}>
                    <QuestionAnswersComponentWrapper
                        Q_ID = {this.props.Q_ID}
                        displayed = {this.state.showingviewofanswers}
                    />
                </div>
                <br/>
                <br/>
            </div>
        )//				

    }
}
function floatingfilteranalysis(long_text_analysis_array:(LongTextAnalysis|null|undefined)[]|undefined,filter_info:FilterInfo|null):LongTextAnalysis|null|undefined{
    if(filter_info!==undefined&&long_text_analysis_array!==undefined){
        if(filter_info!=null){
            return long_text_analysis_array[filter_info.fieldindex]
        }
    } 
    return undefined
}
class HelpPage extends React.Component{
    props!:{
        hidden:boolean,
        toggleHelp:()=>void,
    }
    render(){
        return(
            <div className="HelpPage" hidden={this.props.hidden}>
                <h2>Question Creator Documentation</h2>
                <h3>Default behaviour</h3>
                <p>An isolated word in the box just searches for that word</p>
                <p>Put curley braces around a word to accept alternatives</p>
                <p>If you put a string of words then curley braces is put around each word by default</p>
                <p>If you put curley braces around a phrase then alternatives to the whole phrase are looked for</p>
                <p>You can combine curley braces</p>
                <h3>AND</h3>
                <p>Is a logical AND, all ANDs must be found in order for the filter to be 'found'.</p>
                <p>Only the position of the first AND is recorded, the others considered additional requirements</p>
                <h3>OR +</h3>
                <p>Each AND can have multiple 'OR phrases' that would satisfy them</p>
                <h3>NOT</h3>
                <p>Obvious</p>
                <h3>NOTOVERLAP</h3>
                <p>Compares the outer limits of the ORs in the top AND with the outer limits of the NOTOVERLAP. If the outer limits of these phrases overlap (even if no words themselves overlap) then the 'OR find' being considered is rejected</p>
                <h3>ALSO</h3>
                <p>Indicates filters to be run. Typically used to add feedback. Have no influence on the finding of the parent.</p>
                <h3>Manually specified alternatives within an OR</h3>
                <p>Just put [A|B] to manually specify that A or B is acceptable here</p>
                <p>There may be a maximum number of alternatives, in which case just nest them</p>
                <p>This works in a messy way.</p>
                <h3>Optional</h3>
                <p>Use round brackets to indicate that a phrase is optional e.g. the (large) ball</p>
                <h3>Wild card</h3>
                <p>"wild1" means up to 1 recognised word, at least 1 word, recognised or not.</p>
                <p>"wild2" means up to 2 recognised words, at least 1 word, recognised or not.</p>
                <p>If you want to ensure that there are no words, even unrecognised words, then you write a NOTOVERLAP with a wild in the appropriate place.</p>
                <h3>Hash functions</h3>
                <p>There are also hash functions used by writing #functionname(parameter)</p>
                <p>#x(2,3) is replaced with 6</p>
                <p>#PQname2symbol(mass) is replaced with m, this works for physical quantities</p>ggg
                <h3>anynumber</h3>
                <p>anynumber matches anynumber written in standard js number format, this doesn't includes 'two'</p>
                <h3>((this) NOTOVERLAP (that))</h3>
                <p>This works when you use curley braces not round ones.</p>
            </div>
        )
    }
}
class FloatingFilter extends React.Component{
    props!:{
        filterinfo:FilterInfo|null,
    //    hidden:boolean,
        LongTextfn:{
            generateKeyTerms:           (index:{fieldindex:number,mp:number})=>void,
            FilterControlsfn:           FilterControlFunctions,
            FilterEditterfn:            FilterEditterFunctions, 
        }
        field_elements:FieldElementsAnswers,
        long_text_analysis:LongTextAnalysis|null|undefined,
    }
    state:{
        hidden:boolean
    }
    constructor(props: FloatingFilter['props']) {
        super(props);
        this.state = {
            hidden:true
        };
    }
    render(){
        let floatingfilter:JSX.Element=<div></div>;// = <span></span>;

        if((this.props.filterinfo!==null)&&(this.props.filterinfo!==undefined)){
            let fi:FilterInfo = this.props.filterinfo;
            let instance = this.props.field_elements.field_desc[fi.fieldindex].instance;
            let filter = this.props.field_elements.answers.input_text_longs[instance].markingpoints[fi.mp].filters[fi.filterindex];
            if((filter!==null)&&(filter!==undefined)){
                let [filter_found_info, tokenised_answer] = getfilterfoundinfo(this.props.long_text_analysis,filter);
                let fi2:FilterInfo={
                    mp:fi.mp,
                    fieldindex:fi.fieldindex,
                    filterindex:fi.filterindex,
                    filter:filter,
                    filter_found_info,
                }
                floatingfilter = 
                <div className="FloatingFilter">
                    <table>
                        <tbody>
                            <FilterComponent
                                filterinfo={fi2}
                                type="input_text_long"
                                FilterControlsfn={this.props.LongTextfn.FilterControlsfn}
                                fn={this.props.LongTextfn.FilterEditterfn}
                                tokenised_answer={tokenised_answer}
                            />
                        </tbody>
                    </table>
                </div>
            }
        }
        return(
            <div>

                
                    
                            {floatingfilter}
                        
                {/*}    <div hidden={this.state.hidden}>
                    </div>
                    <button onClick={e=>{e.preventDefault(); this.setState({hidden:!this.state.hidden})}}>Floating filter</button>
                <br/>*/}
            </div>
        )
    }
}
class QuestionEntryBody extends React.Component {
    props!: {
        concepts: Concept[],
        name:string,
        field_elements:FieldElementsAnswers,
        hintOnly:boolean,
        long_text_analysis:         (LongTextAnalysis|undefined|null)[]|undefined,
        togglehintOnly:             ()=>void,
        add_field:					(e:any)=>void,
        onChangetitle:				(e:any)=>void,
        onChangeinfo_text:			(e:any,fieldindex:number)=>void,
        onChangeFieldMax:			(e:any,fieldindex:number,weighting:boolean)=>void,
        fieldControl:				(e:any,fieldindex:number,action:string)=>void,
        addoption:					(index:{fieldindex:number,optionindex?:number,mp?:number},type:FieldType,remove:boolean)=>void,
        onChangeoption_iscorrect:	(e:any,fieldindex:number,optioninstance:number,type:FieldType,mp?:number)=>void,
        //onChangeoption_text:		(e:any,fieldindex:number,optioninstance:number,type:string,mp?:number)=>void,
        onChangeoption_text:		(value:string,fieldindex:number,optioninstance:number,type:FieldType,mp?:number)=>void,
        onChangeHintText:           (value:string,fieldindex:number,type:FieldType)=>void,
        onChangeoption_feedback:	(e:any,fieldindex:number,optioninstance:number,type:FieldType,mp?:number,showwhen?:string)=>void,
        onChangeoption_concept:		(e:any,fieldindex:number,optioninstance:number,conceptinstance:number,mp?:number)=>void,
        addTerm:					(e: React.MouseEvent<HTMLButtonElement>,index:{ANDindex:number,fieldindex:number,mp:number,filterindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
        //    removeOption:               (fieldindex:number,filterindex:number)=>void,
        onChangeinfo_image:			(picture:any, fieldindex:number)=>void
        onChangeMPMax:              (value:any,fieldindex:number,mp:number)=>void,
        modifyMarkingPoint:         (fieldindex:number,modType:string,mp?:number)=>void,
        toggleSymbols:              (fieldindex:number)=>void,
        updateFeedback:             (fieldindex:number)=>void,
        updateFeedbackCheckbox:     (fieldindex:number)=>void,
        toggleMultiSelect:          (fieldindex:number)=>void, 
        LongTextfn:                 {
            generateKeyTerms:           (index:{fieldindex:number,mp:number})=>void,
            FilterControlsfn:           FilterControlFunctions,
            FilterEditterfn:            FilterEditterFunctions,    
            //    onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:string,priority?:number})=>void, 
            //    onThesaurusAction:          (index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"})=>void,
            //    toggleChildren:             (param:{fieldindex:number,mp:number,filterindex:number,mode?:string,condition?:string})=>void,
            //    getStructuralName:          (index:{fieldindex:number,filterindex:number,mp:number})=>void,
            //    modifyAND:                  (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT
            //    modifyFilter:               (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
            //    onChangekeyterm:			(e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,keytermindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType})=>void,
        }
    }
    state: {//state is handled in the above component or the below components?
        }
    constructor(props: Readonly<QuestionEntryBody['props']>) {//TODO why is this Readonly?
        super(props);
        this.state = {
        };
    }

    render() {
        
    //	let numrows = 4;//this.state.numrows; There is a default num of rows that is 4, this will be part of the radio component.
    //	let numconcepts = 2;//this.state.numconcepts;

    //////CREATE FIELDS
        var fe = this.props.field_elements;
    //    console.log("field_elements: "+JSON.stringify(this.props.field_elements,null,4));
        var fields =[];
        for(let i=0;i<fe.field_desc.length;i++){//**this bit of code can be resued from actual question builder */
            //console.log("rendering field i: "+i);
            let type = fe.field_desc[i].type;
    //        console.log("QuestionEntryBody rendering field type: "+type+" fe.field_desc[i]:"+JSON.stringify(fe.field_desc[i]));
            let iswordyinputfield = ((type==="input_text_long")||(type==="input_self_mark")||(type==="input_hybrid"));
            let input_hybrids = this.props.field_elements.input_hybrids;
            let showingsymbols = (!iswordyinputfield)?false
                :type==="input_text_long"?this.props.field_elements.input_text_longs[this.props.field_elements.field_desc[i].instance].showsymbols
                :type==="input_self_mark"?this.props.field_elements.input_self_marks[this.props.field_elements.field_desc[i].instance].showsymbols
                :type==="input_hybrid"?input_hybrids[this.props.field_elements.field_desc[i].instance].showsymbols
                :false //error if we get here. if statements or cases is better than colon stuff. 
                //type==="input_self_mark"?
            fields[2*i] = 	<FieldControls
                                key = {2*i}
                                onChangeFieldMax={this.props.onChangeFieldMax}
                                fieldControl={this.props.fieldControl}
                                fieldindex={i}
                                selectedmaxfieldscore={this.props.field_elements.field_desc[i].maxscore}
                                weighting={this.props.field_elements.field_desc[i].weighting}
                                isinput={this.props.field_elements.field_desc[i].isinput}
                                with_symbol_toggle={iswordyinputfield}
                                toggleSymbols={this.props.toggleSymbols}
                                updateFeedback={this.props.updateFeedback}
                                updateFeedbackCheckbox={this.props.updateFeedbackCheckbox}
                                showsymbols={showingsymbols}
                            />;
            
            if (fe.field_desc[i].type==="info_text"){
                fields[(2*i)+1] = 
                    //<p>{fe.info_texts[fe.field_desc[i].instance]}</p>
                    <div key={(2*i)+1} className="form-example">
                        <label>
                            Text to display:
                            <textarea 
                                className="infotextarea" 
                                name={""+i}//"info_text"+fe.field_desc[i].instance} 
                                rows={3}//data={i}
                                //defaultValue="Write your question here"
                                spellCheck={true}
                                value={fe.info_texts[fe.field_desc[i].instance]}
                                onChange={(e)=>this.props.onChangeinfo_text(e,i)}
                            />
                        </label>
                    </div>
            } else if  (fe.field_desc[i].type==="info_image"){
                fields[(2*i)+1] = 
                    <div key={(2*i)+1} className="form-example">
                        <label>
                            Information image:
                            <div>
                                <input type="file" onChange={e=>this.props.onChangeinfo_image(e.target.files,i)}/>
                                
                            </div>
                        </label>
                    </div>
            }else if(type==="radio"||type==="checkbox"||type==="input_self_mark"||type==="input_hybrid"){//||type==="input_text_word"){
                let fieldtypeinstances = getfieldtypes(type);
                if(fieldtypeinstances!=="input_checkboxes"&&fieldtypeinstances!=="input_radios"&&fieldtypeinstances!=="input_self_marks"&&fieldtypeinstances!=="input_hybrids"){
                    throw "something really wrong just happened here"
                }
                let instances = fe[fieldtypeinstances];
                let ansinstances = fe.answers[fieldtypeinstances];
                if(instances===undefined||ansinstances===undefined){
                    throw ""
                }
                let instance = instances[fe.field_desc[i].instance];
                let instanceanswer = ansinstances[fe.field_desc[i].instance];
                let input_self_mark_component = <div></div>;
                if(fieldtypeinstances==="input_self_marks"||fieldtypeinstances==="input_hybrids"){
                    let selfmark = instance as InputSelfMark;
                    //let selfmarkans = instanceanswer as InputSelfMarkAnswer;
                    input_self_mark_component = 
                        <InputSelfMarkOptions
                            hinttext={selfmark.statichint||''}
                            onChangeHintText={this.props.onChangeHintText}
                            fieldindex={i}
                            type={type}
                        />
                }
                let hybridautopart = <div></div>;
                if(fieldtypeinstances==="input_hybrids"){
                    let long_text_analysis = getLongTextAnalysis(i,this.props.long_text_analysis);
                    hybridautopart = <Longtextcreatorcomponent 
                        inputlonganswer={fe.answers.input_text_longs[fe.field_desc[i].instance]}
                        fieldindex={i} 
                        key={(2*i)+1}
                        onChangeMPMax={this.props.onChangeMPMax}
                        onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                        type={"input_text_long"}//{fe.field_desc[i].type}//TODO bit dodge
                        modifyMarkingPoint={this.props.modifyMarkingPoint}
                        generateKeyTerms={this.props.LongTextfn.generateKeyTerms}
                        FilterEditterfn={this.props.LongTextfn.FilterEditterfn}
                        FilterControlsfn={this.props.LongTextfn.FilterControlsfn}
                        long_text_analysis={long_text_analysis}
                    />
                }
                fields[(2*i)+1] = 
                <div 
                    key={(2*i)+1}>
                    <Radiocreatorcomponent 
                        inputradio={{	
                            options:instance.options,                                        //:					fe.input_text_words[fe.field_desc[i].instance]
                            oneOptionOnly:instance.oneOptionOnly,
                        }} 
                        inputradioanswer={	
                            instanceanswer                                        //:					fe.input_text_words[fe.field_desc[i].instance]
                            //oneOptionOnly:false
                        } 									
                        fieldindex={i} 
                        key={(2*i)+1}
                        concepts={this.props.concepts}
                        addoption={this.props.addoption} 
                        onChangeoption_text={this.props.onChangeoption_text}
                        onChangeoption_concept={this.props.onChangeoption_concept}
                        onChangeoption_feedback={this.props.onChangeoption_feedback}
                        onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                        type={fe.field_desc[i].type}
                        toggleMultiSelect= {this.props.toggleMultiSelect}
                        
                        //field_desc={this.props.field_elements.field_desc[i]}
                    />
                    {input_self_mark_component}
                    {hybridautopart}
                </div>

            } else if  (fe.field_desc[i].type==="input_text_long"||fe.field_desc[i].type==="input_numerical_twopart"){
                let long_text_analysis = getLongTextAnalysis(i,this.props.long_text_analysis);
                fields[(2*i)+1] = 
                    <Longtextcreatorcomponent 
                        //inputlong={fe.input_text_longs[fe.field_desc[i].instance]} 
                        inputlonganswer={fe.answers.input_text_longs[fe.field_desc[i].instance]}
                        fieldindex={i} 
                        key={(2*i)+1}
                    //    concepts={this.props.concepts}
                        onChangeMPMax={this.props.onChangeMPMax}
                    //    addoption={this.props.addoption}
                    //    modifyFilter={this.props.modifyFilter} 
                    //    modifyAND={this.props.modifyAND} 
                    //    onChangeoption_text={this.props.onChangeoption_text}
                    //    onChangeoption_concept={this.props.onChangeoption_concept}
                    //    onChangeoption_feedback={this.props.onChangeoption_feedback}
                        onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                        type={"input_text_long"}//{fe.field_desc[i].type}//TODO bit dodge
                    //    onChangekeyterm={this.props.onChangekeyterm}
                    //    addTerm={this.props.addTerm}
                    //    removeOption={this.props.removeOption}
                    //    onThesaurusAction={this.props.onThesaurusAction}
                    //    toggleChildren={this.props.toggleChildren}
                    //    getStructuralName={this.props.getStructuralName}  
                    
                        modifyMarkingPoint={this.props.modifyMarkingPoint}
                        //field_desc={this.props.field_elements.field_desc[i]}
                    //    onSetFeedbackType={this.props.onSetFeedbackType}
                        generateKeyTerms={this.props.LongTextfn.generateKeyTerms}
                        FilterEditterfn={this.props.LongTextfn.FilterEditterfn}
                        FilterControlsfn={this.props.LongTextfn.FilterControlsfn}
                        long_text_analysis={long_text_analysis}
                    />
            }
            //<img src={this.props.field_elements.info_images[this.props.field_elements.field_desc[i].instance]}/>
            /*<ImageUploader
                            withIcon={true}
                            buttonText='Choose images'
                            onChange={picture=>this.props.onChangeinfo_image(picture,i)}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                            withPreview={true}
                            withLabel={true}
                        />*/	
            //: (fe.field_desc[i].type==="input_text_word")?
            //	<p key={i}>Text input field will go here, this area will allow you to build a markscheme in future</p>
            else{console.log("unknown field type");}
        }
        return (
        <div>
            <h2>Question Maker</h2>
            <br/>
            {/*<p>Fill in the below questions</p>
            {/*<form method="post" action="/modify_question_db" className="form-example">*/}
                <label>
                    Question title: 
                    <input style={{width:"28em"}} value={this.props.name} onChange={this.props.onChangetitle} type="text" name="name"/>
                </label>
                <br/>
                <label>
                    Hint only:
                    <input
                        type="checkbox"
                        name="hintonlycontrol"
                        checked={this.props.hintOnly}
                        onChange={(e)=>this.props.togglehintOnly()}
                    />
                </label>
            {/*}	<fieldset>
                    <div className="form-example">
                        <label>
                            Question text bottom:
                            <input type="text" name="text_bottom"/>
                        </label>
                    </div>
        </fieldset>*/}
                <form>
                    {fields}
                </form>
                
            {/*	<div>
                    <input type="submit" name="action" value="submit" />
                    <input type="submit" name="action" value="view" />
            </div>*/}
            {/*</form>*/}
        </div>
        );
    }
}

class FieldControls extends React.Component{
    props!:{
        onChangeFieldMax:(value:any,fieldindex:number,weighting:boolean)=>void,
        fieldControl:(e:any,fieldindex:number,action:string)=>void,
        fieldindex:number,
        selectedmaxfieldscore:number,
        weighting?:number,
        isinput:boolean,
        with_symbol_toggle:boolean,
        toggleSymbols:(fieldindex:number)=>void,
        showsymbols:boolean,
        updateFeedback:(fieldindex:number)=>void,
        updateFeedbackCheckbox:(fieldindex:number)=>void,
    }
    render(){
        //let fieldcontrolcontainer = [];
        //if(this.props.isinput){
            let maxscoreselect = 	this.props.isinput?
                                        <label>
                                            Max score: 
                                            <select 
                                                onChange={(e)=>this.props.onChangeFieldMax(e.target.value,this.props.fieldindex,false)} 
                                                name={"maxfieldscore"+this.props.fieldindex}
                                                value={this.props.selectedmaxfieldscore} 
                                            >
                                                {//set options
                                                    [1,2,3,4,5,6,7,8,9,10,11,12].map(elt =>
                                                        <option 
                                                            value={elt} 
                                                            key={elt}
                                                        >{elt}
                                                        </option>)
                                                }
                                            </select>
                                        </label>
                                    :<div></div>;
            let scoreweightingselect = 	this.props.isinput?
                <label>
                    Weighting: 
                    <select 
                        onChange={(e)=>this.props.onChangeFieldMax(e.target.value,this.props.fieldindex,true)} 
                        name={"maxfieldscoreweighting"+this.props.fieldindex}
                        value={this.props.weighting} 
                    >
                        {//set options
                            [undefined,1,2,3,4,5,6,7,8,9,10,11,12].map(elt =>
                                <option 
                                    value={elt} 
                                    key={elt===undefined?0:elt}
                                >{elt}
                                </option>)
                        }
                    </select>
                </label>
            :<div></div>
        let fieldcontrolbuttons = 	
            <span>
                <button 
                        name="Move up"
                        style={{minWidth:"20px", minHeight:"20px"}}
                        onClick={(e)=>this.props.fieldControl(e,this.props.fieldindex,"moveup")}
                        >&#9651;
                </button>
                <button 
                        name="Move down"
                        style={{minWidth:"20px", minHeight:"20px"}}
                        onClick={(e)=>this.props.fieldControl(e,this.props.fieldindex,"movedown")}
                        >&#9661;
                </button>
                <button 
                        name="Remove"
                        style={{minWidth:"20px", minHeight:"20px"}}
                        onClick={(e)=>this.props.fieldControl(e,this.props.fieldindex,"remove")}
                        >&#128465;
                </button>
                <button 
                        name="Copy"
                        style={{minWidth:"20px", minHeight:"20px"}}
                        onClick={(e)=>this.props.fieldControl(e,this.props.fieldindex,"copy")}
                        ><i className="fa fa-copy"></i>
                </button>
            </span>;
        //	fieldcontrolcontainer.push(maxscoreselect);
        //} else{
            //maxscore select left as {}, i.e. nothing to be rendered.
        //	let maxscoreselect = <div></div>
        let showsymbolscontrol = 
            <label hidden={!this.props.with_symbol_toggle}>
                Symbols
                <input 
                    type="checkbox"
                    name="showsymbolscontrol"
                    checked={this.props.showsymbols}
                    onChange={(e)=>this.props.toggleSymbols(this.props.fieldindex)}
                    />
            </label>;
        let updateFeedback = 
            <span>
                <button hidden={true}//{!this.props.with_symbol_toggle}
                    onClick={e=>{e.preventDefault();this.props.updateFeedback(this.props.fieldindex)}}
                    >updateFeedback
                </button>
                <button hidden={true}//{!this.props.with_symbol_toggle}
                    onClick={e=>{e.preventDefault();this.props.updateFeedbackCheckbox(this.props.fieldindex)}}
                    >updateFeedbackCheckbox
                </button>
            </span>
            

        return (
        //	{fieldcontrolcontainer}
            <div>
                <hr style={{borderTop: "dotted 3px"}} />
                <br/>
                {fieldcontrolbuttons}
                {maxscoreselect}
                {scoreweightingselect}
                {showsymbolscontrol}
                {updateFeedback}
            </div>
        )
    }
}
class InputSelfMarkOptions extends React.Component{
    props!:{
        hinttext:string,
        onChangeHintText:(value:string,fieldindex:number,type:FieldType)=>void,
        fieldindex:number,
        type:FieldType,
    }
    render(){
        return(
            <div>
                <p>Hint text:</p>
                <textarea 
                    className="filternametextarea"
                    //type="text" 
                    cols={Math.min(80,(this.props.hinttext.length||0)+3)}
                    rows={1}
                    //name={"["+fi.fieldindex+","+fi.filterindex+"]"} 
                    //key={this.props.fieldindex}
                    value={this.props.hinttext||''}
                    onChange={(e)=>{
                        this.props.onChangeHintText(e.target.value,this.props.fieldindex,this.props.type)}}
                />
            </div>
        )
    }
}
class Radiocreatorcomponent extends React.Component{//also for creating checkboxes
    props!:{
        key:number,
        inputradio:InputRadio,
        inputradioanswer:InputRadioAnswer|InputCheckboxAnswer,
        fieldindex:number,
        concepts:Concept[],
        addoption:                  (index:{fieldindex:number,optionindex?:number,mp?:number},type:FieldType,remove:boolean)=>void,
        //onChangeoption_text:        (e:any,fieldindex:number,optioninstance:number,type:string)=>void,
        onChangeoption_text:        (value:string,fieldindex:number,optioninstance:number,type:FieldType)=>void,
        onChangeoption_concept:     (e:any,fieldindex:number,optioninstance:number,conceptinstance:number)=>void,
        onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:undefined,showwhen:FoundNotFound)=>void,
        onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:FieldType)=>void,
        toggleMultiSelect:          (fieldindex:number)=>void,
        type:FieldType
    }
    //static defaultProps = {
        //type:"radio",
    //}
    render(){
        var formrows = [];//each element will hold an option row html element
        for(let i = 0; i < this.props.inputradio.options.length; i++) {// this is a component to render what already exists
            var formconcepts = [];//each element will hold a concept html element
            //var defaultfeedback = "";//unsure about this***
            for(let j = 0; j < this.props.inputradioanswer.options[i].concept.length; j++) {
                formconcepts.push(
                    <td key={""+i+"_"+j}>
                        <Conceptselector 
                            concepts={this.props.concepts} 
                            selectedconcept_ID={this.props.inputradioanswer.options[i].concept[j]} 
                            optioninstance={i}
                            fieldindex={this.props.fieldindex}
                            onChangeoption_concept={this.props.onChangeoption_concept}
                            conceptinstance={j}
                        />
                    </td>
                );
                //<select onchange="myFunction()"> setstate({this.state.draftquestion.options[{i}].feedback:()})
                //feedback should probably be an array? if someone 
                //defaultfeedback += this.props.concept[j].C_long; //havn't inputted any concepts yet
                //defaultfeedback += this.state.draftquestion.options[i].feedback //this won't have been set yet
            }
            //console.log("fieldindex: "+this.props.fieldindex);
            formrows.push(
                <tr key={(2*i)}>
                    <td><input 
                        type={(this.props.type==="radio"?'radio':'checkbox')}
                        checked={this.props.inputradioanswer.options[i].iscorrect} //defaultChecked={i==0? true:false}
                        name={"correctans"+this.props.fieldindex+(this.props.type==="radio"?'':i)}//{JSON.stringify({
                            //name:"correctans",
                            //fieldindex:this.props.fieldindex,
                            //optioninstance:i,
                        //})}//A bit dodgy that the radio buttons have different names even though they are in same radio
                        //TODO put information into the onChange request with an arrow function
                        //Add +(type==="radio"?'':i) to the name to make them different for checkbox
                        value={'MC'+i} //TODO** onChangeoption_iscorrect needs updating for checkbox
                        onChange={(e)=>this.props.onChangeoption_iscorrect(e,this.props.fieldindex,i,this.props.type)}/>
                    </td>
                    <td><input //ggg
                        type="text" 
                        name={"["+this.props.fieldindex+","+i+"]"} 
                        //key={this.props.fieldindex}
                        value={this.props.inputradio.options[i].text}
                        onChange={(e)=>{this.props.onChangeoption_text(e.target.value,this.props.fieldindex,i,this.props.type)}}
                    /></td>
                </tr>
                    //{formconcepts}
            );
            let feedback_if_not_found_exists = (this.props.inputradioanswer.options[i].feedback_if_not_found===undefined)?false:true
            let feedback_if_not_found = feedback_if_not_found_exists?this.props.inputradioanswer.options[i].feedback_if_not_found:create_empty_FeedbackObject();
            let feedback_if_found_exists = (this.props.inputradioanswer.options[i].feedback===undefined)?false:true
            let feedback_if_found = feedback_if_found_exists?this.props.inputradioanswer.options[i].feedback:create_empty_FeedbackObject();
            formrows.push(
                <tr key={(2*i)+1}><td colSpan={this.props.inputradioanswer.options[0].concept.length+2}>
                    <label
                        style={{marginLeft:"3em"}}
                        >Feedback if selected:
                        <textarea
                            cols={Math.min(80,(feedback_if_found.text.length||0)+3)}
                            rows={1}
                            name={JSON.stringify({
                                name:'MC'+i+'Feedback',
                                fieldindex:this.props.fieldindex,
                                optioninstance:i,
                            })}
                            //defaultValue={this.props.inputradio.options[i].feedback}
                            value={this.props.inputradioanswer.options[i].feedback.text}
                            onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,i,this.props.type,undefined,"found")}
                        />
                    </label>
                    <br/>
                    <label
                        hidden={!feedback_if_not_found_exists||this.props.type==="radio"}
                        style={{marginLeft:"3em"}}
                        //onClick={(e)=>{e.preventDefault();this.toggleShowFeedback(this.props.mp,i)}}>
                        >Feedback if not:
                        <textarea
                            cols={Math.min(80,(feedback_if_not_found.text.length||0)+3)}
                            rows={1}
                            name={JSON.stringify({
                                name:'MC'+i+'Feedback',
                                fieldindex:this.props.fieldindex,
                                optioninstance:i,
                            })}
                            value={feedback_if_not_found.text||''}
                            onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,i,this.props.type,undefined,"notfound")}
                        />
                    </label>
                            
                </td></tr>
            );
        }
        var conceptheaders = [];//'Concept 1', 'Concept 2' etc will be stored here to allow rendering of variable length
        for(let c=0;c<this.props.inputradioanswer.options[0].concept.length;c++){//TODO is the [0] here legit?
            conceptheaders[c]=<th key={c}>{'Concept '+(c+1)}</th>
        }
        return(
            <div>
                <p>{this.props.type==="input_self_mark"?"Self mark (SM)":this.props.type==="checkbox"?"Multiple Choice (MC)":this.props.type==="input_hybrid"?"Hybrid":this.props.type==="radio"?"Radio - this is not good":"Error unknown field type"}</p>
                <table>
                <thead>
                    <tr>
                        <th> &#10004;  </th>
                        <th>{this.props.type==="input_numerical_twopart"||"input_text_long"?"Search Terms":"Multiple Choice Options"}</th>
                        {/*conceptheaders*/}
                    </tr>
                </thead>
                <tbody>
                    {formrows}
                </tbody>
                </table>
                <button 
                    name={JSON.stringify({
                        fieldindex:this.props.fieldindex,
                    })}
                    onClick={(e)=>{e.preventDefault();this.props.addoption({fieldindex:this.props.fieldindex},this.props.type,false)}}
                    >Add option
                </button>
                <button 
                    name={JSON.stringify({
                        fieldindex:this.props.fieldindex,
                    })}
                    onClick={(e)=>{e.preventDefault();this.props.addoption({fieldindex:this.props.fieldindex},this.props.type,true)}}
                    >Delete option
                </button>
                <button
                    hidden = {(this.props.type==="radio"||get_checkbox_score(this.props.inputradioanswer,0)>1)}
                    onClick={(e)=>{e.preventDefault();this.props.toggleMultiSelect(this.props.fieldindex)}}
                    >{(this.props.inputradio.oneOptionOnly||false)?"enable multi-select":"disable multi-select"}
                </button>
            </div>
        )//this.props.addoption(this.props.fieldindex) //*** */this 0 needs to change when there are more radio buttons
            //this above is only to be called when a button is pressed.
            
    }
}
class Conceptselector extends React.Component{//This will be used in a filter in the question manager/worksheet manager eventually
    props!:{
        concepts:Concept[],
        selectedconcept_ID:number,
        fieldindex:number,
        optioninstance:number,
        conceptinstance:number,
        mp?:number,
        onChangeoption_concept:(e:any,fieldindex:number,optioninstance:number,conceptinstance:number,mp?:number)=>void,
    }
    static defaultProps = {
        fieldindex:0,
        optioninstance:0,
        conceptinstance:0,
    }
    render(){
        return(
        <select 
            key={this.props.conceptinstance} 
            onChange={(e)=>this.props.onChangeoption_concept(e,this.props.fieldindex,this.props.optioninstance,this.props.conceptinstance)} 
            name={JSON.stringify({
                name:'MC'+this.props.optioninstance+'Concept',
                fieldindex:this.props.fieldindex,
                optioninstance:this.props.optioninstance,
                conceptinstance:this.props.conceptinstance,
            })}
            value={this.props.selectedconcept_ID} 
        >
            <option></option>
            {
                this.props.concepts.map(elt =>
                    <option 
                        value={elt.C_ID} 
                        key={elt.C_ID}
                    >{elt.C_short}
                    </option>)
            }
        </select>
        )
    }
}
/*
class Wordcreatorcomponent extends React.Component{
    props!:{
        inputradio:InputText,
        inputradioanswer:InputTextWordAnswer,
        fieldindex:number,
        concepts:Concept[],
        addoption:                  (index:{fieldindex:number,optionindex?:number,mp?:number},type:string,remove:boolean)=>void,
        onChangeoption_text:        (e:any,fieldindex:number,optioninstance:number,type:string)=>void,
        onChangeoption_concept:     (e:any,fieldindex:number,optioninstance:number,conceptinstance:number)=>void,
        onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:string)=>void,
        onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:string)=>void,
        type:string
    }
    //static defaultProps = {
        //type:"radio",
    //}
    render(){
        console.log("inputradioanswer: "+JSON.stringify(this.props.inputradioanswer,null,4));

        var formrows = [];//each element will hold an option row html element
        for(let i = 0; i < this.props.inputradioanswer.options.length; i++) {// this is a component to render what already exists
            var formconcepts = [];//each element will hold a concept html element
            //var defaultfeedback = "";//unsure about this***
            for(let j = 0; j < this.props.inputradioanswer.options[i].concept.length; j++) {
                formconcepts.push(
                    <td key={j}>
                        <Conceptselector 
                            concepts={this.props.concepts} 
                            selectedconcept_ID={this.props.inputradioanswer.options[i].concept[j]} 
                            optioninstance={i}
                            fieldindex={this.props.fieldindex}
                            onChangeoption_concept={this.props.onChangeoption_concept}
                            conceptinstance={j}
                        />
                    </td>
                );
                //<select onchange="myFunction()"> setstate({this.state.draftquestion.options[{i}].feedback:()})
                //feedback should probably be an array? if someone 
                //defaultfeedback += this.props.concept[j].C_long; //havn't inputted any concepts yet
                //defaultfeedback += this.state.draftquestion.options[i].feedback //this won't have been set yet
            }
            //console.log("fieldindex: "+this.props.fieldindex);
            formrows.push(
                <tr key={(2*i)}>
                    <td><input //TODO Change this to a select to match longanswer
                        type={(this.props.type==="radio"?'radio':'checkbox')}
                        //checked={this.props.inputradioanswer.options[i].iscorrect} //defaultChecked={i==0? true:false}
                        checked={this.props.inputradioanswer.options[i].scoring==="good"} //defaultChecked={i==0? true:false}
                        name={"correctans"+(this.props.type==="radio"?'':i)}//{JSON.stringify({
                            //name:"correctans",
                            //fieldindex:this.props.fieldindex,
                            //optioninstance:i,
                        //})}//A bit dodgy that the radio buttons have different names even though they are in same radio
                        //TODO put information into the onChange request with an arrow function
                        //Add +(type==="radio"?'':i) to the name to make them different for checkbox
                        value={'MC'+i} //TODO** onChangeoption_iscorrect needs updating for checkbox
                        onChange={(e)=>this.props.onChangeoption_iscorrect(e,this.props.fieldindex,i,this.props.type)}/>
                    </td>
                    <td><input 
                        type="text" 
                        name={"["+this.props.fieldindex+","+i+"]"} 
                        //key={this.props.fieldindex}
                        value={this.props.inputradioanswer.options[i].text}
                        onChange={(e)=>this.props.onChangeoption_text(e,this.props.fieldindex,i,this.props.type)}
                    /></td>
                    {formconcepts}
                </tr>
            );
            formrows.push(
                <tr key={(2*i)+1}><td colSpan={this.props.inputradioanswer.options[0].concept.length+2}>
                    <label>
                        Feedback: 
                        <input 
                            type="text" 
                            size={75}
                            name={JSON.stringify({
                                name:'MC'+i+'Feedback',
                                fieldindex:this.props.fieldindex,
                                optioninstance:i,
                            })}
                            //defaultValue={this.props.inputradio.options[i].feedback}
                            //value={this.props.inputradioanswer.options[i].feedback.text}
                            value={"Wordcreatorcomponent is obscelete, old line is above this in code"}
                            onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,i,this.props.type)}
                        />
                    </label>
                </td></tr>
            );
        }
        var conceptheaders = [];//'Concept 1', 'Concept 2' etc will be stored here to allow rendering of variable length
        for(let c=0;c<this.props.inputradioanswer.options[0].concept.length;c++){
            conceptheaders[c]=<th key={c}>{'Concept '+(c+1)}</th>
        }
        return(
            <div>
                <table>
                <thead>
                    <tr>
                        <th> &#10004;  </th>
                        <th>{this.props.type==="input_text_word"||"input_text_long"?"Search Terms":"Multiple Choice Options"}</th>
                        {conceptheaders}
                    </tr>
                </thead>
                <tbody>
                    {formrows}
                </tbody>
                </table>
                <button 
                    name={JSON.stringify({
                        fieldindex:this.props.fieldindex,
                    })}
                    onClick={(e)=>{e.preventDefault();this.props.addoption({fieldindex:this.props.fieldindex},this.props.type,false)}}
                    >Add option
                </button>
            </div>
        )//this.props.addoption(this.props.fieldindex) //*** this 0 needs to change when there are more radio buttons
            //this above is only to be called when a button is pressed.
            
    }
}
*/
class Longtextcreatorcomponent extends React.Component{
    props!:{
        inputlonganswer:InputTextLongAnswer,
        fieldindex:number,
        long_text_analysis:LongTextAnalysis|undefined|null,
        //concepts:Concept[],
        onChangeMPMax:              (value:any,fieldindex:number,mp:number)=>void,
        //onChangeoption_concept:     (e:any,fieldindex:number,optioninstance:number,conceptinstance:number,mp:number)=>void,
        onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number)=>void,
        //removeOption:               (fieldindex:number,filterindex:number)=>void,
        modifyMarkingPoint:         (fieldindex:number,modType:string,mp?:number)=>void,
        generateKeyTerms:           (index:{fieldindex:number,mp:number})=>void,
        type:FieldType,
        FilterControlsfn:           FilterControlFunctions,
        //    modifyFilter:               (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
        //    onThesaurusAction:          (index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"})=>void,
        //    toggleChildren:          (param:{fieldindex:number,mp:number,filterindex:number,mode?:string,condition?:string})=>void,
        //    getStructuralName:          (index:{fieldindex:number,filterindex:number,mp:number})=>void,
        FilterEditterfn:            FilterEditterFunctions,
        //    addTerm:                    (e: React.MouseEvent<HTMLButtonElement>,index:{ANDindex:number,fieldindex:number,mp:number,keytermindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
        //    addoption:                  (index:{fieldindex:number,optionindex?:number,mp:number},type:string,remove:boolean)=>void,
        //    modifyAND:                  (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT
        //    onChangeoption_text:        (e:any,fieldindex:number,optioninstance:number,type:string,mp:number)=>void,
        //    onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:string,mp:number,showwhen:FoundNotFound)=>void,
        //    onChangekeyterm:            (e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,keytermindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType})=>void,
        //    onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:string,priority?:number})=>void, 
    }
    render(){
        let mpelements = [];
        for (let mp=0; mp<this.props.inputlonganswer.markingpoints.length;mp++){
        //    console.log("mp: "+mp);
            mpelements.push(
                <div key={mp}>
                    <div hidden={this.props.inputlonganswer.markingpoints.length==1}>
                        <p>Marking point {mp+1}</p>
                        <label>
                            Max score: 
                            <select 
                                onChange={(e)=>this.props.onChangeMPMax(e.target.value,this.props.fieldindex,mp)}
                                name={"maxfieldscore"+this.props.fieldindex}
                                value={25}//{this.props.inputlonganswer.markingpoints[mp].maxscore} 
                                >
                                {//set options
                                    [-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,25].map(elt =>
                                        <option 
                                        value={elt} 
                                        key={elt}
                                        >{elt}
                                        </option>)
                                }
                            </select>
                        </label>
                        <button onClick={(e)=>{e.preventDefault();console.log("delete mp");this.props.modifyMarkingPoint(this.props.fieldindex,"delete",mp)}}>delete marking point</button>
                        <button onClick={(e)=>{e.preventDefault();console.log("copy mp");this.props.modifyMarkingPoint(this.props.fieldindex,"copy",mp)}}>copy marking point</button>
                        <button 
                                onClick={(e)=>{
                                    e.preventDefault();
                                    console.log("moveup mp");
                                    this.props.modifyMarkingPoint(this.props.fieldindex,"moveup",mp)
                                }}
                                name="Move up"
                                style={{minWidth:"20px", minHeight:"20px"}}
                                >&#9651;
                        </button>
                        <button 
                                onClick={(e)=>{
                                    e.preventDefault();
                                    console.log("movedown mp");
                                    this.props.modifyMarkingPoint(this.props.fieldindex,"movedown",mp)
                                }}
                                name="Move down"
                                style={{minWidth:"20px", minHeight:"20px"}}
                                >&#9661;
                        </button>
                    </div>
                    <LongtextMPcreatorcomponent 
                        mp = {mp}
                        inputlonganswer={this.props.inputlonganswer}
                        fieldindex={this.props.fieldindex} 
                        key={mp}
                    //    concepts={this.props.concepts}
                        onChangeMPMax={this.props.onChangeMPMax}
                        onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                        type={this.props.type}
                        generateKeyTerms={this.props.generateKeyTerms}
                        FilterControlsfn={this.props.FilterControlsfn}
                        FilterEditterfn={this.props.FilterEditterfn}
                        long_text_analysis={this.props.long_text_analysis}
                    //    addoption={this.props.addoption} 
                    //    modifyFilter={this.props.modifyFilter}
                    //    modifyAND={this.props.modifyAND}
                    //    onChangeoption_text={this.props.onChangeoption_text}
                    //    onChangeoption_concept={this.props.onChangeoption_concept}
                    //    onChangeoption_feedback={this.props.onChangeoption_feedback}
                    //    onChangekeyterm={this.props.onChangekeyterm}
                    //    addTerm={this.props.addTerm}
                    //    onThesaurusAction ={this.props.onThesaurusAction}
                    //    toggleChildren ={this.props.toggleChildren}
                    //    getStructuralName ={this.props.getStructuralName}
                        //field_desc={this.props.field_elements.field_desc[i]}
                        //removeOption={this.props.removeOption}
                    />
                </div>
            );//push
    //        console.log("mp: "+mp+" should be the same as above");
        }//for each mp
        return(
            <span>
                {mpelements}
                <button 
                    hidden={true}
                    onClick={(e)=>{
                        e.preventDefault();
    //                    console.log("+ marking point pressed");
                        this.props.modifyMarkingPoint(this.props.fieldindex,"addnew")}
                    }
                    >+ marking point
                </button>
                <FeedbackList 
                    longanswer={this.props.inputlonganswer} 
                    fieldindex={this.props.fieldindex}
                    onSetFeedbackType={this.props.FilterEditterfn.onSetFeedbackType}
                    onChangeoption_feedback={this.props.FilterEditterfn.onChangeoption_feedback}
                    toggleIsFloaty={this.props.FilterControlsfn.toggleIsFloaty}
                />
            </span>
        )//return
    }//render
}
class LongtextMPcreatorcomponent extends React.Component{
    props!:{
        mp:number,
        inputlonganswer:InputTextLongAnswer,
        fieldindex:number,
        long_text_analysis:LongTextAnalysis|undefined|null,
    //    concepts:Concept[],
        onChangeMPMax:              (value:any,fieldindex:number,mp:number)=>void,
        generateKeyTerms:           (index:{fieldindex:number,mp:number})=>void,
        //    onChangeoption_concept:     (e:any,fieldindex:number,optioninstance:number,conceptinstance:number,mp:number)=>void,
        onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number)=>void,
        //    removeOption:               (fieldindex:number,filterindex:number)=>void,
        type:FieldType
        FilterControlsfn:           FilterControlFunctions,
        //    modifyFilter:               (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
        //    onThesaurusAction:          (index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"})=>void,
        //    toggleChildren:          (param:{fieldindex:number,mp:number,filterindex:number,mode?:string,condition?:string})=>void,
        //    getStructuralName:          (index:{fieldindex:number,filterindex:number,mp:number})=>void,
        FilterEditterfn:            FilterEditterFunctions,
        //    addTerm:                    (e:React.MouseEvent<HTMLButtonElement>,index:{ANDindex:number,fieldindex:number,mp:number,keytermindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
        //    addoption:                  (index:{fieldindex:number,optionindex?:number,mp:number},type:string,remove:boolean)=>void,
        //    modifyAND:                  (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT
        //    onChangeoption_text:        (e:any,fieldindex:number,optioninstance:number,type:string,mp:number)=>void,
        //    onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:string,mp:number,showwhen:FoundNotFound)=>void,
        //    onChangekeyterm:            (e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,keytermindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType})=>void,
    }
    //static defaultProps = {
        //type:"radio",
    //}
/*    state:{
        showFeedback:boolean[]
    }
    constructor(props){
        super(props);
        this.state={
            showFeedback:new Array(this.props.inputlonganswer.markingpoints[this.props.mp].filters.length).fill(false)
        }
    }
    toggleShowFeedback(mp:number,st:number){

    }*/
    render(){
            let filterrows = [];//each element will hold a filter
            for(let s = 0; s < this.props.inputlonganswer.markingpoints[this.props.mp].filters.length; s++) {// this is a component to render what already exists
                //Each filter
                let filter = this.props.inputlonganswer.markingpoints[this.props.mp].filters[s];
                if (filter.hidden===true)//||filter.perm_hidden===true)
                    {continue}
                if(filter.displaynameonly===true||(filter.auto_generated&&filter.displaynameonly!==false)){
                    filterrows.push(
                        <tr key={s}><td colSpan={2}><p className="filtername"
                            onClick={(e)=>{e.preventDefault();this.props.FilterControlsfn.toggleDisplayFilter(
                                {
                                    filter,
                                    mp:this.props.mp,
                                    filterindex:s,
                                    fieldindex:this.props.fieldindex,
                                }   
                            )}}>{filter.name}
                        </p></td></tr>
                    )
                }else{
                    let [filter_found_info, tokenised_answer] = getfilterfoundinfo(this.props.long_text_analysis,filter);
                    filterrows.push(
                        <FilterComponent
                            key={s}
                            filterinfo={{
                                filter,
                                mp:this.props.mp,
                                filterindex:s,
                                fieldindex:this.props.fieldindex,
                                filter_found_info,
                            }}
                            tokenised_answer={tokenised_answer}
                            type="input_text_long"//{this.props.type}
                        //    addTerm={this.props.addTerm}          
                        //    onChangekeyterm={this.props.onChangekeyterm}
                        //    addoption={this.props.addoption}
                        //    modifyAND={this.props.modifyAND}
                            FilterControlsfn={this.props.FilterControlsfn}
                            fn={this.props.FilterEditterfn}
                        //    onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                           //    onChangeoption_concept={this.props.onChangeoption_concept}
                        />
                    );
                }
            }
            
        var conceptheaders = [];//'Concept 1', 'Concept 2' etc will be stored here to allow rendering of variable length
        if(this.props.inputlonganswer.markingpoints[0].filters.length>0){ //otherwise keystatements[0] is undefined and we get an error.
            for(let c=0;c<this.props.inputlonganswer.markingpoints[0].filters[0].concept.length;c++){
                conceptheaders[c]=<th key={c}>{'Concept '+(c+1)}</th>
            }
        }
        return(
            <div className="LongtextMPcreatorcomponent">
                <p>{field_type_desc_names_public[4]}</p>
                <table style={{textAlign:"left"}/*, width:"100%"*/}>
                <thead hidden={true}>
                    <tr>
                        <th> &#10004;  </th>
                        <th>{"Filters"/*this.props.type==="input_text_word"||"input_text_long"?"Search Terms":"Multiple Choice Options"*/}</th>
                        {//conceptheaders
                        }
                    </tr>
                </thead>
                <tbody style={{verticalAlign:"text-top"}}>
                    {filterrows}
                </tbody>
                </table>
                <button
                    name={JSON.stringify({
                        fieldindex:this.props.fieldindex,
                    })}//TODO: don't need a complex name like this
                    onClick = {(e)=>{e.preventDefault();this.props.FilterControlsfn.modifyFilter({fieldindex:this.props.fieldindex,mp:this.props.mp},"addnew")}}
                    style={{
                        background: "#d3ffcf",
                        borderColor: "grey",
                        borderWidth: "medium",
                        width: "40%",
                        fontSize: "large",
                        display: "block",
                        }}>Add New Filter
                </button>
                <br/>
                <button
                     style={{
                        background: "#daa4fa",
                        borderColor: "grey",
                        borderWidth: "medium",
                        width: "40%",
                        fontSize: "large",
                        display: "block",
                        }}
                    name="generateKeyTerms"
                    onClick={(e)=>{e.preventDefault();this.props.generateKeyTerms({fieldindex:this.props.fieldindex,mp:this.props.mp})}}
                    >Generate Auto Filters
                </button>
                <span 
                    hidden={this.props.inputlonganswer.markingpoints[this.props.mp].isvalid!==false} 
                    className="adminErrorMsg"
                    >{this.props.inputlonganswer.markingpoints[this.props.mp].errormessage}
                </span>
                <span hidden={this.props.inputlonganswer.markingpoints[this.props.mp].hidden}>
                {/*}    <Keytermsinput
                        inputlonganswer={this.props.inputlonganswer}
                        fieldindex={this.props.fieldindex}
                        onChangekeyterm={this.props.onChangekeyterm}
                        addTerm={this.props.addTerm}
                        removeOption={this.props.removeOption}
                        mp={this.props.mp}
                />*/}
                </span>
            </div>
        )//this.props.addoption(this.props.fieldindex) //*** */this 0 needs to change when there are more radio buttons
            //this above is only to be called when a button is pressed.
    }
}
function getfilterfoundinfo(long_text_analysis:LongTextAnalysis|null|undefined, filter:FilterAll):[FilterPosition|undefined,string[]|undefined]{
    let filter_found_info = undefined;
    let tokenised_answer = undefined;
    if(long_text_analysis!==undefined&&long_text_analysis!==null){
        let filters_found = long_text_analysis.filters_found;
        if(filters_found!==undefined){
            filter_found_info = (long_text_analysis.filters_found as FilterPosition[]).find(
                //(f)=>f.inQFID===filter.inQFID
                (f)=>f.name===filter.name
                )
        }
        tokenised_answer = long_text_analysis.tokenised_answer;
    }
    return [filter_found_info, tokenised_answer]
}
interface FilterEditterFunctions {
    addTerm:                    (e:React.MouseEvent<HTMLButtonElement>,index:{ANDindex:number,fieldindex:number,mp:number,filterindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
    addoption:                  (index:{fieldindex:number,optionindex?:number,mp:number},type:FieldType,remove:boolean)=>void,
    //    modifyFilter:               (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
    modifyAND:                  (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT
    //onChangeoption_text:		(e:any,fieldindex:number,optioninstance:number,type:string,mp?:number)=>void,
    onChangeoption_text:		(value:string,fieldindex:number,optioninstance:number,type:FieldType,mp?:number)=>void,
    onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number,showwhen:FoundNotFound)=>void,
    onChangekeyterm:            (e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,filterindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType})=>void,
    onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:FeedbackType,priority?:number})=>void, 
}
class FilterComponent extends React.Component{
    props!:{
        filterinfo:FilterInfo,
    //    inFloatingFilter:boolean,//is this FilterComponent the one actually in the floaty div
    //    mp:number,
    //    fieldindex:number,
    //    filterindex:number,
        tokenised_answer?:   string[]|undefined,
        type:FieldType,
        FilterControlsfn:           FilterControlFunctions,
        fn:                         FilterEditterFunctions,
    
    //    onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:string,mp:number)=>void,
        
    //    onChangeoption_concept:     (e:any,fieldindex:number,optioninstance:number,conceptinstance:number,mp:number)=>void,
    }
    state:{
        showingfinds:boolean
    }
    constructor(props: FilterComponent['props']){
        super(props);
        this.state={
            showingfinds:false,
        }
    }
    render(){
        let filter = this.props.filterinfo.filter;
        let fi = this.props.filterinfo;
        if(filter===undefined||filter===null){
            return <p>Filter lost</p>
        }
        let filter_found = this.props.filterinfo.filter_found_info;
        console.log("rendering filter with filter_found: "+JSON.stringify(filter_found,null,4));
        let finds=[];
        if(filter_found!==undefined&&this.props.tokenised_answer!==undefined){
            let array_of_finds = getArrayOfFinds(this.props.tokenised_answer,filter_found.word_position_original);
            for(let i = 0 ; i<array_of_finds.length; i++){
                finds.push(
                    <li>{array_of_finds[i]}</li>
                )
            }
        }
        let filterfindscomponent = <div>
            <button
                hidden = {this.state.showingfinds}
                onClick={(e)=>{e.preventDefault();this.setState({showingfinds:true})}}>
                show num finds
            </button>
            <button
                hidden = {!this.state.showingfinds}
                onClick={(e)=>{e.preventDefault();this.setState({showingfinds:false})}}>
                hide finds
            </button>
            <ul
                hidden = {!this.state.showingfinds}>
                {finds}
            </ul>
        </div>
        function getArrayOfFinds(tokenised_answer:string[],word_position_original:number[][]):string[]{
            let array_of_finds:string[] = [];
            for(let f = 0 ; f<word_position_original.length; f++){
                array_of_finds[f] = "";
                for( let p = 0; p<word_position_original[f].length; p++){
                    array_of_finds[f] += (tokenised_answer[word_position_original[f][p]]+" ");
                }
            }
            return array_of_finds;
        }
    //    var formconcepts = [];  //each element will hold a concept html element  
    //    for(let j = 0; j < filter.concept.length; j++) {
    //        //Each concept
    //        formconcepts.push(
    //            <td key={j}>
    //            <Conceptselector 
    //                concepts={this.props.concepts} 
    //                selectedconcept_ID={filter.concept[j]} 
    //                optioninstance={s}
    //                fieldindex={this.props.fieldindex}
    //                onChangeoption_concept={(e,fieldindex,optioninstance,conceptinstance)=>this.props.onChangeoption_concept(e,fieldindex,optioninstance,conceptinstance,this.props.mp)}
    //                conceptinstance={j}
    //                />
    //            </td>
    //        );
        //<select onchange="myFunction()"> setstate({this.state.draftquestion.options[{i}].feedback:()})
        //feedback should probably be an array? if someone 
        //defaultfeedback += this.props.concept[j].C_long; //havn't inputted any concepts yet
        //defaultfeedback += this.state.draftquestion.options[i].feedback //this won't have been set yet
    //      }
        //console.log("fieldindex: "+this.props.fieldindex);
        let NANDs = [];
        if (filter.canNAND===false&&(filter.ANDs.length>1||filter.ANDNOTs.length>0||filter.NOTOVERLAPs.length>0)){//||filter.ALSOs.length>0)){
            console.log( "trying to render multiple ANDs or a NOT for a canNAND=false filter "+filter.name);
            filter.canNAND=true; //TODO, this is dodgy af
        }
        let NANDTypes:NANDType[] = ["AND","ANDNOT","NOTOVERLAP","ALSO"];
        let NANDTypesplural:("ANDs"|"ANDNOTs"|"NOTOVERLAPs"|"ALSOs")[] = ["ANDs","ANDNOTs","NOTOVERLAPs","ALSOs"];
        for (let nandtype = 0; nandtype<NANDTypesplural.length; nandtype++){
            for (let and = 0 ; and<filter[NANDTypesplural[nandtype]].length; and++ ){
                NANDs.push(
                    <KeyStatementsSynonyms
                        key={NANDTypes[nandtype]+and}//don't think I need anything except the and now, other stuff is legacy from old position
                            //""+this.props.filterindex+
                        mp={fi.mp}
                        fieldindex={fi.fieldindex}
                        filterindex={fi.filterindex}
                    //    ORs={filter.ANDs[and]}
                        ORs={filter[NANDTypesplural[nandtype]][and]}
                    //    type={this.props.type}
                        addTerm={this.props.fn.addTerm}          
                        onChangekeyterm={this.props.fn.onChangekeyterm}
                        addoption={this.props.fn.addoption}
                        ANDindex={and}
                        modifyAND={this.props.fn.modifyAND}
                    //    isNOT={false}
                    //    nandtype={"AND"}
                        nandtype={NANDTypes[nandtype]}
                        canNAND={filter.canNAND}
                    />)
            }
        }
        {
    //    for (let and = 0 ; and<filter.ANDs.length; and++ ){
    //        ANDs.push(
    //            <KeyStatementsSynonyms
    //                mp={this.props.mp}
    //                fieldindex={this.props.fieldindex}
    //                filterindex={s}
    //                ORs={filter.ANDs[and]}
    //            //    type={this.props.type}
    //                key={3*and}//don't think I need anything except the and now, other stuff is legacy from old position
    //                addTerm={this.props.addTerm}          
    //                onChangekeyterm={this.props.onChangekeyterm}
    //                addoption={this.props.addoption}
    //                ANDindex={and}
    //                modifyAND={this.props.modifyAND}
    //            //    isNOT={false}
    //                nandtype={"AND"}
    //                canNAND={filter.canNAND}
    //            />)
    //    }
    //    for (let and = 0 ; and<filter.ANDNOTs.length; and++ ){
    //        ANDs.push(
    //            <KeyStatementsSynonyms
    //                mp={this.props.mp}
    //                fieldindex={this.props.fieldindex}
    //                filterindex={s}
    //                ORs={filter.ANDNOTs[and]}
    //                type={this.props.type}
    //                key={(and*3)+1}//don't think I need anything except the and now, other stuff is legacy from old position
    //                addTerm={this.props.addTerm}          
    //                onChangekeyterm={this.props.onChangekeyterm}
    //                addoption={this.props.addoption}
    //                ANDindex={and}
    //                modifyAND={this.props.modifyAND}
    //                //isNOT={true}
    //                nandtype={"ANDNOT"}
    //                canNAND={filter.canNAND}
    //            />
    //        )
    //    }
    //    for (let and = 0 ; and<filter.NOTOVERLAPs.length; and++ ){
    //        ANDs.push(
    //            <KeyStatementsSynonyms
    //                ORs={filter.NOTOVERLAPs[and]}
    //                nandtype={"NOTOVERLAP"}
    //                mp={this.props.mp}
    //                fieldindex={this.props.fieldindex}
    //                filterindex={s}
    //                type={this.props.type}
    //                key={(and*3)+2}//don't think I need anything except the and now, other stuff is legacy from old position
    //                addTerm={this.props.addTerm}          
    //                onChangekeyterm={this.props.onChangekeyterm}
    //                addoption={this.props.addoption}
    //                ANDindex={and}
    //                modifyAND={this.props.modifyAND}
    //                //isNOT={true}
    //                canNAND={filter.canNAND}
    //            />
    //        )
    //    }
    //    for (let and = 0 ; and<filter.ALSOs.length; and++ ){
    //        ANDs.push(
    //            <KeyStatementsSynonyms
    //                ORs={filter.ALSOs[and]}
    //                nandtype={"ALSO"}
    //                mp={this.props.mp}
    //                fieldindex={this.props.fieldindex}
    //                filterindex={s}
    //                type={this.props.type}
    //                key={(and*3)+2}//don't think I need anything except the and now, other stuff is legacy from old position
    //                addTerm={this.props.addTerm}          
    //                onChangekeyterm={this.props.onChangekeyterm}
    //                addoption={this.props.addoption}
    //                ANDindex={and}
    //                modifyAND={this.props.modifyAND}
    //                //isNOT={true}
    //                canNAND={filter.canNAND}
    //            />
    //        )
    //    }
    
        
//        formrows.push(
 //           <tr key={(3*s)+1}>
 //               {//<td colSpan={this.props.inputlonganswer.markingpoints[this.props.mp].filters[0].concept.length+2}>
 //               }
 //               <td>        
 //                   
 //               </td>
 //           </tr>
 //       );
 //       formrows.push(
 //           <tr key={(3*s)+2}>
 //               <td colSpan={this.props.inputlonganswer.markingpoints[this.props.mp].filters[0].concept.length+2}>
 //                   
 //               </td>
 //           </tr>
 //       );
        }
        return (
            <tr key={(3*fi.filterindex)}
            className="FilterComponent">
                <td key="FilterControls">
                <div className="FilterComponentLeft">

                    <FilterControls
                    //    onChangeoption_iscorrect={this.props.onChangeoption_iscorrect}
                    filterinfo = {this.props.filterinfo}
                    //    filterindex = {fi.filterindex}
                    //    fieldindex = {fi.fieldindex}
                    //    mp = {fi.mp}
                    //    filter = {filter}
                    type = "input_text_long"
                    //    removeOption={this.props.removeOption}
                    fn={this.props.FilterControlsfn}
                    //    modifyFilter={this.props.modifyFilter}
                    //    onThesaurusAction={this.props.onThesaurusAction}
                    //    toggleChildren={this.props.toggleChildren}
                    //    getStructuralName={this.props.getStructuralName}
                    />
                    
                    </div>
                </td>
                <td key={"bodyoffilter"+fi.filterindex}
                    >
                    <div className="FilterComponentRight">

                        
                        <textarea 
                            className="filternametextarea"
                            placeholder="Filter name e.g. 'gave definition for frequency'"
                            //type="text" 
                            cols={filter.name.length+5}
                            rows={1}
                            name={"["+fi.fieldindex+","+fi.filterindex+"]"} 
                            //key={this.props.fieldindex}
                            value={filter.name}
                            onChange={(e)=>{this.props.fn.onChangeoption_text(e.target.value,fi.fieldindex,fi.filterindex,this.props.type,fi.mp)}}
                        />
                        <FeedbackInput
                            filter={filter}
                            fieldindex={fi.fieldindex}
                            filterindex={fi.filterindex}
                            onChangeoption_feedback = {this.props.fn.onChangeoption_feedback}
                            onSetFeedbackType = {this.props.fn.onSetFeedbackType}
                            mp={fi.mp}
                            type={this.props.type}
                        />
                        {NANDs}
                        <AddANDNOTButtons
                            mp={fi.mp}
                            fieldindex={fi.fieldindex}
                            filterindex={fi.filterindex}
                            type={this.props.type}
                            modifyAND={this.props.fn.modifyAND}    
                        //    NOTexists={filter.ANDNOTs.length>0}
                            canNAND={filter.canNAND}
                        />
                        <div>{filterfindscomponent}</div>
                    </div>
                </td>

                {//formconcepts
                }
            </tr>
        )
    }
}
class AddANDNOTButtons extends React.Component{
    props!:{
        mp:number,
        fieldindex:number,
        filterindex:number,
        canNAND:boolean,
        type:FieldType,
    //    NOTexists:boolean,
        modifyAND:      (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT    
    }
    render(){
        let s = this.props.filterindex;
        return(
            <span>
                <button
                //    hidden = {!this.props.canNAND}
                    key = {'addAND'}
                    onClick = {(e)=>{e.preventDefault();this.props.modifyAND(
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:this.props.filterindex,
                            ANDindex:-1,
                            //NOT:false
                            nandtype:"AND"
                        },"add")
                    }}
                    >+AND
                </button>
                <button
                //    hidden = {!this.props.canNAND}//{this.props.NOTexists||!this.props.canNAND}
                    key = {'addNOT'}
                    onClick = {(e)=>{e.preventDefault();this.props.modifyAND(
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:this.props.filterindex,
                            ANDindex:-1,
                            //NOT:true
                            nandtype:"ANDNOT"
                        },"add")
                    }}
                    >+NOT
                </button>
                <button
                //    hidden = {!this.props.canNAND}//{this.props.NOTexists||!this.props.canNAND}
                    key = {'addNOTOVERLAP'}
                    onClick = {(e)=>{e.preventDefault();this.props.modifyAND(
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:this.props.filterindex,
                            ANDindex:-1,
                            //NOT:true
                            nandtype:"NOTOVERLAP"
                        },"add")
                    }}
                    >+NOTOVERLAP
                </button>
                <button
                    //hidden = {!this.props.canNAND}//{this.props.NOTexists||!this.props.canNAND}
                    key = {'addALSO'}
                    onClick = {(e)=>{e.preventDefault();this.props.modifyAND(
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:this.props.filterindex,
                            ANDindex:-1,
                            //NOT:true
                            nandtype:"ALSO"
                        },"add")
                    }}
                    >+ALSO
                </button>

            </span>
        )
    }
}
interface FilterControlFunctions {
    modifyFilter:               (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
    onThesaurusAction:          (index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"})=>void,
    toggleChildren:             (param:{fieldindex:number,mp:number,filterindex:number,mode?:string,condition?:string})=>void,
    getStructuralName:          (index:{fieldindex:number,filterindex:number,mp:number,uniqueparams?:boolean})=>void,
    onChangeoption_iscorrect:   (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number)=>void,
    toggleIsFloaty:             (filterinfo:FilterInfo)=>void,
    toggleDisplayFilter:        (filterinfo:FilterInfo)=>void,
}
class FilterControls extends React.Component{
    props!:{
        filterinfo:FilterInfo,
    //    filter:Filter,
    //    fieldindex:number,
    //    filterindex:number,
    //    mp:number,
        type:FieldType,
    //    removeOption:               (fieldindex:number,filterindex:number)=>void,
        fn:FilterControlFunctions,
    }
    render(){
        let fi = this.props.filterinfo;
        let s = fi.filterindex;
        return(
            <div className={"FilterControls"}>
                <button
                    key = {'displaystatustoggle'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.toggleDisplayFilter(this.props.filterinfo)}}
                    >_
                </button>
                <select
                    className={"scoreselect"}
                    onChange={(e)=>this.props.fn.onChangeoption_iscorrect(e,fi.fieldindex,s,this.props.type,fi.mp)}
                    value={fi.filter.scoring||""} 
                >
                    {//set options
                        //  ["must","+1","0","-1"].map(elt =>
                        [
                            {   display:"set me",    value:"",     desc:"not set"},
                            {   display:"+",    value:"+1",     desc:"+1 if found, 0 if not found"},
                            {   display:"0",    value:"0",      desc:"0 if found, 0 if not found"},
                            {   display:"-",    value:"-1",     desc:"-1 if found, 0 if not found"}
                        ].map(elt =>
                            <option 
                                value={elt.value} 
                                key={elt.display}
                            >{elt.display}
                            </option>)
                    }
                </select>
            {/*    <button
                    key = {'moveup'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.modifyFilter({fieldindex:this.props.fieldindex,mp:this.props.mp,filterindex:s},"moveup")}}
                    >&#9651;
                </button>
                <button
                    key = {'movedown'}
                    onClick = {(e)=>{e.preventDefault();
                        this.props.fn.modifyFilter(
                            {
                                fieldindex:this.props.fieldindex,
                                mp:this.props.mp,
                                filterindex:s
                            },"movedown")}}
                    >&#9661;
                        </button>        */}  
                        <PopOutButton
                            filterinfo={this.props.filterinfo}
                            toggleIsFloaty={this.props.fn.toggleIsFloaty}
                            />

                              
                <button
                    key = {'removestatement'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.modifyFilter(
                        {
                            fieldindex:fi.fieldindex,
                            mp:fi.mp,
                            filterindex:s,
                        },
                        "remove")
                    }}
                    >&#128465;
                </button>
                <ThesaurusControl
                    filterinfo = {fi}
                //    filterindex = {s}
                //    fieldindex = {this.props.fieldindex}
                //    mp = {this.props.mp}
                //    filter = {this.props.filter}
                    type = {this.props.type}
                    onThesaurusAction={this.props.fn.onThesaurusAction}
                    getStructuralName={this.props.fn.getStructuralName}
                />
                <button
                    key = {'toggleChildren'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.toggleChildren(
                        {
                            fieldindex:fi.fieldindex,
                            mp:fi.mp,
                            filterindex:s,
                            condition:"children",
                        }
                    )}}
                    >kids
                </button>
                <button
                    key = {'toggleParents'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.toggleChildren(
                        {
                            fieldindex:fi.fieldindex,
                            mp:fi.mp,
                            filterindex:s,
                            condition:"parents",
                        }
                    )}}
                    >olds
                </button>
                <button
                    key = {'hide self'}
                    onClick = {(e)=>{e.preventDefault();this.props.fn.toggleChildren(
                        {
                            fieldindex:fi.fieldindex,
                            mp:fi.mp,
                            filterindex:s,
                            condition:"self",
                        }
                    )}}
                    >hide
                </button>
            </div>
        )
    }
}

class PopOutButton extends React.Component{
    props!:{
        toggleIsFloaty:             (filterinfo:FilterInfo)=>void,
        filterinfo:FilterInfo,
    }
    render(){
        return(
            <button
                key = {'popout'}
                onClick = {(e)=>{e.preventDefault();
                    this.props.toggleIsFloaty(this.props.filterinfo)
                }}
                ><svg xmlns="http://www.w3.org/2000/svg" className="ButtonIcon___1AgYV " width="15" height="15" viewBox="0 0 24 18"><path d="M0 0h24v24H0z" fill="none"></path><path fill="currentColor" d="
                    M3 13h2v-2H3v2zm0 4h2v-2H3v2zm2 4v-2H3a2 2 0 0 0 2 2zM3 9h2V7H3v2zm12 12h2v-2h-2v2zm4-18H9a2 2 0 0 0-2
                    2v10a2 2 0 0 0 2 2h10c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12H9V5h10v10zm-8 6h2v-2h-2v2zm-4 0h2v-2H7v2z
                    "></path>
                    </svg>
            </button>
        )
    }
}
    
class ThesaurusControl extends React.Component{
    props!:{
        filterinfo:FilterInfo
    //    filter:Filter,
    //    fieldindex:number,
    //    filterindex:number,
    //    mp:number,
        type:FieldType,
    //    onThesaurusAction:(index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
        onThesaurusAction:(index:{fieldindex:number,filterindex:number,mp:number,action:"fetch"|"edit"|"pull"|"createnew"})=>void,
        getStructuralName:          (index:{fieldindex:number,filterindex:number,mp:number,uniqueparams?:boolean})=>void,
    }
    render(){
        let fi = this.props.filterinfo;
        let filter = fi.filter;
        let buttons:any = [];
        let actions:Array<"fetch"|"edit"|"pull"|"createnew"> = ["fetch","edit","pull","createnew"];
        let undefinedID = (filter.ID===undefined||filter.ID===null);
        let show:boolean[] = [
            undefinedID,//show fetch if ID is undefined, means we havn;t checked db //fetch
            !undefinedID&&filter.ID!==-1,//push
            !undefinedID&&filter.ID!==-1,//pull
            filter.ID===-1,//show createnew if ID is -1, means its not in the db //add
        ]
        let action_labels = ["find","push","pull","add"]//this is just how they appear
        let hidden = show.map(e=>!e);
        actions.forEach((action,a) => {
            buttons.push(
                <button
                    hidden = {fi.filter.matches_thesaurus===true
                        ||hidden[a]}
                    key = {""+action}
                    onClick = {(e)=>{e.preventDefault();
                        this.props.onThesaurusAction(
                            {
                                fieldindex:fi.fieldindex,
                                mp:fi.mp,
                                filterindex:fi.filterindex,
                                action:action
                            })
                    }}
                    >{action_labels[a]}
                </button>
            )
        })
        buttons.push(
            <div
                key="dollarbuttons">
                <button
                //    hidden = {this.props.filter.matches_thesaurus===true
                //       ||hidden[a]}
                    key = {"$1"}
                    onClick = {(e)=>{e.preventDefault();
                        this.props.getStructuralName(
                            {
                                fieldindex:fi.fieldindex,
                                mp:fi.mp,
                                filterindex:fi.filterindex,
                            })
                    }}
                    >{"$"}
                </button>
                <button
                //    hidden = {this.props.filter.matches_thesaurus===true
                //       ||hidden[a]}
                    key = {"$2"}
                    onClick = {(e)=>{e.preventDefault();
                        this.props.getStructuralName(
                            {
                                fieldindex:fi.fieldindex,
                                mp:fi.mp,
                                filterindex:fi.filterindex,
                                uniqueparams:true,
                            })
                    }}
                    >{"$$"}
                </button>
            </div>
        )
        ;
        return(
            <span>
                 {buttons} 
            </span>
        )
    }
}
        
class FeedbackInput extends React.Component{
    props!:{
        filter:FilterAll,
        fieldindex:number,
        filterindex:number,
        onChangeoption_feedback:    (e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number,showwhen:FoundNotFound)=>void,
        onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:FeedbackType,priority?:number})=>void, 
        mp:number,
        type:FieldType,
    }
    render(){
        let s = this.props.filterindex;
        let feedbackObject_found = this.props.filter.feedback_if_found;
        let feedbackObject_notfound = this.props.filter.feedback_if_not_found;
        return(
            <div className="FeedbackInput">
                <label
                    //onClick={(e)=>{e.preventDefault();this.toggleShowFeedback(this.props.mp,i)}}>
                    >F+
                    <textarea
                        className={"feedbacksummarytext "+feedbackObject_found.type}
                        placeholder={"feedback if found"}
                        cols={Math.min(80,this.props.filter.feedback_if_found.text.length+3)}
                        rows={1}
                        name={JSON.stringify({
                            name:'MC'+s+'Feedback',
                            fieldindex:this.props.fieldindex,
                            optioninstance:s,
                        })}
                        value={this.props.filter.feedback_if_found.text}
                        onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,s,this.props.type,this.props.mp,"found")}
                    />
                </label>
                <FeedbackTypeControl
                    onSetFeedbackType={this.props.onSetFeedbackType}
                    mp={this.props.mp}
                    fieldindex={this.props.fieldindex}
                    filterindex={s}
                    showwhen={"found"}
                    type={feedbackObject_found.type}
                />
                <FeedbackPriorityControl
                    onSetFeedbackType={this.props.onSetFeedbackType}
                    mp={this.props.mp}
                    fieldindex={this.props.fieldindex}
                    filterindex={this.props.filterindex}
                    showwhen={"found"}
                    priority={feedbackObject_found.priority}
                />
                <label
                    //onClick={(e)=>{e.preventDefault();this.toggleShowFeedback(this.props.mp,i)}}>
                    >F-
                    <textarea
                        className={"feedbacksummarytext "+feedbackObject_notfound.type}
                        cols={Math.min(80,this.props.filter.feedback_if_not_found.text.length+3)}
                        placeholder={"feedback if NOT found"}
                        rows={1}
                        name={JSON.stringify({
                            name:'MC'+s+'Feedback',
                            fieldindex:this.props.fieldindex,
                            optioninstance:s,
                        })}
                        value={this.props.filter.feedback_if_not_found.text}
                        onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,s,this.props.type,this.props.mp,"notfound")}
                    />
                </label>
                <FeedbackTypeControl
                    onSetFeedbackType={this.props.onSetFeedbackType}
                    mp={this.props.mp}
                    fieldindex={this.props.fieldindex}
                    filterindex={s}
                    showwhen={"notfound"}
                    type={feedbackObject_notfound.type}
                />
                <FeedbackPriorityControl
                    onSetFeedbackType={this.props.onSetFeedbackType}
                    mp={this.props.mp}
                    fieldindex={this.props.fieldindex}
                    filterindex={this.props.filterindex}
                    showwhen={"notfound"}
                    priority={feedbackObject_notfound.priority}
                />
            </div>
        )
    }
}
class KeyStatementsSynonyms extends React.Component{
    props!:{
        //key:number,//don't need to put this. 
        mp:number,
        fieldindex:number,
        filterindex:number,
        ORs:ORobj[],
//        type:string,
        addTerm:          (e:React.MouseEvent<HTMLButtonElement>,index:{ANDindex:number,fieldindex:number,mp:number,filterindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
    //    removeOption:     (fieldindex:number,filterindex:number)=>void,
        onChangekeyterm:  (e:React.ChangeEvent<HTMLTextAreaElement>,index:{fieldindex:number,isstatement:boolean,filterindex:number,mp:number,synonymindex?:number,ANDindex:number,nandtype:NANDType})=>void,
        addoption:        (index:{fieldindex:number,optionindex?:number,mp:number},type:FieldType,remove:boolean)=>void,
    //    modifyFilter:  (index:{fieldindex:number,filterindex?:number,mp:number},modType:string)=>void,
        modifyAND:      (index:{fieldindex:number,filterindex:number,mp:number,ANDindex:number,nandtype:NANDType},modType:string)=>void,//ANDindex is always 0 for NOT
        ANDindex:number,
    //    isNOT:boolean,
        nandtype:NANDType
        canNAND:boolean,
    }
    render(){
        let i = this.props.filterindex;
        let keystatementsynonyms = [];
        for(let j=0;j<this.props.ORs.length;j++){
            keystatementsynonyms.push(
                <div style={{display:"inline"}}>
                    <span>{j>0?"|":""}</span>
                    <textarea 
                        key = {j}
                        //type="text" 
                        cols={(this.props.ORs[j].str.length||0)+3}
                        rows={1}
                        name={"keystatementsynonyms"} 
                        //key={this.props.fieldindex}
                        value={this.props.ORs[j].str}
                        onChange={(e)=>this.props.onChangekeyterm(e,//TODO this function needs an object as param to avoid miscatorgorisation.
                            {
                                fieldindex:this.props.fieldindex,
                                isstatement:true,
                                filterindex:i,
                                mp:this.props.mp,
                                synonymindex:j,
                                ANDindex:this.props.ANDindex,
                                nandtype:this.props.nandtype
                            }
                        )}
                    />
                </div>
            );
        }
        let wordatstartofline:string = this.props.nandtype;
        //if it is the first AND then replace the word AND with Find:
        if((wordatstartofline==="AND")&&(this.props.ANDindex===0)){
            wordatstartofline = "Find: ";
        }
        return(
            <div>
                {/*<span hidden={!this.props.canNAND}>{this.props.isNOT?"NOT":"AND"}</span>*/}
                <span 
                    hidden={((!this.props.canNAND)&&!(this.props.nandtype==="ALSO")&&!(this.props.nandtype==="AND"))}
                    >{wordatstartofline}
                </span>
                <button
                    //hidden = {this.props.isNOT}
                    hidden = {this.props.nandtype!=="AND"}
                    key = {'button'+(2*i)}
                    onClick = {(e) => this.props.addTerm(e,
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:i,
                            ANDindex:this.props.ANDindex,
                            issynonym:true
                            // need isNOT here if I wanted multiple NOTOR
                        },
                        true,false)
                    }
                    >+
                </button>
                <button
                    //hidden = {this.props.isNOT}
                    hidden = {this.props.nandtype!=="AND"}
                    key = {'button'+(2*i)+1}
                    onClick = {(e) => this.props.addTerm(e,
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:i,
                            ANDindex:this.props.ANDindex,
                            issynonym:true
                            //as above
                        },
                        true,true)}
                    >-
                </button>
                {keystatementsynonyms}
                <button
                    hidden={((!this.props.canNAND)&&!(this.props.nandtype==="ALSO")&&!(this.props.nandtype==="AND"))} // possibly don't need this condition?
                    key = {'removestatement'}
                    onClick = {(e)=>{e.preventDefault();this.props.modifyAND(
                        {
                            fieldindex:this.props.fieldindex,
                            mp:this.props.mp,
                            filterindex:i,
                            ANDindex:this.props.ANDindex,
                            //NOT:this.props.isNOT,
                            nandtype:this.props.nandtype
                        },
                        "remove")
                    }}
                    >&#128465;
                </button>
            </div>
        );    
    }
}
/*
class Keytermsinput extends React.Component{
    props!:{
        inputlonganswer:InputTextLongAnswer,
        fieldindex:number,
        mp:number,
        onChangekeyterm:(e:any,fieldindex:number,isstatement:boolean,keytermindex:number,mp:number,synonymindex?:number)=>void,
        addTerm:(e: React.MouseEvent<HTMLButtonElement>,index:{fieldindex:number,mp:number,keytermindex?:number,issynonym:boolean},isstatement:boolean,remove:boolean)=>void,
        removeOption:(fieldindex:number,filterindex:number)=>void,
    }
    render(){
        
        let keytermsinput = [];
        for (let i = 0; i<this.props.inputlonganswer.markingpoints[this.props.mp].keyterms.length;i++){
            
            keytermsinput.push(
                <label key = {i}>
                    kt:
                    <textarea 
                        rows={1}
                        //type="text" 
                        name={"keytermentry"} 
                        //key={this.props.fieldindex}
                        value={this.props.inputlonganswer.markingpoints[this.props.mp].keyterms[i].text}
                        onChange={(e)=>this.props.onChangekeyterm(e,this.props.fieldindex,false,i,this.props.mp)}
                    />
                    |s|
                </label>
            );
            for (let j = 0; j<this.props.inputlonganswer.markingpoints[this.props.mp].keyterms[i].ANDs[0].length;j++){
                keytermsinput.push(
                    <textarea 
                        rows={1}
                        key = {""+i+"_"+j}
                        name={"keytermentry"} 
                        //key={this.props.fieldindex}
                        value={this.props.inputlonganswer.markingpoints[this.props.mp].keyterms[i].ANDs[0][j]}
                        onChange={(e)=>this.props.onChangekeyterm(e,this.props.fieldindex,false,i,this.props.mp,j)}
                    />
                );
            }
            keytermsinput.push(
                <button
                    key = {'button'+(2*i)}
                    onClick = {(e) => this.props.addTerm(e,{fieldindex:this.props.fieldindex,keytermindex:i,mp:this.props.mp,issynonym:true},false,false)}
                    >+
                </button>
            );
            keytermsinput.push(
                <button
                    key = {'button'+(2*i)+1}
                    onClick = {(e) => this.props.addTerm(e,{fieldindex:this.props.fieldindex,keytermindex:i,mp:this.props.mp,issynonym:true},false,true)}
                    >-
                </button>
            );
            keytermsinput.push(
                <button
                    key = {"removekeyterm"+i}
                    onClick = {(e) => this.props.addTerm(e,{fieldindex:this.props.fieldindex,keytermindex:i,mp:this.props.mp,issynonym:false},false,true)}
                    >&#128465;
                </button>
            );
            keytermsinput.push(<br key = {'br'+i}/>);
        }
        keytermsinput.push(
            <button
                key = "addkeyterm"
                onClick = {(e) => this.props.addTerm(e,{fieldindex:this.props.fieldindex,mp:this.props.mp,issynonym:false},false,false)}
                >+ key term
            </button>
        );
        return(
            <div>
                {keytermsinput}
            </div>
        )
    }
}
*/
class FeedbackList extends React.Component{
    props!:{
        longanswer:InputTextLongAnswer,
        fieldindex:number,
        onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:FeedbackType,priority?:number})=>void, 
        onChangeoption_feedback:	(e:any,fieldindex:number,optioninstance:number,type:FieldType,mp:number,showwhen:FoundNotFound)=>void,
        toggleIsFloaty:             (filterinfo:FilterInfo)=>void,
    }
//    state:{
//        feedbacklist
//    }
    render(){
        let feedbacklist:{priority:number,rowhtml:any}[] = [];
        for(let mp = 0; mp < this.props.longanswer.markingpoints.length ; mp ++){
            for(let s = 0 ; s < this.props.longanswer.markingpoints[mp].filters.length ; s ++){
                let fi:FilterInfo = {
                    mp,
                    filterindex:s,
                    fieldindex:this.props.fieldindex,
                    filter:this.props.longanswer.markingpoints[mp].filters[s],
                }
                for (let f_showwhen = 0 ; f_showwhen < 2 ; f_showwhen++){
                    let feedbackObject:FeedbackObject = create_empty_FeedbackObject();
                    let showwhen:FoundNotFound;
                    if      (f_showwhen === 0){showwhen = "found"; feedbackObject = this.props.longanswer.markingpoints[mp].filters[s].feedback_if_found;}
                    else if (f_showwhen === 1){showwhen = "notfound"; feedbackObject = this.props.longanswer.markingpoints[mp].filters[s].feedback_if_not_found;}
                    //else if (f_showwhen === 2){feedbacktext = this.props.longanswer.markingpoints[mp].filters[s].feedback.text;}
                    else {throw "couldn't find f_showwhen"}
                    if(feedbackObject.text.length>0){
                        feedbacklist.push({
                            priority:feedbackObject.priority,
                            rowhtml:
                            <tr key={""+mp+"_"+s+"_"+f_showwhen}>
                                <th key = {0}>
                                    <FeedbackPriorityControl
                                        onSetFeedbackType={this.props.onSetFeedbackType}
                                        mp={mp}
                                        fieldindex={this.props.fieldindex}
                                        filterindex={s}
                                        showwhen={showwhen}
                                        priority={feedbackObject.priority}
                                    />
                                </th>
                                <th key = {1}>
                                    <FeedbackTypeControl
                                        onSetFeedbackType={this.props.onSetFeedbackType}
                                        mp={mp}
                                        fieldindex={this.props.fieldindex}
                                        filterindex={s}
                                        showwhen={showwhen}
                                        type={feedbackObject.type}
                                    />
                                    
                                </th>
                                <th key = {2}>
                                    {/*<span>{mp} {s} {f_showwhen}</span>*/}
                                    <PopOutButton
                                        filterinfo={fi}
                                        toggleIsFloaty={this.props.toggleIsFloaty}
                                    />
                                    <textarea
                                        className={"feedbacksummarytext "+feedbackObject.type}
                                        cols={Math.min(80,feedbackObject.text.length+3)}
                                        rows={1}
                                        value={feedbackObject.text}
                                        onChange={(e)=>this.props.onChangeoption_feedback(e,this.props.fieldindex,s,"input_text_long",mp,showwhen)}
                                        />
                                </th>
                            </tr>
                        });
                    }
                }
            }
        }
        //let sortedfeedbacklist = feedbacklist.sort(compare_priority);
        //let sortedfeedbacklist:{priority:number,rowhtml:any}[] = feedbacklist.sort(compare_priority);
        feedbacklist.sort(compare_priority);
        //feedbacklist = sortedfeedbacklist.map(e=>e.rowhtml);
        
        let sortedfeedbacklist: any[] = feedbacklist.map(e=>e.rowhtml);
        console.log(feedbacklist);
        return(
            <div
                hidden={sortedfeedbacklist.length===0}>
                <p>Feedback summary</p>
                <table style={{textAlign:"left"}}>
                    <thead>
                        <tr>
                            <th>Pri</th>
                            <th>type</th>
                            <th>text</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedfeedbacklist}
                    </tbody>
                </table>
            </div>
        )
    }
}
class FeedbackTypeControl extends React.Component {
    props!:{
        onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:FeedbackType,priority?:number})=>void, 
        mp:number,
        fieldindex:number,
        filterindex:number,
        showwhen:FoundNotFound,
        type:FeedbackType,
    }
    render(){
        let feedbacktypes:FeedbackType[] = ["hint","correct","incorrect"];
        return(
            <div className="FeedbackTypeControl">
                <select 
                    onChange={(e)=>this.props.onSetFeedbackType({fieldindex:this.props.fieldindex,mp:this.props.mp,filterindex:this.props.filterindex,showwhen:this.props.showwhen},{type:(e.target as any).value})} //bit of a hack
                    name={"feedbacktype"+this.props.fieldindex+"_"+this.props.mp+"_"+this.props.filterindex}
                    value={this.props.type} 
                    className={"feedbacktypecontrol "+this.props.type}
                    >
                    {//set options
                        feedbacktypes.map(elt =>
                            <option 
                                key={elt}
                                value={elt} 
                                className={"feedbacktypecontroloption "+elt}
                                >{elt}
                            </option>)
                    }
                </select>
            </div>
        )
    }
}
class FeedbackPriorityControl extends React.Component {
    props!:{
        onSetFeedbackType:          (index:{fieldindex:number,mp:number,filterindex:number,showwhen:FoundNotFound},feedback:{type?:FeedbackType,priority?:number})=>void, 
        mp:number,
        fieldindex:number,
        filterindex:number,
        showwhen:FoundNotFound,
        priority:number,
    }
    render(){
        return(
            <div className="FeedbackPriorityControl">
                <button
                    key = {'moveup'}
                    onClick = {(e)=>{
                        e.preventDefault();
                        this.props.onSetFeedbackType(
                            {   fieldindex : this.props.fieldindex,  mp:this.props.mp,  filterindex:this.props.filterindex,   showwhen:this.props.showwhen    },
                            {   priority : this.props.priority+1  }
                        )
                    }}
                    >&#9651;
                </button>
                <button
                    key = {'movedown'}
                    onClick = {(e)=>{
                        e.preventDefault();
                        this.props.onSetFeedbackType(
                            {   fieldindex : this.props.fieldindex,  mp:this.props.mp,  filterindex:this.props.filterindex,   showwhen:this.props.showwhen    },
                            {   priority : this.props.priority-1  }
                            )
                        }}
                        >&#9661;
                </button>
                <span>{this.props.priority}</span>
                {/*<select
                    onChange={(e)=>this.props.onSetFeedbackType({fieldindex:this.props.fieldindex,mp:mp,filterindex:s,showwhen},{priority:parseInt(e.target.value)})} 
                    name={"feedbackpriority"+this.props.fieldindex+"_"+mp+"_"+s}
                    value={feedbackObject.priority} 
                    >
                    {//set options
                        [0,1,2,3,4,5].map(elt =>
                            <option 
                            value={elt} 
                            key={elt}
                            >{elt}
                            </option>)
                        }
                </select>*/}
            </div>
        )
    }
}
interface QuestionManagerPageprops
{
    inworksheeteditor:boolean,
    singleQmode:boolean,
    Q_IDs?:number[],
    handleButtonClick?:(Q_ID:number, action:string, Q_index:number)=>void,
    addQuestion?:(Q_ID:number)=>void,
}
export default class QuestionManagerPage extends React.Component<QuestionManagerPageprops> {
    
    static defaultProps = {
        inworksheeteditor:false,
        singleQmode:false,
        
//        W_ID:null,//I don't think I want this here.
    }
    state:{
        questionlist:QuestionListInfo[],
        //questions:QuestionAnswers[],
        target_Q_ID?:number,
        targetquestion?:QuestionAnswers,
    //	viewquestion:boolean,
    //	editquestion:boolean,
        action:string,
    }
    constructor(props: QuestionManagerPage['props']){
        super(props);
        this.state={
            questionlist:[],
            //questions:[],
            //target_Q_ID:-1,
            action:"",
        }
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.fetchquestionlist = this.fetchquestionlist.bind(this);
        this.targetquestionfield_elements = this.targetquestionfield_elements.bind(this);
        this.targetquestionname = this.targetquestionname.bind(this);
        this.fetchTargetQuestion = this.fetchTargetQuestion.bind(this);
    }
    componentDidMount(){
        this.fetchquestionlist();
    }
    componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
    //    console.log("QuestionManagerPage componentDidUpdate called");
    //    console.log("props: "+JSON.stringify(this.props.Q_IDs,null,4));
    //    console.log("prevProps: "+JSON.stringify(prevProps.Q_IDs,null,4));
        if(prevProps.Q_IDs!=this.props.Q_IDs){
			//console.log("props found to have changed, so fetch question list");
            this.fetchquestionlist();
        } else {
            //console.log("props were found to be unchanged")
        }
        //if(prevState!==this.state){
        //	console.log("2number of questions is"+this.state.questions.length+"before copying");
        //	this.fetchquestionlist();
        //	console.log("3number of questions is"+this.state.questions.length+"after copying");
        //}
    }
    async fetchquestionlist(){//newQ_ID is there because it is needed by other stuff, doesn't need to be there actually
        //this is called whenever basically anything happens. 
        //fetches question info list
        console.log("fetching question list");
        if(this.props.inworksheeteditor||this.props.singleQmode){
            let questionlistjson = await fetch('/questionlist', {
                method: 'POST',
                body: JSON.stringify({
                    Q_IDs:this.props.Q_IDs
                }),
                headers:{'Content-Type': 'application/json'}
            });
            let questionlist = await questionlistjson.json();
            this.setState({questionlist});
        } else {
            fetch("/questionlist", {method:'POST'})
            .then(questionlistjson => questionlistjson.json())
            .then(questionlist => {
                this.setState({questionlist:questionlist})
                console.log("After fetching, question number is"+questionlist.length+"after copying")
            })
            //.then(wtf => console.log("After fetching, question number is"+questionlist.length+"after copying"));
            console.log("It just tried to fetch question list but is probably still doing that");
        }
    }
    async fetchTargetQuestion(Q_ID:number):Promise<QuestionAnswers>{
        //if(this.state.target_Q_ID!==undefined){
        if(Q_ID!==undefined){
            let targetquestionjson = await fetch('/questions', {
                method: 'POST',
                body: JSON.stringify({
                //    Q_IDs:[this.state.target_Q_ID]
                    Q_IDs:[Q_ID]
                }),
                headers:{'Content-Type': 'application/json'}
            });
            let returnedquestions:QuestionAnswers[] = await targetquestionjson.json();
            return returnedquestions[0];//this.setState({targetquestion:targetquestion[0]});
        } else throw "Q_ID was not defined"
    }
    async handleButtonClick(e:React.MouseEvent<HTMLButtonElement>, Q_ID:number, action:string, Q_index:number){
        console.log("Button to "+action+" Q with Q_ID: "+Q_ID+" pressed");
        if (action==="view"){
            let targetquestion = await this.fetchTargetQuestion(Q_ID);
            this.setState({target_Q_ID:Q_ID, action, targetquestion});
        }
        else if(action==="edit"){
            let targetquestion = await this.fetchTargetQuestion(Q_ID);
            this.setState({target_Q_ID:Q_ID, action, targetquestion});
            //this.setState({target_Q_ID:Q_ID, action:action});
            //this.updateTargetQuestion();
        }
        else if (action==="copy"||action==="delete"){
            console.log("Number of questions when button pressed is "+this.state.questionlist.length);
            fetch('/modify_question_db', {
                method: 'POST', // or 'PUT'
                body: JSON.stringify({Q_ID:Q_ID, action:action, question:null}), // data can be `string` or {object}!
                headers:{'Content-Type': 'application/json'	}
            })
            .then(res => res.json())
            .then(result => 
                {	if(action==="copy"){
                        console.log("Q_ID of copied question is: "+JSON.parse(result.Q_ID));
                        if(this.props.addQuestion){this.props.addQuestion(result.Q_ID);}
                        this.setState({target_Q_ID:JSON.parse(result.Q_ID),action:"copy",targetquestion:result.question});
                        //this.updateTargetQuestion();
                    } else if (action==="delete"){
                        console.log("Question with Q_ID: "+JSON.parse(result.Q_ID)+" deleted");
                        this.setState({target_Q_ID:null,action:"delete",targetquestion:null});
                    } else {console.log("action not recognised")};
                }
            )
            .then(somerubbish=>	this.fetchquestionlist())
            .catch(error => console.error('Error:', error));
            console.log("should have a comment about deleting or copying around here");
        } 
        else if (action==="remove"||action==="moveup"||action==="movedown"){
            console.log("action: "+action);
            if(this.props.handleButtonClick!==undefined){
                console.log("needs to be handled by WorksheetEditor, calling other handleButtonClick");
                this.props.handleButtonClick(Q_ID,action,Q_index);
            } else {
                console.log("could not find handleButtonClick");
                throw "could not find handleButtonClick";
            }
        } 
        //else {console.log("action was not copy or delete")}
        //console.log("2number of questions is"+this.state.questions.length+"before copying");
        //this.fetchquestions();
        //console.log("3number of questions is"+this.state.questions.length+"after copying");
    }
    targetquestionname():string{
        //var targetquestionname:string|undefined = this.state.questionlist.find(Q=>Q.Q_ID===this.state.target_Q_ID).name;
        let targetquestion = this.state.targetquestion;
        if(targetquestion==undefined){throw "target question not defined yet when asking for targetquestionname"}
        else {
            return targetquestion.name
        }
    //    if(targetquestionname!=null){
    //        return targetquestionname
    //    }
    //    else {return 'The question was not found'}//TODO this is not proper handling neither is the one below
    }
    targetquestionhintOnly():boolean{
        //The old version only changed if the target Q_ID changed. 
        //The new version changes if the targetquestion changes. this is a problem because I just made the target question update with the ...
        //I should make the target question update with the target_Q_ID:
        //var targetquestion = this.state.questions.find(Q=>Q.Q_ID===this.state.target_Q_ID);
        var targetquestion = this.state.targetquestion;
        if(targetquestion!=null){
            if(targetquestion.hintOnly===undefined){
                return false
            } 
            else if(targetquestion.hintOnly===true||targetquestion.hintOnly===false){
                return targetquestion.hintOnly
            }
            else {
                console.log("ERROR: targetquestion.hintOnly should be true or false, instead it is: "+targetquestion.hintOnly);
                return false
            }
        }
        else {throw "The question was not found"}//TODO this is not proper handling neither is the one below
    }
    targetquestionfield_elements(){
        //var targetquestion = this.state.questions.find(Q=>Q.Q_ID===this.state.target_Q_ID);
        var targetquestion = this.state.targetquestion;
        if(targetquestion!=null){			
            return targetquestion.field_elements;
        }
        else {throw "tried to render field elements but no question ID was provided"}//return create_default_field_elements()}
    }
    render(){
        var lowerpanel = <p>There has been an error</p>
        if(this.state.action==="view"){
            if(this.state.target_Q_ID){
                console.log("Calling QuestionpagebyID with ID: "+ this.state.target_Q_ID);
            lowerpanel = <QuestionpagebyID 
                Q_ID={this.state.target_Q_ID} 
                action="view" 
                isdisplayed={true}
            />
            console.log("Just called QuestionpagebyID");
            }else{
                throw "Action set to view question but no Q_ID provided"
            }
            
        }else if(this.state.action==="edit"){
        //    console.log("Edit => QuestionManagerPage renders QuestionEntryPage with ID: "+ this.state.target_Q_ID);
        //    console.log("4number of questions is"+this.state.questions.length);
            if(this.state.targetquestion==undefined){
                lowerpanel = <p>Loading question...</p>
            } else{
                lowerpanel = <QuestionEntryPage 
                Q_ID={this.state.target_Q_ID} 
                action="edit"
                //name={this.state.questions[this.state.target_Q_ID].name}
                name={this.targetquestionname()}
                //field_elements={this.state.questions[this.state.target_Q_ID].field_elements}
                field_elements={this.targetquestionfield_elements()}
                onSubmit={this.fetchquestionlist}
                key={this.state.target_Q_ID===undefined?-1:this.state.target_Q_ID}
                hintOnly={this.targetquestionhintOnly()}
                />
            }
            //console.log("Since state.action is edit, just called QuestionEntryPage with ID");
        } else {
            lowerpanel = this.props.singleQmode?<span/>:<p>Use the buttons in the table above to view, edit or delete questions</p>
        }
        return(
            <div className={"admin_page"}>
                <h2 hidden={this.props.inworksheeteditor||this.props.singleQmode}>Question manager page2</h2>
                <span hidden={this.props.singleQmode}>

                <QuestionManagerTable 
                    questionlist={this.state.questionlist} 
                    handleButtonClick={this.handleButtonClick}
                    inworksheeteditor={this.props.inworksheeteditor}
                />
                {//    <QuestionFilters/>
                }
                <hr/>
                </span>
                <span hidden={!this.props.singleQmode}>
                    {//this.state.questions[0]!==undefined?this.state.questions[0].name:"Loading question name"
                    }
                    <button 
                        onClick={e=>{
                            e.preventDefault();
                            if(this.state.action==="edit") this.setState({action:"hidelowerpanel"});
                            else this.handleButtonClick(e,this.state.questionlist[0].Q_ID,"edit",0);
                        }}
                        >{this.state.action==="edit"?"close editter":"edit question"}
                    </button>
                </span> 
                {lowerpanel}				
            </div>
        )
    }
}
class QuestionManagerTable extends React.Component {
    props!:{
        inworksheeteditor:boolean,
        questionlist:QuestionListInfo[],
        handleButtonClick:(e:React.MouseEvent<HTMLButtonElement>, Q_ID:number, action:string, Q_index:number)=>void,
    }
    render(){
        return(
            <div>
                <table>
                    <QuestionManagerTableHeader 
                        inworksheeteditor={this.props.inworksheeteditor}
                    />
                    <QuestionManagerTableBody 
                        questionlist={this.props.questionlist} 
                        handleButtonClick={this.props.handleButtonClick}
                        inworksheeteditor={this.props.inworksheeteditor}
                    />
                </table>
            </div>
        )
    }
}
class QuestionManagerTableHeader extends React.Component {
    props!:{
        inworksheeteditor:boolean,
    }
    render(){
        return(
            <thead>
                <tr>
                    <th hidden={!this.props.inworksheeteditor}>#</th>
                    <th hidden={this.props.inworksheeteditor}>ID</th>
                    <th>Name</th>
                    <th>View</th>
                    <th>Edit</th>
                    <th>Copy</th>
                    <th hidden={this.props.inworksheeteditor}>Delete</th>
                    <th hidden={!this.props.inworksheeteditor}>Remove</th>
                    <th hidden={!this.props.inworksheeteditor}>Move</th>
                </tr>
            </thead>
        )
    }
}
class QuestionManagerTableBody extends React.Component {
    props!:{
        inworksheeteditor:boolean,
        questionlist:QuestionListInfo[],
        handleButtonClick:(e:React.MouseEvent<HTMLButtonElement>, Q_ID:number, action:string, Q_index:number)=>void,
    }
    render(){
        let question_table_rows = [];
        let rowcount=-1;
        for(let i=0;i<this.props.questionlist.length;i++){
            if(true){//this.props.questions[i].info.status!=="deleted"){
                rowcount++;
                question_table_rows[rowcount] = 
                    <tr key={i}>
                        <th hidden={!this.props.inworksheeteditor}>
                            {i+1}</th>
                        <th hidden={this.props.inworksheeteditor}>
                            {this.props.questionlist[i].Q_ID}</th>
                        <th>{this.props.questionlist[i].name}</th>
                        <th><button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"view",i)}>__</button></th>
                        <th><button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"edit",i)}>__</button></th>
                        <th><button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"copy",i)}>__</button></th>
                        <th hidden={this.props.inworksheeteditor}>
                            <button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"delete",i)}>__</button></th>
                        <th hidden={!this.props.inworksheeteditor}>
                            <button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"remove",i)}>__</button></th>
                        <th hidden={!this.props.inworksheeteditor}>
                            <button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"movedown",i)}>__</button>
                            <button onClick={e=>this.props.handleButtonClick(e,this.props.questionlist[i].Q_ID,"moveup",i)}>__</button>
                        </th>
                    </tr>
            }
        }
        return(
            <tbody>
                {question_table_rows}
            </tbody>
        )
    }
}
class QuestionFilters extends React.Component {
    render(){
        return(
            <div>
                <h3>Question filters</h3>
            </div>
        )
    }
}
//export default {QuestionEntryPage, QuestionManagerPage};
//junk from toggleChildren:
/*
            ...this.state.field_elements,
            answers:{
                ...this.state.field_elements.answers,
                input_text_longs:{
                    ...this.state.field_elements.answers.input_text_longs,
                    [fieldinstance]:{
                        ...this.state.field_elements.answers.input_text_longs[fieldinstance],
                        markingpoints:{
                            ...this.state.field_elements.answers.input_text_longs[fieldinstance].markingpoints,
                            [index.mp]:{
                                ...this.state.field_elements.answers.input_text_longs[fieldinstance].markingpoints[index.mp],
                                filters:tempfilters
                            }
                        }
                    }
                }
            }
        }});
*/
async function modifyFilterInDB(filter:FilterAll,action:"createnew"|"edit"):Promise<number>{
    console.log("adding new filter to thesaurus "+filter.name);
    var url = '/modifyRecord';
    var data = {
        action:action,
        record_type:"filter",
        record:filter,
    };
    let response = await fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}}).then(res => res.json())
    //.then(response => {
    //    console.log('Success:', JSON.stringify(response));
        return response.ID;
    //})
    //.catch(error => console.error('Error:', error));
}
