//TODO where ever I am checking for the presence of something by just checking it rather than a loaded x variable, I need to be careful that the loaded number is not zero
import * as PATHS from "./shared_files/constants/paths";
import React, { ChangeEvent, FormEvent, MouseEvent, Suspense } from 'react';
import { RouteComponentProps, BrowserRouter as Router, Route, Link, Switch, withRouter, useParams, Redirect, RouteProps } from "react-router-dom";
//https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually
import './shared_files/App.css';
import { optionalCallExpression } from '@babel/types';
import { any } from 'prop-types';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import classes from '*.module.css';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import "react-tabs/style/react-tabs.css";
//import ImageUploader from 'react-images-upload';
//import { Document,Page } from 'react-pdf';
//import ReactPasswordStrength from 'react-password-strength';
//import owaspPasswordStrengthTest from 'owasp-password-strength-test';
//import {Privacy} from './PrivacyPage';
import {DeleteDialog, Dialog, ExercisesPage, FeedbackBox, FlashcardWorksheetComponent, 
	getResetNumber, QuestionAnswersComponent, QuestionAnswersComponentWrapper, 
	QuestionpagebyID, QuestionsToPractice, QuestionView, 
	SummaryPage, TableKey, UserPage, WorksheetComponent, ClassPageblank, ClassPage,
	TeacherHomePage} from './shared_files/Common'; //interfaces
import { removeUserFromClass, resetPassword, unlockUser} from './shared_files/components/elearning/TeacherTools/UserManagerList/UserManagerFunctions'
import {fetchquestionsbyID,modifyArray} from './shared_files/Common'; //functions
import {Questionpage, AddClassComponent} from './shared_files/Common'; //classes
import {commonpasswords} from './shared_files/commonpasswords.json';
import { stringify } from 'querystring';
import {
	FeedbackObject, Worksheet, PupilAnswers, QuestionData, Attempt, ClassAssignmentData, User, create_empty_worksheet,
	PupilData, Question, create_empty_formvalues, WorksheetType, Report, AssignmentSortType, PupilWSData, ButtonColour,
	RecordAction, Cohort, PupilNames, UserType, PageName, UserData, PageChangeFunctions, LoginPageProps, MarkerType, Assignment, PupilWsStats
} from './shared_files/my_interfaces';
import { loggy, copy, getpercentagescore, stringarraytolist, getattemptmanscore, updateManscore, classdatatocsv, formatcheckboxanswer, formatlongtextanswer, formatnumericalanswer, formatselfmarkanswer, checkPassStrength, scorePassword, calcScores, getFeedback } from './shared_files/shared_functions';
import update from 'immutability-helper'; //https://github.com/kolodny/immutability-helper
import { Stats } from 'fs';
import { userInfo } from 'os';
import { isBlock, textChangeRangeIsUnchanged } from 'typescript';
import LandingPage from "./shared_files/components/LandingPage";
import Navigation from "./shared_files/components/Navigation";
import Footer from "./shared_files/components/Footer";
import {LoginPage} from "./shared_files/components/LoginPage";
import RegisterPage from "./shared_files/components/RegisterPage";
//import { json } from "body-parser";
import { Topic, topic_table } from "./shared_files/topics";
import { resolve } from "url";
import { ThreeSixty } from "@mui/icons-material";
import internal from "assert";
import { loadtest1 } from "./shared_files/testing_scripts/load_testing";

import { StyledEngineProvider, ThemeProvider, createTheme, makeStyles } from '@mui/material/styles';
import TeacherToolTabs from './shared_files/components/elearning/TeacherTools/TeacherToolTabs'
import AboutPage from './shared_files/components/AboutPage/AboutPage'
import BlogPage from "./shared_files/components/BlogPage/BlogPage";
import {WorksheetManagerPage} from "./shared_files/components/AdminComponents/WorksheetManager"

import QuestionManagerPage from './shared_files/components/AdminComponents/Admin';//classes
import QuestionEntryPage from './shared_files/components/AdminComponents/QuestionEntryPage';//classes

//const QuestionManagerPage = React.lazy(() => import(/* webpackChunkName: "Admin_QuestionManagerPage" */'./shared_files/components/AdminComponents/Admin')); //https://hackernoon.com/lazy-loading-and-preloading-components-in-react-16-6-804de091c82d
//const QuestionEntryPage = React.lazy(() => import(/* webpackChunkName: "QuestionEntryPage" */'./shared_files/components/AdminComponents/QuestionEntryPage'));

const theme = createTheme({
	palette: {
	  primary: {
		main: '#3f51b5',  // Set your primary color here
	  },
	  secondary: {
		main: '#3f51b5',  // Set your primary color here
	  },
	},
	typography: {
	  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',  // Set your desired font
	  body1: {
		lineHeight: 1.5,  // Adjust text height (line height)
		textAlign: 'left',  // Make text left-aligned by default
	  },
	  body2: {
		lineHeight: 1.5,  // Adjust text height (line height) for smaller text
		textAlign: 'left',  // Make text left-aligned by default
	  },
	  h1: {
		margin: '20px 0',  // Set margin for headers (h1)
		textAlign: 'left',  // Make headers left-aligned by default
	  },
	  h2: {
		margin: '18px 0',  // Set margin for headers (h2)
		textAlign: 'left',  // Make headers left-aligned by default
	  },
	  h3: {
		margin: '16px 0',  // Set margin for headers (h3)
		textAlign: 'left',  // Make headers left-aligned by default
		color: 'black',
	  },
	  h4: {
		margin: '14px 0',  // Set margin for headers (h4)
		textAlign: 'left',  // Make headers left-aligned by default
	  },
	  h5: {
		margin: '12px 0',  // Set margin for headers (h5)
		textAlign: 'left',  // Make headers left-aligned by default
	  },
	  h6: {
		margin: '10px 0',  // Set margin for headers (h6)
		textAlign: 'left',  // Make headers left-aligned by default
	  },
	},
	components: {
	  MuiTypography: {
		styleOverrides: {
		  root: {
			textAlign: 'left',  // Ensures all Typography components are left-aligned by default
		  },
		},
	  },
	//  MuiCssBaseline: {
	//	styleOverrides: {
	//	  // Target the <hr> HTML element
	//	  hr: {
	//		margin: '500px', // Set your desired margin (top and bottom)
	//		borderColor: '#e0e0e0', // Optional: Customize the border color
	//		borderWidth: '1px',     // Optional: Customize the border width
	//	  },
	//	},
	//},
}
  });
  


//Lizzy should really have a comment here
//comment made by Mark
//second comment made by Mark
export async function isFieldvalueNew(fieldname:string,fieldvalue:string): Promise<boolean> {
	console.log('checking if '+ fieldname+' is used');
	var url = '/checkisnew';
	var data = {fieldtocheck:fieldname,
				[fieldname]:fieldvalue};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	console.log("returning isnew: "+js_obj.isnew);
	return js_obj.isnew;
}
export async function Login(username: string, password: string): Promise<{message: string, success: boolean, usertype?: UserType}>{
    let url = PATHS.LOGIN_USER;
    let data = {username, password};
    let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers:{'Content-Type': 'application/json'	}
    });
    return await response.json();
}
async function fetchUsersData(Class_ID:number):Promise<UserData[]>{
	let response = await fetch('/usersdata', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify({
			Class_ID,
		}), // data can be `string` or {object}!
		headers:{'Content-Type': 'application/json'	}
	}) //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	let js_obj = await response.json();
	return js_obj;
}
function refreshPage(){
	window.location.reload();
}

function rescaleImage(image_name:HTMLImageElement, max_height:number,max_width:number){
	let height = image_name.height;
	let width = image_name.width;
	let ratio = height/width;
	if(height >max_height){
		ratio = max_height/height;
		height = height*ratio;
		width = width*ratio;
	}
	if(width >max_width){
		ratio = max_width/width;
		height = height*ratio;
		width = width*ratio;
	}
	image_name.width = width;
	image_name.height = height;
	return image_name;
}

function sum(input:any[]):number{
	if (toString.call(input) !== "[object Array]"){
		return 0;
	}
	var total =  0;
	for(var i=0;i<input.length;i++)
		{
		if(isNaN(input[i])){
		continue;
		}
			total += Number(input[i]);
		}
	return total;
}
type PageNamePublic='log_in'|'about'|'contact'|'exercises'|"user_page"|"pupil_manager";
//type PageName="exercises"|"about"|"contact"|"pupil_manager"|"log_in"|"user_page"|"worksheet_manager"|"worksheet"|"question_entry"|"question_manager"|"test_page"|"report_manager";
var globaluser:User|null|undefined = null;//TODO this is dreadful!! there should be no gloabl variables in a react website.

// class App extends React.Component {
// 	render(){
// 		return(
// 			<Router>
// 	<Switch>
// 		{/* <Route path="/question_page" component={Qiframe}/> */}
// 		<Route path="/" component={App2} />


// 	</Switch>
// 	</Router>
// 		)
// 	}
// }
//	<Route path="/exercises" //component={ExercisesPage} />
	//		render={props => <ExercisesPage selectWorksheet={this.selectWorksheet} {...props} />} />
	//	<Route path="/worksheet" //component={WorksheetComponent} />***need to set the worksheet ID properly
	//		render={props => <WorksheetComponent worksheet_ID={this.state.currentworksheet} {...props} />} />
// class Qiframe extends React.Component<RouteComponentProps<any>> {
// 	state:{
// 		timeout:any
// 	}
// 	constructor(props:RouteComponentProps<any>){//constructor is a method of the component
// 		super(props);
// 		this.state={
// 			timeout:null
// 		}
// 		this.timeouttovalidateField = this.timeouttovalidateField.bind(this);
// 	}
// 	timeouttovalidateField(){
// 		clearTimeout(this.state.timeout);
// 			// Make a new timeout set to go off in 800ms
// 			this.setState({
// 				timeout:setTimeout(
// 					()=>{	//console.log(''+JSON.stringify(,null,4));
// 					this.adjust_iframe_height();
// 					this.timeouttovalidateField();
// 				}, 300
// 				)
// 			});
// 	}
// 	render() {
// 		let Q_ID = this.getQ_ID();
// 		return (
// 				//Q_ID is: {Q_ID}
// 			<div className="what is my height" id="questionpagetempid">
// 				<QuestionpagebyID
// 					key={Number(Q_ID)}
// 					Q_ID={Number(Q_ID)}
// 					isdisplayed={true}
// 					is_iframe={true}
// 					/>
// 			</div>
// 		);
// 	}

// 	componentDidMount(){
// 		this.adjust_iframe_height();
// 		this.timeouttovalidateField();
// 	}

// 	componentDidUpdate(){
// 		this.adjust_iframe_height();
// 	}

// 	getQ_ID(): string {
// 		let search = this.getUrlParams();
// 		return search.get("Q_ID") || "";
// 	}

// 	getUrlParams(): URLSearchParams {
// 		if (!this.props.location.search) return new URLSearchParams();
// 		return new URLSearchParams(this.props.location.search);
// 	}

// 	adjust_iframe_height(){
// 		let element = document.getElementById("questionpagetempid");
// 		if(element===null) throw 'cannot find document.getElementById("questionpagetempid")';
// 		var actual_height = element.scrollHeight;
// 	//	loggy(actual_height, "actual_height");
// 		window.parent.postMessage(actual_height,"*");
// 		//https://davidwalsh.name/window-iframe, alternative: http://davidjbradshaw.github.io/iframe-resizer/
// 		//https://stackoverflow.com/questions/5908676/cross-domain-iframe-resize
// 		//https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
// 		//BEST ONE://https://github.com/davidjbradshaw/iframe-resizer
// 		//https://css-tricks.com/cross-domain-iframe-resizing/
// 		//TEST: http://localhost:8080/testing%20iframe2.html
// 		//* allows this to post to any parent iframe regardless of domain
// 		//need to type npx http-server in the simple server folder within scrap
// 	}

// //	componentDidMount(){
// //		console.log("componentDidMount called, fetching /userinfo...");
// //		this.getQuestion();
// //	}
// //	render(){
// //		return(
// //			<p>This is a Qiframe {JSON.stringify(this.props.location)} {this.props.location</p>
// //		)
// //	}
// }
type AppProps = {} & RouteComponentProps; // needed to use the router

class App extends React.Component<AppProps> {
	state: {//state is a property of the component and is an object
		//username:string,
		currentpage?:PageName,//string,
		test:string,
		user?:User|null,
		// TODO: this shouldn't be in this state
		currentworksheet?:number,
		worksheettype?:WorksheetType,
		cookiesdisabled?:boolean,
		showWelcomeDialog:boolean,
//		loginattempt:boolean,
	}
	public pageChangeFunctions: PageChangeFunctions;
	constructor(props: AppProps){//constructor is a method of the component
		super(props);
		this.state = {
			user:null,
			//username:'',
			currentpage:undefined,//'log_in',//TODOability to set default page to reduce navigation, should be set for each usertype
			test:"",
			currentworksheet:undefined,
			worksheettype:"original",
			cookiesdisabled:undefined,
			showWelcomeDialog:false,
//			loginattempt:false,
		}
//		this.onUsernameChange = this.onUsernameChange.bind(this);
//		this.logThemIn = this.logThemIn.bind(this);
		this.selectWorksheet = this.selectWorksheet.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
//		this.onLoginAttempt = this.onLoginAttempt.bind(this);
		this.isdisplayed = this.isdisplayed.bind(this);
		this.onLoginSuccess = this.onLoginSuccess.bind(this);
		this.setPage = this.setPage.bind(this);

		// These functions are accessible to sub components to allow them to change the currently displayed page
		this.pageChangeFunctions = {
			exercises: () => this.setState({currentpage: 'exercises'}),
			log_in: () => this.setState({currentpage: 'log_in'}),
			about: () => this.setState({currentpage: 'about'}),
			report_manager: () => this.setState({currentpage: 'report_manager'}),
			pupil_manager: () => this.setState({currentpage: 'pupil_manager'}),
			question_manager: () => this.setState({currentpage: 'question_manager'}),
			worksheet_manager: () => this.setState({currentpage: 'worksheet_manager'}),
			question_entry: () => this.setState({currentpage: 'question_entry'}),
			test_page: () => this.setState({currentpage: 'test_page'}),
			user_manager: () => this.setState({currentpage: 'user_manager'}),
			user_page: () => this.setState({currentpage: 'user_page'}),
			log_out: () => this.handleLogout(),
			register: () => this.setState({currentpage: 'register'}),
			// Add new functions above this comment REF_83192
		}
	}
//	logThemIn(){
//		this.setState(//TODO don't need this, it is handled elsewhere
//			{	//loggedin:true,
//				currentpage:"topics"
//			}
//		);
//	}
//	onUsernameChange(e: React.ChangeEvent<HTMLInputElement>){
//		this.setState({
//			username:e.target.value
//		})
//	}
	selectWorksheet(W_ID:number,worksheet_type:WorksheetType){
		console.log("selectWorksheet called");
		this.setState({currentworksheet:W_ID, currentpage:'worksheet',worksheettype:worksheet_type})
	}
	componentDidMount(){
		console.log("componentDidMount called, fetching /userinfo...");
		this.getUserInfo();
	}
	componentDidUpdate(){
		globaluser = this.state.user;
	}
	async getUserInfo():Promise<User>{
		console.log("getUserInfo called");
		let response = await fetch("/userinfo")
		let js_obj:{user:User,undefinedcookies?:boolean} = await response.json()
		console.log("...response from /userinfo: "+JSON.stringify(js_obj,null,4)+`
		setting as user (in state)`);
		//cookiesdisabled = js_obj.undefinedcookies;
		this.setState({
			user: js_obj.user,
			cookiesdisabled: js_obj.undefinedcookies
		})
		return js_obj.user
	}
	//i think handleLogin should similarly be defined here and then passed down.
	async handleLogin(username: string, password: string) {
		try {
			// Perform the login operation
			const response = await Login(username, password);
			
			// Check if the login was successful
			if (!response.success) {
				throw new Error(response.message);
			}

			// Fetch user information
			const user = await this.getUserInfo();

			// Update the state with the user information
			this.setState({ user });
			
			// Navigate to the home page after successful login
			// this.props.history.push('/');

			// Return the user information
			return user;
		} catch (error) {
			// console.error('Login failed:', error.message);
			// Handle login error appropriately here
			throw error;
		}
	}	
	async handleLogout() {
		try {
			console.log("handleLogout called");
	
			// Perform the logout operation
			const response = await fetch("/logout", { method: 'GET' });
	
			if (!response.ok) {
				throw new Error('Logout failed');
			}
	
			const result = await response.json();
			console.log(result.message);
	
			// Clear the user state
			this.setState({ user: null });
				
			// Navigate to the home page after successful logout
			this.props.history.push('/');
	
		} catch (error) {
			console.error('Logout failed:', error);
			// Handle logout error appropriately here
		}
	}
	
	
//	onLoginAttempt(){
//		this.setState({loginattempt:true});
//	}
	isdisplayed(requestedpage:PageName){
		if (this.state.user!==undefined&&this.state.user!==null){
			if( requestedpage==='log_in'){return 'none'}
			if(this.state.user.usertype==='superuser'){
				return ''//i.e. show all regardless, /'block'
			}else if(this.state.user.usertype==="qcreator"){
				if(requestedpage==="user_manager"){ return 'none'}
				else {return ''}
			}else if(this.state.user.usertype==='teacher'){
				if(requestedpage==='about'||
				requestedpage==='exercises'||
				requestedpage==='contact'||
				requestedpage==='pupil_manager'){
					return ''} else {return 'none'}
			}else if(this.state.user.usertype==='pupil'){
				if(//requestedpage==='about'||
				requestedpage==='exercises'||
				requestedpage==='contact'){
					return ''} else {return 'none'}
			}else {
				console.log(`this.state.user is defined but couldn't find usertype when deciding which page buttons to show. 
				I think that the current user is: `+JSON.stringify(this.state.user,null,4));//+1this.state.user.usertype);
				throw "couldn't find usertype when deciding which page buttons to show.";
			}
		}else {
			console.log("this.state.user===null|undefined so couldn't find user when deciding which page buttons to show.");
			if( requestedpage==='log_in'||
				requestedpage==='about'||
				requestedpage==='contact'){
					return ''
			} else {return 'none'}
		}
	}
	setPage(pagename:PageName){
		if(pagename==='log_in'||pagename==='about'||pagename==='contact'||pagename==='exercises'||pagename==="user_page"||pagename==="pupil_manager"){//TODO, how to check to see if it is one
			this.setState({currentpage:pagename}); // why is this cancelled?
		}
		else throw "setPage: pagename: "+pagename+" is not recognised"
	}
	// onLoginsuccess doesn't call Login
	async onLoginSuccess(type:UserType,justregistered:boolean):Promise<User>{
		//send them to pupil_manager if they are teacher or exercises if they are a pupil
		//TODO ggg need to setstate to say show welcome popup if they have just registered, then for this to be passed as a prop to the class manager page.
		console.log("onLoginSuccess called");
		let user = await this.getUserInfo();
		let newState = update(this.state,{
			showWelcomeDialog:{$set:justregistered&&type==="teacher"},
			currentpage:{$set:'exercises'} //default
		});
		if(type!==undefined&&type!==null){
			if(type ==="teacher"){
				newState = update(newState,{
					//currentpage:{$set:'pupil_manager'}
					currentpage:{$set:'exercises'}
				})
		//		this.setState({currentpage:'pupil_manager'}); // why is this cancelled?
	//		} else {
	//			newState = update(newState,{
	//				currentpage:{$set:'exercises'}
	//			})
	//	//		this.setState({currentpage:'exercises'});
			}
		}
		else if(user!==undefined&&user!==null){//TODO I think that this bit doesn't really work. hmm. maybe it does.
			if(user.usertype ==="teacher"){
				newState = update(newState,{
					currentpage:{$set:'pupil_manager'}
				})
				//this.setState({currentpage:'pupil_manager'}); // why is this cancelled?
	//		} else {
	//			newState = update(newState,{
	//				currentpage:{$set:'exercises'}
	//			})
	//		//	this.setState({currentpage:'exercises'});
			}
		}
	//	else {
	//		newState = update(newState,{
	//			currentpage:{$set:'exercises'}
	//		})
	//	//	this.setState({currentpage:'exercises'});
	//	}
		this.setState(newState);
		return user
	}
	render() {
// 		console.log(this.state);
// 		var Page = [];

// 		if(this.state.user!==undefined&&this.state.user!==null){
// 			if(this.state.currentpage==="exercises"){//TODOI should push select worksheet function down or pull it out of app

// 				Page[0] = <ExercisesPage
// 								//selectWorksheet={this.selectWorksheet}
// 								exerciseButtonFunctions={{
// 									selectWorksheet:this.selectWorksheet
// 								}}
// 								setPage={this.setPage}
// 								user={this.state.user}
// 							/>
// 			}
// 	//		else if(this.state.currentpage==="question_entry"){
// 	//			Page[0] = <QuestionEntryPage/>
// 	//		}
// 			else if(this.state.currentpage==="log_in"||this.state.currentpage==="register"){
// 				//I think this is part of the logic that causes a new registration be passed through the login screen and be logged in automatically
// 				//needed to add /register page to allow for new registrations since we'll be on the register page now. 
// 				Page[0] = 	<LoginPage
// 								onLoginSuccess = {this.onLoginSuccess}
// 								cookiesdisabled = {this.state.cookiesdisabled}
// 								buttonOnClicks={this.pageChangeFunctions}
// //								loginattempted={this.state.loginattempt}
// //								onLoginAttempt={this.onLoginAttempt}
// //								username={this.state.username} //***what is going on with this username?
// //								onSuccessfulLogin={this.logThemIn}
// //								onUsernameChange={this.onUsernameChange}
// 							/>
// 			}
// 			// else if(this.state.currentpage==="about"){
// 			// 	Page[0] = <LandingPage 
// 			// 		user={this.state.user} 
// 			// 		buttonOnClicks={this.pageChangeFunctions}
// 			// 	/> //takes you to about page if logged in. TODO could consolidate
// 			// }
// 			else if(this.state.currentpage==="worksheet"){
// 				console.log("currentpage=worksheet, currentworksheet="+this.state.currentworksheet);
// 				if(this.state.currentworksheet !== undefined){//TODO I don't know what this is for
// 				//	if(this.state.currentworksheet){
// 						if(this.state.worksheettype==="flashcards"){
// 							Page[0] = <FlashcardWorksheetComponent 
// 								worksheet_ID={this.state.currentworksheet}
// 								globaluser={globaluser}
// 								/>
// 						} else{
// 							console.log("decided to try to render WorksheetComponent");
// 							Page[0] = <WorksheetComponent
// 								worksheet_ID={this.state.currentworksheet}
// 								setPage={this.setPage}
// 								globaluser={globaluser}
// 							/>
// 						}
// 				//	}
// 				}
// 				else {
// 					console.log("currentpage===worksheet but currentworksheet= "+this.state.currentworksheet);
// 				}
// 			}
// 		//	else if(this.state.currentpage==="worksheet_manager"){
// 		//		Page[0] = <WorksheetManagerPage
// 		//			setPage={this.setPage}
// 		//		/>
// 		//	}
// 			else if(this.state.currentpage==="report_manager"){
// 				Page[0] = <ReportManagerPage/>
// 			}
// 		//	else if(this.state.currentpage==="question_manager"){
// 		//		Page[0] = <QuestionManagerPage/>
// 		//	}
// 			else if(this.state.currentpage==="pupil_manager"){
// 				Page[0] = <PupilManagerPage
// 					globaluser={globaluser}
// 					/>
// 			}
// 			else if(this.state.currentpage==="test_page"){
// 				Page[0] = <TestPage/>
// 			}
// 			else if(this.state.currentpage==="user_manager"){
// 				Page[0] = <UserManagerPage/>
// 			}
// 			//else if(this.state.currentpage==="privacy"){
// 			//	Page[0] = <Privacy/>
// 			//}
// 			else if(this.state.currentpage==="user_page"){
// 				Page[0] = <UserPage
// 					user={this.state.user}/>
// 			}
// 			else {
// 				//***Here the render function changes the state, BAD TODO: put this stuff into a gunction.
// 				//This function should not be called in render but rather in componentdidupdate or component did mount etc.
// 				console.log("Did not recognise requested page: "+this.state.currentpage);
// 				if(this.state.user.usertype==='superuser'){this.setState({currentpage:"question_entry"})}//this should be set in a user access table and pulled from there as the default page for each user, just make it he firt one on the list?
// 				else if(this.state.user.usertype==='pupil'){this.setState({currentpage:"exercises"})}
// 				else if(this.state.user.usertype==='teacher'){this.setState({currentpage:"pupil_manager"})}
// 				else {Page[0] = <div>Did not recognise requested page: {this.state.currentpage}</div>}
// 				throw "Did not recognise requested page: "+this.state.currentpage;
// 			}
// 			//Page[1] = <span className="button innavigationbar float">{this.state.user.username}</span>
// 			Page[1] = <button
// 					className="button innavigationbar float"
// 					onClick={()=>this.setState({currentpage:"user_page"})}
// 					>{this.state.user.username}
// 				</button>
// 			Page[2] = <button
// 					className="button innavigationbar float"
// 					onClick={this.handleLogout}
// 					>Logout
// 				</button>
// 		}
// 		else {
// 			if(this.state.currentpage==="about"){//TODO this should be part of the permissions, whereby this is an accessbile page for none logged in users
// 				// Page[0] = <LandingPage user={this.state.user} buttonOnClicks={this.pageChangeFunctions}/> //takes you to about page if not logged in
// 			} else {
// 				Page[0] = 	<LoginPage
// 								onLoginSuccess={this.onLoginSuccess}
// 								cookiesdisabled = {this.state.cookiesdisabled}
// 								buttonOnClicks={this.pageChangeFunctions}
// //								loginattempted={this.state.loginattempt}
// //								onLoginAttempt={this.onLoginAttempt}
// //								username={this.state.username} //***what is going on with this username?
// //								onSuccessfulLogin={this.logThemIn}
// //								onUsernameChange={this.onUsernameChange}
// 							/>
// 			}
// 		}
// don't touch the indentation of the dialog (annoying)
		let dialog = this.state.showWelcomeDialog?<Dialog
			dialog_text={`Welcome!

Click 'Teacher Home' in the navigation menu above for:

- Teacher tools
- Video tutorial`}
			onDialogResponse={(responseOption:number)=>this.setState({showWelcomeDialog:false})}
			buttons_text={["Got it"]}
			/>:<span/>;

// 		if (this.state.currentpage === "log_in") {
// 			Page[0] = 	<LoginPage
// 				onLoginSuccess={this.onLoginSuccess}
// 				cookiesdisabled = {this.state.cookiesdisabled}
// 				buttonOnClicks={this.pageChangeFunctions}
// 			/>
// 		}
// 		else if (this.state.currentpage === "register") {
// 			Page[0] = 	<RegisterPage
// 				onRegisterSuccess={this.onLoginSuccess}
// 				cookiesdisabled = {this.state.cookiesdisabled}
// 				buttonOnClicks={this.pageChangeFunctions}
// 			/>
// 		}
// 		console.log(Page[0])
		const user = this.state.user;
		const isUserSuperuser = user && user.usertype === 'superuser';
		const isUserTeacher = user && user.usertype === 'teacher';
		const isUserPupil = user && user.usertype === 'pupil';
		const isUserQcreator = user && user.usertype === 'qcreator';

		
		return (
			<ThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
				<Router>
					<div className="container1" style={{minHeight:"100%"}}>
						<div className="container2" style={{position: "absolute", zIndex: 100, width: "100%"}}>
							<Navigation
								user={this.state.user}
								handleLogout={this.handleLogout}  
							/>

							<Switch>
							<Route 
								exact path = "/" 
								render = {(props: any) => (
								<LandingPage 
									user={this.state.user} 
									handleLogout={this.handleLogout} 
								/>
							)}
							/>
							<ProtectedRoute 
								path="/exercises"
								isAuthenticated={!!user}
								render={(props) => <ExercisesPage {...props} user={user} exerciseButtonFunctions={{ selectWorksheet: this.selectWorksheet }}/>}
							/>
							<ProtectedRoute 
								path="/worksheet/:worksheetId/flashcards"
								isAuthenticated={!!user}
								render={(props) => <FlashcardWorksheetComponent 
													worksheet_ID={Number(props.match.params.worksheetId)}
													globaluser={this.state.user} />} />
							<ProtectedRoute 
								path="/worksheet/:worksheetId" 
								isAuthenticated={!!user}
								render={(props) => <WorksheetComponent 
													worksheet_ID={Number(props.match.params.worksheetId)}
													globaluser={this.state.user}/>} />
							<Route 
								path="/login" 
								render={(props) => (
									<LoginPage
										onLoginSuccess={this.onLoginSuccess}
										handleLogin={this.handleLogin}
										cookiesdisabled = {this.state.cookiesdisabled}
									/>
								)}
							/>
							<Route 
								exact path = "/about" 
								render = {(props: any) => (
								<AboutPage 
								//	user={this.state.user} 
								//	handleLogout={this.handleLogout} 
								/>
							)}
							/>
							<Route 
								exact path = "/blog" 
								render = {(props: any) => (
								<BlogPage 
								//	user={this.state.user} 
								//	handleLogout={this.handleLogout} 
								/>
							)}
							/>
							{/* <Route path="/login" render={(props) => (
								!this.state.user ? (
									<LoginPage
										onLoginSuccess={this.onLoginSuccess}
										handleLogin={this.handleLogin}
										cookiesdisabled = {this.state.cookiesdisabled}
									/>
								) : <LandingPage user={this.state.user} handleLogout={this.handleLogout} />
								)}
							/> */}
							
							<ProtectedRoute 
								path = "/register"
								isAuthenticated={!user}
								render={(props) => (
									<RegisterPage 
										onRegisterSuccess={this.onLoginSuccess}
										handleLogin={this.handleLogin}
										cookiesdisabled = {this.state.cookiesdisabled}
									/>
								)}
							/>
							<ProtectedRoute
								path="/teacherhome"
								isAuthenticated={!!isUserTeacher || !!isUserSuperuser || !!isUserQcreator}
								render={(props) => <TeacherHomePage {...props} globaluser={this.state.user} />}
							/>
							<ProtectedRoute
								path="/users"
								isAuthenticated={!!isUserSuperuser}
								render={(props) => <UserManagerPage {...props} />}
							/>
							<Route path = "/myaccount" render={(props) => (this.state.user ? ( <UserPage user={this.state.user}/>) : null)}/>
							<ProtectedRoute
								path="/reports"
								isAuthenticated={!!isUserSuperuser || !!isUserQcreator}
								render={(props) => <ReportManagerPage {...props} />}
							/>
							<ProtectedRoute
								path="/question_entry"
								isAuthenticated={!!isUserSuperuser || !!isUserQcreator}
								render={(props) => <QuestionEntryPage {...props} />}
							/>
							<ProtectedRoute
								path="/worksheet_manager"
								isAuthenticated={!!isUserSuperuser || !!isUserQcreator}
								render={(props) => <WorksheetManagerPage {...props} globaluser={this.state.user}/>}
							/>
							<ProtectedRoute
								path="/question_manager"
								isAuthenticated={!!isUserSuperuser || !!isUserQcreator}
								render={(props) => <QuestionManagerPage {...props} />}
							/>
							<ProtectedRoute
								path="/test"
								isAuthenticated={!!isUserSuperuser || !!isUserQcreator}
								render={(props) => <TestPage {...props} />}
							/>
							<ProtectedRoute 
								path="/classes/:classID" 
								isAuthenticated={!!user}
								render={(props) => <ClassPage
													{...props}
													class_ID={Number(props.match.params.classID)}
													globaluser={this.state.user}/>} />
							<Route component={NotFound} />
							</Switch>
							
							<div className="main" style={{flexGrow: 1}}>
								<Suspense
									fallback={<div className="center">Loading... If you can read to the end of this then there is problem. Try refreshing the page.</div>}
								>
									{dialog}
								</Suspense>
							</div>
							<Footer /* Add props here REF_51753 *//>
						</div>
					</div>
				</Router>
				</StyledEngineProvider>
			</ThemeProvider>
		)
	}
}
/*class Privacy extends React.Component{
	state = {
		numPages: null,
		pageNumber: 1,
	}

	onDocumentLoadSuccess = ({ numPages }:{numPages:number}) => {
		this.setState({ numPages });
	}

	render() {
		const { pageNumber, numPages } = this.state;

		return (
			<div>
				<Document
					file="\public\radiation.pdf"
					onLoadSuccess={this.onDocumentLoadSuccess}
				>
					<Page pageNumber={pageNumber} />
				</Document>
				<p>Page {pageNumber} of {numPages}</p>
			</div>
			);
		}
}*/
/*
// I've implemented a new Footer using Material UI, so this one is redundant
// - Matt
class Footer extends React.Component{
	render(){
		return(
			<div className="footer">
				<button type="submit" onClick={(e)=>{e.preventDefault();window.open("picture000.jpg")}}>button to open new window</button>
				<form method="get" action="picture000.jpg">
					<button type="submit"
						className="button innavigationbar"
						>Privacy by Form
					</button>
				</form>

				<a  hidden={false} href="/public/radiation2.pdf"
						download="proposed_file_name"
						>Privacy by anchor</a>

			</div>
		)
	}
}
*/
const NotFound = () => (
    <div>
        <h2>404 Not Found</h2>
        <p>The page you are looking for does not exist.</p>
    </div>
);
interface ProtectedRouteProps extends RouteProps {
	// Define additional props you need, if any
	isAuthenticated: boolean; // Example prop for authentication status
	redirectPath?: string; // Optional path to redirect if not authenticated
	render?: (props: any) => React.ReactNode;
  }
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
	isAuthenticated,
	redirectPath = '/',
	render,
	...rest
	}) => {
	return (
		<Route
		{...rest}
		render={(props: { location: any; }) =>
			isAuthenticated ? (
			render ? render(props) : null
			) : (
			<Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
			)
		}
		/>
	);
};
class TestPage extends React.Component {
	state:{
		imgHeight:number,
		imgWidth:number
	}
	constructor(props:Readonly<{}>){//TODO this is dodge.
		super(props);
		this.state={
			imgHeight:0,
			imgWidth:0,
		}
	}
	/*
	showImage(imgPath:string){
		let	imgHeight=0;
		let	imgWidth=0;
		let myImage = new Image();
		myImage.name = imgPath;
		myImage.onload = function getwidthandheight{
			imgHeight = this.height;
			imgWidth = this.width;
				return true;
		}
		myImage.src = imgPath;*/
	render(){
		let img1 = new Image(); //create HTMLImageElement
		img1.onload = function(){
			let height = img1.height;
			let width = img1.width;
			img1.width=img1.height;
		}
		img1.src="/public/1569597122722-19_0_saharaforest.jpg";
		//let img2 = rescaleImage(img1,100,100);
		console.log("img1.src = "+img1.src);
		console.log("img1.src = "+img1.height);
		console.log("img1.src = "+img1.width);
		return(
			<div className="admin_page">
				<p>This is a test page</p>
				<img src={img1.src} height={100} width={100}/>
				<img src={img1.src} height={img1.height} width={img1.width}/>
				<button
					onClick={
						(e:any)=>{fetch('/check_remarking', {method: 'POST',body: "",headers:{'Content-Type': 'application/json'}})}
					}
					>check remarking
				</button>
					
				<button
					onClick={(e:any)=>{loadtest1()}}>
						loadtest
				</button>
				<TeacherToolTabs/>
				
			</div>
		)
		//<img src="/public/1569597122722-19_0_saharaforest.jpg"/>
		//<img src="http://localhost:3000/public/1569597122722-19_0_saharaforest.jpg"/>
	}
}

//Old switch thing
//{<div></div>
	/*(this.state.loggedin)?		//(this.state.loadedconcept && this.state.loadedquestion) ?


//		if(this.state.)
//</Router>
//	<Switch>
//		<Route exact path="/" component={Home} />
//		<Route path="/question_entry" component={QuestionEntryPage} />
//			{/*render={props => <QuestionEntry concept={this.state.concept} {...props} />} />*//*}
//		<Route path="/about" component={About} />
//		<Route path="/exercises" //component={ExercisesPage} />
//			render={props => <ExercisesPage selectWorksheet={this.selectWorksheet} {...props} />} />
//		<Route path="/worksheet" //component={WorksheetComponent} />***need to set the worksheet ID properly
//			render={props => <WorksheetComponent worksheet_ID={this.state.currentworksheet} {...props} />} />
//	</Switch>
//</Switch></Switch>: <LoginPage username={this.state.username} onSuccessfulLogin={this.logThemIn} onUsernameChange={this.onUsernameChange}/>
//					<Route path="/question" //component={Questionpage}
//render={props => <Questionpage question={this.state.question} {...props} />} />
//</Switch></Switch>}
//</Router>*/
//}

//function makequestionbutton(i:number,colourOfButton:string):JSX.IntrinsicElements.button{
//	let questionbutton=
//	return questionbutton
//}




class UserManagerPage extends React.Component {
	state:{
	//	questionclicked:number|undefined,
	//	pupilclicked:number|undefined,
	//	classassignmentdata:ClassAssignmentData|undefined,
	//	worksheet?:Worksheet,
	//	sorted_indices:number[],
	//	anonymise:boolean,
	//	refreshtimeout:any,
	//	sort_type:AssignmentSortType,
	//	sort_reversed:boolean,
	//	component_is_mounted:boolean,
	//	clas:null|Cohort,
	//	compact:boolean,
		fullscreen:boolean,
		usersdata:UserData[],
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
		//	questionclicked:undefined,
		//	pupilclicked:undefined,
		//	classassignmentdata:undefined,
		//	sorted_indices:[],
		//	anonymise:false,
		//	refreshtimeout:null,
		//	sort_type:"lastname",
		//	sort_reversed:false,
		//	component_is_mounted:true,
		//	clas:null,
		//	compact:true,
			//removingpupil:Array(props.classes.length).fill(false),
			fullscreen:true,
			usersdata:[]
		}
		this.refreshUsersData = this.refreshUsersData.bind(this);
	}
	async componentDidMount(){
		this.refreshUsersData();
	}
	async componentWillUnmount(){
//		clearTimeout(this.state.refreshtimeout);
		this.setState({component_is_mounted:false});
	}
	async refreshUsersData(){
//		clearTimeout(this.state.refreshtimeout);
		let usersdata = await fetchUsersData(19);//this.props.class_ID);//I think that this is the class ID for the main class. wierd way to do it.
//	//	classassignmentdata = calcScores(classassignmentdata);
//	//	let sort_params = this.makeSortOrder(classassignmentdata);
//	//	let refreshtimeout = setTimeout(
//	//		()=>{
//	//				this.refreshAssignmentData()
//	//		},
//	//		10000 //wait 1000 milliseconds
//	//	)
//		let setnewtimeout = true;
//	//	if(this.props.class_ID===19||!this.state.component_is_mounted){//we don't want auto refresh for the whole table because it is too intense.
//	//		clearTimeout(this.state.refreshtimeout);
//	//		//clearTimeout(refreshtimeout);
//	//		setnewtimeout = false;
//	//	}
		this.setState({
//	//		sorted_indices:sort_params.sorted_indices,
//	//		sort_type:sort_params.sort_type,
//	//		sort_reversed:sort_params.sort_reversed,
//	//		worksheet,
//	//		classassignmentdata,
//			refreshtimeout:setnewtimeout?setTimeout(
//				()=>{
//						this.refreshAssignmentData()
//				},
//				10000 //wait 1000 milliseconds
//			):null,
//			clas,
			usersdata,
		});
	}
	render(){
		return(
			<div
				className="center"
				style={this.state.fullscreen?{maxWidth:"95vw", width:"95vw"}:{}}
				>
				<h2>User manager page</h2>
				<UserManagerTable
					classID={19}
					usersdata={this.state.usersdata}
					refreshUsersData={this.refreshUsersData}
					anonymise={false}
				//	sorted_indices={Array}
				/>
			</div>
		)
	}
}
class UserManagerTable extends React.Component {
	props!:{
	//	class:Cohort,
		classID:number,
	//	closeAssignmentView:(e:any)=>void,
	//	selectQToView:(Q_index:number)=>void,
	//	selectPToView:(P_index:number)=>void,
		usersdata:UserData[],
	//	classassignmentdata:ClassAssignmentData|undefined,
	//	worksheet:Worksheet|undefined,
	//	assignmentname:string,
	//	sorted_indices:number[]
	//	updateTempPasswords:(p:number,newpassword:string)=>void,
		anonymise?:boolean,
	//	onChangeSortType:(new_sort_type:AssignmentSortType)=>void,
	//	refreshAssignmentData:()=>void,
		refreshUsersData:()=>void,
		//C_ID:number,//could actually just pass class in
		//handleButtonClick:(e:any)=>void,
	}
	state:{
		removingpupil:boolean[],
		modifyingpupil:boolean[],
		showingdialog:boolean,
		showingtypedialog:boolean,
		selecteduser_index?:number,
	}
	constructor(props: Readonly<UserManagerTable["props"]>){
		super(props);
		this.state = {
			showingdialog:false,
			showingtypedialog:false,
			removingpupil:Array(props.usersdata.length).fill(false),
			modifyingpupil:Array(props.usersdata.length).fill(false),
			selecteduser_index:undefined,
		};
		this.passwordReset = this.passwordReset.bind(this);
		this.userUnlock = this.userUnlock.bind(this);
		this.onClickRemoveUser = this.onClickRemoveUser.bind(this);
		this.removeUserConfirmed = this.removeUserConfirmed.bind(this);
		this.removeUser = this.removeUser.bind(this);
		this.changeUserType=this.changeUserType.bind(this);
		this.changeUserTypeConfirmed=this.changeUserTypeConfirmed.bind(this);
		this.onClickChangeUserType=this.onClickChangeUserType.bind(this);
	}
	async passwordReset(p:number){
		let U_ID = this.props.usersdata[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let newpassword = await resetPassword(U_ID,this.props.classID);
		if (!newpassword) throw "new password returned false"
	//	this.props.updateTempPasswords(p,newpassword);
		this.props.refreshUsersData();
	}
	async userUnlock(p:number){
		let U_ID = this.props.usersdata[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let success = await unlockUser(U_ID,this.props.classID);
		if (!success) throw "unlock failed"
		//this.props.updateTempPasswords(p,newpassword);//TODO make it so that the unlock buttons aren't everywhere
	}
	async removeUser(){
		let p = this.state.selecteduser_index;
		if(p===undefined){throw "p should not be undefined when removing user"}
		let newState = update(this.state,
			{
				showingdialog:{$set:false},
				removingpupil: {
					[p]: {$set:true},
				},
			}
		)
		this.setState(newState);
		let U_ID = this.props.usersdata[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let success = await removeUserFromClass(U_ID,this.props.classID);
		if (!success) throw "unlock failed"
		else {
			this.props.refreshUsersData();
			this.setState({removingpupil: Array(this.props.usersdata.length).fill(false)});
		}
	}
	onClickRemoveUser(p:number){
		this.setState({
			showingdialog:true,
			selecteduser_index:p,
		})
	}
	removeUserConfirmed(confirmed:boolean){
		if(confirmed){
			this.removeUser();
		} else {
			this.setState({showingdialog:false})
		}
	}
	onClickChangeUserType(p:number){
		this.setState({
			showingtypedialog:true,
			selecteduser_index:p,
		})
	}
	changeUserTypeConfirmed(response:number){
		if(response===1){
			this.changeUserType();
		} else if (response===2){
			this.setState({showingtypedialog:false})
		} else {
			throw "we shouldn't be here changeUserTypeConfirmed"
		}
	}
	async changeUserType(){
		let p = this.state.selecteduser_index;
		if(p===undefined){throw "p should not be undefined when modifying user"}
		let newState = update(this.state,
			{
				showingtypedialog:{$set:false},
				modifyingpupil: { //may need to be specific to type of modification
					[p]: {$set:true},
				},
			}
		)
		this.setState(newState);
		let U_ID = this.props.usersdata[p].U_ID;
		console.log(U_ID)//TODO should send through U_ID not p.
		let success = await changeUserType(U_ID);
		if (!success) throw "change user type failed"
		else {
			this.props.refreshUsersData();
			this.setState({modifyingpupil: Array(this.props.usersdata.length).fill(false)});
		}
	}
	render(){
		var header = [];
		var rows = [];
		//if((this.props.worksheet!=undefined)&&(this.props.classassignmentdata!=undefined)){
		if(this.props.usersdata!==undefined){
		//	if(!this.props.anonymise){
			header.push( <th
				className="freezecol"
				key={"lastname"}
				//onClick={(e)=>this.props.onChangeSortType("lastname")}
			>Lastname</th>);
			header.push( <th key={"U_ID"} className={"notprintable"}>U_ID</th> );
			header.push( <th key={"usertype"} className={"notprintable"}>Type</th> );
			header.push( <th key={"firstname"} className={"notprintable"}>Firstname</th> );

		//		header.push( <th key={"presence"} >Present</th>);//onClick={(e)=>this.props.onChangeSortType("lastname")}
		//	};
			//Q1 Q2 Q3
		//	for(let i=0;i<this.props.worksheet.Q_IDs.length;i++){
		//		//header[i+2]= <th key={i+2}>Q{i+1}</th>;
		//		header.push(
		//			<th key={header.length}
		//				style={{minWidth:"1.3em"}}
		//				onClick={(e)=>{e.preventDefault();this.props.selectQToView(i)}}
		//				>{i+1}
		//			</th>
		//		);
		//	}
		//	if(!this.props.anonymise){
				header.push( <th key={"email"} className={"notprintable"}>Email</th> );
				header.push( <th key={"username"} className={"notprintable"}>Username</th> );
				header.push( <th key={"password"} className={"notprintable"}>Password</th> );
				header.push( <th key={"remove"} className={"notprintable"}>Remove</th> );
		//	};
		//	let pupilswsstats = this.props.classassignmentdata.pupilswsstats;
		//	let pupilsnames = this.props.classassignmentdata.pupilsnames;
		//	if(pupilswsstats===undefined)throw "pupilswsstats is undefined"
		//	if(pupilsnames===undefined)throw "pupilsnames is undefined"
			//each pupil
			for(let po=0;po<this.props.usersdata.length;po++){
				let p = po;//this.props.sorted_indices[po]; //po
				let duplicateID = (this.props.usersdata[p-1]===undefined)?false:(this.props.usersdata[p].U_ID===this.props.usersdata[p-1].U_ID);
				let row = [];//where the data for this pupil is put before being added to rows[]
				if(!this.props.anonymise){
					row.push( <th
						className="freezecol"
						key={"lastnamevalues"}
		//				onClick={e=>{e.preventDefault();this.props.selectPToView(po)}}
						//>{this.props.anonymise?"":pupilsnames[p].lastname}
						>{this.props.usersdata[p].lastname}
					</th> );
					row.push(
						<th
							className={"notprintable"+(duplicateID?" duplicateID":"")}
							key={"U_ID"}
							>{this.props.usersdata[p].U_ID}
						</th>
					);
					row.push(
						<th
							className={"notprintable"}
							key={"type"}
							onClick={(e)=>{e.preventDefault();this.onClickChangeUserType(p);}}
							>{this.props.usersdata[p].usertype}
						</th>
					);
					row.push(
						<th
							className={"notprintable"}
							key={"firstname"}
							>{this.props.usersdata[p].firstname}
						</th>
					);

		//		};
	//			let presence = 0;
	//			let presence_text = "";
	//			let now = new Date();
	//	//		let last_mod_date = pupilswsstats[p].last_mod_date;
	//			if(last_mod_date===undefined){
	//				last_mod_date=new Date();
	//			}
	//			if((now.getTime()-last_mod_date.getTime())/(1000*60*60*24)>7){
	//				presence = 0.0;//0;
	//				presence_text = ">week";
	//			} else if((now.getTime()-last_mod_date.getTime())/(1000*60*60)>1){
	//				presence = 0.20//7;
	//				presence_text = ">hour";
	//			} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>10){
	//				presence = 0.40//60;
	//				presence_text = ">10min";
	//			} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>5){
	//				presence = 0.60//10;
	//				presence_text = ">5min";
	//			} else if((now.getTime()-last_mod_date.getTime())/(1000*60)>2){
	//				presence = 0.80//5;
	//				presence_text = ">2min";
	//			} else if((now.getTime()-last_mod_date.getTime())/(1000*60)<=2){
	//				presence = 1.00//2;
	//				presence_text = "now";
	//			} else { throw "last_mod_date is: "+last_mod_date}
	//			let [textcolorPres,backgroundColorPres]=coloursFromPercent(presence); //Display colour
	//			if(!this.props.anonymise){
	//				row.push( <th //Ini
	//							key={"presence"}
	//							style={{backgroundColor:backgroundColorPres}}//color:textcolor,
	//							//{JSON.stringify(pupilswsstats[p].last_mod_date)}
	//							>{presence_text}
	//				</th> );
	//			}
		//		if(!this.props.anonymise){

					row.push(
						<th
							className={"notprintable"}
							key={"emailAddress"}
							>{this.props.usersdata[p].emailAddress}
						</th>
					);
					row.push(
						<th
							className={"notprintable"}
							key={"username"}
							>{this.props.usersdata[p].username}
						</th>
					);
				//	let temppasswords = this.props.classassignmentdata.temppasswords;
				//	if(temppasswords===undefined)throw "temppasswords is undefined"
					let pupiltemppassword = this.props.usersdata[p].temppassword;
					row.push(
						<th
							style={{whiteSpace:"nowrap"}}
							key={"psswrdreset"}
							className={"notprintable"}
							>{(pupiltemppassword===undefined||pupiltemppassword===null)?
								<button
									onClick={(e)=>{e.preventDefault();this.passwordReset(p)}}
									>reset
								</button>
								: pupiltemppassword
							}
							<button
								onClick={(e)=>{e.preventDefault();this.userUnlock(p)}}
								><i className="fa fa-unlock"/>
							</button>
						</th>
					);
					let delete_button = this.state.removingpupil[p]?"removing...":<button
						onClick={(e)=>{e.preventDefault();this.onClickRemoveUser(p)}}
						>❌
					</button>
					row.push(
						<th
							style={{whiteSpace:"nowrap"}}
							key={"remove"}
							className={"notprintable"}
							>
							{delete_button}
						</th>
					);
				};
				//add pupil row to table
				rows.push(<tr key={rows.length}>{row}</tr>);
			}
		} else {
			header[0] = <th key="0">Loading table</th>;
		}
		let selecteduser
		if(this.state.selecteduser_index!==undefined){
			selecteduser = this.props.usersdata[this.state.selecteduser_index].username;
		} else {
			selecteduser= "no selected user";
		}
		return(
			<div>
				<div className="assignment_table_div">
					<table>
						<thead>
							<tr>
								{header}
							</tr>
						</thead>
						<tbody>
							{rows}
						</tbody>
					</table>
				</div>
				<div
					hidden = {!this.state.showingdialog}>
					<DeleteDialog
						onDeleteDialogResponse={this.removeUserConfirmed}
						record_type="pupil"
					/>
				</div>
				<div
					hidden = {!this.state.showingtypedialog}>
					<Dialog
						onDialogResponse={this.changeUserTypeConfirmed}
						buttons_text={["Confirm","Cancel"]}
						dialog_text={"Are you sure you want to change the type of "+selecteduser}
					/>
				</div>
			</div>
		)//TODO not sure what TableKey is doing there.
	}
}

async function changeUserType(U_ID: Number): Promise<boolean> {//{success:boolean,currentpasswordcorrect:boolean,newpassword:string} success:true,currentpasswordcorrect:true,newpassword:returnpassword?newpassword:false});
	console.log('changing type user for Q_ID,'+U_ID);
	var url = '/changeusertype';//yes, I should change the name of this.
	var data = {U_ID};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
	let js_obj = await response.json();
	return js_obj.success;
}


/*
class Assignment_table_body extends React.Component {
	props!:{
		questions:Question[],
		handleButtonClick:(e:any)=>void,
	}
	render(){
		let assignment_table_rows = [];
		let rowcount=-1;
		for(let i=0;i<this.props.questions.length;i++){
			if(true){//this.props.questions[i].info.status!=="deleted"){
				rowcount++;
				assignment_table_rows[rowcount] =
					<tr key={i}>
						<th>{this.props.questions[i].Q_ID}</th>
						<th>{this.props.questions[i].name}</th>
						<th><button
								name={JSON.stringify({
									Q_ID:this.props.questions[i].Q_ID,
									action:"view",
								})}
								onClick={this.props.handleButtonClick}
						>__</button></th>
						<th><button
								name={JSON.stringify({
									Q_ID:this.props.questions[i].Q_ID,
									action:"edit",
								})}
								onClick={this.props.handleButtonClick}
						>__</button></th>
						<th><button
								name={JSON.stringify({
									Q_ID:this.props.questions[i].Q_ID,
									action:"copy",
								})}
								onClick={this.props.handleButtonClick}
						>__</button></th>
						<th><button
								name={JSON.stringify({
									Q_ID:this.props.questions[i].Q_ID,
									action:"delete",
								})}
								onClick={this.props.handleButtonClick}
						>__</button></th>
					</tr>
			}
		}
		return(
			<tbody>
				{assignment_table_rows}
			</tbody>
		)
	}
}*/

class ReportManagerPage extends React.Component {
	state:{
		reports?:Report[],
		openedreport_ID?:number,
	//	openedclass_ID?:number,
		reportclicked:boolean,
	//	selected_assignments_to_set:number[],
	//	publicworksheets:{worksheet_name:string, W_ID:number}[],
		admin:boolean,
	//	updateResolved:boolean,
	//	R_ID:number,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			//classes:[],
			//openedassignment_ID:null,
			//openedclass_ID:null,
			reportclicked:false,
		//	publicworksheets:[],
		//	selected_assignments_to_set:[],
			admin:false,
			reports:[],
		//	updateResolved:false,
		//	R_ID:0,
		}
		this.onReportbuttonclicked=this.onReportbuttonclicked.bind(this);
	//	this.onSetAssignmentbuttonclicked=this.onSetAssignmentbuttonclicked.bind(this);
		this.closeReportView=this.closeReportView.bind(this);
		this.fetchReports=this.fetchReports.bind(this);
		this.report=this.report.bind(this);
	//	this.fetchPublicWorksheetTitles=this.fetchPublicWorksheetTitles.bind(this);
	//	this.deleteClass=this.deleteClass.bind(this);
	}
	componentDidMount(){
		this.fetchReports();
	//	this.fetchPublicWorksheetTitles();
	}
	fetchReports(){
		console.log("fetching reports");
		fetch("/getallreports") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
		.then(response => response.json())
		.then(js_obj => this.setState({
			reports:js_obj,
		//	selected_assignments_to_set:Array(js_obj.length) //should fill array so that I don't have undefined
		}));
	}
	//fetchPublicWorksheetTitles(){
	//	console.log("fetchPublicWorksheetTitles");
	//	fetch("/fetchPublicWorksheetTitles") //not sure exactly where this would go, I don't think I want it to make a fetch for concepts everytime the person creates a question, how often does render run? Perhaps this should go into state?
	//	.then(response => response.json())
	//	.then(js_obj => this.setState({
	//		publicworksheets:js_obj.publicworksheets
	//	}));
	//}
//	onSetAssignmentbuttonclicked(classindex:number,W_ID:number){
//		console.log("onSetAssignmentbuttonclicked");
//		if(!(this.state.selected_assignments_to_set===undefined||this.state.classes===undefined)){
//			if(this.state.selected_assignments_to_set.length>classindex||this.state.classes.length>classindex){
//				let temp = this.state.selected_assignments_to_set;
//				temp[classindex] = -1;//W_ID;
//				this.setState({
//					selected_assignments_to_set:temp
//				})
//			//	let editted_class_record = this.state.classes[classindex];
//			//	editted_class_record.Assignments[0]. .push(W_ID);
//			//	var url = '/modifyRecord';
//			//	var data = {action:edit,
//			//			ID:this.state.classes[classindex].ID,//using record instead of 'worksheet' as trying to build generic function on App.js side.
//			//			record_type:"classe",
//			//			record:this.state.worksheets[index],
//			//			};
//				let url = '/set_assignment';
//				let data = {
//					class_ID: this.state.classes[classindex].ID,
//					W_ID :W_ID,
//				}
//				fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
//				.then(res => res.json())
//				.then(res => this.fetchClasses())
//			} else {throw "trying to onSetAssignmentbuttonclicked when classindex >= this.state.selected_assignments_to_set.length"}
//		} else {throw "trying to onSetAssignmentbuttonclicked when selected_assignments_to_set is undefined"}
//	}
	onReportbuttonclicked(R_ID:number){//e: React.MouseEvent<HTMLButtonElement>){
		//console.log("Button clicked with: "+JSON.stringify(e.currentTarget.name,null,4));
		this.setState({	openedreport_ID:R_ID,//JSON.parse(e.currentTarget.name).Assignment_ID,
						//openedclass_ID:JSON.parse(e.currentTarget.name).Class_ID,
						reportclicked:true,
					});
		//console.log("setstate successful");
	}
	closeReportView(){
		//console.log("Assignment closed");
		this.setState({	openedreport_ID:null,
						//openedclass_ID:null,
						reportclicked:false,
					});
	}
	report():Report{
	//	console.log("PupilManagerPage is getting a class to give to assignmentview");
		if(this.state.reports!=null){
			let openedreport = this.state.reports.find(e=>e.ID===this.state.openedreport_ID);
			if(openedreport!=null){
				console.log("found a report");
				return openedreport
			} else{
				console.log("could not find report matching this ID");
				throw "could not find report matching this ID";
			}
		} else {
			console.log("tried to open a report when there was no report");
			throw "tried to open a report when there was no report";
		}

	}
	render(){
		var managerbody=<p>There has been an error</p>;
		if(!this.state.reports){
			console.log("reports not loaded"+JSON.stringify(this.state.reports));
			managerbody = <p>Loading reports...</p>;
		} else {
			console.log("reports loaded");//+JSON.stringify(this.state.classes));
			if(!this.state.reportclicked){
				console.log("report not clicked");
				managerbody = 	<div>
									<Reportlist
										reports={this.state.reports}
										handleClick={this.onReportbuttonclicked}
										fetchreports={this.fetchReports}
										//updateresolved={this.state.updateResolved}
										//R_ID={this.state.R_ID}
										//setAssignmentSelected={this.onSetAssignmentbuttonclicked}
										//publicworksheets={this.state.publicworksheets}
										//selected_assignments_in_dropdowns={this.state.selected_assignments_to_set}
										//deleteClass={this.deleteClass}
									//	fetchReports={this.fetchReports} //don't think I need this as I'm not going to delete stuff
									/>
								</div>
			} else {
				console.log("assignment clicked");
				if(this.state.openedreport_ID!=null){//&&(this.state.openedclass_ID!=null)){
					console.log("assignment clicked, openedassignment set, openedclass set will attempt to render AssignmentView");
					managerbody = 	<span>
										<ReportView
											closeReportView={this.closeReportView}
											Report_ID={this.state.openedreport_ID}
											report={this.report()}
										//	admin={this.state.admin}
										/>
									</span>
				}else{
				//	console.log("either opened assignment_ID or openedclass_ID are not true");
				//	console.log("this.state.openedassignment_ID"+this.state.openedassignment_ID);
				//	console.log("this.state.openedclass_ID"+this.state.openedclass_ID);
					throw "tried to open rport view but didnt have this.state.openedreport_ID";// or this.state.openedclass_ID";
				}
			}
		}
		return(//Report manager body will take worksheet and class as props, eventually the teacher will have a list or classes to view
			<div className="center">
				<h2>Report manager page</h2>
				<span
					hidden = {globaluser!=undefined?globaluser.usertype!=="superuser":false}>
					<button
						className="button innavigationbar float"
						onClick={(e)=>{e.preventDefault();this.setState({admin:!this.state.admin})}}
						>Admin
					</button>
				</span>
				{managerbody}
			</div>
		)
		//<PupilManagerInstructions/>
	}
}

class Reportlist extends React.Component {

	state!:{
		value: string,
		questionIdSearch?: number,
		hidden: number,
	}
	
	props!:{
		reports:Report[],
		handleClick:(R_ID:number)=>void,
		fetchreports:()=>void,
	}

	constructor(props: any) {
		super(props);
		this.state={
			value: 'No',
			questionIdSearch: 0,
			hidden: 1,
		}
	}

	onTodoChange(event: any){
        this.setState({
             questionIdSearch: event
        });
    }

	handlehidden(){
		if (this.state.hidden == 1) {
			this.setState({
				hidden: 0,
			})
        } else {
			this.setState({
				hidden: 1,
			})
		} ;
    }

	isResolved(props: any) {
		const r = this.props.reports;
		let resolved = [];
		for(let i=r.length - 1;i>-1;i--) {
			resolved.push(r[i].isresolved)
		}
		if (props == true) {
		  return 'Yes';
		}
		return 'No';
	  }
	  
	  handleresolved(R_ID:number){
		var url = '/resolvereport';
		var data = {
			R_ID: R_ID,
		};
		fetch(url, {method: 'POST', body: JSON.stringify(data), headers:{'Content-Type': 'application/json'}
				}).then(res => res.json())
				.then(response => {
					console.log('Success:', JSON.stringify(response))
					this.props.fetchreports();
				})
				.catch(error => console.error('Error:', error));
	}


// workinghere


	render(){
		const r = this.props.reports;
		let records = [];
		var today = new Date();
		var date = today.getTime()
		for(let i=r.length - 1;i>-1;i--){

			var datecreated = new Date(r[i].info.creation_date).getTime();

			if ((this.state.hidden == 1)) {
				if ((this.state.questionIdSearch == 0)||(r[i].Q_ID == this.state.questionIdSearch)) {
					records.push(
						<tr className="zebra_table_body"
						hidden={r[i].isresolved}>
							<td><button
								key = {i}
								onClick = {(e:any)=>{e.preventDefault(); this.props.handleClick(r[i].ID)}}
								>{i}
							</button></td>
							<td>{r[i].U_ID}</td>
							<td><button
								onClick = {(e:any)=>{e.preventDefault(); this.handleresolved(r[i].ID)}}
								>{this.isResolved(r[i].isresolved)}
							</button></td>
							<td>{r[i].Q_ID}</td>
							<td><div 
								style={{display:"block",maxHeight:"500px",overflow:"hidden"}}
								>{r[i].str}
								</div>
							</td>
							<td>{Math.round((date - datecreated)/(1000*60*60*24))}</td>
						</tr>
					)
				} 
			} else {
				records.push(
					<tr className="zebra_table_body"
					hidden={r[i].isresolved == false}>
						<td><button
							key = {i}
							onClick = {(e:any)=>{e.preventDefault(); this.props.handleClick(r[i].ID)}}
							>{i}
						</button></td>
						<td>{r[i].U_ID}</td>
						<td><button
							onClick = {(e:any)=>{e.preventDefault(); this.handleresolved(r[i].ID)}}
							>{this.isResolved(r[i].isresolved)}
						</button></td>
						<td>{r[i].Q_ID}</td>
						<td>{r[i].str}</td>
						<td>{Math.round((date - datecreated)/(1000*60*60*24))}</td>
					</tr>
				)}
		}
		
		return(
			<div>
				<input type="search" placeholder=" Search for Q_ID" onChange={e => this.onTodoChange(e.target.value)}></input>
				<button onClick={e => this.handlehidden()}>Show/hide resolved </button>
				<table>
					<thead className="zebra_table_head">
					<tr>
						<th>R_ID</th>
						<th>U_ID</th>
						<th>Resolved</th>
						<th>Q_ID</th>
						<th>Comment(s)</th>
						<th>Days Ago</th>
					</tr>
					</thead>
					<tbody>
					{records}
					</tbody>
				</table>
			</div>
			
		)
	}
}

class ReportView extends React.Component {
	props!:{
		Report_ID:number,
		report:Report,
		closeReportView:()=>void,
		}
	state:{
		edittoropened:boolean,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			edittoropened:false,
		}
	}
//	async componentDidMount(){
//		let [worksheet,classassignmentdata] = await fetchClassAssignmentData(this.props.Assignment_ID,this.props.class.ID)
//		this.makeSortOrder(classassignmentdata);
//		classassignmentdata = calcScores(classassignmentdata);
//		this.setState({worksheet,classassignmentdata});
//	}
	render(){
		let Q_ID = this.props.report.Q_ID;
		if(Q_ID===undefined){throw "Q_ID undefined in ReportView"}
		let questionview = <span></span>;
		if(Q_ID!==undefined){
			questionview =
			<QuestionView
				key={0}
				Q_ID={Q_ID}
			/>
		}
		let feedbackhead = "";
		let feedbackbody:FeedbackObject[] = [];
		if(this.props.report.feedback===undefined){}
		else{
			for(let f = 0 ; f<this.props.report.feedback.head.length; f++){
				if (this.props.report.feedback.head[f]!=null){ //this is feeling dodgy, apparently, this is never null.
					feedbackhead = this.props.report.feedback.head[f];
					feedbackbody = this.props.report.feedback.body[f];
				}
			}
		}
		
		return(
			<span>
				<button
					className="button innavigationbar float"
					onClick={(e)=>{e.preventDefault();this.props.closeReportView()}}
					>Back
				</button>
				<button 
					className="button innavigationbar float"
					// onClick = {(e:any)=>{e.preventDefault(); this.props.handleClick(r[i].ID)}}
				>Next
				</button>
				<button className="button innavigationbar float"
				>Prev
				</button>
				<div >
					<h2>
						Report inspector
					</h2>
				</div>
				<p>{(this.props.report.isresolved===true)?"resolved":"open"}</p>
				<p>concern: {this.props.report.str}</p>
				<p>formvalues: {JSON.stringify(this.props.report.formvalues)}</p>
				<p>feedback:</p>
				<FeedbackBox
					faded={false}//possibly want to know if it is faded to know if it applies to their current formvalues.
					head={feedbackhead}
					feedbackbody={feedbackbody}
				/>
				<p>Q_ID: {this.props.report.Q_ID}</p>
				{questionview}
				<QuestionAnswersComponentWrapper
					Q_ID = {Q_ID}
					U_ID = {this.props.report.U_ID}
					displayed = {true}//{this.state.showingviewofanswers}
				/>
			</span>
		)
	//	<div
	//				//hidden={!displaying_responses}
	//				style={{"display":displaying_responses?"inline":"none"}}>
	//
	//			</div>
	}
}

//function Topics({ match }) {
//	return (
//		<div>
//			<h2>Topics</h2>
//			<ul>
//				<li>
//					<Link to={`${match.url}/rendering`}>Rendering with React</Link>
//				</li>
//				<li>
//					<Link to={`${match.url}/components`}>Components</Link>
//				</li>
//				<li>
//					<Link to={`${match.url}/props-v-state`}>Props v. State</Link>
//				</li>
//			</ul>
//
//			<Route path={`${match.path}/:topicId`} component={Topic} />
//			<Route
//				exact
//				path={match.path}
//				render={() => <h3>Please select a topic.</h3>}
//			/>
//		</div>
//	);
//}

//function Topic({ match }) {
//	return (
//		<div>
//			<h3>{match.params.topicId}</h3>
//		</div>
//	);
//}

export default withRouter(App);
