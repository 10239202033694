//import { createLogger, format, transports } from 'winston';
import { FilterAll, DBQFilterMap, FilterDB, ORobj, KeyTermProperties, ORobjmini} from './marking_interfaces';
//import { FieldType, FieldTypes, getlongtypes, getfieldtypes, FeedbackObject, Question} from '../../shared_files/my_interfaces';
import { parseMantissa } from '../../significant_figures';

//export var logger: any = null;

//if (typeof window !== 'object') {
//    import('winston').then((winston) => {
//        var winstonConfig = function() {
//            return {
//                level: 'info',
//                format: winston.format.combine(
//                    winston.format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
//                    winston.format.errors({ stack: true }),
//                    winston.format.splat(),
//                    winston.format.prettyPrint(),
//                    winston.format.printf(info => `${info.timestamp} ${info.level}: ${info.message}`)
//                ),
//                defaultMeta: { service: 'marking' },
//                transports: [
//                    new winston.transports.Console({ level: 'info' }),
//                    new winston.transports.File({ filename: 'combined.log' })
//                ]
//            };
//        };
//        logger = winston.createLogger(winstonConfig());
//    }).catch((err) => {
//        console.error("Failed to load Winston:", err);
//    });
//}
//error: 0, 
//warn: 1, 
//info: 2, 
//verbose: 3, 
//debug: 4, 
//silly: 5 

//https://stackoverflow.com/questions/15604140/replace-multiple-strings-with-multiple-other-strings


//let symbolPanelSymbols = ["√","÷","×","²"];
export const symbolPanelSymbolsShared= ["√","÷","×","λ","ρ","Ω","⁻","⁺","¹","²","³","⁴","⁵","⁶","⁷","⁸","⁹","⁰"];
let symbolPanelSymbolsSharedRegexes = symbolPanelSymbolsShared.map((s) => new RegExp(s, "g"));
//symbolPanelSymbolsShared= ["√","÷","×","²","⁻¹","λ","ρ"];

/**
 * returns canonical string in the fastest way
 * updates orobj if needed
 * @param orobj 
 * @returns 
 */
export function getCanonicalOrstr(orobj:ORobj|ORobjmini):string{//TODO, this should be pre-done for everything
    if(orobj.str==undefined){
        throw "orobj.str shouldn't be undefined here, we should not be using an ORobjmini at this point"
    }
    if(orobj.striscanonical===true){
        return orobj.hashhandled||orobj.str;//possibly dodge.
    } else if(orobj.striscanonical===false){
        if(orobj.canonicalstr!=undefined){
            return orobj.canonicalstr;
        } else{
            orobj.canonicalstr = handlePunctuation(orobj.hashhandled||orobj.str);
            return orobj.canonicalstr;
        }//throw "getCanonicalOrstr if striscanonical is false then there should be a canonicalstr orobj: "+JSON.stringify(orobj,null,4);//maybe not, maybe we need to make one. 
    //} else if(orobj.canonicalstr==undefined){
    } else if(orobj.striscanonical==undefined){
        let handledstr = handlePunctuation(orobj.hashhandled||orobj.str);
        if(handledstr===orobj.str){
            orobj.striscanonical=true;
        } else {
            orobj.canonicalstr=handledstr;
            orobj.striscanonical=false;
        }
        return handledstr;
    } else throw "got to end of getCanonicalOrstr"
}

export function handlePunctuation(string:string,context?:string):string{
    let original_string = string;
    //console.log("handle punctuation called on string: "+string+" context: "+(context?context:""));//TODO should this be the same as the one below for tokenise_answer
//    let haspunctuation = ["[",".",",","|","]","/","#","!","%","^","&","*",";",":","=","'","-","_","~","\n"].some((char:string)=>string.includes(char));
    //string = string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, ' $& ');
    //string = string.replace(/[.,\/#!%\^&\*;:=\-_`~]/g,""); //replace punctuation with nothing         //let punctuationless = string.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,""); //replace punctuation with nothing
        for(let i=0;i<symbolPanelSymbolsSharedRegexes.length;i++){
            string = string.replace(symbolPanelSymbolsSharedRegexes[i]," $& ");
        }
        //string = string.replace(/[.,\[\|\]\/#!%\^&\*;:=\-_`~\n]/g," $& ")
        string = string.replace(/[><+⟶→➡➡️√÷×²\.\,\[\|\]\/#\!\?%\^&\*;:=(){}\-_`~\nΔδ𝛿]/g," $& ")
        .replace(/\|/g,"} / / {").replace(/\[/g,"{{{").replace(/\]/g,"}}}")//handle optional syntax//TODO only want to do this if they have matching brackets correct.Can't put here as it seems to run it repeatedly!
        .replace(/([\{\(]) */g," $1").replace(/ *([\}\)])/g,"$1 ")//removes spaces inside {} but adds a space outside
        .replace(/([\{\(]) */g,"$1").replace(/ *([\}\)])/g,"$1")//removes spaces inside that may have been added by the ABOVE STEP
        .replace(/ +/g," ")
        .replace(/^ +/g,"")
        .replace(/ +$/g,"")
        .replace(/ \. \./g,"..")//could replace replace(/ {2,}/g," ") with replace(/ +/g," ") ?
        .replace(/\} ! _ \{/g,"")//replace {A} !_ {B} with {AB} obviously doesn't work with ] and [ being used. could fix that in future. 
        .replace(/(\d) *\. *(\d)/g,"$1.$2") //removes space around point when it has a digit on either side. )
        .replace(/(\de) *([⁻⁺\-+]) *(\d)/g,"$1$2$3"); //removes space around 1.23456e - 8
    //    .replace(/(- *){1,4}>/g,"⟶"); //removes space around 1.23456e - 8
    
    //.replace(/ *\} *! *_ *\{ */g,"");//replace {A} !_ {B} with {AB} obviously doesn't work with ] and [ being used. could fix that in future. 
//	string = string.replace(/ +/g," "); //replace pairs groups of spaces with a single space
    //string = string.replace(/\s{2,}/g," "); //replace pairs groups of spaces with a single space
  //  string = string.replace(/^ +/g,"").replace(/ +$/g,""); //get rid of spaces at the ends
//    if(context!==undefined){
//        //if(string !== original_string){
//            logger.info(context+`: original_string:
//    `+original_string+` new: 
//    `+string);
//         //   }
//    }  
    return string
}
export function handleSpaces(string:string):string{
    string = string.replace(/ +/g," ")
    .replace(/^ +/g,"")
    .replace(/ +$/g,"");
    return string;
}
export function tokenise_answer(inputstring:Readonly<string>):string[]{
    let string = inputstring;
    for(let i=0;i<symbolPanelSymbolsSharedRegexes.length;i++){
        string = string.replace(symbolPanelSymbolsSharedRegexes[i]," $& ");
    }
    string = string.replace(/[><+⟶→➡➡️√÷×²\.\,\/#\!\?%\^&\*;:=(){}\-_`~\nΔδ𝛿]/g," $& ")//TODO do these back slashes need to go elsewhere.
    .replace(/ +/g," ")
    .replace(/^ +/g,"")
    .replace(/ +$/g,"")//could replace replace(/ {2,}/g," ") with replace(/ +/g," ") ?
	.replace(/ +/g," ") //replace pairs groups of spaces with a single space
    //string = string.replace(/\s{2,}/g," "); //replace pairs groups of spaces with a single space
    .replace(/(\d) \. (\d)/g,"$1.$2")
    .replace(/^ +/g,"").replace(/ +$/g,"") //get rid of spaces at the ends
    .replace(/(\de) ([⁻⁺\-+]) (\d)/g,"$1$2$3"); //removes space around 1.23456e - 8//I think handle exp and Exp etc is done before this state in markit. TODO move to hear. 


    let token_array = string.split(" ");
    return token_array;
}
export function tokenise(string:string):string[]{
    //handle punctuation
    string = string.replace(/[√÷×²\.\,\/#\!\?%\^&\*;:=(){}\-_`~\nΔδ𝛿]/g," $& ")
    .replace(/ +/g," ")
    .replace(/^ +/g,"")
    .replace(/ +$/g,"")//could replace replace(/ {2,}/g," ") with replace(/ +/g," ") ?
	.replace(/ +/g," ") //replace pairs groups of spaces with a single space
    //string = string.replace(/\s{2,}/g," "); //replace pairs groups of spaces with a single space
    .replace(/^ +/g,"").replace(/ +$/g,""); //get rid of spaces at the ends

    let token_array = string.split(" ");
    return token_array;
}
export function comparesf(useranswer:string,stdformtoken:string){
    //assuming that the useranswer has been determined to be a number (if converted)
    //check that the two numbers have the same value?
    let useranswer2 = useranswer.replace(".","");
    let stdformtoken2 = stdformtoken.replace(".","");
    let msdigitstring = stdformtoken2.substring(0,stdformtoken2.indexOf("e"));
    let testdigit = useranswer2[useranswer2.indexOf(msdigitstring)+msdigitstring.length];
    return testdigit!=="0"
}
export function digitFromSuperscript(superChar:string):string {
    let result = "⁰¹²³⁴⁵⁶⁷⁸⁹".indexOf(superChar);
    if(result > -1) { 
        return result.toString(); 
    }
    else { 
        if(superChar==="⁻"){return "-"}
        if(superChar==="⁺"){return "+"}
        return superChar; 
    }
}
export function digittoSuperscript(digits:string):string {
    let superscriptdigits = "⁰¹²³⁴⁵⁶⁷⁸⁹";
    let digitstring = "";
    for (let d = 0 ; d<digits.length; d++){
        let digit = digits[d];
        if(digit==="-"){
            digitstring += "⁻";
        } else if(digit==="+"){
            digitstring += "⁺";
        } else if ("0123456789".indexOf(digit)>-1){
            digitstring += superscriptdigits[Number(digits[d])];
        } else throw "unrecognised character sent to digittoSuperscript: "+digit;
    }
    return digitstring;
}
export function prettyNumber(numbertobemadepretty:string):string {
    let myRegexp = /(\d+)e(\-?\d+)/g;
    let match = myRegexp.exec(numbertobemadepretty);
    if (match == null) {
        return numbertobemadepretty
    }
//    logger.info("match[3]: "+match[3]);
    let newtext = ""+match[1]+" × 10"+digittoSuperscript(match[2]);//match[2]==="-"?+
    let prettynumber = numbertobemadepretty.replace(match[0],newtext);
    return prettynumber;
}
//export function handlePunctuation(string:string):string{
//    console.log("handle punctuation called on string: "+string);
//    let haspunctuation = ["[",".",",","|","]","/","#","!","%","^","&","*",";",":","=","'","-","_","~","\n"].some((char:string)=>string.includes(char));
//    //string = string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, ' $& ');
//    //string = string.replace(/[.,\/#!%\^&\*;:=\-_`~]/g,""); //replace punctuation with nothing         //let punctuationless = string.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,""); //replace punctuation with nothing
//    if(haspunctuation){
//        string = string.replace(/[.,\[\|\]\/#!%\^&\*;:=\-_`~\n]/g," $& ")
//        .replace(/\|/g,"} // {").replace(/\[/g,"{{").replace(/\]/g,"}}")//handle optional syntax//TODO only want to do this if they have matching brackets correct.Can't put here as it seems to run it repeatedly!
//        .replace(/\{ */g," {").replace(/ *\}/g,"} ")//removes spaces inside {} but adds a space outside
//        .replace(/\{ */g,"{").replace(/ *\}/g,"}");//removes spaces inside that may have been added by the ABOVE STEP
//    }
//    if(string.includes(" ")){
//        string = string.replace(/ +/g," ")
//        .replace(/^ +/g,"")
//        .replace(/ +$/g,"");
//    }
//    if(haspunctuation){
//        string = string.replace(/ \. \./g,"..")//could replace replace(/ {2,}/g," ") with replace(/ +/g," ") ?
//        .replace(/\} ! _ \{/g,"");//replace {A} !_ {B} with {AB} obviously doesn't work with ] and [ being used. could fix that in future. 
//    }
//    //.replace(/ *\} *! *_ *\{ */g,"");//replace {A} !_ {B} with {AB} obviously doesn't work with ] and [ being used. could fix that in future. 
////	string = string.replace(/ +/g," "); //replace pairs groups of spaces with a single space
//    //string = string.replace(/\s{2,}/g," "); //replace pairs groups of spaces with a single space
//  //  string = string.replace(/^ +/g,"").replace(/ +$/g,""); //get rid of spaces at the ends
//    return string
//}
////function translateAlternativesSyntax(string:string){
//    string = string.replace(/\|/g,"}|{")
//    .replace(/\[/g,"{[{")
//    .replace(/\]/g,"}]}")
//}
//export async function ishintavailable(formvalueslength:number, Q_ID?:number, question?:Question):Promise<boolean>{
//    let data = {
//        formvalues:JSON.stringify(Array(formvalueslength).fill([''])),
//        Q_ID//we don't have this in view only mode.
//    };
//    let feedback = await fetch('/viewfeedback', {
//        method: 'POST', // or 'PUT'
//        body: JSON.stringify(data), // data can be `string` or {object}!
//        headers:{
//            'Content-Type': 'application/json'
//        }
//        });
//    let feedbackjson:{head:string[], body:FeedbackObject[][]} = await feedback.json();
//    let body:FeedbackObject[][] =  feedbackjson.body;
//    let hintavailable = false;
//    body.forEach((field:FeedbackObject[])=>{
//        if(field!=undefined){
//            if(field.length>0){
//                hintavailable=true;
//            }
//        }
//    })
//    return hintavailable
//}

export function extractBraceContents(string:Readonly<string>,open:string,close:string,keepbraces:boolean,treatduplicateparamsseparately:boolean){
    let string_postextraction = string;
    checkBracketPairs(string,open,close);//check that there are matching pairs of curley braces
    let bracket_contents_array:string[] = []; //prepare for curley
    //logger.silly("bracket_contents_array before"+JSON.stringify(bracket_contents_array));//TODO handleExistingDollars has unknown purpose
    bracket_contents_array = handleExistingDollars(string, bracket_contents_array,open,close);
    //logger.silly("bracket_contents_array after"+JSON.stringify(bracket_contents_array));
    for(let current_position = string.length-1; current_position>-1;current_position--){
        //for each position
        //find last }
        let closing_bracket_position = string_postextraction.lastIndexOf(close,current_position);
        if (closing_bracket_position>-1){   //if there is a new curley
            //find corresponding {
            let opening_bracket_position = findMatchingOpeningBracket(string_postextraction,closing_bracket_position,open,close);
            //get contents
            let contents = '';
            if(!keepbraces){
                contents = string_postextraction.substring(opening_bracket_position+1,closing_bracket_position);
            } else  {
                contents = string_postextraction.substring(opening_bracket_position,closing_bracket_position+1);
            }

            if(keepbraces?(contents[1]==="$"):(contents[0]==="$")){//don't know if the keepbraces thing does anything
                //ignore already handled contents
            } else { //for normal cases
                
            //    bracket_contents_array.push(handlePunctuation(contents,"extractBraceContents on extracted contents"));
                bracket_contents_array.push(contents);
                //logger.silly("bracket_contents_array: "+JSON.stringify(bracket_contents_array));
                let replace_string = keepbraces?contents:(open+contents+close);
                //{$#}
                //let new_string = " "+open+"$"+(bracket_contents_array.length-1)+close+" ";//TODO why are the spaces here?
                let new_string = open+"$"+(bracket_contents_array.length-1)+close;//why are the spaces here?
                //logger.silly("bracket_contents_array: "+JSON.stringify(bracket_contents_array));
                if(treatduplicateparamsseparately){
                    let lastindex = string_postextraction.lastIndexOf(replace_string);
                    string_postextraction = string_postextraction.substring(0,lastindex)+string_postextraction.substring(lastindex).replace(RegExp(regExpEscape(replace_string),"g"),new_string);
                }else{
                    string_postextraction = string_postextraction.replace(RegExp(regExpEscape(replace_string),"g"),new_string);
                }
                current_position = string_postextraction.lastIndexOf(new_string);
                //logger.silly("string_postextraction: "+JSON.stringify(string_postextraction));

                bracket_contents_array = handleExistingDollars(string_postextraction, bracket_contents_array,open,close);

                //here I want to check for $bracket_contents_array.length
//ggg                /////////L///////////////////////////////////////////////////
                /////////L///////////////////////////////////////////////////
                /////////L///////////////////////////////////////////////////
                /////////L///////////////////////////////////////////////////
                /////////L///////////////////////////////////////////////////
                /////////L///////////////////////////////////////////////////
            }
            current_position = Math.max(current_position,opening_bracket_position); //subtraction get's made by for loop//open bracket position has moved if something earlier got replaced. 
        }
        else {
            break
        }
    }
    return {string:string_postextraction, bracket_contents_array}
}
function handleExistingDollars(string:Readonly<string>, bracket_contents_array:string[],open:string,close:string):string[]{//TODO missing the keepbraces variable. 
    //I don't understand what this does. 
    //this is called handleExistingDollars, since it looks for $0 already in the string for some reason. 
    //Note that the function only handles $1 if $0 is also there, so otherwise it misses $1. //TODO this is possibly a bug. 
    //bracketcontents array comes in as empty initially. 
    let dollar_string = open+"$"+(bracket_contents_array.length)+close; //creates "$0" initially// or if we've just put in $0, it looks for $1?
    //loggy(dollar_string,"dollar_string");
    if(string.indexOf(dollar_string)>-1){   //finds "$0" initially //does the thing we're already trying to find exist?
        //console.log(""+dollar_string+" found");
        bracket_contents_array.push(dollar_string); //adds "$0" to bracket_contents_array!?
        bracket_contents_array = handleExistingDollars(string,bracket_contents_array,open,string); //continues until we have ["$0","$1"] for each parameter, only works if there are already dollars.
    }
    return bracket_contents_array;
}
export function checkBracketPairs(string:Readonly<string>,open:string,close:string){
    let depth = 0;
    for (let c = 0; c<string.length; c++){//TODO ERROR if the first character is a closing bracket
        if (string[c]===open){
            depth++
        }
        else if (string[c]===close){
            depth--
        }
        if (depth<0){
            throw ""+open+" bracket error in string: "+string+" attempt to close bracket at position: "+c
        }
    }
    if (depth!==0){
        throw ""+open+" bracket error in string: "+string+" depth: "+depth+" should be zero at end"
    }
}
export function regExpEscape(literal_string:string) {
    return literal_string.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');
};
export function findMatchingOpeningBracket(string:string,closing_bracket_position:number,open:string,close:string):number{
    let opening_bracket_position = undefined;
    //find start {
    let depth = 0;
    for (let c = closing_bracket_position-1; c>-1; c--){//TODO ERROR if the first character is a closing bracket
        if (string[c]===close){
            depth++
        }
        else if (string[c]===open){
            if (depth===0){
                opening_bracket_position = c;
                break;
            } else {
                depth--;
            }
        }
    }
    if(opening_bracket_position===undefined){
        throw "unable to find closing bracket"
    }
    if(closing_bracket_position-opening_bracket_position===1){
        throw "there is nothing inside the curley braces"
    }
    return opening_bracket_position;
}

export function getpercentagescore(score:(number|null)[],maxscore:(number|null)[]):number{

    let tot_score = 0;
		let tot_possible_score = 0;
		for(let f=0; f<score.length; f++){
            if(maxscore[f]===undefined||score[f]===undefined) throw "maxscore and score should not have undefined elements (in getpercentagescore)"
			//let scoref = score[f];
			//let maxscoref = maxscore[f];
			tot_score += Number(score[f]);
			tot_possible_score += Number(maxscore[f]);
			//tot_possible_score += (maxscoref!=null)?maxscoref:0;
			//tot_score += score[f]|0;
			//tot_possible_score += maxscore[f]|0;
			//tot_possible_score += (maxscore[f]!=null)?score[f]:0;
        }
        if (tot_possible_score===0) throw "ERROR in getpercentagescore() tot_possible_score is zero"
		return tot_score/tot_possible_score;
}
export function toggleAllKeyTermsFilters(filters:FilterAll[],mode:string,condition:string|undefined):FilterAll[]{
    filters.forEach(filter=>{
        if(condition===undefined
            ||(condition==="scoring"&&filter.scoring!=="0")
            ||(condition==="child"&&filter.ischild)
            ||(condition==="auto"&&filter.auto_generated)
            ||(condition==="manual"&&(!(filter.auto_generated)))
            ||(condition==="feedback"&&0<filter.feedback_if_found.text.length+filter.feedback_if_not_found.text.length)){
            if (mode==="hide"){filter.hidden=true;}
            if (mode==="show"){filter.hidden=false;}
            if (mode==="minimise"){filter.displaynameonly=true;}
            if (mode==="expand"){filter.displaynameonly=false;}
        }
    })
    return filters;
}
export function getsf(n:string){
    let mantissastring:string = parseMantissa(n).toString();
    return mantissastring.length;
}
export function parsePartsValues(formvalue:string|undefined):string[]{
    if(formvalue===undefined){//throw "the formvalue is undefined for the input_numerical_twopart";
        //console.log("setting formvalue for input_numerical_twopart since it was undefined");
        formvalue=JSON.stringify(["",""]);//TODO don't think this does anything, think it is set here:create_empty_formvalues() in my_interfaces
    }
    let fieldpartsvalues:string[]=JSON.parse(formvalue);
    return fieldpartsvalues
    //console.log("fieldpartsvalues[0]"+fieldpartsvalues[0]);
}
export function getfiltermap(filters:FilterDB[]):Readonly<DBQFilterMap>{//{[n: string]: FilterDB}>{
    let filtermap:any = {};//todo need to find a way of typing this. 
    for(let f = 0; f<filters.length; f++){
        if(filters[f].name==undefined) throw "filters[f].name undefined in getfiltermap"
        filtermap[filters[f].name] = filters[f];
    }
    return filtermap
}
//a: { [n: string]: number} = {};
export function getfiltermapIDs(filters:FilterAll[]|FilterDB[]):Readonly<{[n: string]: number}>{
    let filtermap:any = {};//todo need to find a way of typing this. 
    for(let f = 0; f<filters.length; f++){
        if(filters[f].name==undefined) throw "filters[f].name undefined in getfiltermapIDs"
        filtermap[filters[f].name] = f;
    }
    return filtermap
}
export function truncate(numbertoround:number,sf:number,mode:"ceil"|"floor"):number{
    let exponent = numbertoround.toExponential().split("e")[1];
    let digits = Number(numbertoround.toExponential().split("e")[0]);
    let multiplier = Number("1e"+(sf-1))
    let truncateddigits:number;
    if(mode==="floor"){
        truncateddigits = Math.floor(digits*multiplier)/multiplier
    } else if(mode==="ceil"){
        truncateddigits = Math.ceil(digits*multiplier)/multiplier
    } else throw "truncate mode must be ceil of floor"
    let truncatedvalue = Number(Number((truncateddigits*Number("1e"+exponent)).toExponential(sf)).toExponential());//the outside number and exponential may not be needed. 
    return truncatedvalue;
}
export function getCaseMattersArray(keytermslength:number,keytermsproperties:undefined|(KeyTermProperties|undefined)[]):(null|boolean)[]{
    let casematters:(null|boolean)[] = Array(keytermslength).fill(null);
    if(keytermsproperties!==undefined){
        for(let i = 0 ; i<keytermslength; i++){
            let keytermproperties = keytermsproperties[i];
            if(keytermproperties!==undefined){
                if(keytermproperties.casematters!==undefined){
                    casematters[i]=keytermproperties.casematters;
                }
            }
        }
    }
    return casematters
}