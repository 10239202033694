import { type } from "os"
import { FeedbackObject, EmptyConcept, create_empty_FeedbackObject, FieldDesc, 
    ImageBespoke, InputRadio, InputText, InputSelfMark, QuestionInfo, Concept, Worksheet, InputHybrid } from "../../my_interfaces";

export interface MarkingOPQ{
    feedbackbody: FeedbackObject[][],//	understanding: [],
    feedbackhead: string[]
    //iscorrect?: boolean[],
    score:number[],
    maxscore:number[],
    error:"fieldmissmatcherror"|null,
    positivepoints?:(number|undefined)[],
    negativepoints?:(number|undefined)[],
    secret_sauce?:{
        filters_found:((KTFilter|FilterPosition)[]|undefined),
        processed_text:string,
        tokenised_answer:string[]|undefined,
    }[]
}
export interface LongAnswerMarkingPoint{
    maxscore:number,//this is only really used if there is more than one markingpoint //see in mark_text_long_mp() in mark_text_long.ts
    filters:FilterAll[],
    keyterms:string[],
    keytermsproperties?:(KeyTermProperties|undefined)[],//TODO, possibly incorrect type here. 
    isvalid?:boolean, //if it is false then show the error message. otherwise it is undefined or true
    errormessage?:string,
    hidden?:boolean,
    filterOrder:number[]
}
export interface LongAnswerMarkingPointMini{
    maxscore:number,//this is only really used if there is more than one markingpoint //see in mark_text_long_mp() in mark_text_long.ts
    filters:FilterMini[],
    keyterms:string[],
    keytermsproperties?:(KeyTermProperties|undefined)[],
    isvalid?:boolean, //if it is false then show the error message. otherwise it is undefined or true
    errormessage?:string,
    hidden?:boolean,
    filterOrder:number[]
}
export interface KeyTermObj extends KeyTermProperties{
    keyterm:string,
}
export interface KeyTermProperties{
    casematters?:boolean,
    keyterm?:string //just added this so I can delete it in minify. 
}
export interface FilterAll {
//    F_ID?:number|null,//if I pull a filter, edit it but don't push. then the T_ID points to the filter that was pulled, but the F_ID points to a copy that holds the current edits, T_IDs exist only for public filters.
//    T_ID?:number|null,// used to be simply 'ID' -1 means we have checked this name and it doesn't exist. undefined means we never checked it. null means we changed it since checking it. Ideally there wouldn't be an undefined, but I didn't want to set something = undefined. 
    ID?:number|null, //what is this ID
    name: string,
    structure?: string, //TODO:remove when handleMixedContents  goes to appThese are temporarily stored here, hmm, this could be created on the other side. string, used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.//used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.
    curley_bracket_contents_array?:string[], //goes with structure above.
    scoring: string, //how many marks are associated with this filter, +1, 0 or -1. 
    concept: number[],
    feedback_if_found: FeedbackObject,
    feedback_if_not_found: FeedbackObject,
    ANDs: ORobj[][],
    Tokenised_ANDs?: string[][][],
    isOptional_ANDs?: boolean[][][],//why is, is optional optional? I should set a default value. 
    ANDNOTs: ORobj[][],
    Tokenised_ANDNOTs?: string[][][],
    isOptional_ANDNOTs?: boolean[][][],
    NOTOVERLAPs: ORobj[][],
    Tokenised_NOTOVERLAPs?: string[][][],
    isOptional_NOTOVERLAPs?: boolean[][][],
    ALSOs: ORobj[][],
    Tokenised_ALSOs?: string[][][],
    isOptional_ALSOs?: boolean[][][],
    levels: number[],
    ischild:boolean,
    canNAND:boolean,//I think this is just for display
    hidden:boolean,
    auto_generated?:boolean,
    perm_hidden?:boolean,//TODO what is this for?
    matches_thesaurus?:boolean,//TODO in future switch this to indicate which was editted more recently
    displaynameonly?:boolean,
    parentnames?:string[],
    is_cyclic:boolean,
    inQFID?:number,////this is so that parents and children can be matched without having to search by name, it is also so that results of what filters were found where can be correctly tagged to the filters that they came from
//    filter_index?:number,//used in marking
//    word_position_original?: number[][],
//    word_position_concise?:number[][],
}
export interface FilterMinimising { //question marks allow us to delete stuff
    ID?:number|null, //what is this ID
    name?: string,
    structure?: string, //TODO:remove when handleMixedContents  goes to appThese are temporarily stored here, hmm, this could be created on the other side. string, used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.//used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.
    curley_bracket_contents_array?:string[], //goes with structure above.
    scoring?: string, //how many marks are associated with this filter, +1, 0 or -1. 
    feedback_if_found?: FeedbackObject,
    feedback_if_not_found?: FeedbackObject,
    ANDs?: ORobjmini[][],
    Tokenised_ANDs?: (string|null|undefined)[][][], //maybe this is not optional, maybe it is if the ORobj just has numbers
    isOptional_ANDs?: boolean[][][],//why is, is optional optional? I should set a default value. 
    ANDNOTs?: ORobjmini[][],
    Tokenised_ANDNOTs?: (string|null|undefined)[][][],
    isOptional_ANDNOTs?: boolean[][][],
    NOTOVERLAPs?: ORobjmini[][],
    Tokenised_NOTOVERLAPs?: (string|null|undefined)[][][],
    isOptional_NOTOVERLAPs?: boolean[][][],
    ALSOs?: ORobjmini[][],
    Tokenised_ALSOs?: (string|null|undefined)[][][],
    isOptional_ALSOs?: boolean[][][],
    concept?: number[],
    levels?: number[],
    ischild?:boolean,
    canNAND?:boolean,//I think this is just for display
    hidden?:boolean,
    auto_generated?:boolean,
    perm_hidden?:boolean,//TODO what is this for?
    matches_thesaurus?:boolean,//TODO in future switch this to indicate which was editted more recently
    displaynameonly?:boolean,
    parentnames?:string[],
    is_cyclic?:boolean,
    inQFID?:number,////this is so that parents and children can be matched without having to search by name, it is also so that results of what filters were found where can be correctly tagged to the filters that they came from
}
export interface FilterMini {
    //ID?:number|null, //what is this ID
    name?: string,
    //structure?: string, //TODO:remove when handleMixedContents  goes to appThese are temporarily stored here, hmm, this could be created on the other side. string, used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.//used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.
    //curley_bracket_contents_array?:string[], //goes with structure above.
    scoring?: string, //how many marks are associated with this filter, +1, 0 or -1. 
    //concept: number[],
    feedback_if_found?: FeedbackObject,
    feedback_if_not_found?: FeedbackObject,
    ANDs?: ORobjmini[][],
    Tokenised_ANDs?: (string|null|undefined)[][][], //maybe this is not optional, maybe it is if the ORobj just has numbers
    //Tokenised_ANDs?: ((string[]|undefined|null)[]|undefined|null)[], //maybe this is not optional, maybe it is if the ORobj just has numbers
    isOptional_ANDs?: boolean[][][],//why is, is optional optional? I should set a default value. 
    ANDNOTs?: ORobjmini[][],
    Tokenised_ANDNOTs?: (string|null|undefined)[][][],
    //Tokenised_ANDNOTs?: ((string[]|undefined|null)[]|undefined|null)[],
    isOptional_ANDNOTs?: boolean[][][],
    NOTOVERLAPs?: ORobjmini[][],
    Tokenised_NOTOVERLAPs?: (string|null|undefined)[][][],
    //Tokenised_NOTOVERLAPs?: ((string[]|undefined|null)[]|undefined|null)[],
    isOptional_NOTOVERLAPs?: boolean[][][],
    ALSOs?: ORobjmini[][],
    Tokenised_ALSOs?: (string|null|undefined)[][][],
    isOptional_ALSOs?: boolean[][][],
    //levels: number[],
    //ischild:boolean,
    //canNAND:boolean,//I think this is just for display
    //hidden:boolean,
    //auto_generated?:boolean,
    //perm_hidden?:boolean,//TODO what is this for?
    //matches_thesaurus?:boolean,//TODO in future switch this to indicate which was editted more recently
    //displaynameonly?:boolean,
    //parentnames?:string[],
    //is_cyclic:boolean,
    inQFID?:number,////this is so that parents and children can be matched without having to search by name, it is also so that results of what filters were found where can be correctly tagged to the filters that they came from
}
export interface LongTextAnalysis {
    filters_found:(FilterPosition|KTFilter)[]|undefined,
    tokenised_answer:string[],
    processed_text:string,
}
/**
 * Simple function to clean up the code. 
 * It checks to see if we have any analysis back fro the marker after having submitted a question and then gets it if we have
 * @param propsanalysis This is the input from props
 */
export function getLongTextAnalysis(i:number, propsanalysis:(LongTextAnalysis|null|undefined)[]|undefined):undefined|LongTextAnalysis|null{
    let long_text_analysis;
    if(propsanalysis===undefined){
        return long_text_analysis = undefined;
    } else {
        return long_text_analysis = propsanalysis[i];
    }
}
export interface FilterInfo {
    mp:number,
    filterindex:number,
    fieldindex:number,
    filter:FilterAll,
    filter_found_info?:FilterPosition|undefined,
}
export interface FilterPosition {
    name?:string,
    name_lowercase?: string,//it literally never uses this, this is just a dodge work around to get filter_matches to not make a stupid complaint about this not being in FilterPosition even when just checking to see if it is there or not
    casematters?: boolean,//as above
    filter_index:number,//used in marking
    word_position_original: number[][],
    word_position_concise:number[][],
    isKTFilter:false,
    inQFID?:number,////this is so that parents and children can be matched without having to search by name, it is also so that results of what filters were found where can be correctly tagged to the filters that they came from
    //can be undefined only because some questions havn't got inQFID yet
}
export interface KTFilter {
    name: string,
    name_lowercase?: string,
    casematters?: boolean,
    word_position_original: number[][], //not sure why this is number[] and not number[][], I don't think it is used anywhere yet so doesn't relly matter. 
    word_position_concise: number[][] //to match the Filter interface above //the outer is how many times it was found, the inner is one find?
    value?:number,
    mantissa?:string,
//    exp_order:
    isnegative?:boolean,
//    word_position_concise_temp:number[][],
    isKTFilter:true,
}
export interface FilterDB {
    ID:undefined|number|null,//There should not be a null here TODO TODO, there not be null or undefined here. 
    updated_ID:number|null,//holds the ID of the most up to date version of a public filter
    name: string,
    structure?: string, //string, used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.
    curley_bracket_contents_array?:string[], //goes with the above
    scoring: string, //how many marks are associated with this filter, +1, 0 or -1. 
    concept: number[],
    feedback_if_found: FeedbackObject,
    feedback_if_not_found: FeedbackObject,
    ANDs: ORobj[][],
    ANDNOTs: ORobj[][],
    NOTOVERLAPs: ORobj[][],
    ALSOs: ORobj[][],
    isOptional_ANDs?: boolean[][][],//why is, is optional optional? I should set a default value. 
    isOptional_ANDNOTs?: boolean[][][],
    isOptional_NOTOVERLAPs?: boolean[][][],
    isOptional_ALSOs?: boolean[][][],
//    Tokenised_ANDs: string[][][],
//    Tokenised_ANDNOTs: string[][][],
//    Tokenised_NOTOVERLAPs: string[][][],
//    Tokenised_ALSOs: string[][][],
    ispublic: boolean, //ispublic===true means that the filter is read write from any question, ispublic===false means that it is question specific. 
}
export interface ORobj {
    cIDs?: number[];//Child filter index, allows rapid finding of child filters rather than searching
    str:string,
    striscanonical?:boolean, //what did I mean bby this? something about has been processed
    canonicalstr?:string,
    hashhandled?:string,
//    numberical_special_match?:"specialcodes",
    mantissa?:string,
    comparison_type?:ComparisonType,
    value?:number,
    sfs?:[number,number],
    casematters?:boolean,
}
export interface ORobjmini {
    cIDs?: number[];//Child filter index, allows rapid finding of child filters rather than searching
    str?:string,
    striscanonical?:boolean, //what did I mean bby this? something about has been processed
    canonicalstr?:string,
    hashhandled?:string,
//    numberical_special_match?:"specialcodes",
    mantissa?:string,
    comparison_type?:ComparisonType,
    value?:number,
    sfs?:[number,number],
    casematters?:boolean,
}
export type ComparisonType = "casematters"|"lessthan"|"morethan"|"roundsto"|"allmags"|"value"|"roundedincorrectly"|"allmagsroundedincorrectly"|"roundedtobetween"|"allmagsroundedtobetween";
/*
export interface FilterQDetails {
    T_ID:number|null,//-1 means we have checked this name and it doesn't exist. undefined means we never checked it. null means we changed it since checking it. Ideally there wouldn't be an undefined, but I didn't want to set something = undefined. 
    F_ID:number|null,//This is for storing what is 'actually in the question'. 
    structure?: string, //TODO:remove when handleMixedContents  goes to appThese are temporarily stored here, hmm, this could be created on the other side. string, used to store the structure by those filters that have {} which don't contain $. Goes with curley_bracket_contents_array below.
    curley_bracket_contents_array?:string[], //TODO:remove when handleMixedContents  goes to appgoes with the above
    curley_bracket_contents_arrayQ?:string[],
    Tokenised_ANDs: string[][][],       //these will be removed from here but are temporarily here as part of the transition since there will be difficulty in reproducing these. 
    Tokenised_ANDNOTs: string[][][],
    Tokenised_NOTOVERLAPs: string[][][],
    Tokenised_ALSOs: string[][][],
    levels: number[],
    ischild:boolean,
    canNAND:boolean,//I think this is just for display
    hidden:boolean,
    auto_generated?:boolean,
    perm_hidden?:boolean,//TODO what is this for?
    matches_thesaurus?:boolean,//TODO in future switch this to indicate which was editted more recently
    displaynameonly?:boolean,
    parentnames?:string[],
    is_cyclic:boolean,
    filter_index?:number,//used in marking, need to take these things used in marking out of QFilterDetails
    word_position_original?: number[][],
    word_position_concise?:number[][],
}*/

export interface InputTextLongAnswer {
    markingpoints:LongAnswerMarkingPoint[]
}
export interface InputTextLongAnswerMini {
    markingpoints:LongAnswerMarkingPointMini[]
}
export interface InputHybridAnswer {
    markingpoints:LongAnswerMarkingPoint[],
    options:CheckboxOptionAnswer[],
}
export interface InputHybridAnswerMini {
    markingpoints:LongAnswerMarkingPointMini[],
    options:CheckboxOptionAnswer[],
}
export function create_default_hybrid_answer():InputHybridAnswer {
    return {
        markingpoints:create_default_text_long_answer().markingpoints,
        options:[
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
        ],
    }
}
export interface Term {
    idealtoken:string,						//record idealtoken
    searchterm:string,				//searchterm
    termstart:number,										//termstart
    termend:number,	//termend
    termlength:number,		//termlength
    pve:string,//keyterms[i].scoring,//.iscorrect?1:-1,//1//keyterms[i].pve TODO pull from iscorrect
    keytermindex:number,
}
export interface QuestionOptionAnswer {
    concept: number[],
    iscorrect: boolean,
    feedback: FeedbackObject,
    feedback_if_not_found: FeedbackObject,
}
export function create_default_radio_option_answer():QuestionOptionAnswer {
return { 
    concept:[EmptyConcept,EmptyConcept],
    iscorrect:false,
    feedback:create_empty_FeedbackObject(),
    feedback_if_not_found:create_empty_FeedbackObject(),
}
}
export interface InputRadioAnswer {
    options:QuestionOptionAnswer[],
}

export function create_default_radio_answer():InputRadioAnswer{//this could actually be a default MC / even default single word
return {
    options:[
        create_default_radio_option_answer(),
        create_default_radio_option_answer(),
        create_default_radio_option_answer(),
        create_default_radio_option_answer(),
    ]
}
}
export interface CheckboxOptionAnswer {
    concept: number[],
    iscorrect: boolean,
    feedback: FeedbackObject,//TODO***: Change to feedback object. 
    feedback_if_not_found: FeedbackObject,
}

export function create_default_checkbox_option_answer(istrue:boolean):CheckboxOptionAnswer {
return { 
    concept:[EmptyConcept,EmptyConcept],
    iscorrect:istrue?true:false,
    feedback:create_empty_FeedbackObject(),
    feedback_if_not_found:create_empty_FeedbackObject(),
}
}
export interface InputCheckboxAnswer {
    options:CheckboxOptionAnswer[],
}
export interface InputSelfMarkAnswer {
    options:CheckboxOptionAnswer[],
//    statichint:string,
}
export function create_default_checkbox_answer():InputCheckboxAnswer{//this could actually be a default MC / even default single word
    return {
        options:[
            create_default_checkbox_option_answer(false),
            create_default_checkbox_option_answer(false),
            create_default_checkbox_option_answer(false),
            create_default_checkbox_option_answer(false),
        ]
    }
}
export function create_default_selfmark_answer():InputSelfMarkAnswer{//this could actually be a default MC / even default single word
    return {
        options:[
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
            create_default_checkbox_option_answer(true),
        ],
//        statichint:'',
    }
}
/*export interface Filter {
name: string,
scoring: string,
//    iscorrect: boolean,
concept: number[],
feedback_if_found: FeedbackObject,
feedback_if_not_found: FeedbackObject,
ANDs: string[][],
Tokenised_ANDs: string[][][],
ANDNOTs: string[][],
Tokenised_ANDNOTs: string[][][],
NOTOVERLAPs: string[][],
Tokenised_NOTOVERLAPs: string[][][],
level: number|undefined,
ischild:boolean,
canNAND:boolean,
hidden:boolean,
is_cyclic:boolean,
auto_generated?:boolean,
perm_hidden?:boolean,
matches_thesaurus?:boolean,//TODO in future switch this to indicate which was editted more recently
ID?:number,
}*/
export function create_default_filterAll():FilterAll{
    return { 
    //    F_ID:undefined,
    //    T_ID:undefined, //its the ... below which pulls in the F_ID and the T_ID, possibly they should be at this level and not the spread, I haven't thought about it. 
        ...default_filterAll_extra_properties,
        name: '',
        scoring: "0",
    //        iscorrect: false,
        concept: [],
        feedback_if_found: create_empty_FeedbackObject(),
        feedback_if_not_found: create_empty_FeedbackObject(),
        ANDs: [[{str:''}]],
        ANDNOTs: [],
        NOTOVERLAPs: [],
        ALSOs: [],
//        Tokenised_ANDs: [[[]]],
//        Tokenised_ANDNOTs: [[[]]],
//        Tokenised_NOTOVERLAPs: [[[]]],
//        Tokenised_ALSOs: [[[]]],
//    //    level: undefined,
//        levels: [],
//        ischild:false,
//        canNAND:true,
//        hidden:false,
//        is_cyclic:false,//-1 indicates not cyclic, 0 indicates that the filter is its own child, 1 indicates the filter is its own grandchild
//        auto_generated:true,
    }
}
export let default_filterAll_extra_properties = {
    F_ID:undefined,
    T_ID:undefined,
    Tokenised_ANDs: [[[]]],
    Tokenised_ANDNOTs: [[[]]],
    Tokenised_NOTOVERLAPs: [[[]]],
    Tokenised_ALSOs: [[[]]],
    levels: [],
    ischild:false,
    canNAND:true,
    hidden:false,
    is_cyclic:false,//-1 indicates not cyclic, 0 indicates that the filter is its own child, 1 indicates the filter is its own grandchild
    auto_generated:true,
};
export function convert_filterDB_to_filterAll_default(filterDB:FilterDB):FilterAll{
    let thing:FilterAll = {
    //    F_ID:undefined,
    //    T_ID: filterDB.ID,//undefined,
        ID: filterDB.ID,
        name: filterDB.name,
        scoring: filterDB.scoring,
        concept: filterDB.concept,
        feedback_if_found: filterDB.feedback_if_found,
        feedback_if_not_found: filterDB.feedback_if_not_found,
        ANDs: filterDB.ANDs,
        ANDNOTs: filterDB.ANDNOTs,
        NOTOVERLAPs: filterDB.NOTOVERLAPs,
        ALSOs: filterDB.ALSOs,
        ...default_filterAll_extra_properties,
    }
    return thing
}
export function create_default_filter():FilterDB{
    return { 
        ID:undefined,//null,//change with SQL
        updated_ID:null,
        name: '',
        scoring: "0",
    //        iscorrect: false,
        concept: [],
        feedback_if_found: create_empty_FeedbackObject(),
        feedback_if_not_found: create_empty_FeedbackObject(),
        ANDs: [[{str:''}]],
        ANDNOTs: [],
        NOTOVERLAPs: [],
        ALSOs: [],
    //    Tokenised_ANDs: [[[]]],
    //    Tokenised_ANDNOTs: [[[]]],
    //    Tokenised_NOTOVERLAPs: [[[]]],
    //    Tokenised_ALSOs: [[[]]],
        ispublic: false
    }
}
/*
export interface StatementAnswer {
text: string,
concept: number[],
scoring: string,
iscorrect: boolean,
// feedback: FeedbackObject,
feedback_if_found: FeedbackObject,
feedback_if_not_found: FeedbackObject,
synonyms: string[],
}

export function create_default_filter():StatementAnswer{
return { 
    text:'',
    concept:[EmptyConcept,EmptyConcept],
    scoring:"0",
    iscorrect:false,
  //  feedback:create_empty_FeedbackObject(),
    feedback_if_found: create_empty_FeedbackObject(),
    feedback_if_not_found: create_empty_FeedbackObject(),
    synonyms:[],
}
}
*//*
export interface InputTextWordAnswer {
options:StatementAnswer[],
}
export function create_default_word_answer():InputTextWordAnswer{//this could actually be a default MC / even default single word
return {
    options:[create_default_filter()]
}
}
*//*
export interface Keyterm {
text: string,
synonyms: string[],
iscorrect: boolean,//I don't think that this is used for anything
}

export function create_keyterm(text:string):Keyterm {
return {
    text,
    synonyms:[],
    iscorrect: true,
}
}
export interface LongAnswerMarkingPoint{
maxscore:number,
keystatements:StatementAnswer[],
keyterms:Keyterm[],
isvalid?:boolean, //if it is false then show the error message. otherwise it is undefined or true
errormessage?:string,
hidden?:boolean,
}
export interface InputTextLongAnswer {
markingpoints:LongAnswerMarkingPoint[]
}
export function create_default_text_long_answer_marking_point():LongAnswerMarkingPoint {
return {
    maxscore:0,
    keystatements:[create_default_filter()],
    keyterms:[],
    hidden:false,
}
}
export function create_default_text_long_answer():InputTextLongAnswer {
let markingpoints:LongAnswerMarkingPoint[] = [];
markingpoints[0] = create_default_text_long_answer_marking_point();
markingpoints[0].maxscore = 1;
return {
    markingpoints
}
}
*/
/*export interface LongAnswerMarkingPoint{
maxscore:number,
filters:Filter[],
keyterms:string[],
isvalid?:boolean, //if it is false then show the error message. otherwise it is undefined or true
errormessage?:string,
hidden?:boolean,
}*/
/*export interface InputTextLongAnswer {
markingpoints:LongAnswerMarkingPoint[]
}*/
export function create_default_text_long_answer_marking_point():LongAnswerMarkingPoint {
    let filter = create_default_filterAll();
    filter.auto_generated=false;
    return {
        maxscore:0,
        filters:[filter],
        keyterms:[],
        hidden:false,
        filterOrder:[0]
    }
}
export function create_default_text_long_answer():InputTextLongAnswer {
    let markingpoints:LongAnswerMarkingPoint[] = [];
    markingpoints[0] = create_default_text_long_answer_marking_point();
    markingpoints[0].maxscore = 1;
    return {
        markingpoints
    }
}
export interface Answers {//TODO dynamic keys?
    input_radios: 		InputRadioAnswer[],
    input_checkboxes: 	InputCheckboxAnswer[],
    //    input_text_words: 	InputTextWordAnswer[],//InputRadioAnswer[],
    input_text_longs: 	InputTextLongAnswer[],
    input_numerical_twoparts: 	InputTextLongAnswer[],//I don't think this is used at all, I don't know why but the numerical two parts seem to be saved into the mark_text_long property.
    input_self_marks: 	InputSelfMarkAnswer[],
    input_hybrids: 	    InputHybridAnswer[],
}
export interface AnswersMini {//TODO dynamic keys?
    input_radios: 		InputRadioAnswer[],
    input_checkboxes: 	InputCheckboxAnswer[],
    //    input_text_words: 	InputTextWordAnswer[],//InputRadioAnswer[],
    input_text_longs: 	InputTextLongAnswerMini[],
    input_numerical_twoparts: 	InputTextLongAnswerMini[],//I added Mini to this line on 19/09/2022
    input_self_marks: 	InputSelfMarkAnswer[],
    input_hybrids: 	    InputHybridAnswerMini[],
}
export function create_default_answers(){
    return{
        input_radios: [],
        input_checkboxes: [],
    //        input_text_words: [],
        input_text_longs: [],
        input_numerical_twoparts: [],
        input_self_marks: [],
        input_hybrids: [],
    }
}
export interface FieldElementsAnswers { //TODO why are the things below not Answers? because answers are in the answers property dummy
    field_desc: FieldDesc[],
    info_texts: string[],
    info_images: ImageBespoke[],//{file:File,modified:Boolean}[],//string[]
    input_radios: 		InputRadio[],
    input_checkboxes: 	InputRadio[],
    //    input_text_words: InputText[],
    input_text_longs: InputText[],//is this right? Ahh, info is in answers a few lines below
    input_numerical_twoparts: InputText[],
    input_self_marks:InputSelfMark[],
    input_hybrids:InputHybrid[],//question mark because old questions will not have.
    answers: Answers,
}
export interface FieldElementsAnswersMini {//TODO is this ever used?
    field_desc: FieldDesc[], //could get rid of this
    info_texts?: string[],
    info_images?: ImageBespoke[],//{file:File,modified:Boolean}[],//string[]
    input_radios: 		InputRadio[],
    input_checkboxes: 	InputRadio[],
    //    input_text_words: InputText[],
    input_text_longs: InputText[],//is this right? Ahh, info is in answers a few lines below
    input_numerical_twoparts: InputText[],
    input_self_marks:InputSelfMark[],
    input_hybrids:InputHybrid[],
    answers: AnswersMini,
}

export function create_default_field_elements_answers():FieldElementsAnswers {
    return {//initialise question creator with two text fields and one radio field, the radio field will have 4 options
        field_desc: [
            {isinput:false,type:"info_text",instance:0,maxscore:0},//Warning, the field_desc must match the below
            //{isinput:false,type:"info_text",instance:1,maxscore:0},
            //{isinput:true,type:"radio",instance:0,maxscore:1},
        ],
        info_texts: [''],//start with two information text TODO: maybe just one.
        info_images: [],
        input_radios: [],//create_default_radio()],//start with one default radio
        input_checkboxes:[],//create_default_radio()],//start with one default radio
    //        input_text_words:[],//start with no input_text
        input_text_longs:[],//start with no input_text
        input_numerical_twoparts:[],//start with no input_text
        input_self_marks:[],
        input_hybrids:[],
        answers:create_default_answers(),
    }
}
export interface QuestionAnswers {
    Q_ID: number,
    name: string,
    field_elements: FieldElementsAnswers,
    info: QuestionInfo,
    hintOnly: boolean,
}
export interface QuestionAnswersMini {
    Q_ID: number,
    //name: string,
    field_elements: FieldElementsAnswersMini,
    //info: QuestionInfo,
    info:{last_mod_date?:Date;}
    //hintOnly: boolean,
}
export interface QuestionAnswersNoInfo {
    Q_ID: number,
    name: string,
    field_elements: FieldElementsAnswers,
    hintOnly: boolean,
}
export interface QuestionEntryPageProps { //note that the defaults are set in Admin
    action:string, 		//is either 'createnew' or 'edit'
    name:string,		//name of question is passed in if in edit mode, default is ''
    field_elements:FieldElementsAnswers,	//field elements are passed in if in edit mode
    Q_ID:number,
    key:number,//used to force the recreation of a new component so that the constructor runs again, otherwise react will not change the component :-(. )
    hintOnly: boolean,
    onSubmit?:()=>void,//the newQ_ID is desired when passed up to Worksheeteditor but not to questionmanager
    addQuestion?:(newQ_ID:number)=>void,
}
export interface DBQ {
    images:{name:string}[],
    //questions:undefined,//QuestionAnswers[],
    filters:FilterDB[],
    concepts:Concept[],
    worksheets:Worksheet[],
}
export type NANDType="AND"|"ANDNOT"|"NOTOVERLAP"|"ALSO"
export type NANDsType="ANDs"|"ANDNOTs"|"NOTOVERLAPs"|"ALSOs"
export const NANDsName:NANDsType[] = ["ANDs","ANDNOTs","NOTOVERLAPs","ALSOs"];
export type TokenisedNANDType="Tokenised_ANDs"|"Tokenised_ANDNOTs"|"Tokenised_NOTOVERLAPs"|"Tokenised_ALSOs"
export const TokenisedNANDNames:TokenisedNANDType[] = ["Tokenised_ANDs","Tokenised_ANDNOTs","Tokenised_NOTOVERLAPs","Tokenised_ALSOs"];
export type isOptionalNANDType="isOptional_ANDs"|"isOptional_ANDNOTs"|"isOptional_NOTOVERLAPs"|"isOptional_ALSOs"
export const isOptionalNANDNames:isOptionalNANDType[] = ["isOptional_ANDs","isOptional_ANDNOTs","isOptional_NOTOVERLAPs","isOptional_ALSOs"];

export const PQs = [
    {
        name:"luminous intensity",
        adjective:"luminous",
        symbol:"I",
        SIunitname:"candela",
        SIunitsymbol:"cd",
    },
    {
        name:"extension",
        adjective:"extended",
        symbol:"e",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"intensity",
        adjective:"intense",
        symbol:"I",
        SIunitname:"Watts per meter squared",
        SIunitsymbol:"W/m^2",
    },
    {
        name:"amount",
        adjective:"much",
        symbol:"n",
        SIunitname:"mole",
        SIunitsymbol:"mol",
    },
    {
        name:"velocity",
        adjective:"fast",
        greater:"faster",
        opposite:"slow",
        lesser:"slower",
        symbol:"v",
        SIunitname:"meters per second",
        SIunitsymbol:"m/s",
    },
    {
        name:"mass",
        adjective:"massive",
    //    greater:"more massive",
    //    opposite:"light",
    //    lesser:"lighter",
        symbol:"m",
        SIunitname:"kilogram",
        SIunitsymbol:"kg",
    },
    {
        name:"momentum",
     //   adjective:"fast",
        symbol:"p",
        SIunitname:"kilogram meters per second",
        SIunitsymbol:"kgm/s",
    },
    {
        name:"speed",
        adjective:"fast",
        greater:"faster",
        opposite:"slow",
        lesser:"slower",
        symbol:"v",
        SIunitname:"meters per second",
        SIunitsymbol:"m/s",
    },
    {
        name:"density",
        adjective:"dense",
        symbol:"ρ",
        SIunitname:"kilograms per cubic meter",
        SIunitsymbol:"kg/m³",
    },
    {
        name:"volume",
        adjective:"volumous",
        symbol:"V",
        SIunitname:"cubic meter",
        SIunitsymbol:"m³",
    },
    {
        name:"voltage",
        symbol:"V",
        SIunitname:"Volt",
        SIunitsymbol:"V",
    },
    {
        name:"potential",
        symbol:"V",
        SIunitname:"Volt",//technically could be J/kg etc
        SIunitsymbol:"V",
    },
    {
        name:"current",
        symbol:"I",
        SIunitname:"Ampere",
        SIunitsymbol:"A",
    },
    {
        name:"resistance",
        symbol:"R",
        SIunitname:"Ohm",
        SIunitsymbol:"Ω",
    },
    {
        name:"force",
        symbol:"F",
        SIunitname:"Newton",
        SIunitsymbol:"N",
    },
    {
        name:"weight",
        sybmol:"heavy",
        symbol:"W",
        SIunitname:"Newton",
        SIunitsymbol:"N",
    },
    {
        name:"gravitational field strength",
        symbol:"g",
        SIunitname:"Newtons per kilogram",
        SIunitsymbol:"N/kg",
    },
    {
        name:"work",
        symbol:"W",
        SIunitname:"Joule",
        SIunitsymbol:"J",
    },
    {
        name:"energy",
        symbol:"E",
        SIunitname:"Joule",
        SIunitsymbol:"J",
    },
    {
        name:"distance",
        adjective:"far",//distant
        greater:"further",
        opposite:"close",
        lesser:"closer",
        symbol:"d",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"distance moved",
        adjective: "far",
        greater:"further",
        opposite:"close",//near
        lesser:"closer",//nearer
        symbol:"d",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"moment",
        symbol:"M", //τ (tau for torque)
        SIunitname:"Newton meter",
        SIunitsymbol:"Nm",
    },
    {
        name:"time",
        adjective:"long",
        greater:"longer",
        opposite:"short",
        lesser:"shorter",
        symbol:"t",
        SIunitname:"second",
        SIunitsymbol:"s",
    },
    {
        name:"displacement",
        adjective:"displaced",
        symbol:"s",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"acceleration",
        adjective:"accelerated",
        opposite:"retarded",
        symbol:"a",
        SIunitname:"meters per second squared",
        SIunitsymbol:"m/s²",
    },
    {
        name:"area",
        symbol:"A",
        SIunitname:"square meter",
        SIunitsymbol:"m²",
    },
    {
        name:"stiffness",
        adjective:"stiff",
        greater:"stiffer",
        symbol:"k",
        SIunitname:"Newtons per meter",
        SIunitsymbol:"N/m",
    },
    {
        name:"spring constant",
        symbol:"k",
        SIunitname:"Newtons per meter",
        SIunitsymbol:"N/m",
    },
    {
        name:"power",
        symbol:"P",
        SIunitname:"Watt",
        SIunitsymbol:"W",
    },
    {
        name:"height",
        adjective:"high",
        greater:"higher",
        opposite:"low",
        lesser:"lower",
        symbol:"h",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"frequency",
        adjective:"frequent",
    //    greater:"further",
        opposite:"infrequent",
    //    lesser:"closer",
        symbol:"f",
        SIunitname:"Hertz",
        SIunitsymbol:"Hz",
    },
    {
        name:"period",
        symbol:"T",
        SIunitname:"second",
        SIunitsymbol:"s",
    },
    {
        name:"charge",
        adjective:"charged",
        symbol:"Q",
        SIunitname:"Coulomb",
        SIunitsymbol:"C",
    },
    {
        name:"wavelength",
        symbol:"λ",
        SIunitname:"meter",
        SIunitsymbol:"m",
    },
    {
        name:"gravitational potential energy",
        symbol:"GPE",
        SIunitname:"Joule",
        SIunitsymbol:"J",
    },
    {
        name:"kinetic energy",
        symbol:"KE",
        SIunitname:"Joule",
        SIunitsymbol:"J",
    },
    {
        name:"pressure",
        adjective:"pressured",//pressurised
        symbol:"p",
        SIunitname:"Pascal",
        SIunitsymbol:"Pa",
    },
    {
        name:"thickness",
        adjective:"thick",
        symbol:"t",
        SIunitname:"meter",
        SIunitsymbol:"m",
        opposite:"thin",
        more:"thicker",
        less:"thinner",
    },
    {
        name:"Planck constant",
        symbol:"h",
        SIunitname:"Joule second",
        SIunitsymbol:"Js",
    }
];    
export interface DBQFilterMap{
    [n: string]: FilterDB
}
export interface FetchFilterResponse {
    ID:number,
    matches_thesaurus:boolean,
}