import { type } from "os"

//import {FeedbackObject} from './Common';//export interfaces
export interface MarkingOPField{
    feedbackbody: FeedbackObject[],//	understanding: [],
    iscorrect?: boolean,
    score:number,
    positivepoints?:number,
    negativepoints?:number,
}
export interface FeedbackObject {
	text:string,
	type:FeedbackType,
	priority:number,
}
export type FeedbackType = "incorrect"|"correct"|"hint"
export type FoundNotFound = "found"|"notfound"
export function create_empty_FeedbackObject():FeedbackObject{
	return {
		text:'',
		type:'hint',
		priority:0,
	}
}
export interface QuestionListInfo {
    Q_ID: number,
    name: string,
}
export function compare_priority(a:any,b:any){//TODO want to be able to compare by different properties. 
    if(a.priority<b.priority){
        return 1;
    }
    if(a.priority>b.priority){
        return -1;
    }
    return 0;
    }
///////////////////////////////////////////////
export interface User {
	username: string,//TODO *** change to username
	U_ID: number,
    usertype: UserType,
    firstname:string,
    lastname:string,
    password:string,
    emailAddress:string,
    temppassword?:string|undefined,
}
export interface UserData {
    username: string,//TODO *** change to username
	U_ID: number,
    usertype: UserType,
    firstname:string,
    lastname:string,
    emailAddress:string,
    temppassword?:string|undefined,
}
//export interface Class {
//	ID:number,
//	class_code:string,
//	name:string,
//	Teacher_ID:number[],
//	Pupils:	  {	U_ID:number,surname:string  }[],
//	Assignments:Assignment[],
//}
export interface Cohort {
    ID:number,
    class_code:string,
    name:string,
    Teacher_IDs:number[],
    Pupils:{U_ID:number, surname:string}[],
    Assignments:Assignment[]
}
export interface Assignment {
	W_ID:number,
	Assignment_ID:number,
	setbyU_ID:number,
	name:string,
    islive:boolean,
}
export interface PupilAnswers {
	questions: (string|undefined)[][],
	showingfeedback:boolean[],
	disabled:boolean[],
	isdraft:boolean[],
	first:(undefined|1)[],
}
export interface Attempt {
	A_ID:number,
	U_ID:number,
	Q_ID:number,
	useranswer:(string|undefined)[],
	showingfeedback:boolean, //don't need
	disabled:boolean, //don't need
	status:"draft"|"submitted", //don't need TODO should be "submitted" or "draft" I think
    manscore?:ManScore[],
	score:(number|null)[]|undefined, //should just be number //I think it is an array because there are multiple parts to the question
    //maxscore:(number|undefined|null)[], //why can it be undefined?
    maxscore:(number|null)[], //why can it be undefined? This is affected by weighting. I think this is the maxscore possible. 
    info:{
        creation_date: Date,
        last_mod_date: Date, //I think this is when the record was last modified, so should be updated upon remark
        last_mark_date?: Date, //what is the point of this? we check this to see if it needs a remark. //both are updated when marked
    }
    first?:1,//1 means it is first attempt after a reset, otherwise null
    W_ID?:number|null,//null is when the attempt was not submitted through a worksheet. undefined is when it hasn't been given. 
}
export interface ManScore {
    marker_ID: number,   //who gave this manual mark
    marker_type: MarkerType,
    mark_date: Date,    //when did they give the mark
    field: number,       //which field was marked
    score: number,
    marking?: boolean[] //this is for questions where marks are awarded for individual marking points
}
export type MarkerType = "self"|"peer"|"teacher"|"admin";

//data for a particular question
export interface PupilData {//TODO is this a duplicate of something
//	firstname:string,
//	lastname:string,
//	username:string,
	stats?:PupilQuestionStats,
    attempts:Attempt[],
    index?:number,
    U_ID:number,//should this not be here?
}
export interface PupilQuestionStats {
    initial_score?:number,
    max_score?:number, //does this mean the max possible score, or the max score achieved ever. 
    wohelp_score?:number,
    reset_score?:number, //this is only used in calculations but is stored :-/
    resetwoh_score?:number, //this is only used in calculations but is stored :-/ (completed after reset and without help)
    adjusted_final_score?:number,
    initial_percentage?:number,
    max_percentage?:number,
    adjusted_percentage?:number,
    reset_percentage?:number,
    resetwoh_percentage?:number,
    num_attempts?:number,
    last_mod_date?:Date,
}
export type ButtonColour = "green"|"red"|"yellow";
export type AssignmentSortType = "lastname"|"initial"|"final"|"adjusted"|"accuracy"|"summary";
export interface QuestionData {
	Q_ID:number,
	name:string,
	field_desc:FieldDesc[],
	pupils:PupilData[],
	stats?:{
		out_of?:number,
		num_pupils_attempted?:number,
		tot_adjusted_score?:number,
		tot_max_score?:number,
		tot_initial_score?:number,
		tot_num_attempts?:number,
	}
}
export interface PupilWssStats{ //note the extra s for plural worksheets
    U_ID:number,
    firstname:string,
    lastname:string,
    pupilwssstats:(PupilWsStats|undefined)[],
}
export interface PupilWsStats{
    U_ID:number,
    W_ID:number,
    lastAttemptdate:Date, 
    //lastCalcdate:Date, 
    stats?:PupilWSData,//when a person makes an attempt it makes the record but stats are not calculated until someone wants to see them
    upToDate:boolean,
}
export interface PWSID{
    U_ID:number,
    W_ID:number,
    upToDate:boolean,
}
export interface PupilWSData {//why does this not have userID or W_ID, this is kind of mad.
	username:string,
	initial_score?:number, 	//first attempt score
	max_score?:number,		//max score they've ever achieved
    wohelp_score?:number, //need to add this in
    //reset_score?:number, //this is only used in calculations but is stored :-/
    //resetwoh_score?:number, //this is only used in calculations but is stored :-/ (completed after reset and without help)
	adjusted_final_score?:number,	//adjusted score
	initial_percentage?:number, //note that the percentages are [0,1] not [0,100]
	max_percentage?:number,
    wohelp_percentage?:number,
	adjusted_percentage?:number,
	attempted_out_of?:number,
    attempted_adjusted_percentage?:number,
    reset_percentage?:number,
    resetwoh_percentage?:number,
    num_attempts?:number,
    last_mod_date?:Date, //This is the last attempt date when the calculation was last done
    last_calc_date?:Date,
	reset_dates?: Date[]; //this is when the pupil clicks reset at the end of the worksheet
    //working calcpupilwsstats erases anything not already in its list, so you'll need to add stuff there or change the way that it works. 
}
export interface ClassAssignmentData {//no difference in type between the one with pupilswstats and one without, this is a problem
	questions:QuestionData[],
	stats?:{
		out_of?:number,
	}
	pupilswsstats?:PupilWSData[],
    temppasswords?:(undefined|string|null)[],
    pupilsnames:PupilNames[],
}
//export interface ClassAssignmentDataWStats {//no difference in type between the one with pupilswstats and one without, this is a problem
//	questions:QuestionData[],
//	stats:{
//		out_of?:number,
//	}
//	pupilswsstats:PupilWSData[],
//    temppasswords?:(undefined|string|null)[],
//    pupilsnames:PupilNames[],
//}
export interface PupilNames {
    username: string,
    firstname: string,
    lastname: string,
    usertype: UserType,
}
export interface PupilIDs {
    username: string,
    firstname: string,
    lastname: string,
    usertype: UserType,
    U_ID: number,
    temppassword:(string|null|undefined),
}
export interface TeacherIDs {
    username: string,
    firstname: string,
    lastname: string,
    U_ID: number,
}
export interface Worksheet {
	ID: number,
	Q_IDs: number[],
	name: string,
    info: {
        creator?:number,
        creation_date?:Date,
        status:string, 
        lastmoddate?:Date, //this is updated when the worksheet is submitted. //I haven't implemented this yet 
        questionlastmoddate?:Date //this is updated when a question in the worksheet is submitted. //I haven't implemented this yet 
    },
    type:WorksheetType|undefined,
}
export type WorksheetType = 'original'|'flashcards'|'assessment';

export function create_empty_worksheet():Worksheet {
	return {
		ID: -1,
		Q_IDs: [],
		name: 'Empty worksheet',
        info: {status:"live"},
        type: 'original'
	}
}
export interface Dialog {
	//diologtype... e.g. report a concern, ask a question, report a problem, suggest improvement
	//submission_date:DateConstructor,
	//response_date:DateConstructor,
	text:string,
	//response_text:string,
	isresolvedsayssubmitter:boolean,
	//isresolvedsaysresponder:boolean,
}
export const EmptyConcept: number = -1;//***TODO what is going on here?

export interface Concept { //creates a sort of type of thing call a concept
	C_ID: number,
	C_short: string,
	C_long: string,
}


export interface QuestionOption {
	text: string,
//	concept: number[],
//	iscorrect: boolean,
//	feedback: string,
//	synonyms: string[],//TODO: need to have separate export interface for long answer key statements
}

export function create_default_option():QuestionOption {
	return { 
		text:'',
//		concept:[EmptyConcept,EmptyConcept],
//		iscorrect:false,
//		feedback:'',
//		synonyms:[],
	}
}

export interface InputRadio {//***possibly don't need this level, it is here in case I have checkboxes I think*/
	options: QuestionOption[],
	oneOptionOnly: boolean,//potentially change to multioption?
	//type: string;
}
//function not needed in the common side but is nice to put with InputRadio Interface and is not sensitive
export function create_default_radio():InputRadio{//this could actually be a default MC / even default single word
	return {
		options:[
			create_default_option(),
			create_default_option(),
			create_default_option(),
			create_default_option(),
		],
		oneOptionOnly:true,
	}
}

export interface ImageBespoke {
	name:string,
	modified:boolean,
	file:File|null, //removed string option, don't know if this is bad
}

export function create_default_image():ImageBespoke{//not needed in common, but Image is and it is nice to put them together
	return {
		name:'',
		modified:false,
		file: null as File | null,  // or undefined as File | undefined
	}
}
export interface InputSelfMark {
    showsymbols:boolean,
    options:QuestionOption[],
    statichint:string|undefined,
    oneOptionOnly:boolean,
}
export function create_default_self_mark():InputSelfMark{//this could actually be a default MC / even default single word
	return {
		options:[
			create_default_option(),
			create_default_option(),
			create_default_option(),
			create_default_option(),
		],
        oneOptionOnly:false,
        showsymbols:false,
        statichint:'',
	}
}
export interface InputText {
	//isn't anything since nothing is needed outside of that which is in desc to render
    //this might in future have number of lines to help render the text box
//    hidehint:boolean,
	showsymbols:boolean,
}
export function create_default_input_text():InputText{//not needed in common but the interface is
	return {
        showsymbols:false,//shouldn't be like this 
//        hidehint:false,
    };
}
export interface InputHybrid {
    showsymbols:boolean,
    options:QuestionOption[],
    statichint:string|undefined,
    oneOptionOnly:boolean, //what is this about?
}
export function create_default_input_hybrid():InputHybrid{//this could actually be a default MC / even default single word
	return {
		options:[
			create_default_option(),
			create_default_option(),
			create_default_option(),
			create_default_option(),
		],
        oneOptionOnly:false,
        showsymbols:false,
        statichint:'',
	}
}
export interface FieldDesc {
	isinput:boolean, 
	type:FieldType,
	instance:number,
	maxscore:number,
    weighting?:number,
}

//export let field_type_array_names:FieldTypes[] = [
//    "info_texts",
//    "info_images",
//    "input_radios",
//    "input_checkboxes",
////    "input_text_words",
//    "input_text_longs",
//    "input_numerical_twoparts",
//];
export let field_type_desc_names:FieldType[] = [
    "info_text",
    "info_image",
    "radio",
    "checkbox",
    "input_text_long",
    "input_numerical_twopart",
    "input_self_mark",
    "input_hybrid"
];
export let field_type_desc_names_public:string[] = [
    "text",
    "image",
    "don't click me",
    "multiple choice (MC)",
    "auto-mark (AM)",
    "numerical (N2P)",
    "self-mark (SM)",
    "hybrid"
];

//let fieldtypeoptions:FieldType[] = ["info_text","info_image","radio","checkbox","input_text_long","input_numerical_twopart"];

export type FieldType="radio"|"checkbox"|"input_text_long"|"info_text"|"info_image"|"input_numerical_twopart"|"input_self_mark"|"input_hybrid"
export type FieldTypes="input_radios"|"input_checkboxes"|"input_text_longs"|"info_texts"|"info_images"|"input_numerical_twoparts"|"input_self_marks"|"input_hybrids"

export function getlongtypes(type:FieldType):"input_text_longs"|"input_numerical_twoparts"|"input_hybrids"{ //"input_text_long"|"input_numerical_twopart"
    let field_type = getfieldtypes(type);
    if(field_type!=="input_text_longs"&&field_type!=="input_numerical_twoparts"&&field_type!=="input_hybrids") throw "expected long type but got "+field_type;
    return field_type;
}

export function getfieldtypes(type:FieldType):FieldTypes{
    switch(type){
        case "radio":
            return "input_radios";
        case "checkbox":
            return "input_checkboxes"
        case "info_image":
            return "info_images"
        case "info_text":
            return "info_texts"
        case "input_text_long":
            return "input_text_longs"
        case "input_numerical_twopart":
            return "input_numerical_twoparts"
        case "input_self_mark":
            return "input_self_marks"
        case "input_hybrid":
            return "input_hybrids"
        default:
            throw "getfieldtypes couldn't find the type"
    }
}

//***I will create a second export interface which has all of these w/o the answers.
//question view does not need the answers. 
//when question creator passes the question to question page, it must make a copy, and then delete answers (this is so that the type matches)
export interface FieldElements {
	field_desc: FieldDesc[],
	info_texts: string[],
	info_images: ImageBespoke[],//{file:File,modified:Boolean}[],//string[]
	input_radios: 		InputRadio[],
	input_checkboxes: 	InputRadio[],
//	input_text_words: InputText[],
    input_text_longs: InputText[],
    input_self_marks: InputSelfMark[],
    input_hybrids: InputHybrid[], //could be problem. 
	//answers: Answers, This is in FieldElementsAnswers
}
export function create_empty_field_elements():FieldElements {
	//throw "create_empty_field_elements shouldn't be called, it means that a question returned as null";
	return {
		field_desc: [],
		info_texts: [],
		info_images: [],
		input_radios: [],
		input_checkboxes: [],
//		input_text_words: [],
		input_text_longs: [],
		input_self_marks: [],
		input_hybrids: [],
		//answers:create_default_answers(),
	}
}
export interface QuestionInfo{
	creator?:number,
    status:string, //TODO:what is this for?
    last_mod_date?:Date;
    creation_date?:Date;
}
export function create_empty_question_info():QuestionInfo{
    return {
        creator:-1, 
        status:"not found",
        last_mod_date:new Date(),
        creation_date:new Date(),
    }
}

export interface Question {
	Q_ID: number,
	name: string,
	field_elements: FieldElements,
    info: QuestionInfo,
    hintOnly: boolean,
}

export function create_empty_question():Question {//TODO why do I want to create_empty_field_elements rather than default field elements
	return {
		Q_ID: -1,
		name: "Failed to find question",
		field_elements: create_empty_field_elements(),//*** */possible just put the contents of export interface FieldElements here directly
        info: create_empty_question_info(),
        hintOnly: false,
	}
}

export function create_empty_formvalues(question:Question):(string|undefined)[]{
    //function to create empty formvalues object to match the provided question as some question types can't have undefined value
    console.log("create_empty_formvalues");
	let formvalues:(string|undefined)[] = [];
	for (let i=0;i<question.field_elements.field_desc.length;i++){
		let field_desc_i = question.field_elements.field_desc[i];
		if(field_desc_i.type==="checkbox"){//because I use JSON.parse later
			formvalues[i]=JSON.stringify(Array(question.field_elements.input_checkboxes[field_desc_i.instance].options.length).fill(false))
		}
		//else if(field_desc_i.type==="input_text_word"){I don't think text needs this since, it is just a string
		//	formvalues[i]=JSON.stringify(Array(question.field_elements.input_text_words[field_desc_i.instance].options.length).fill(false))
		//}
		//else if(field_desc_i.type==="input_text_word"||field_desc_i.type==="input_text_long"){
		else if(field_desc_i.type==="input_text_long"){
			formvalues[i]='';
        }
        else if(field_desc_i.type==="input_numerical_twopart"){
            formvalues[i]=JSON.stringify(["",""]);
        }
        else if(field_desc_i.type==="input_self_mark"){
            formvalues[i]=JSON.stringify(
                [
                    "",
                    JSON.stringify(Array(question.field_elements.input_self_marks[field_desc_i.instance].options.length).fill(false))
                ]);
        }
        else if(field_desc_i.type==="input_hybrid"){
            formvalues[i]=JSON.stringify(
                [
                    "",
                    JSON.stringify(Array(question.field_elements.input_hybrids[field_desc_i.instance].options.length).fill(false))
                ]);
        }
		else if(field_desc_i.type==="info_text"||field_desc_i.type==="info_image"){
			formvalues[i]=undefined;
		} 
        else if(field_desc_i.type==="radio"){
			//don't need to do anything. 
		} 
        else {
            throw "field_desc_i.type not recognised, type: "+field_desc_i.type;
        }
	}
	return formvalues;
}
export interface DBU {
    comments:Comment[],
    loginattempts:LoginAttempt[],
    users:User[],
    classes:Cohort[],
    //attempts:Attempt[],
    understanding:Understanding[],
    reports:Report[],
    pupilwsstats?:PupilWsStats[]
}
export interface Comment {
    ID: number,
    U_ID: number,
    Q_ID: number,
    comment_text: string,
    status: string //TODO make this stricter
}
export interface Report {
    ID: number,
    U_ID: number,
    Q_ID?: number,
    A_ID?:number,
    str: string,
    status: "draft"|"submitted"
    isresolved: boolean,
    formvalues?: (undefined|string)[],
    feedback?: {head:string[],body:FeedbackObject[][]}//FeedbackObject[][]
    info:Info
}
export function create_empty_report(ID:number,U_ID:number,Q_ID?:number):Report{
    return{
        ID,
        U_ID,
        Q_ID,
        str: '',
        status: "draft",
        isresolved: false,
        info:create_empty_info(U_ID),
    }
}
export interface Info {
    creator: number,
    last_mod_date: Date,
    creation_date: Date,
}
export function create_empty_info(U_ID?:number):Info{
    return {
        creator:U_ID||-1, 
        last_mod_date:new Date(),
        creation_date:new Date(),
    }
}
export interface LoginAttempt {
    username: string,
    success: boolean,
    datetime: Date,
    code?: "reset"|"unlock", //to store 'reset' and 'unlock' so we know that they should be able to now try again. 
}

export interface Understanding {
    C_ID:string,
    U_ID:number,
    score:number,
}

export interface Concept {
    C_ID:number,
    C_short:string,
    C_long:string,
}

export interface RegistrationFieldsString{
    usertype?:string,
    currentpassword?:string,
    classcode?:string,
    emailAddress?:string,
    username?:string,
    firstname?:string,
    lastname?:string,
    newpassword1?:string,
    newpassword2?:string,
}
export interface RegistrationFieldsBoolean{
    usertype?:boolean,
    currentpassword?:boolean,
    classcode?:boolean,
    emailAddress?:boolean,
    username?:boolean,
    firstname?:boolean,
    lastname?:boolean,
    newpassword1?:boolean,
    newpassword2?:boolean,
}
export type RegistrationFieldName="usertype"|"currentpassword"|"classcode"|"emailAddress"|"username"|"firstname"|"lastname"|"newpassword1"|"newpassword2"

export interface PupilsUnknown {
    useranswers:(string|null|undefined)[][],
    showingfeedback:boolean[],
    disabled:boolean[],
    isdraft:boolean[],
}



export type RecordAction = "copy"|"delete"|"view"|"edit";
export type PageName = "log_in"|"about"|"contact"|"exercises"|"user_page"|"pupil_manager"|"worksheet_manager"|"worksheet"|"question_entry"|"question_manager"|"test_page"|"report_manager"|"user_manager"|"register";
export type UserType = "superuser"|"teacher"|"pupil"|"qcreator";

// This type holds the functions responsible for changing the currently displayed page,
// currently, they are defined by App2 and passed down to other components that may need to access them.
// TODO Improve this by using either React Contexts or by using ReactRouter
export type PageChangeFunctions = {
    exercises: () => void,
    log_in: () => void,
    about: () => void,
    report_manager: () => void,
    pupil_manager: () => void,
    worksheet_manager: () => void,
    question_manager: () => void,
    question_entry: () => void,
    test_page: () => void,
    user_manager: () => void,
    user_page: () => void,
    log_out: () => void,
    register: () => void,
    // Add new functions above this comment REF_61121
}
export type mmmURLs = {
    
}

export type LoginPageProps = {
    onLoginSuccess:(usertype:UserType,justregistered:boolean)=>void,
    cookiesdisabled?:boolean,
    register?:boolean //prop to indicate if we want the registration page or the login page
//		username: string,
//		onSuccessfulLogin: () => void,
//		onUsernameChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
//		onLoginAttempt: (e: any) => void,
//		loginattempted: boolean,
}