import React from "react";
import { WorksheetComponent, modifyArray } from "../../Common";
import { PageName, Worksheet, RecordAction, WorksheetType, Question, User } from "../../my_interfaces";
import QuestionManagerPage, { QuestionEntryPage } from "./Admin";

export class WorksheetManagerPage extends React.Component {
	props!:{
		setPage:(page:PageName)=>void,//not sure this is needed but passing through for the sake of view worksheet I think. 
		globaluser:User, //		globaluser:User|null|undefined, - shouldn't really be null or undefined for this
	}
	state:{
		worksheets:Worksheet[],
		action:string,
		target_W_ID:number|null,
	//	target_worksheet?:Worksheet,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state={
			worksheets:[],
			action:'',
			target_W_ID:null,
		}
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleCreateNew = this.handleCreateNew.bind(this);
		this.exitLowerPanel = this.exitLowerPanel.bind(this);
	}
	async componentDidMount(){
		let worksheets = await fetchallworksheets(true);
		this.setState({worksheets});	//	OLD: fetch("/worksheets").then(response => response.json()).then(js_obj => this.setState({worksheets:js_obj}));
	}
	handleButtonClick(e: React.MouseEvent<HTMLButtonElement>,W_ID:number,action:RecordAction,index:number){
		console.log("Button to "+action+" W with ID: "+W_ID+" pressed");
		if (action==="view"||action==="edit"){
			this.setState({target_W_ID:W_ID, action:action});
		}
		else if (action==="copy"||action==="delete"){
			console.log("Number of worksheets when button pressed is "+this.state.worksheets.length);
			var url = '/modifyRecord';
			var data = {action:action,
						ID:W_ID,//using record instead of 'worksheet' as trying to build generic function on App.js side.
						record_type:"worksheet",
						record:this.state.worksheets[index],
						};
			fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
			.then(res => res.json())
			.then(result => 
				{	if(action==="copy"){
						console.log("W_ID of copied question is: "+JSON.parse(result.ID));
						this.setState({target_W_ID:JSON.parse(result.ID),action:""});
					} else if (action==="delete"){
						console.log("Worksheet with W_ID: "+JSON.parse(result.ID)+" deleted");
						this.setState({target_W_ID:null,action:""});
					} else {console.log("action not recognised")};
				}
			)
			.then(reply=>fetchallworksheets())
			.then(worksheets=>this.setState({worksheets}))
			.catch(error => console.error('Error:', error));
			console.log("should have a comment about deleting or copying around here");
		} 
		
		else {
			console.log("could not find action");
			throw "could not find action";
		}
		//console.log("2number of questions is"+this.state.questions.length+"before copying");
		//this.fetchquestions();
		//console.log("3number of questions is"+this.state.questions.length+"after copying");
	}
	handleCreateNew(name:string){
		var url = '/modifyRecord';
		var data = {action:"createnew",
					record_type:"worksheet",
					record:{name},
					};
		fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
		.then(res => res.json())
		.then(result => {	
			console.log("W_ID of created question is: "+JSON.parse(result.ID));
			this.setState({target_W_ID:JSON.parse(result.ID),action:""});
		})
		.then(reply=>fetchallworksheets())
		.then(worksheets=>this.setState({worksheets}))
		.catch(error => console.error('Error:', error));
		console.log("should have a comment about deleting or copying around here");
	}
	exitLowerPanel(e:React.MouseEvent<HTMLButtonElement>){
		e.preventDefault();
		this.setState({action:""});
	}
	render(){
		var lowerpanel = <p>There has been an error</p>
		if(this.state.action==="view"){
			if(this.state.target_W_ID!==null&&this.state.target_W_ID!==undefined){
				//console.log("Calling QuestionpagebyID with ID: "+ this.state.target_W_ID);
			lowerpanel = <WorksheetComponent 
				worksheet_ID={this.state.target_W_ID}
				//setPage={this.props.setPage}
				globaluser={this.props.globaluser}
			/>
			//<p>here will go the viewing of a worksheet</p>//<QuestionpagebyID Q_ID={this.state.target_W_ID} action="view"/>
			//console.log("Just called QuestionpagebyID");
			}else{
				throw "Action set to view question but no Q_ID provided"
			}
		}else if(this.state.action==="edit"){
			console.log("Edit => WorksheetManagerPage renders WorksheetEditorPage with ID: "+ this.state.target_W_ID);
			console.log("The number of worksheets is"+this.state.worksheets.length);
			let chosenworksheet = this.state.worksheets.find(w=>w.ID===this.state.target_W_ID);
			if(chosenworksheet!==undefined&&chosenworksheet!==null){
				lowerpanel = <WorksheetEditorPage worksheet={chosenworksheet}/>
			}else throw "Tried to open WorksheetEditorPage without a valid worksheet"
			/* 			<p>worksheet editor will appear here</p>

				<WorksheetEditorPage
					W_ID={this.state.target_W_ID}
					action="edit"
					//name={this.state.questions[this.state.target_W_ID].name}
					//name={this.targetquestionname()}
					//field_elements={this.state.questions[this.state.target_W_ID].field_elements}
					//field_elements={this.targetquestionfield_elements()}
					//onSubmit={this.fetchquestions}
					//key={this.state.target_W_ID===undefined?-1:this.state.target_W_ID}
				/>
				*/
			//console.log("Since state.action is edit, just called QuestionEntryPage with ID");
		} else {
			lowerpanel = <p>Use the buttons in the table above to view, edit or delete questions</p>
		}
		return(
			<div className={"admin_page"}>
				<span hidden={this.state.action==="edit"}>
					<h2>Worksheet manager</h2>
					<WorksheetTable worksheets={this.state.worksheets} handleButtonClick={this.handleButtonClick}/>
					<WorksheetCreator handleCreateNew={this.handleCreateNew}/>
					<hr/>
				</span>
				<button 
					style={{display:(this.state.action!=="edit")?"none":"block"}} 
					className="button innavigationbar float" 
					onClick={this.exitLowerPanel}>Back</button>
				{lowerpanel}
			</div>
		)
	}
}
class WorksheetCreator extends React.Component {
	props!:{
		handleCreateNew:(name:string)=>void,
	}
	state:{
		showform:boolean,
		worksheet_name:string,
		worksheet_saved:boolean,
	}
	constructor(props: Readonly<{}>){
		super(props);
		this.state = {
			showform:false,
			worksheet_name:'',
			worksheet_saved:false,
		};
		this.handleToggle = this.handleToggle.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	handleToggle(e:React.MouseEvent<HTMLButtonElement>){
		e.preventDefault();
		console.log("handleToggle");
		this.setState({showform:!this.state.showform});
	}
	handleChange(e:React.ChangeEvent<HTMLInputElement>){
		console.log("handleChange");
		this.setState({worksheet_name:e.target.value})
	}
	handleClick(e:React.FormEvent<HTMLButtonElement>){
		e.preventDefault();
		this.props.handleCreateNew(this.state.worksheet_name);
	}
	handleSubmit(e:React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		this.props.handleCreateNew(this.state.worksheet_name);
	}
	render(){
		return(
			<span>
				<button className="button" onClick={this.handleToggle}>Create Worksheet</button>
				<div hidden = {!this.state.showform}>
					<form onSubmit={this.handleSubmit}>
						<input 
							type="text area"
							value = {this.state.worksheet_name}
							onChange = {this.handleChange}
							placeholder = "Name of worksheet."
							>
						</input>
					</form>
					<button onClick={this.handleClick}
					>Submit</button>
					<br/>
					<span hidden={!this.state.worksheet_saved}>Worksheet created</span>
				</div>
			</span>
		)
	}
}
class WorksheetTable extends React.Component {
	props!:{
		worksheets:Worksheet[],
		handleButtonClick:(e: React.MouseEvent<HTMLButtonElement>,W_ID:number,action:RecordAction,index:number)=>void,
	}
	render(){
		return(
			<div>
				<table>
					<WorksheetTableHead/>
					<WorksheetTableBody worksheets={this.props.worksheets} handleButtonClick={this.props.handleButtonClick}/>
				</table>
			</div>
		)
	}
}
class WorksheetTableHead extends React.Component {
	render(){
		return(
			<thead>
				<tr>
					<th>ID</th>
					<th>Name</th>
					<th>View</th>
					<th>Edit</th>
					<th>Copy</th>
					<th>Delete</th>
					<th>Status</th>
				</tr>
			</thead>
		)
	}
}
class WorksheetTableBody extends React.Component {
	props!:{
		worksheets:Worksheet[],
		handleButtonClick:(e: React.MouseEvent<HTMLButtonElement>,W_ID:number,action:RecordAction,index:number)=>void,
	}
	render(){
		let worksheet_table_rows = [];
		for(let i=0;i<this.props.worksheets.length;i++){
			let W_ID = this.props.worksheets[i].ID;
			worksheet_table_rows[i] = 
				<tr key={i}>
					<th>{W_ID}</th>
					<th>{this.props.worksheets[i].name}</th>
					<th><button
							name={"view"} onClick={e=>this.props.handleButtonClick(e,W_ID,"view",i)}
							>__</button></th>
					<th><button 
							name={"edit"} onClick={e=>this.props.handleButtonClick(e,W_ID,"edit",i)}
							>__</button></th>
					<th><button 
							//name={"assign"} onClick={e=>this.props.handleButtonClick(e,W_ID,"assign",i)}
							name={"copy"} onClick={e=>this.props.handleButtonClick(e,W_ID,"copy",i)}
							>__</button></th>
					<th><button 
							name={"delete"} onClick={e=>this.props.handleButtonClick(e,W_ID,"delete",i)}
							>__</button></th>
					<th>{this.props.worksheets[i].info.status}</th>
				</tr>
		}
		return(
			<tbody>
				{worksheet_table_rows}
			</tbody>
		)
	}
}
class Worksheet_filters extends React.Component {
	render(){
		return(
			<div>
				<h3>Worksheet filters</h3>
			</div>
		)
	}
}
class WorksheetEditorPage extends React.Component{
	props!:{
//		action:string,//edit or createnew
		worksheet:Worksheet,
	//	onSubmit?:()=>void,
	}
//	static defaultProps = {
//		action:"edit", //createnew is used in the separate componenet WorksheetCreator
//	}
	state:{
		timeout:any,
		worksheet:Worksheet,
		newquestionpanel:string,
	}
	constructor(props: WorksheetEditorPage["props"]){//why do I have to props again
		super(props);
		this.state={
			timeout:null,
			worksheet:props.worksheet,
			newquestionpanel:'',
			};
		this.onSubmit = this.onSubmit.bind(this);
		this.onChangetitle= this.onChangetitle.bind(this);
		this.onChangeStatus= this.onChangeStatus.bind(this);
		this.handleButtonClick= this.handleButtonClick.bind(this);
		this.addQuestion= this.addQuestion.bind(this);
		this.timeouttoSubmit= this.timeouttoSubmit.bind(this);
//		console.log("QuestoinEntryPage has props.Q_ID: "+props.Q_ID+" no state Q_ID");
	}
/*	componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
		if(prevProps!==this.props){
			this.setState({
				name:this.props.name,
				field_elements:	this.props.field_elements,
			})
		}
	}	*/
	onChangetitle(e: React.ChangeEvent<HTMLInputElement>){
		let worksheettemp = this.state.worksheet;
		worksheettemp.name = e.target.value;
		this.setState({worksheet:worksheettemp});
		this.timeouttoSubmit();//TODO all of these functions should actually start a timeout, and then only call on submit if there has not been a change for a second. 
	}
	onChangeStatus(e: React.ChangeEvent<HTMLSelectElement>){
		let worksheettemp = this.state.worksheet;
		worksheettemp.info.status = e.target.value;
		this.setState({worksheet:worksheettemp});
		this.timeouttoSubmit();
	}
	onChangeType(value:WorksheetType){//e: React.ChangeEvent<HTMLSelectElement>){
		let worksheettemp = this.state.worksheet;
		worksheettemp.type = value;//e.target.value;
		this.setState({worksheet:worksheettemp});
		this.timeouttoSubmit();
	}
	timeouttoSubmit(){
		clearTimeout(this.state.timeout);
		this.setState({
			timeout:setTimeout(
				()=>{	//console.log(''+JSON.stringify(,null,4));
				this.onSubmit()
			}, 1000 //wait 1000 milliseconds
			)
		});
	}
	onSubmit(){
		console.log('submitting W with ID: '+this.props.worksheet.ID+' name: '+this.state.worksheet.name);
		var url = '/modifyRecord';
		var data = {action:"edit",//this.props.action,
					record:this.state.worksheet,//using recor dinstead of 'worksheet' as trying to build generic function on App.js side.
					record_type:"worksheet",
					ID:this.props.worksheet.ID,
					};
		fetch(url, {method: 'POST',body: JSON.stringify(data),headers:{'Content-Type': 'application/json'}})
		.then(res => res.json())
		.then(response => {console.log('Success submitting W:'+ JSON.stringify(response))})
		.catch(error => console.error('Error:', error));
	}
	handleButtonClick(Q_ID:number,action:string,Q_index:number){
		console.log("WorksheetEditorPage>handleButtonClick: "+action+" "+Q_index);
		let new_Q_IDs:number[] = [];
		new_Q_IDs = new_Q_IDs.concat(this.state.worksheet.Q_IDs);//so that we aren't modifying the old state directly?
		let Q_IDs = modifyArray(new_Q_IDs,Q_index,action);
		let worksheettemp = this.state.worksheet;
		worksheettemp.Q_IDs = Q_IDs;
		this.setState({worksheet:worksheettemp});
		this.timeouttoSubmit();
	}
	addQuestion(Q_ID:number){
		console.log("addQuestion WorksheetEditorPage called Q_ID: "+Q_ID);
		let Q_IDstemp:number[] = [];
		Q_IDstemp = Q_IDstemp.concat(this.state.worksheet.Q_IDs);
		Q_IDstemp.push(Q_ID);
		this.setState({worksheet:{
			...this.state.worksheet,
			Q_IDs:Q_IDstemp,
		}});
		this.onSubmit();
	}
	render(){
		console.log("render WorksheetEditorPage with worksheet: "+this.state.worksheet);
		//let concepts = [];
		let newquestionpanel = []
		if(this.state.newquestionpanel==="create"){
			newquestionpanel[0] = <QuestionEntryPage key={1} addQuestion={this.addQuestion}/>;
		}else if (this.state.newquestionpanel==="add"){
			newquestionpanel[0] = <QuestionSelectorPage key={1} Q_IDs={this.state.worksheet.Q_IDs} addQuestion={this.addQuestion}/>;
		}else if (this.state.newquestionpanel===""){
			newquestionpanel[0] = <span key={1}></span>;
		}
		return(//*** could put this inside the QuestionEntryBody and it runs on component did mount and changes the constructor value of ["concepts loading"] to the actual concepts
			<div>
				<span>
					<h2>Worksheet Editor</h2>
					<input type="text" onChange={this.onChangetitle} value={this.state.worksheet.name}/>
					<label hidden>Status:</label>
						<select name="worksheetstatus" value={this.state.worksheet.info.status} onChange={this.onChangeStatus}>
							<option value="live">hidden</option>
							<option value="public">public</option>
						</select> 
					<label hidden>Type:</label>
					<select name="worksheettype" value={this.state.worksheet.type} onChange={(e)=>this.onChangeType(e.target.value as WorksheetType)}>
						<option value="original">original</option>
						<option value="flashcards">flashcards</option>
						<option value="assessment">assessment</option>
					</select> 
				</span>
				<QuestionManagerPage 
					addQuestion={this.addQuestion} 
					inworksheeteditor={true} 
					Q_IDs={this.state.worksheet.Q_IDs} 
					handleButtonClick={this.handleButtonClick}
				/>
				<div>
					<button onClick={(e)=>this.setState({newquestionpanel:"create"})}>Create question</button>
					<button onClick={(e)=>this.setState({newquestionpanel:"add"})}>Select quesiton to add</button>
					<button onClick={(e)=>this.setState({newquestionpanel:""})}>Close new question panel</button>
					<button onClick={(e)=>{e.preventDefault(); this.onSubmit()}}>Submit worksheet</button>
					{newquestionpanel}
				</div>
				<hr/>
				<h2>
					View of worksheet
				</h2>
				<p>
					A view of the worksheet will be visible here
					question=
						Q_ID:this.props.worksheet.ID,
						name:this.state.name,
						field_elements:this.state.field_elements,
						info:status:"draft",creator:null
					action="view"
				</p>
			</div>
		)//				

	}
}

class QuestionSelectorPage extends React.Component{
	props!:{
		Q_IDs:number[],
		addQuestion:(Q_ID:number)=>void,
		key:number,
	}
	static defaultProps = {
		key:-2,
	}
	state:{
		questions:Question[],
		numUsed:number[]
	}
	constructor(props:Readonly<QuestionSelectorPage['props']>){
		super(props);
		this.state = {
			questions:[],
			numUsed:[]
		};
		this.fetchquestionlist = this.fetchquestionlist.bind(this);
		this.countUsedQuestions = this.countUsedQuestions.bind(this);
	}
	componentDidMount(){
		console.log("QuestionSelectorPage Did Mount");
		this.fetchquestionlist();
		//this.countUsedQuestions(); put this in the above function so I could use then easily.
    }
    componentDidUpdate(prevProps: this["props"], prevState: this["state"]){
        console.log("QuestionSelectorPage componentDidUpdate called");
        console.log("props: "+JSON.stringify(this.props.Q_IDs,null,4));
        console.log("prevProps: "+JSON.stringify(prevProps.Q_IDs,null,4));
        if(prevProps.Q_IDs!=this.props.Q_IDs){
			console.log("props found to have changed, so fetch questions");
            this.fetchquestionlist();
        } else {console.log("props were found to be unchanged")}
    }
    async fetchquestionlist(){
        console.log("fetching questions");
		fetch("/questionlist", {method:'POST'})
		.then(response => response.json())
		.then(js_obj => this.setState({questions:js_obj}))
		.then(wtf => console.log("After fetching, question number is"+this.state.questions.length+"after copying"))
		.then(dontcare => this.countUsedQuestions());
		console.log("It just tried to fetch questions but is probably still doing that");
	}
	countUsedQuestions(){
		console.log("countUsedQuestions called");
		let numUsedtemp:number[] = [];
		let Q_IDs:number[] = [];
		Q_IDs = Q_IDs.concat(this.props.Q_IDs);
		for(let q in this.state.questions){
			let usedlist = Q_IDs.filter(Q_ID=>Q_ID===this.state.questions[q].Q_ID);
			numUsedtemp[q] = usedlist.length;
		}
		this.setState({numUsed:numUsedtemp});
	}
	render(){
		console.log("render QuestionSelectorPage");
		return(
			<div>
				<table>
					<QuestionSelectorTableHeader/>
					<QuestionSelectorTableBody 
						questions={this.state.questions} 
						addQuestion={this.props.addQuestion}
						numUsed={this.state.numUsed}
					/>
				</table>
			</div>
		)
	}
}
class QuestionSelectorTableHeader extends React.Component {
    props!:{
    }
    render(){
		console.log("render QuestionSelectorTableHeader");
        return(
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Add</th>
                    <th># in ws</th>
                </tr>
            </thead>
        )
    }
}
class QuestionSelectorTableBody extends React.Component {
    props!:{
		questions:Question[],
		numUsed:number[],
        addQuestion:(Q_ID:number)=>void,
    }
    render(){
		console.log("render QuestionSelectorTableBody");
		let question_table_rows = [];
        let rowcount=-1;
        for(let i=0;i<this.props.questions.length;i++){
            if(true){//this.props.questions[i].info.status!=="deleted"){TODO //hidden
                rowcount++;
                question_table_rows[rowcount] = 
                    <tr key={i}>
                        <th>{this.props.questions[i].Q_ID}</th>
                        <th>{this.props.questions[i].name}</th>
                        <th><button onClick={e=>{e.preventDefault(); this.props.addQuestion(this.props.questions[i].Q_ID)}}>__</button></th>
                        <th>{this.props.numUsed[i]}</th>
					  {//TODO Need to add view mode here, can be included in this component  <th><button onClick={e=>this.props.handleButtonClick(e,this.props.questions[i].Q_ID,"view",i)}>__</button></th>
					  }
                    </tr>
            }
        }
        return(
            <tbody>
                {question_table_rows}
            </tbody>
        )
    }
}

async function fetchallworksheets(inclassessments?:boolean): Promise<Worksheet[]> {//gets all worksheets from the server
	if(inclassessments===true){
		let response = await fetch("/allworksheets"); //something dodgy here, why is this the same as below. Maybe this is only called by admins.
		let worksheets = await response.json();
		return worksheets;
	} else {
		let response = await fetch("/allworksheets");
		let worksheets = await response.json();
		return worksheets;
	}
}